import { createTeleporter } from 'react-teleporter'

const HeaderNotification = createTeleporter()

export function HeaderNotificationTarget() {
  return <HeaderNotification.Target as={'div'} />
}

export interface HeaderNotificationSourceProps {
  children: any
}

export function HeaderNotificationSource(props: HeaderNotificationSourceProps) {
  const { children } = props
  return (
    <HeaderNotification.Source>
      <div style={{ padding: '0.7em' }}>{children}</div>
    </HeaderNotification.Source>
  )
}
