const INJECT = ['resourceId', 'documentId', 'versionId', 'templateId'] as const
type KEYS = (typeof INJECT)[number]

type DataInjection = {
  [key in KEYS]: string | undefined
}

// Gets the set of data injected into the docx file
export function useDataInjection() {
  if (!Office?.context?.document?.settings) return {} as DataInjection

  const { get } = Office.context.document.settings
  const inj = {} as DataInjection

  return INJECT.reduce((inj, key) => ({ ...inj, [key]: get(key) }), inj)
}

export async function dataInject(data: Partial<DataInjection>) {
  const { set, saveAsync } = Office.context.document.settings

  return new Promise<void>((resolve, reject) => {
    Object.entries(data).map(([key, val]) => set(key, val))

    saveAsync(({ status, error }) => {
      if (status === Office.AsyncResultStatus.Succeeded) return resolve()

      console.warn(`Failed to data inject: ${status}`)
      console.error(error)
      return reject(error)
    })
  })
}
