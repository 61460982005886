import { useState, useContext, useEffect } from 'react'

import { type ContractMetadata } from '@blaw/contracts-api-schema'
import {
  contractFormFieldDefaults,
  RequiredFields,
  type ContractField,
  type ContractFormFields,
} from '@modules/Contract'
import { ContractContext } from '@contexts/ContractContext'
import { IChoiceGroupOption } from '@fluentui/react'
import { CustomFieldValueType } from '@components/ContractCustomFields'

export type FormInputOnChange = (
  e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | string,
  value: string | string[] | CustomFieldValueType | undefined,
) => void

// Used by form components to handle validation and updating of form data.
// Assumes the form inputs have name attributes.
export default function useContractFormData(
  data: Partial<ContractFormFields>,
  required: Partial<ContractField[]> = RequiredFields,
  setHidden: React.Dispatch<React.SetStateAction<boolean>> = () => null,
) {
  const { loadContract } = useContext(ContractContext)
  const initialData = {
    ...contractFormFieldDefaults,
    ...data,
  }

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<ContractFormFields>(initialData)
  const [valid, setValid] = useState(false)
  const [formError, setFormError] = useState<string | null>(null)
  const [selectedStatusIdx, setSelectedStatusIdx] = useState(formData.statusIdx || '')

  useEffect(() => {
    const updatedFormData = { ...formData, statusIdx: selectedStatusIdx }
    setFormData(updatedFormData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatusIdx])

  const isValid = (fields: ContractFormFields) => {
    return required.every(key => key && fields[key]?.toString().trim().length)
  }

  function updateFirstDraftOrigin(
    _e: React.FormEvent<HTMLInputElement | HTMLElement> | undefined,
    option: IChoiceGroupOption | undefined,
  ): void {
    const newData = { ...formData, firstDraftOrigin: option?.key || '' }
    setFormData(newData)
    setValid(isValid(newData))
  }

  function updateAccess(
    _e: React.FormEvent<HTMLInputElement | HTMLElement> | undefined,
    option: IChoiceGroupOption | undefined,
  ): void {
    const newData = { ...formData, access: option?.key || '' }
    setFormData(newData)
    setValid(isValid(newData))
  }

  const change: FormInputOnChange = (e, value) => {
    const key = typeof e === 'string' ? e : e.currentTarget.name
    const newData = { ...formData, [key]: value }

    setValid(isValid(newData))
    setFormData(newData)
  }

  const updateComboboxField = (name: string, value?: string | string[]) => {
    const newData = { ...formData, [name]: value }
    setValid(isValid(newData))
    setFormData(newData)
  }

  const reset = () => {
    setFormData(initialData)
    setValid(false)
    setSelectedStatusIdx(formData.statusIdx || '')
    setFormError(null)
  }

  const handleEditContractFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    metadata: ContractMetadata,
    name: string | undefined,
    submit: (...args: any) => Promise<any>,
  ): Promise<void> => {
    try {
      if (!name) throw Error('Invalid form data')
      setLoading(true)
      await submit(JSON.stringify({ name, metadata, resourceType: 'contract' }), e)
      loadContract()
      setHidden(true)
    } catch (e) {
      console.error(e)
      setFormError(`Failed to update Contract: ${(e as Error).message}`)
    }
    setLoading(false)
  }

  return {
    valid,
    change,
    updateComboboxField,
    formData,
    setFormData,
    reset,
    handleEditContractFormSubmit,
    formError,
    updateFirstDraftOrigin,
    updateAccess,
    selectedStatusIdx,
    setSelectedStatusIdx,
    loading,
  }
}
