import React, { useState, useContext, Dispatch, SetStateAction } from 'react'
import { AxiosResponse, AxiosError } from 'axios'

import ClauseAnalyzer, { FilterState, SimilarClausesResponse } from '@modules/ClauseAnalyzer'
import { AnalysisContext } from '@contexts/AnalysisContext'
import { PaginationMeta } from './usePagination'
import { searchResultsViewed } from '@modules/analytics'

const maxRetry = 10
const pollInterval = 200

// Handles getting the document selection and polling the
// clause analysis endpoint for results
export default function useSimilarClauses(
  analysisType: string,
  filterState: FilterState,
  setFilterState: React.Dispatch<React.SetStateAction<FilterState>>,
  hitsPerPage: number,
) {
  const { selection } = useContext(AnalysisContext)
  const [items, setItems] = useState<SimilarClausesResponse>({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [analyzer, setAnalyzer] = useState(new ClauseAnalyzer(selection, analysisType))

  return { error, loading, items, selection, runAnalysis, loadNextPage, loadPage }

  async function loadNextPage(pageNum: number) {
    await analyzeAndGetSimilar(selection, analyzer, pageNum)
  }

  async function loadPage(shouldTriggerNewAnalysis: boolean) {
    return shouldTriggerNewAnalysis ? await runAnalysis() : await applyFilters()
  }

  function appendNewItems(newItems: SimilarClausesResponse) {
    const itemsCopy = JSON.parse(JSON.stringify(items))
    itemsCopy.items
      ? (itemsCopy.items = [...itemsCopy.items, ...(newItems.items || [])])
      : (itemsCopy.items = newItems.items)
    return itemsCopy
  }

  async function applyFilters(pageNum = 0): Promise<PaginationMeta> {
    return await analyzeAndGetSimilar(selection, analyzer, pageNum, true)
  }

  async function runAnalysis(pageNum = 0): Promise<PaginationMeta> {
    const analyzer = new ClauseAnalyzer(selection, analysisType)
    setAnalyzer(analyzer)
    setFilterState({})
    filterState = {}
    return await analyzeAndGetSimilar(selection, analyzer, pageNum)
  }

  async function analyzeAndGetSimilar(
    selection: string,
    analyzer: ClauseAnalyzer,
    pageNum: number,
    usingFacets = false,
  ) {
    if (selection?.length === 0) return { totalPages: 0, totalHits: 0 }
    setError(null)
    setLoading(true)
    setItems({})
    await analyzer.putSimilarClauses()
    const paginationMeta = await fetchSimilar(analyzer, pageNum)

    searchResultsViewed({
      isLoggedIn: true,
      pageTitle: 'Similar Clauses',
      searchScope:
        analysisType === 'EDGAR' ? 'Publicly Filed Contracts' : 'Internal Company Clauses',
      searchAction: !usingFacets ? 'initial search' : 'refine',
      resultsCount: paginationMeta.totalHits || 0,
      searchTermsExist: !!paginationMeta.totalHits,
      searchTool: 'feature search',
      searchType: 'feature search',
    })

    setLoading(false)
    return paginationMeta
  }

  async function fetchSimilar(analyzer: ClauseAnalyzer, pageNum: number): Promise<PaginationMeta> {
    try {
      const { data } = await poll(async () => analyzer.getSimilarClauses(filterState, pageNum))
      const s: SimilarClausesResponse = data.similarClauses
      if (!s.type) {
        s.type = analyzer.analysisType
      }

      pageNum > 0 ? setItems(appendNewItems(s)) : setItems(s)

      const totalPages = s.count ? Math.ceil(s.count / hitsPerPage) : 0
      const totalHits = s.count || 0
      return { totalPages, totalHits }
    } catch (e) {
      handlerError(e, setError)
      return { totalPages: 0, totalHits: 0 }
    }
  }

  async function poll(getSimilar: () => Promise<AxiosResponse<any>>): Promise<any> {
    let retry = maxRetry

    return new Promise((resolve, reject) => {
      setTimeout(doPoll, pollInterval)

      async function doPoll() {
        const response = await getSimilar()
        if (response.status === 200) resolve(response)
        else if (response.status === 204 && retry) setTimeout(doPoll, pollInterval)
        else reject(response)
        retry--
      }
    })
  }
}

function handlerError(e: any, setError: Dispatch<SetStateAction<string | null>>) {
  if ((e as AxiosError).status === 204) return console.debug('No similar clauses', e)

  console.error(e)
  setError((e as Error)?.message ?? 'An error occurred')
}
