import { MetadataTypes } from '@blaw/contracts-api-schema'

export const indemnificationObligation = {
  obligations: {
    'OB-027-INDEMNIFICATION': [
      {
        code: 'OB-027-INDEMNIFICATION',
        data_type: '',
        is_deleted: false,
        type: '',
        value: 'Value for Indemnification',
        status: '',
        reference: 'Text Block for Auto Renew',
        offsets: [],
        label: '',
        schemaKey: 'indemnification',
        schemaType: MetadataTypes.BOOLEAN,
        schemaLabel: 'Indemnification',
      },
    ],
  },
}

export const confidentialInfoObligation = {
  obligations: {
    'OB-013-DEFINITION-OF-CONFIDENTIAL-INFORMATION': [
      {
        code: 'OB-013-DEFINITION-OF-CONFIDENTIAL-INFORMATION',
        data_type: '',
        is_deleted: false,
        type: '',
        value: 'New Value',
        status: '',
        reference: 'Text Block for Confidential Information',
        offsets: [],
        label: '',
        schemaKey: 'confidential_info',
        schemaType: MetadataTypes.TEXT,
        schemaLabel: 'Confidential Information',
      },
    ],
  },
}

export const autoRenewObligation = {
  obligations: {
    'OB-012-AUTO-RENEW': [
      {
        code: 'OB-012-AUTO-RENEW',
        data_type: '',
        is_deleted: false,
        type: '',
        value: 'Value for Auto Renew',
        status: '',
        reference: 'Text Block for Auto Renew',
        offsets: [],
        label: '',
        schemaKey: 'auto_renew',
        schemaType: MetadataTypes.BOOLEAN,
        schemaLabel: 'Auto Renew',
      },
    ],
  },
}

export const twoObligations = {
  obligations: {
    'OB-013-DEFINITION-OF-CONFIDENTIAL-INFORMATION': [
      {
        code: 'OB-013-DEFINITION-OF-CONFIDENTIAL-INFORMATION',
        data_type: '',
        is_deleted: false,
        type: '',
        value: 'New Value',
        status: '',
        reference: 'Text Block for Confidential Information',
        offsets: [],
        label: '',
        schemaKey: 'confidential_info',
        schemaType: MetadataTypes.TEXT,
        schemaLabel: 'Confidential Information',
      },
    ],
    'OB-014-LICENSE-GRANT': [
      {
        code: 'OB-014-LICENSE-GRANT',
        data_type: '',
        is_deleted: false,
        type: '',
        value: 'New Value for License Grant',
        status: '',
        reference: 'Text Block for License Grant',
        offsets: [],
        label: '',
        schemaKey: 'license_grant',
        schemaType: MetadataTypes.TEXT,
        schemaLabel: 'License Value',
      },
    ],
  },
}

export const mockObligations = {
  obligations: {
    'OB-012-AUTO-RENEW': [
      {
        code: 'OB-012-AUTO-RENEW',
        data_type: '',
        is_deleted: false,
        type: '',
        value: 'Value for Auto Renew',
        status: '',
        reference: 'Text Block for Auto Renew',
        offsets: [],
        label: '',
        schemaKey: 'auto_renew',
        schemaType: MetadataTypes.BOOLEAN,
        schemaLabel: 'Auto Renew',
      },
    ],
    'OB-009-KEY-PAYMENT-AMOUNT': [
      {
        code: 'OB-009-KEY-PAYMENT-AMOUNT',
        data_type: '',
        is_deleted: false,
        type: '',
        value: 'Value for Key Payment Amount',
        status: '',
        reference: 'Text Block for Key Payment Amount',
        offsets: [],
        label: '',
        schemaKey: 'key_payment_amount',
        schemaType: MetadataTypes.CURRENCY,
        schemaLabel: 'Key Payment Amount',
      },
    ],
    'OB-010-KEY-PAYMENT-DATE': [
      {
        code: 'OB-010-KEY-PAYMENT-DATE',
        data_type: '',
        is_deleted: false,
        type: '',
        value: 'Value for Key Payment Date',
        status: '',
        reference: 'Text Block for Key Payment Date',
        offsets: [],
        label: '',
        schemaKey: 'key_payment_date',
        schemaType: MetadataTypes.DATE,
        schemaLabel: 'Key Payment Date',
      },
    ],
    'OB-006-KEY-TERM-DURATION': [
      {
        code: 'OB-006-KEY-TERM-DURATION',
        data_type: '',
        is_deleted: false,
        type: '',
        value: 'Value for Contract Duration',
        status: '',
        reference: 'Text Block for Contract Duration',
        offsets: [],
        label: '',
        schemaKey: 'key_term_duration',
        schemaType: MetadataTypes.DURATION_MONTHS,
        schemaLabel: 'Contract Duration',
      },
    ],
    'OB-041-RENEWAL-NOTICE-DURATION': [
      {
        code: 'OB-041-RENEWAL-NOTICE-DURATION',
        data_type: '',
        is_deleted: false,
        type: '',
        value: 'Value for Renewal Notice Duration',
        status: '',
        reference: 'Text Block for Renewal Notice Duration',
        offsets: [],
        label: '',
        schemaKey: 'renewal_notice_duration',
        schemaType: MetadataTypes.DURATION_DAYS,
        schemaLabel: 'Renewal Notice Duration',
      },
    ],
    'OB-013-DEFINITION-OF-CONFIDENTIAL-INFORMATION': [
      {
        code: 'OB-013-DEFINITION-OF-CONFIDENTIAL-INFORMATION',
        data_type: '',
        is_deleted: false,
        type: '',
        value: 'Value for Confidential Information',
        status: '',
        reference: 'Text Block for Confidential Information',
        offsets: [],
        label: '',
        schemaKey: 'confidential_info',
        schemaType: MetadataTypes.TEXT,
        schemaLabel: 'Confidential Information',
      },
    ],
    GOVERNING_LAW: [
      {
        code: 'GOVERNING_LAW',
        data_type: '',
        is_deleted: false,
        type: '',
        value: 'Value for Governing Law',
        status: '',
        reference: 'Text Block for Governing Law',
        offsets: [],
        label: '',
        schemaKey: 'governing_law',
        schemaType: MetadataTypes.CHOICE_TEXT,
        schemaLabel: 'Governing Law',
      },
    ],
  },
}
