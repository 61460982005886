import { Depths, Icon } from '@fluentui/react'
import styled from 'styled-components'

export interface IconBadgeProps {
  iconName: string
  iconColor: string
  text: string
  badgeColor: string
  style?: any
}

const StyledSpan = styled.span<IconBadgeProps>`
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
  width: fit-content;
  border-radius: 4px;
  boxshadow: ${Depths.depth8};
  background-color: ${props => props.badgeColor};
  border: 1px solid ${props => props.badgeColor};
`

export const IconBadge: React.FC<IconBadgeProps> = (props: IconBadgeProps) => {
  return (
    <StyledSpan {...props}>
      <Icon
        iconName={props.iconName}
        data-testid={props.iconName}
        style={{
          color: props.iconColor,
          padding: '0em 0.5em 0em 0em',
        }}
      />
      {props.text}
    </StyledSpan>
  )
}
