import { MIMETYPE_DOCX } from '@blaw/contracts-api-schema'
import ApiClient from '@modules/ApiClient'
import Routes from './routes'
import { b64Encode } from './utils'

const apiClient = new ApiClient()
const routes = new Routes()

export default async function getCompareVersions(
  originalContractId: string,
  originalDocumentId: string,
  originalDocumentVersion: string,
  modifiedContractId: string,
  modifiedDocumentId: string,
  modifiedDocumentVersion: string,
  outputType: string,
) {
  const params = {
    originalContractId,
    originalDocumentId,
    originalDocumentVersion,
    modifiedContractId,
    modifiedDocumentId,
    modifiedDocumentVersion,
    outputType,
  }

  const contentUrl = await apiClient.get<{ url: string }>(routes.compareVersionsUrl, { params })

  const response = await apiClient.getBinaryContent<string>(contentUrl.data.url)
  const content = response.data
  const documentType = response.headers['content-type']

  if (documentType !== MIMETYPE_DOCX) throw new Error('Document type not supported')
  console.log(documentType)

  return await Word.run(async context => {
    const encoded = b64Encode(content)
    const doc = context.application.createDocument(encoded)
    context.load(doc)
    doc.open()
    await context.sync()
  })
}
