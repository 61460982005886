import { IToggleStyles, List, SharedColors, Stack, Toggle } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

import { isPrivate } from '@blaw/contracts-api-schema'
import LoadingShimmer from '@components/LoadingShimmer'
import Contract from '@modules/Contract'
import ResourceListHeadings from '@components/ResourceListHeadings'
import TextWithLabel from '@components/TextWithLabel'
import ContractsCard from '@components/ContractsCard'
import StatusBadge from '@components/StatusBadge'
import { Dispatch, SetStateAction, useContext } from 'react'
import HighlightedText from '@baseComponents/HighlightedText'
import IconBadge from '@components/IconBadge'
import { accessDisplayData } from '@modules/accessDisplayData'
import { getSession } from '@modules/SessionInfoStorage'
import { StoreContext } from '@contexts/StoreContext'

const toggleStyles: Partial<IToggleStyles> = {
  root: { marginBottom: '0' },
  label: { marginLeft: '0.5em' },
}

export interface ContractsListProps {
  contracts: Contract[]
  loading?: boolean
  isDocCardExpanded: boolean
  setIsDocCardExpanded: Dispatch<SetStateAction<boolean>>
  isViewingDocuments: boolean
}

const ContractsList: React.FunctionComponent<ContractsListProps> = ({
  loading,
  contracts,
  isDocCardExpanded,
  setIsDocCardExpanded,
  isViewingDocuments,
}: ContractsListProps) => {
  const { t } = useTranslation()
  const userInfo = getSession()
  const { isResourceAccessEnabled, access } = useContext(StoreContext)

  if (loading) return <LoadingShimmer />

  return (
    <div style={{ marginBottom: '2em' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ResourceListHeadings
          label={
            isViewingDocuments ? t('label.contracts-search-results') : t('label.all-contracts')
          }
        />
        {isViewingDocuments && (
          <Toggle
            label={t('label.show-details')}
            inlineLabel
            styles={toggleStyles}
            defaultChecked={isDocCardExpanded}
            onChange={() => setIsDocCardExpanded(!isDocCardExpanded)}
          />
        )}
      </div>
      <List onShouldVirtualize={() => false} items={contracts} onRenderCell={renderCard} />
    </div>
  )

  function renderCard(item: Contract | undefined) {
    if (!item) return null

    const { beforeContent, content, iconName, iconColor } = populateCard(item)

    return (
      <ContractsCard
        contract={item}
        beforeContent={beforeContent}
        iconName={iconName}
        iconColor={iconColor}
      >
        {content}
      </ContractsCard>
    )
  }

  function populateCard(item: Contract) {
    const displayData = accessDisplayData(isPrivate(item.accessControls))
    const enableAccessIcon =
      isResourceAccessEnabled && (access.isAdmin() || userInfo.uuid === item.owner)

    if (!isViewingDocuments)
      return {
        content: (
          <>
            <TextWithLabel
              label={t('label.ContractMetadata.last-modified')}
              content={item.dateModified}
            />
            {renderStatusAndAccess()}
          </>
        ),
        iconName: 'FabricFolder',
        iconColor: SharedColors.blue10,
      }

    return {
      beforeContent: (
        <div style={{ marginBottom: '0.7em' }}>
          <TextWithLabel label={t('label.ContractMetadata.contract')} content={item.parentName} />
          <TextWithLabel
            label={t('label.ContractMetadata.last-modified')}
            content={item.dateModified}
          />
          <TextWithLabel label="Type" content={item.type} />
          {renderStatusAndAccess()}
        </div>
      ),
      content: (
        <>
          {isDocCardExpanded && (
            <HighlightedText text={item.digests?.[0]} title={t('label.keyword-match')} />
          )}
        </>
      ),
      iconName: item.icon.iconName,
      iconColor: item.icon.iconColor,
    }

    function renderStatusAndAccess() {
      return (
        <Stack
          horizontal
          style={{
            alignItems: 'center',
            display: 'flex',
            marginTop: '0.3em',
          }}
        >
          <StatusBadge status={item.status} badgeColor={item.badgeColor}></StatusBadge>
          {enableAccessIcon && (
            <Stack.Item>
              <IconBadge
                iconName={displayData.iconName}
                iconColor={displayData.iconColor}
                text={displayData.label}
                badgeColor="white"
                style={{ fontSize: '0.8em' }}
              />
            </Stack.Item>
          )}
        </Stack>
      )
    }
  }
}

export default ContractsList
