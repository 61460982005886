import { IMessageBarStyles, MessageBar, MessageBarType } from '@fluentui/react'

interface Props {
  warning: boolean
}

const warningStyles: IMessageBarStyles = {
  root: { marginBottom: '0.5em' },
}

export default function VersionWarning(props: Props) {
  if (!props.warning) return <></>

  return (
    <MessageBar messageBarType={MessageBarType.warning} styles={warningStyles}>
      You are not viewing the current version of this file.
    </MessageBar>
  )
}
