import { useContext, useEffect, useState } from 'react'
import { MessageBar, MessageBarType, Spinner, Stack, Toggle, Label } from '@fluentui/react'

import { DefinitionsContext } from '@contexts/DefinitionsContext'
import NotificationBadge from '@components/NotificationBadge'
import { AnalysisToolsContext } from '@contexts/AnalysisToolsContext'
import LoadingShimmer from '@components/LoadingShimmer'
import Highlight from '@baseComponents/Highlight'
import QuickMessage from '@components/QuickMessage'
import { useTranslation } from '@hooks/useTranslation'

export const stackStyles: React.CSSProperties = {
  marginBottom: '0.2em',
  width: '100%',
}
export const subHeadingStyles: React.CSSProperties = {
  marginBottom: '0.2em',
  fontSize: '1.2em',
}

export default function DefinitionsActions() {
  const {
    loading,
    error,
    reset,
    definitions,
    selectedDefinitions,
    callGetAndDisplayDefinitions,
    toggledDefinitions,
    setToggledDefinitions,
    definitionsLoaded,
    clearDefinedTerms,
    termsCleared,
    clearDefinitions,
  } = useContext(DefinitionsContext)
  const { setAnalysisToolTrigger } = useContext(AnalysisToolsContext)
  const [toggling, setToggling] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    enable()

    return () => {
      clearDefinedTerms().catch(e => console.error(e))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!loading && !definitions.length && toggledDefinitions) {
    return <QuickMessage msg={t('page.Definitions.No Definitions Message')} type="info" />
  }

  return <div style={stackStyles}>{body()}</div>

  function body() {
    return (
      <>
        {error && (
          <MessageBar
            messageBarType={MessageBarType.error}
            styles={{ root: { marginBottom: '1em' } }}
            style={{ fontSize: '1.1em' }}
          >
            {error}
          </MessageBar>
        )}

        <Stack horizontal horizontalAlign="space-between">
          <Toggle
            onText={loading ? 'Identifying Terms...' : 'Hide Defined Terms'}
            offText="Show Defined Terms"
            checked={toggledDefinitions}
            disabled={!!error || loading || toggling}
            onChange={toggle}
          />
          {(loading || toggling) && <Spinner data-testid="spinner" />}
        </Stack>

        <div style={{ display: definitionsLoaded ? 'block' : 'none' }}>
          <Stack horizontal horizontalAlign="space-between" style={{ alignItems: 'baseline' }}>
            <h3 style={subHeadingStyles}> Definitions </h3>
            {selectedDefinitions?.length > 0 && renderNumSelected()}
          </Stack>

          <p>
            Click on <Highlight>highlighted</Highlight> references to view their definitions.
          </p>
        </div>

        {loading && <LoadingShimmer />}
      </>
    )
  }

  function renderNumSelected() {
    return (
      <Label>
        Selected <NotificationBadge>{selectedDefinitions.length}</NotificationBadge>
      </Label>
    )
  }

  async function toggle() {
    setToggling(true)

    if (toggledDefinitions) {
      await reset()
      setAnalysisToolTrigger('defex', undefined)
      setToggledDefinitions(false)
    } else {
      await enable()
    }
    setToggling(false)
  }

  async function enable() {
    termsCleared.current = false
    setToggledDefinitions(true)
    await callGetAndDisplayDefinitions()
    setAnalysisToolTrigger('defex', reanalyze)
  }

  async function reanalyze() {
    await clearDefinitions()
    termsCleared.current = false
    await callGetAndDisplayDefinitions()
  }
}
