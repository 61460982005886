import { useContext, useEffect, useState, FC, FormEvent } from 'react'
import {
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ProgressIndicator,
  Toggle,
} from '@fluentui/react'
import { useNavigate } from 'react-router-dom'

import {
  type MetadataConfigValue,
  type TermsMetadataKey,
  type ResourceVersionMetadataDetails,
  MetadataTypes,
} from '@blaw/contracts-api-schema'

import { useTranslation } from '@hooks/useTranslation'
import { useDataInjection } from '@hooks/useDataInjection'

import { ContractContext } from '@contexts/ContractContext'
import { StoreContext } from '@contexts/StoreContext'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import { formDataType, OXContext } from '@contexts/OXContext'

import { OXFormProps } from '@modules/OXForm'
import ApiClient from '@modules/ApiClient'
import Routes from '@modules/routes'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { KeyTerm, KeyTermValue } from '@modules/KeyTerm'

import Box from '@baseComponents/Box'
import UnstyledList from '@baseComponents/UnstyledList'
import BoldText from '@baseComponents/BoldText'
import ContractTitle from '@baseComponents/ContractTitle'

import CollapsibleItem from '@components/CollapsibleItem'
import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import LoadingShimmer from '@components/LoadingShimmer'
import ErrorMessage from '@components/ErrorMessage'
import DateOXForm from '@components/DateOXForm'
import TextOXForm from '@components/TextOXForm'
import BooleanOXForm from '@components/BooleanOXForm'
import CurrencyOXForm from '@components/CurrencyOXForm'
import DurationMonthsOXForm from '@components/DurationMonthsOXForm'
import DurationDaysOXForm from '@components/DurationDaysOXForm'
import ChoiceTextOXForm from '@components/ChoiceTextOXForm'
import ModalProgress from '@components/ModalProgress'

import { mockObligations } from '@fixtures/extractedObligations'

type KeyTermDetailTemp = {
  system_extracted: boolean
}

// TODO: type annotate key
// https://jira.bna.com/browse/KMGT-6660
export type GetObligationsResponse = {
  obligations: {
    [key: string]: ExtractedObligation[]
  }
}

// TODO: share types between contracts-api and add-in
// https://jira.bna.com/browse/KMGT-6659
type ExtractedObligation = {
  code: string
  data_type: string | null
  is_deleted: boolean
  type: string
  value: string | null
  status: string
  reference: string | null
  offsets: string[][] | null
  schemaKey: string
  schemaType: MetadataTypes
  schemaLabel: string
}

export interface FormattedExtractedObligation extends ExtractedObligation {
  active: boolean
  key: string
}

const OXTypeToFormComponent: {
  [key: string]: FC<OXFormProps>
} = {
  BOOLEAN: BooleanOXForm,
  CURRENCY: CurrencyOXForm,
  DATE: DateOXForm,
  DURATION_MONTHS: DurationMonthsOXForm,
  DURATION_DAYS: DurationDaysOXForm,
  TEXT: TextOXForm,
  CHOICE_TEXT: ChoiceTextOXForm,
}

const pageTitle = 'Add with GenAI'
const apiClient = new ApiClient()
const routes = new Routes()

export default function AddObligations() {
  const navigate = useNavigate()
  const { documentId, versionId } = useDataInjection()
  const { t } = useTranslation()

  const [message, setMessage] = useState({
    type: MessageBarType.info,
    message: t('page.Extract Obligations.Select Extracted Terms'),
  })
  const [loadingObligations, setLoadingObligations] = useState(true)
  const [obligations, setObligations] = useState([] as FormattedExtractedObligation[])
  const [submitting, setSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [hideModalProgress, setHideModalProgress] = useState(true)

  const { isOnDemandOxEnabled } = useContext(StoreContext)
  const { contract, title, loading, updateKeyTerms, loadContract } = useContext(ContractContext)
  const { metadataConfig, loadingMetadataConfig } = useContext(KeyTermsContext)
  const { formValid, formData, setFormData, toggleObligation, handleFormValidation } =
    useContext(OXContext)

  !loading && console.debug(contract?.id, documentId, versionId)

  async function getObligations(): Promise<GetObligationsResponse> {
    if (!documentId || !versionId) return { obligations: {} }
    const { data } = await apiClient.get<GetObligationsResponse>(
      routes.getObligationsUrl(documentId, versionId),
    )
    return data
    return mockObligations
  }

  function formatAndFilterObligations(
    obligations: ExtractedObligation[],
    metadataConfigValue: MetadataConfigValue[],
    keyTermsDetails: ResourceVersionMetadataDetails,
  ): FormattedExtractedObligation[] {
    const formattedObligations = obligations.map(obligation => {
      return {
        ...obligation,
        key: obligation.schemaKey,
        active: false,
      }
    })
    const formattedAndFilteredObligations = formattedObligations.filter(obligation => {
      const keyTermConfig =
        metadataConfigValue.find(term => term.id === obligation.schemaKey) ||
        ({} as MetadataConfigValue)
      const keyTermDetail =
        keyTermsDetails[obligation.schemaKey as TermsMetadataKey] || ({} as KeyTermDetailTemp)
      return displayObligation(keyTermConfig, keyTermDetail, obligation)
    })
    return formattedAndFilteredObligations
  }

  function displayObligation(
    keyTermConfig: MetadataConfigValue,
    keyTermDetail: KeyTermDetailTemp,
    obligation: FormattedExtractedObligation,
  ) {
    // obligation cannot be manually entered from key terms page
    if (!Object.keys(keyTermConfig).length) {
      console.debug(`${obligation.schemaKey} is not editable`)
      return false
    }
    // obligation has already been manually entered on key terms page
    if (Object.keys(keyTermDetail).length && !keyTermDetail.system_extracted) {
      console.debug(`${obligation.schemaKey}  was already manually entered`)
      return false
    }
    // obligation is of the wrong type and doesn't have a system extracted value
    if (obligation.schemaType !== 'TEXT' && !keyTermDetail.system_extracted) {
      console.debug(`${obligation.schemaKey} is of the wrong type`)
      return false
    }
    return true
  }

  function getValueAndValidate(
    systemExtractedValue: KeyTermValue,
    obligationExtractedValue: string | null,
    schemaType: MetadataTypes,
  ) {
    const value =
      systemExtractedValue ?? (schemaType === 'TEXT' ? obligationExtractedValue : undefined)
    const isValid = Boolean(value) || typeof systemExtractedValue === 'boolean'
    const errorMessage = isValid ? '' : 'Invalid'
    return { value, isValid, errorMessage }
  }

  useEffect(() => {
    if (
      !contract ||
      loadingMetadataConfig ||
      !metadataConfig ||
      !metadataConfig?.value.length ||
      !Object.keys(contract.details).length
    )
      return

    setLoadingObligations(true)
    getObligations()
      .then(response => {
        const obligations = Object.entries(response.obligations).map(
          ([_, obligation]) => obligation[0],
        )
        console.debug('RESPONSE OBLIGATIONS', obligations)
        const formattedAndFilteredObligations = formatAndFilterObligations(
          obligations,
          metadataConfig.value,
          contract.details,
        )
        const keyTerms = contract.existingKeyTerms(metadataConfig.value)
        const initialFormData = formattedAndFilteredObligations.reduce(
          (acc, { key, value: obligationExtractedValue, schemaLabel, schemaType }) => {
            const keyTerm = keyTerms.find(keyTerm => keyTerm.key === key)
            const { value, isValid, errorMessage } = getValueAndValidate(
              keyTerm?.value, // system extracted value
              obligationExtractedValue, // obigation extracted value
              schemaType,
            )
            acc[key] = {
              label: schemaLabel,
              value: value,
              included: true,
              isValid: isValid,
              errorMessage: errorMessage,
              items: keyTerm?.items || [],
              notes: keyTerm?.data?.notes || '',
            }
            return acc
          },
          {} as formDataType,
        )
        setFormData(initialFormData)
        handleFormValidation(initialFormData)
        setObligations(formattedAndFilteredObligations)
        !formattedAndFilteredObligations.length &&
          setMessage({
            type: MessageBarType.error,
            message: t('page.Extract Obligations.No Extracted Obligations'),
          })
      })
      .catch(error => {
        setMessage({
          type: MessageBarType.error,
          message: error.message,
        })
      })
      .finally(() => {
        setLoadingObligations(false)
      })
  }, [contract, loadingMetadataConfig, metadataConfig])

  // use the following for any access related checks
  // const { access } = useContext(StoreContext)

  useContractTaskPaneViewed({ pageTitle })

  if (!isOnDemandOxEnabled)
    return <ErrorMessage message={t('page.Extract Obligations.Service Unavailable')} />

  return (
    <>
      <TopNav title={pageTitle} prevPath={() => navigate(-1)} />
      <MessageBar messageBarType={message.type}>{message.message}</MessageBar>
      <StyledStack>{renderMain()}</StyledStack>
    </>
  )

  function renderMain() {
    if (loading || loadingMetadataConfig || loadingObligations) {
      return (
        <>
          <ProgressIndicator label={t('page.Extract Obligations.Extracting')} />
          <LoadingShimmer />
        </>
      )
    }

    return (
      <>
        <form onSubmit={onSubmit}>
          <ContractTitle title={title} />
          <UnstyledList>{obligations.map(renderObligation)}</UnstyledList>
          <PrimaryButton
            type="submit"
            disabled={!formValid || submitting}
            style={{ width: '100%' }}
          >
            {submitting
              ? t('page.Extract Obligations.Adding Selected')
              : t('page.Extract Obligations.Add Selected')}
          </PrimaryButton>
        </form>
        <ModalProgress
          hidden={hideModalProgress}
          text={
            submitError
              ? t('page.Extract Obligations.Unable To Add')
              : t('page.Extract Obligations.Adding Selected')
          }
          error={Boolean(submitError)}
          onDismiss={() => {
            setSubmitError('')
            setHideModalProgress(true)
          }}
        />
      </>
    )
  }

  function renderObligation(obligation: FormattedExtractedObligation) {
    const FormComponent = OXTypeToFormComponent[obligation.schemaType]
    const disabled = !formData[obligation.key].included

    return (
      <li key={obligation.key}>
        <Box>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}>
            <Toggle
              styles={{ root: { marginRight: '0.5em', marginBottom: '0' } }}
              checked={!disabled}
              onChange={() => toggleObligation(obligation.schemaKey)}
              disabled={submitting}
              data-testid={`obligation_toggle_${obligation.schemaKey}`}
            />
            <Label>{obligation.schemaLabel}</Label>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <BoldText>Suggestion: </BoldText>
            <div style={{ margin: '0.5em 0' }}>{obligation.value}</div>
          </div>
          <UnstyledList>
            <CollapsibleItem
              item={obligation}
              itemHeader={() => <div>Contract Text Snippet</div>}
              itemContent={() => <div>{obligation.reference}</div>}
              iconStyles={{ paddingLeft: 0 }}
              listItemStyles={{ border: 'none' }}
            />
          </UnstyledList>
          <FormComponent schemaKey={obligation.schemaKey} submitting={submitting} />
        </Box>
      </li>
    )
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      setSubmitting(true)
      setHideModalProgress(false)
      const newTerms = Object.fromEntries(
        Object.entries(formData)
          .filter(([_, entry]) => entry.included)
          .map(([key, entry]) => {
            const { value, notes } = entry
            return [key, { value, data: { notes, block: '', text: '' } }]
          }),
      )
      const termsCopy = JSON.parse(
        JSON.stringify({
          ...contract?.customMetadata.terms,
          ...newTerms,
        }),
      )
      const metadataCopy = JSON.parse(JSON.stringify(contract?.metadata))
      metadataCopy.systemMetadata = {}
      metadataCopy.userMetadata = {}
      metadataCopy.customMetadata.terms = termsCopy
      await updateKeyTerms(metadataCopy, e)
      setHideModalProgress(true)
      loadContract()
      contract?.id && navigate(`/contracts/${contract.id}/keyTerms`)
    } catch (e) {
      setSubmitError(t('page.Extract Obligations.Unable To Add'))
    } finally {
      setSubmitting(false)
    }
  }
}
