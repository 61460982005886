import { CSSProperties } from 'react'
import { IComboBoxStyles, SharedColors, IButtonStyles, NeutralColors } from '@fluentui/react'

import { LightTheme } from '@src/themes'

export const selectedLeftBorderStyle: CSSProperties = {
  borderLeft: `0.3em solid ${LightTheme.palette.themePrimary}`,
  paddingLeft: '0.8em',
}

export const unselectedLeftBorderStyle: CSSProperties = {
  borderLeft: '0.1em solid lightgray',
  paddingLeft: '1em',
}

export const comboBoxStyles: Partial<IComboBoxStyles> = {
  optionsContainerWrapper: { maxHeight: '432px' },
  container: { marginBottom: '1em' },
}

export const disabledTextFieldError = {
  errorMessage: { color: NeutralColors.gray90 },
  fieldGroup: {
    border: 'none',
    borderColor: NeutralColors.gray90,
  },
}

const styleBtnRed = {
  backgroundColor: SharedColors.red10,
  borderColor: SharedColors.red10,
}

export const deleteBtnStyles: IButtonStyles = {
  root: { ...styleBtnRed },
  rootHovered: { ...styleBtnRed },
  rootPressed: { backgroundColor: SharedColors.red20, borderColor: SharedColors.red20 },
}
