import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import * as en from '@src/i18n/en/translation.json'

i18next.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en,
  },
})

export default i18next
