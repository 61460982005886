import { FC, useContext } from 'react'
import { ITextStyles } from '@fluentui/react'

import StyledTextField from '@components/StyledTextField'
import useKeyTermForm from '@hooks/useKeyTermForm'
import { useTranslation } from '@hooks/useTranslation'
import { removeNonNumberValues } from '@modules/utils'
import { KeyTermFormProps as Props } from '@modules/KeyTermForm'
import { KeyTermFormContext } from '@contexts/KeyTermFormContext'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'

const styles: Partial<ITextStyles> = {
  root: { width: '100%' },
}

const CurrencyKeyTermForm: FC<Props> = ({ keyTermType }: Props) => {
  const { validationError, copySelectionDisabled } = useContext(KeyTermFormContext)
  const { newKeyTerm, updateKeyTermNotes, updateNumericKeyTerm, copyFromSelection } =
    useKeyTermForm(keyTermType, 'CURRENCY')
  const { t } = useTranslation()

  if (!newKeyTerm) return null

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <StyledTextField
          prefix="$"
          name="value"
          label={keyTermType}
          onChange={updateNumericKeyTerm}
          required
          styles={styles}
          type="number"
          min="0"
          value={newKeyTerm.value ? removeNonNumberValues(newKeyTerm.value) : ''}
          errorMessage={validationError}
        />
      </div>
      <StyledTextField
        label={t('label.notes')}
        multiline
        name="notes"
        onChange={updateKeyTermNotes}
        value={newKeyTerm?.data?.notes ?? ''}
      />
      <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} />
    </>
  )
}

export default CurrencyKeyTermForm
