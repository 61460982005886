import { MIMETYPE_DOCX } from '@blaw/contracts-api-schema'
import { iterDocSlices } from './wordDocument'

export async function GetWordDocumentContent() {
  const docdata = await GetWordDocumentContentRaw()
  return new Blob([new Uint8Array(docdata)], {
    type: MIMETYPE_DOCX,
  })
}

export async function GetWordDocumentContentRaw() {
  const docdataSlices = []
  for await (const slice of iterDocSlices(65536)) {
    docdataSlices[slice.index] = slice.data
  }

  const docdata = [].concat(...docdataSlices)
  return docdata
}
