import { Icon, NeutralColors, SharedColors } from '@fluentui/react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import NotificationBadge from '@components/NotificationBadge'

interface Props {
  isLinkDisabled: boolean
  pathName: string
  numFilters: () => number
}

const StyledSpan = styled.div`
  &:hover {
    text-decoration: underline;
  }
`

export default function FilterButton(props: Props) {
  const { isLinkDisabled, pathName, numFilters } = props

  return (
    <div
      style={{
        cursor: isLinkDisabled ? 'not-allowed' : 'pointer',
      }}
    >
      <Link
        className="filtersLink"
        to={{
          pathname: isLinkDisabled ? undefined : pathName,
        }}
        style={{
          pointerEvents: isLinkDisabled ? 'none' : 'auto',
          textDecoration: 'none',
          color: isLinkDisabled ? NeutralColors.gray110 : SharedColors.cyanBlue10,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: 'auto',
            padding: '0.3em 0',
          }}
        >
          <Icon iconName="Filter" style={{ marginRight: '0.3em' }} />
          <StyledSpan style={{ position: 'relative', paddingRight: '0.3em' }}>
            Filters {numFilters() ? <NotificationBadge>{numFilters()}</NotificationBadge> : null}
          </StyledSpan>
        </div>
      </Link>
    </div>
  )
}
