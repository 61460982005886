import StyledTextField from '../StyledTextField'
import { type FieldProps } from './ContractCustomFields'

export default function CustomTextField({
  name,
  value,
  prefix,
  disabled,
  onChange,
  inputType = 'text',
  textFieldProps = {},
}: FieldProps) {
  const unit = value.unit
  return (
    <StyledTextField
      data-testid={name}
      styles={{ root: { flexGrow: 1 } }}
      name={name}
      type={inputType}
      disabled={disabled}
      value={value.value as string}
      onChange={(_e, value) => onChange(prefix, { unit, value })}
      {...textFieldProps}
    />
  )
}
