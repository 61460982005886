import { useContext, useEffect } from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'

import TopNav from '@components/TopNav'
import { useDataInjection } from '@hooks/useDataInjection'
import DocumentVersionsContent from './DocumentVersionsContent'
import useVersions from '@hooks/useVersions'
import DeleteVersionForm from '@components/DeleteVersionForm'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { ContractContext } from '@contexts/ContractContext'
import { StoreContext } from '@contexts/StoreContext'

const pageTitle = 'Version History'

const DocumentVersions: React.FC = () => {
  const { contractId, documentId } = useParams()

  if (!contractId || !documentId) throw Error('Missing id param')

  const navigate = useNavigate()
  const {
    loadDocumentVersions,
    setDocumentName,
    documentAccess,
    setDocumentAccess,
    versionsPreloaded,
    setVersionsPreloaded,
  } = useVersions(documentId)
  const { versionId } = useDataInjection()
  const { contract } = useContext(ContractContext)
  const { access } = useContext(StoreContext)

  useEffect(() => {
    !versionsPreloaded ? loadDocumentVersions() : setVersionsPreloaded(false)
    // Note: the following eslint is disabled because the suggested fix causes a render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, versionsPreloaded])

  useEffect(() => {
    if (contract) {
      const document = contract.findDocument(documentId)
      document && setDocumentName(document.name)
      document && setDocumentAccess(document.accessControls)
    }
  }, [contract, documentId, setDocumentName, setDocumentAccess])

  useContractTaskPaneViewed({
    pageTitle,
    eventDetails: [contractId, versionId ?? 'unknown'],
  })

  return (
    <>
      <TopNav
        title={pageTitle}
        prevPath={`#/contracts/${contractId}`}
        showPrimaryButton={access.canEditDocumentVersion(documentAccess || {})}
        primaryButtonTooltip="Add Version"
        onPrimaryButtonClick={() =>
          navigate({
            pathname: '/contracts/versions/new',
            search: createSearchParams({
              contractId,
              documentId,
            }).toString(),
          })
        }
      />
      <DocumentVersionsContent
        contractId={contractId}
        documentId={documentId}
        // @ts-ignore TODO: versionId was previously typed as string but that was a mistake,
        // it's not guaranteed to exist. Need to handle this case.
        versionId={versionId}
        showDeleteBtn={access.canDeleteDocumentVersion(documentAccess || {})}
      />
      <DeleteVersionForm documentId={documentId} />
    </>
  )
}

export default DocumentVersions
