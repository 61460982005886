import { useState } from 'react'
import { Stack } from '@fluentui/react'

import { type OptionValue } from '@blaw/contracts-api-schema'
import ComboboxField from '@components/ComboboxField'
import { type CustomFieldState, type FieldProps } from './ContractCustomFields'
import CustomTextField from './CustomTextField'
import { transformToComboBoxOptions } from '@modules/utils'
import { currencyChoices } from '@modules/CustomFields'

const currencies = transformToComboBoxOptions(currencyChoices, 'value', 'label')
const defaultCurrency = currencies[0].text

export default function CustomMonetaryField({
  type,
  label,
  name,
  value,
  prefix,
  disabled,
  onChange,
}: FieldProps) {
  const [state, setState] = useState<CustomFieldState>({
    ...value,
    unit: value.unit || defaultCurrency,
  })
  const update = (fieldKey: OptionValue, newState: CustomFieldState) => {
    setState({ ...state, ...newState })
    onChange(fieldKey, { ...state, ...newState })
  }

  return (
    <Stack horizontal tokens={{ childrenGap: '0.5em' }}>
      <ComboboxField
        style={{ container: { width: '5.5em' } }}
        value={state.unit?.toString()}
        options={currencies}
        disabled={disabled}
        onChange={(_e, _o, _i, unit) => update(unitize(prefix), { unit })}
      />
      <CustomTextField
        data-testid={name}
        type={type}
        label={label}
        name={name}
        value={state}
        prefix={prefix}
        inputType="number"
        disabled={disabled}
        textFieldProps={{ min: 0 }}
        onChange={(_k, state) => {
          update(prefix, state)
          // also emit the unit in case user doesn't change it manually
          update(unitize(prefix), { ...state, unit: state.unit ?? defaultCurrency })
        }}
      />
    </Stack>
  )
}

function unitize(prefix: OptionValue) {
  return prefix.toString().replace(/(_\d+)/, '_unit$1')
}
