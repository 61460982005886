import { type Resource, MIMETYPE_DOCX } from '@blaw/contracts-api-schema'
import { getSession } from './SessionInfoStorage'

export interface TemplateFormFields {
  name: string
  description?: string
  contractType?: string
}

export type TemplateField = keyof TemplateFormFields

export const TemplateFormFieldDefaults: TemplateFormFields = {
  name: '',
  description: '',
  contractType: '',
}

export const RequiredFields: Partial<TemplateField[]> = ['name']

export function formToAddNewTemplateParams(formData: TemplateFormFields) {
  return {
    name: formData.name,
    metadata: {
      userMetadata: {
        description: formData.description,
        contract_type: formData.contractType,
      },
    },
    resourceType: 'template_document',
    mimeType: MIMETYPE_DOCX,
  }
}

export function createdTemplateToResource(
  formData: TemplateFormFields,
  documentId: string,
): Resource {
  const params = formToAddNewTemplateParams(formData)
  const userInfo = getSession()
  const date = new Date()
  return {
    id: documentId,
    name: params.name,
    mimeType: params.mimeType,
    resourceType: params.resourceType,
    author: `${userInfo.firstName}`,
    links: { self: '', metadata: '', content: '' },
    updated: date.toISOString(),
    created: date.toISOString(),
    metadata: {
      userMetadata: params.metadata.userMetadata,
      systemMetadata: {},
      customMetadata: {},
    },
  } as Resource
}

export type AddNewTemplateParams = ReturnType<typeof formToAddNewTemplateParams>
