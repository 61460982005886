import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

import { AnalysisType } from '@modules/DocumentAnalysisAsync'
import { getDocBodyText } from '@modules/wordDocument'

type Props = PropsWithChildren

type AnalysisTrigger = Record<AnalysisType, undefined | (() => void)>
type AnalysisToolsContextState = {
  enabledAnalyses: AnalysisTrigger
  analysisIsStale: boolean
  setAnalysisIsStale: Dispatch<SetStateAction<boolean>>
  runEnabledAnalyses: () => void
  setAnalysisToolTrigger: (tool: AnalysisType, trigger?: () => void) => void
  docBodyText: string
  setDocBodyText: Dispatch<SetStateAction<string>>
  getDocBodyText: () => Promise<string>
}

export const AnalysisToolsContext = createContext({} as AnalysisToolsContextState)

export default function AnalysisToolsContextProvider(props: Props) {
  const [analysisIsStale, setAnalysisIsStale] = useState(false)
  const [enabledAnalyses, setEnabledAnalyses] = useState({} as AnalysisTrigger)
  const [docBodyText, setDocBodyText] = useState('')

  const setAnalysisToolTrigger = (tool: AnalysisType, trigger?: () => void) => {
    enabledAnalyses[tool] = trigger
    setEnabledAnalyses(enabledAnalyses)
  }

  const runEnabledAnalyses = () => {
    Object.entries(enabledAnalyses).forEach(([, trigger]) => trigger && trigger())
  }

  useEffect(() => {
    getDocBodyText()
      .then(t => setDocBodyText(t))
      .catch(e => console.error(e))
  }, [])

  const value: AnalysisToolsContextState = {
    enabledAnalyses,
    analysisIsStale,
    setAnalysisIsStale,
    runEnabledAnalyses,
    setAnalysisToolTrigger,
    getDocBodyText,
    setDocBodyText,
    docBodyText,
  }

  return (
    <AnalysisToolsContext.Provider value={value}>{props.children}</AnalysisToolsContext.Provider>
  )
}
