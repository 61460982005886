import { useState } from 'react'

import { MIMETYPE_DOCX, type RESOURCETYPES } from '@blaw/contracts-api-schema'
import { webappTemplateView, webappVersionPath } from '@modules/WebappPaths'
import openDocument from '@modules/openDocument'
import { navigateToExternalSite } from '@modules/utils'
import getPdfConversion from '@modules/PdfConversion'

type Props = {
  documentFinishedLoading: () => void
}

// Used by ContractsList and ResourcesList to download and open documents
export default function useDocumentOpen(props?: Props) {
  const [loadingDocument, setLoadingDocument] = useState(false)
  const [openDocumentError, setOpenDocumentError] = useState<string>('')

  async function triggerDocumentOpen(
    documentId: string,
    contractId: string,
    versionId?: string,
    resourceType?: string,
  ) {
    setLoadingDocument(true)
    setOpenDocumentError('')

    try {
      await openDocument(documentId, contractId, versionId, resourceType)
    } catch (e: any) {
      console.error(e)
      if (e.code != 'OperationCancelled') {
        setOpenDocumentError((e as Error).message)
      }
      console.debug(e.debugInfo)
    } finally {
      setLoadingDocument(false)
      props?.documentFinishedLoading()
    }
  }

  async function triggerPdfOpen(resourceId: string) {
    setLoadingDocument(true)
    setOpenDocumentError('')

    try {
      await getPdfConversion(resourceId)
    } catch (e: any) {
      console.error(e)
      if (e.code != 'OperationCancelled') {
        setOpenDocumentError((e as Error).message)
      }
      console.debug(e.debugInfo)
    } finally {
      setLoadingDocument(false)
    }
  }

  async function openDocumentHandler(
    documentId: string,
    contractId: string,
    mimeType: string,
    versionId = 'latest',
    resourceType: RESOURCETYPES = 'document',
    viewInWeb = false,
  ) {
    if (!viewInWeb && mimeType === MIMETYPE_DOCX)
      return await triggerDocumentOpen(documentId, contractId, versionId, resourceType)

    if (!viewInWeb && resourceType === 'template_document') {
      return await triggerPdfOpen(documentId)
    }

    return resourceType === 'template_document'
      ? navigateToExternalSite(webappTemplateView(contractId))
      : navigateToExternalSite(webappVersionPath(documentId || contractId, versionId))
  }

  return {
    loadingDocument,
    openDocumentError,
    triggerDocumentOpen,
    setOpenDocumentError,
    openDocumentHandler,
  }
}
