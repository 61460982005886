// Gets the text selection from the currently open Word document
export default async function GrabTextSelection() {
  let selected = ''

  await Word.run(async context => {
    const selection = context.document.getSelection()
    selection.load('text')
    await context.sync()
    selected = selection.text.trim()
  })
  return selected
}
