import { useContext, useState } from 'react'

import ConfirmDialog from '@components/ConfirmDialog'
import ComboboxField from '@components/ComboboxField'
import StyledTextField from '@components/StyledTextField'
import { ContractsContext } from '@contexts/ContractsContext'
import { TemplatesContext } from '@contexts/TemplatesContext'
import { IStackTokens, MessageBar, Stack } from '@fluentui/react'
import { FormInputOnChange } from '@hooks/useContractFormData'
import { dataInject } from '@hooks/useDataInjection'
import useSignedUpload, { UploadCompleteResponse, UploadType } from '@hooks/useSignedUpload'
import { useTranslation } from '@hooks/useTranslation'
import {
  TemplateField,
  TemplateFormFieldDefaults,
  TemplateFormFields,
  createdTemplateToResource,
  formToAddNewTemplateParams,
} from '@modules/Template'
import { formSubmitted } from '@modules/analytics'

const pageTitle = 'Add New Template'
const requiredFields: Partial<TemplateField[]> = ['name']
const largeStackTokens: IStackTokens = {
  childrenGap: 'l1',
}

type Props = {
  hidden: boolean
  toggleHidden: () => void
}

export default function AddTemplateForm({ hidden, toggleHidden }: Props) {
  const [valid, setValid] = useState(false)
  const [formData, setFormData] = useState<TemplateFormFields>(TemplateFormFieldDefaults)
  const { uploading, uploadDocument, UploadStatus } = useSignedUpload()
  const { getFacetOptions, loading } = useContext(ContractsContext)
  const updateComboboxField = (name: string, value?: string | string[]) => {
    const newData = { ...formData, [name]: value }
    setValid(isValid(newData))
    setFormData(newData)
  }
  const { injectNewTemplateIntoResults, metadataConfig } = useContext(TemplatesContext)
  const { t } = useTranslation()

  const isValid = (fields: TemplateFormFields) => {
    return requiredFields.every(key => key && fields[key]?.toString().trim().length)
  }

  const change: FormInputOnChange = (e, value) => {
    const key = typeof e === 'string' ? e : e.currentTarget.name
    const newData = { ...formData, [key]: value }
    setValid(isValid(newData))
    setFormData(newData)
  }

  const reset = () => {
    setFormData(TemplateFormFieldDefaults)
    setValid(false)
  }

  return (
    <ConfirmDialog
      title={pageTitle}
      hidden={hidden}
      disableBtn={!valid || uploading}
      onConfirm={onSubmit}
      onDismiss={closeForm}
      confirm={uploading ? t('modal.Adding') : t('modal.Add Template.Add')}
      confirmBtnTitle={valid ? t('modal.Add Template.Add') : t('modal.Is Invalid')}
    >
      <form onSubmit={onSubmit}>
        <UploadStatus />
        <Stack tokens={largeStackTokens}>
          <Stack.Item>
            <MessageBar>{t('modal.Add Template.Message')}</MessageBar>
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              required
              autoFocus
              name="name"
              label={t('label.template-name')}
              placeholder={t('placeholder.template-name')}
              value={formData.name}
              onChange={change}
              disabled={uploading}
            />
          </Stack.Item>
          <Stack.Item>
            <ComboboxField
              label={metadataConfig && metadataConfig.getLabel('contract_type')}
              value={formData.contractType || ''}
              options={getFacetOptions('contract_type')}
              onChange={(_event, _option, _index, value) =>
                updateComboboxField('contractType', value)
              }
              disabled={uploading || loading}
            />
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              multiline
              autoAdjustHeight
              name="description"
              label={t('label.file-description')}
              placeholder={t('placeholder.enter-description')}
              value={formData.description}
              onChange={change}
              disabled={uploading}
            />
          </Stack.Item>
        </Stack>
      </form>
    </ConfirmDialog>
  )

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const params = formToAddNewTemplateParams(formData)
    // Clear any existing data injection before uploading template
    await dataInject({ resourceId: undefined, documentId: undefined, versionId: undefined })
    await uploadDocument(params, onSuccess, UploadType.createTemplate)
  }

  async function onSuccess({ documentId, versionId }: UploadCompleteResponse) {
    injectNewTemplateIntoResults(createdTemplateToResource(formData, documentId))
    formSubmitted({
      pageTitle,
      itemClicked: 'Add Template',
      eventDetails: [documentId, versionId],
    })
    closeForm()
  }

  function closeForm() {
    reset()
    toggleHidden()
  }
}
