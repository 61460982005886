import { useContext } from 'react'

import StyledTextField from '@components/StyledTextField'
import useKeyTermForm from '@hooks/useKeyTermForm'
import { useTranslation } from '@hooks/useTranslation'
import { KeyTermFormProps } from '@modules/KeyTermForm'
import { KeyTermFormContext } from '@contexts/KeyTermFormContext'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'

const StringKeyTermForm: React.FC<KeyTermFormProps> = ({ keyTermType }: KeyTermFormProps) => {
  const { copySelectionDisabled, validationError } = useContext(KeyTermFormContext)
  const { newKeyTerm, updateStringKeyTerm, updateKeyTermNotes, copyFromSelection } = useKeyTermForm(
    keyTermType,
    'TEXT',
  )
  const { t } = useTranslation()

  return (
    <>
      <StyledTextField
        required
        label={keyTermType}
        value={newKeyTerm?.value?.toString()}
        name="value"
        onChange={updateStringKeyTerm}
        errorMessage={validationError}
        multiline
      />
      <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} />
      <StyledTextField
        label={t('label.notes')}
        value={newKeyTerm?.data?.notes || ''}
        name="notes"
        onChange={updateKeyTermNotes}
        styles={{ root: { marginTop: '1em' } }}
        multiline
      />
    </>
  )
}

export default StringKeyTermForm
