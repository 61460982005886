import { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Stack, MessageBar, Label, Text } from '@fluentui/react'

import { useDataInjection, dataInject } from '@hooks/useDataInjection'
import TopNav from '@components/TopNav'
import StyledTextField from '@components/StyledTextField'
import StyledStack from '@components/StyledStack'
import { VersionFormFields } from '@modules/Contract'
import { ContractContext } from '@contexts/ContractContext'
import GeneralInformation from '@components/GeneralInformation'
import ContractTitle from '@baseComponents/ContractTitle'
import LoadingShimmer from '@components/LoadingShimmer'
import useSaveNewVersion from '@hooks/useSaveNewVersion'
import { transformVersionToDocVersion } from '@modules/DocumentVersion'
import useVersions from '@hooks/useVersions'
import VersionWarning from '@pages/DocumentVersions/VersionWarning'
import Footer from './Footer'
import ErrorMessage from '@components/ErrorMessage'
import { formSubmitted, useContractTaskPaneViewed } from '@modules/analytics'
import { useTranslation } from '@hooks/useTranslation'
import FileCategory from '@components/FileCategory'
import { FormInputOnChange } from '@hooks/useContractFormData'
import useContextualContractId from '@hooks/useContextualContractId'

const pageTitle = 'Save New Version'

export default function SaveNewVersion() {
  const [searchParams] = useSearchParams()
  const resourceId = useContextualContractId() || ''
  const { documentId: injectedDocumentId } = useDataInjection()
  const documentId = searchParams.get('documentId') || injectedDocumentId || ''
  const navigate = useNavigate()
  const versionsPath = `/contracts/${resourceId}/documents/${documentId}/versions`
  const {
    loadDocumentVersions,
    warning,
    documentName,
    setDocumentName,
    versions,
    setVersions,
    setVersionsPreloaded,
    // @ts-ignore TODO: documentId was previously typed as string but that was a mistake,
    // it's not guaranteed to exist. Need to handle this case.
  } = useVersions(documentId)
  const { contract, title, error, children, loadContract } = useContext(ContractContext)
  const [loading, setLoading] = useState(true)
  const [formData, setFormData] = useState<VersionFormFields>({
    fileCategory: '',
    description: versions[0]?.description ?? '',
  })
  const contractPath = `/contracts/${resourceId}`
  const {
    submitNewVersion,
    submitting,
    percentUploaded,
    setPercentUploaded,
    setSubmitting,
    uploadError,
    // @ts-ignore TODO: resourceId was previously typed as string but that was a mistake,
    // it's not guaranteed to exist. Need to handle this case.
  } = useSaveNewVersion(
    documentName,
    resourceId,
    documentId,
    formData.description,
    formData.fileCategory,
  )
  const { t } = useTranslation()

  useEffect(() => {
    const fileCategory = children.find(obj => obj.id === documentId)?.metadata?.userMetadata
      ?.content_type
    setFormData({ ...formData, fileCategory: fileCategory })
  }, [children, documentId])

  useEffect(() => {
    if (!(resourceId || documentId)) navigate('/contracts/detect')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceId, documentId])

  useEffect(() => {
    loadDocumentVersions()
    // Note: disable eslint warning, only need to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (contract && documentId) {
      const document = contract.findDocument(documentId)
      document && setDocumentName(document.name)
      setLoading(false)
    }
  }, [contract, documentId, setDocumentName])

  const change: FormInputOnChange = (e, value) => {
    const key = typeof e === 'string' ? e : e.currentTarget.name
    setFormData({ ...formData, [key]: value })
  }

  useContractTaskPaneViewed({ pageTitle })

  return (
    <>
      <TopNav title={pageTitle} prevPath={`#${contractPath}`} />
      {pageContent()}
    </>
  )

  function pageContent() {
    if (loading)
      return (
        <StyledStack>
          <LoadingShimmer />
        </StyledStack>
      )

    return (
      <form onSubmit={onSubmit}>
        <StyledStack>
          <VersionWarning warning={warning} />
          <ContractTitle title={title} />

          <Stack.Item>
            <GeneralInformation error={error} showEditBtn={false} />
          </Stack.Item>

          <Stack.Item>
            <MessageBar>
              The currently opened file will be uploaded as a new version of this Contract.
            </MessageBar>
          </Stack.Item>

          <Stack.Item>
            <Label>{t('label.file-name')}</Label>
            <Text>{documentName}</Text>
          </Stack.Item>
          <Stack.Item>
            <FileCategory
              change={change}
              disabled={submitting}
              fileCategoryKey={formData.fileCategory}
            />
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              autoFocus
              multiline
              autoAdjustHeight
              name="description"
              label="Description"
              placeholder={t('placeholder.enter-description')}
              value={formData.description}
              onChange={change}
              disabled={submitting}
            />
          </Stack.Item>

          <Footer
            uploading={submitting}
            path={`#${contractPath}`}
            percentUploaded={percentUploaded}
          />
          <ErrorMessage message={uploadError} />
        </StyledStack>
      </form>
    )
  }

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const putVersionResponse = await submitNewVersion()
    if (!putVersionResponse) throw Error('Failed to update version')

    const { version, date } = putVersionResponse
    setPercentUploaded(80)
    await dataInject({ versionId: putVersionResponse.version })
    setPercentUploaded(100)

    formSubmitted({
      pageTitle,
      itemClicked: 'Save',
      eventDetails: [resourceId ?? 'unknown', documentId ?? 'unknown', version],
    })

    const newVersion = transformVersionToDocVersion(version, date, formData.description)
    setVersions([newVersion, ...versions])
    setVersionsPreloaded(true)
    setTimeout(() => {
      navigate(versionsPath)
      setSubmitting(false)
    }, 1000)
    loadContract()
  }
}
