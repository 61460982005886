import { AxiosError } from 'axios'

import { MetadataTypes } from '@blaw/contracts-api-schema'
import ApiClient from './ApiClient'
import Routes from './routes'
import { DEFAULT_NUM_ITEMS_PER_PAGE } from './utils'
import { ClauseSourceLabels } from '@contexts/SimilarClausesContext'

const routes = new Routes()

type PutSimilarClausesResponse = {
  analysis: {
    id: string
  }
}

type GetSimilarClausesRealms = {
  realms: string[]
}

type ErrorResponse = {
  error: string
}

export type SimilarClausesResponseEnvelope = {
  similarClauses: SimilarClausesResponse
}

export type SimilarClausesResponse = {
  items?: SimilarClauseItem[]
  facets?: FacetListItem[]
  count?: number
  type?: string
}

export interface ItemAndCount {
  value: string
  count: number
  label?: string
  type?: MetadataTypes
}

export interface FacetListItem {
  field: string
  values: ItemAndCount[]
}

export type SimilarClauseItem = {
  id: number
  text: string
  documentId: string
  clauseId: string
  contractId: string
  metadata: {
    allParties: string[]
    docType: string
    filedDate?: string
    clauseSource?: keyof ClauseSourceLabels
    contractName?: string
  }
}

export type FilterState = {
  [key: string]: string[]
}

export type FacetLabel = { [key: string]: string }

export const EDGAR = 'EDGAR'
export const MY_CLAUSES = 'MY_CLAUSES'

export default class ClauseAnalyzer {
  apiClient: ApiClient
  id: string | null
  clauseText: string
  analysisType: string
  analysisSent: boolean

  constructor(clauseText: string, analysisType: string) {
    this.apiClient = new ApiClient()
    this.id = null
    this.clauseText = clauseText
    this.analysisType = analysisType
    this.analysisSent = false
  }

  async putSimilarClauses() {
    if (this.analysisSent) {
      return
    }

    let error = 'Unknown error'
    try {
      const payload = {
        text: this.clauseText,
        type: this.analysisType,
      }
      const { data } = await this.apiClient.put<PutSimilarClausesResponse>(
        routes.createSimilarClausesUrl,
        payload,
      )
      this.analysisSent = true
      this.id = data.analysis.id
      return
    } catch (e) {
      const err = e as AxiosError<ErrorResponse>
      console.error(err)
      const body = err.response?.data
      error = body?.error ?? err.message ?? error
    }
    this.id = null
    throw Error(error)
  }

  parameterizeFilters(filters: FilterState) {
    const params = ''
    const filterParams = new URLSearchParams(params)
    Object.entries(filters).forEach(([filterKey, filtersArray]) => {
      filtersArray.forEach(filterItem => {
        filterParams.append(filterKey, filterItem)
      })
    })
    const paramsString = filterParams.toString()
    return paramsString ? `&${paramsString}` : ''
  }

  async getSimilarClauses(filters: FilterState, pageNum: number) {
    const url = routes.getSimilarClausesUrl(this.id as string)
    const path = `${url}?pageNum=${
      pageNum + 1
    }&pageSize=${DEFAULT_NUM_ITEMS_PER_PAGE}${this.parameterizeFilters(filters)}`
    return await this.apiClient.get<SimilarClausesResponseEnvelope>(path)
  }
}

export class Typify {
  apiClient: ApiClient

  constructor() {
    this.apiClient = new ApiClient()
  }

  async getRealms() {
    try {
      const { data } = await this.apiClient.get<GetSimilarClausesRealms>(
        routes.similarClausesRealmsUrl,
      )
      return data.realms
    } catch (e) {
      console.error(e)
    }
    return [EDGAR]
  }
}
