import React, { PropsWithChildren } from 'react'
import { ActionButton, IButtonStyles } from '@fluentui/react'

type Props = PropsWithChildren & {
  onClick: any
  iconProps: any
  disabled?: boolean
  styles?: IButtonStyles
  ariaLabel?: string
}

const unstyledButton: IButtonStyles = {
  root: { padding: 0, height: 'auto' },
  icon: { padding: 0, margin: 0 },
}

const UnstyledActionButton: React.FC<Props> = ({
  children,
  iconProps,
  onClick,
  disabled = false,
  styles = {},
  ariaLabel = '',
}) => {
  return (
    <ActionButton
      styles={{ ...styles, ...unstyledButton }}
      iconProps={iconProps}
      onClick={onClick}
      disabled={disabled}
      ariaLabel={ariaLabel}
    >
      {children}
    </ActionButton>
  )
}
export default UnstyledActionButton
