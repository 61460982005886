import { useContext } from 'react'
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react'

import { OXContext } from '@contexts/OXContext'
import { OXFormProps } from '@modules/OXForm'
import { serialize } from '@modules/utils'
import StyledTextField from '@components/StyledTextField'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'
import { useTranslation } from '@hooks/useTranslation'

const options: IChoiceGroupOption[] = [
  { key: 'Yes', text: 'Yes' },
  { key: 'No', text: 'No' },
]

const booleanToOptionMap = ['No', 'Yes']

export default function BooleanOXForm({ schemaKey, submitting }: OXFormProps) {
  const { copySelectionDisabled, formData, updateBooleanOX, updateNotesOX, copyFromSelection } =
    useContext(OXContext)
  const { t } = useTranslation()
  const booleanOX = formData[schemaKey]
  const disabled = !booleanOX.included || submitting

  if (typeof booleanOX.value !== 'boolean') {
    console.error(`Unexpected value for booleanOX ${serialize(booleanOX)}`)
  }

  return (
    <>
      <ChoiceGroup
        styles={{
          flexContainer: { display: 'flex', justifyContent: 'space-between', width: '45%' },
        }}
        label={booleanOX.label}
        disabled={disabled}
        options={options}
        required
        onChange={(_, option) => updateBooleanOX(schemaKey, option)}
        selectedKey={
          typeof booleanOX.value === 'boolean'
            ? booleanToOptionMap[booleanOX.value ? 1 : 0]
            : undefined
        }
      />
      <StyledTextField
        label={t('label.notes')}
        disabled={disabled}
        value={booleanOX.notes}
        onChange={e => updateNotesOX(schemaKey, e.currentTarget.value)}
        multiline
        styles={{ root: { marginTop: '1em' } }}
      />
      <CopyFromSelectionBtn
        onClick={() => copyFromSelection(schemaKey)}
        disabled={copySelectionDisabled}
      />
    </>
  )
}
