import { useEffect, useRef } from 'react'

type FocusableInputs = HTMLInputElement | HTMLTextAreaElement

// Returns a ref that can be assigned to an input element e.g. `ref={inputRef}`
// to have that input get focus when it renders.
// Toggle prop should be related to the disabled state of the input.
export default function useAutoFocus<T extends FocusableInputs>(toggle?: boolean) {
  const inputRef = useRef<T>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [toggle])

  return inputRef
}
