import { ActionButton, Toggle } from '@fluentui/react'
import { CSSProperties, useEffect, useState } from 'react'

type Props = {
  onChange: (_ev: React.MouseEvent<HTMLElement>, show?: boolean) => void | Promise<void>
  replace: () => void
  disableReplace: boolean
  error?: string
  style?: CSSProperties
}

export default function useComparisonToolbar(defaultChecked = false) {
  const [showComparison, setShowComparison] = useState(defaultChecked)

  function ComparisonToolbar(props: Props) {
    const { onChange, replace, disableReplace, error, style } = props

    useEffect(() => {
      error && setShowComparison(false)
    }, [error])

    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          ...style,
        }}
      >
        <Toggle
          onText="Hide Comparison"
          offText="Show Comparison"
          checked={showComparison}
          onChange={(_ev, show) => {
            setShowComparison(showComparison => !showComparison)
            onChange(_ev, show)
          }}
        />
        <ActionButton
          disabled={disableReplace}
          iconProps={{ iconName: 'ChangeEntitlements' }}
          style={{ verticalAlign: 'bottom' }}
          onClick={replace}
        >
          Replace
        </ActionButton>
      </div>
    )
  }

  return {
    showComparison,
    ComparisonToolbar,
  }
}
