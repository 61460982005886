import { DefaultButton, PrimaryButton, ProgressIndicator, Stack } from '@fluentui/react'

interface Props {
  uploading: boolean
  path: string
  percentUploaded: number
}

export default function Footer(props: Props) {
  const { uploading, path, percentUploaded } = props

  if (uploading)
    return (
      <ProgressIndicator
        label={`Uploading ${percentUploaded}%`}
        percentComplete={percentUploaded * 0.01}
      />
    )

  return (
    <Stack.Item
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0.5em 0',
        gap: '1em',
      }}
    >
      <PrimaryButton type="Submit">Save</PrimaryButton>
      <DefaultButton href={path}>Cancel</DefaultButton>
    </Stack.Item>
  )
}
