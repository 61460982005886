import { Label, Text } from '@fluentui/react'

interface Props {
  label: string
  content?: string | null
}

export default function TextWithLabel({ label, content }: Props) {
  if (!content) return null

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Label style={{ marginRight: '0.2em', padding: '0.2em 0' }}>{`${label}:`}</Label>
      <Text>{content}</Text>
    </div>
  )
}
