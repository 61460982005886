import { MIMETYPE_DOCX } from '@blaw/contracts-api-schema'
import ApiClient from './ApiClient'
import { ResourceUploadLink, S3Fields } from './GetResourceUploadLink'
import Routes from './routes'

const apiClient = new ApiClient()
const routes = new Routes()

type PutVersionResponse = {
  version: string
  date: string
}

export async function PostVersionToS3(
  signedS3LinkData: ResourceUploadLink,
  file: Blob,
  onUploadProgress: (progressEvent: any) => void,
): Promise<string> {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  }
  await apiClient.publicPost(
    signedS3LinkData.url,
    buildFormData(file, signedS3LinkData.fields),
    config,
  )
  return signedS3LinkData.fields.key
}

function buildFormData(file: Blob, fields: S3Fields) {
  const payload = { ...fields, 'Content-Type': MIMETYPE_DOCX, file }

  return Object.entries(payload).reduce((data, [k, v]) => {
    data.append(k, v)
    return data
  }, new FormData())
}

export async function putVersionToApi(id: string, body: any) {
  const url = routes.putVersionUrl(id)
  const { data } = await apiClient.put<PutVersionResponse>(url, body)
  return data
}

export function formatVersionUploadBody(
  s3Key: string,
  fileName: string,
  parentId: string,
  description?: string,
  content_type?: string,
  overwriteVersion?: string,
) {
  return {
    s3Key,
    metadata: {
      userMetadata: {
        ...(description && { description }),
        ...(content_type && { content_type }),
      },
    },
    name: fileName,
    resourceType: 'document',
    mimeType: MIMETYPE_DOCX,
    parentId,
    overwriteVersion,
  }
}
