import { useContext, useEffect, useState } from 'react'
import { ActionButton, DefaultButton, PrimaryButton, SharedColors, Stack } from '@fluentui/react'
import ReactMarkdown from 'react-markdown'

import TopNav from '@components/TopNav'
import useDocumentSelection from '@hooks/useDocumentSelection'
import StyledStack from '@components/StyledStack'
import { StyledDivider } from '@baseComponents/StyledDivider'
import ApiClient from '@modules/ApiClient'
import { StoreContext } from '@contexts/StoreContext'
import QuickMessage from '@components/QuickMessage'
import LoadingShimmer from '@components/LoadingShimmer'
import StyledSlider from '@components/StyledSlider'
import { getDocBodyText, insertDiff, insertText } from '@modules/wordDocument'
import { Redline, markupToRedline } from '@modules/Redline'
import RedlineContent from '@components/RedlineContent'
import useComparisonToolbar from '@hooks/useComparisonToolbar'
import { formSubmitted, useContractTaskPaneViewed } from '@modules/analytics'
import LinkContentFooter from '@components/LinkContentFooter'
import { WEBAPP_HOST } from '@src/constants'
import { useTranslation } from '@hooks/useTranslation'

const pageTitle = 'Clause Adviser'
const NEUTRAL_SLIDER_VALUE = 2

interface RatingResult {
  rating: number
  reasoning: string
  partyA: string
  partyB: string
}

interface ModificationResult {
  reasoning: string
  redline: Redline
  text: string
}

type RatingResponse = {
  errorResponse?: any
  ratingResponse: RatingResponseData[]
}

type RatingResponseData = {
  rating: string
  reasoning: string
  party_a: string
  party_b: string
}

type ModificationResponse = {
  generateResponse: ModificationResponseData
}

type ModificationResponseData = {
  errorResponse?: string
  reasoning: string
  redline: Redline
  text: string
}

function ClauseAdviser() {
  const { routes, storeSessionInfo } = useContext(StoreContext)
  const selection = useDocumentSelection()

  const [selectedClause, setSelectedClause] = useState('')
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [originalRating, setOriginalRating] = useState<number | undefined>()
  const [originalReasoning, setOriginalReasoning] = useState<string | undefined>()
  const [generatedText, setGeneratedText] = useState<string | undefined>()
  const [generatedReasoning, setGeneratedReasoning] = useState<string | undefined>()
  const [sliderValue, setSliderValue] = useState(originalRating)
  const [selectedRating, setSelectedRating] = useState(sliderValue)
  const [partyA, setPartyA] = useState('')
  const [partyB, setPartyB] = useState('')
  const [markup, setMarkup] = useState<Redline>([])
  const [redline, setRedline] = useState('')
  const { showComparison, ComparisonToolbar } = useComparisonToolbar(true)
  const { t } = useTranslation()

  const apiClient = new ApiClient(storeSessionInfo, setError)

  const sliderOnChange = (val: number) => setSliderValue(val)

  const ratingColor =
    sliderValue && sliderValue > NEUTRAL_SLIDER_VALUE
      ? SharedColors.blue10
      : sliderValue && sliderValue < NEUTRAL_SLIDER_VALUE
      ? SharedColors.magenta10
      : SharedColors.gray30
  const sliderColor = loading ? SharedColors.gray30 : ratingColor

  const ratingMap = {
    1: `Favors ${partyA}`,
    2: 'Neutral',
    3: `Favors ${partyB}`,
  }

  function replace() {
    showComparison ? insertDiff(markup) : insertText(generatedText)
    formSubmitted({
      pageTitle,
      itemClicked: 'Replace Clause',
      eventDetails: [
        showComparison ? 'Replaced with redlined generated text' : 'Replaced with generated text',
      ],
    })
  }

  useEffect(() => {
    if (selection) {
      setSelectedClause(selection)
      getRating()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection])

  useContractTaskPaneViewed({ pageTitle })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <TopNav title={pageTitle} />

      {!selection && !selectedClause && (
        <QuickMessage
          msg="Please select a section of the text in your document to run Clause Adviser."
          type="info"
        />
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'hidden',
        }}
      >
        <StyledStack style={{ overflowY: 'auto', height: '100vh' }}>
          <Stack.Item>{renderBody()}</Stack.Item>
        </StyledStack>
        {renderControls()}
      </div>
    </div>
  )

  function renderBody() {
    if (!selection && !selectedClause) return
    if (error) return <QuickMessage msg={error} type="error" />
    if (loading) return <LoadingShimmer />
    return mainContent()
  }

  function mainContent() {
    if (selectedRating === originalRating)
      return (
        <div>
          {CALabel('Rating')}
          <p>{ratingToText(originalRating)}</p>
          {CALabel('Reasoning')}
          <ReactMarkdown>{originalReasoning}</ReactMarkdown>
        </div>
      )
    else
      return (
        <div>
          {CALabel('Original Rating')}
          <p>{ratingToText(originalRating)}</p>
          {CALabel('Generated Text')}
          <p>{renderContent()}</p>
          <ComparisonToolbar
            onChange={() => undefined}
            replace={replace}
            disableReplace={!selection || (showComparison && !redline)}
            style={{
              marginBottom: '14px',
            }}
          />
          {CALabel('New Rating')}
          <p>{ratingToText(selectedRating)}</p>
          {CALabel('Reasoning')}
          <ReactMarkdown>{generatedReasoning}</ReactMarkdown>
        </div>
      )
  }

  function renderContent() {
    return showComparison ? <RedlineContent markup={markup} /> : generatedText
  }

  function renderControls() {
    if (!selection && !selectedClause) return <></>
    return (
      <div>
        <StyledDivider />
        <StyledStack>
          {!!originalRating && (
            <>
              <h3 style={{ margin: '0 0 1em' }}>Modify Section</h3>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 0,
                }}
              >
                <div style={{ paddingRight: '0.5em' }}>
                  <b>Favors: </b>
                  {partyA}
                </div>
                <div>
                  <b>Favors: </b>
                  {partyB}
                </div>
              </div>
              <StyledSlider
                min={1}
                max={3}
                step={1}
                value={sliderValue}
                disabled={loading || !!error}
                onChange={sliderOnChange}
                sliderColor={sliderColor}
              />
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ActionButton
              onClick={revertRating}
              disabled={selectedRating === originalRating || loading || !!error}
            >
              Revert To Original
            </ActionButton>
            <div>
              <DefaultButton onClick={retry} disabled={loading}>
                Retry
              </DefaultButton>
              <PrimaryButton
                style={{ marginLeft: '0.5em' }}
                onClick={applyModification}
                disabled={
                  sliderValue === selectedRating ||
                  sliderValue === originalRating ||
                  loading ||
                  !!error
                }
              >
                Apply
              </PrimaryButton>
            </div>
          </div>
        </StyledStack>
        <LinkContentFooter href={WEBAPP_HOST} href_name={t('label.webapp_name')} />
      </div>
    )
  }

  async function putClauseAdvisorRating(body: string): Promise<RatingResult | undefined> {
    try {
      const response = await apiClient.put<RatingResponse>(routes.putClauseAdvisorUrl('rating'), [
        {
          text: selection,
          full_agreement_text: body,
          block_id: '',
          doc_id: '',
        },
      ])

      const result = response.data
      if ('errorResponse' in result) {
        throw new Error('Error getting rating. Please retry.')
      }

      const data = result.ratingResponse[0]
      if (data.rating === '0') {
        throw new Error(`Error getting rating: ${data.reasoning}`)
      }

      return {
        rating: parseInt(data.rating),
        reasoning: data.reasoning,
        partyA: data.party_a,
        partyB: data.party_b,
      }
    } catch (e) {
      console.error('Error getting rating')
      setError((e as Error).message)
    }
  }

  async function putClauseAdvisorModification(
    body: string,
  ): Promise<ModificationResult | undefined> {
    try {
      const payload = {
        requested_rating: sliderValue,
        original: {
          text: selectedClause,
          full_agreement_text: body,
          rating_response: {
            rating: originalRating,
            reasoning: originalReasoning,
            party_a: partyA,
            party_b: partyB,
          },
        },
      }
      const response = await apiClient.put<ModificationResponse>(
        routes.putClauseAdvisorUrl('generate'),
        payload,
      )
      const result = response.data
      const data = result.generateResponse

      if ('errorResponse' in result || !('reasoning' in data) || !('text' in data))
        throw new Error('Error generating modified clause. Please retry.')

      return { reasoning: data.reasoning, redline: data.redline, text: data.text }
    } catch (e) {
      console.error(e)
      setError((e as Error).message)
    }
  }

  async function getRating() {
    if (loading) return

    setError('')
    setLoading(true)
    const body = await getDocBodyText()
    const ratingResponse = await putClauseAdvisorRating(body)

    if (ratingResponse) {
      setOriginalRating(ratingResponse.rating)
      setSliderValue(ratingResponse.rating)
      setSelectedRating(ratingResponse.rating)
      setOriginalReasoning(ratingResponse.reasoning)
      setPartyA(ratingResponse.partyA)
      setPartyB(ratingResponse.partyB)
      formSubmitted({
        pageTitle,
        itemClicked: 'Get Clause Rating',
      })
    }
    setLoading(false)
  }

  async function applyModification() {
    if (loading) return
    setError('')
    setSelectedRating(sliderValue)
    setLoading(true)
    const body = await getDocBodyText()
    const modificationResponse = await putClauseAdvisorModification(body)
    if (modificationResponse) {
      setGeneratedText(modificationResponse.text)
      setGeneratedReasoning(modificationResponse.reasoning)
      setMarkup(modificationResponse.redline)
      setRedline(markupToRedline(modificationResponse.redline))
      formSubmitted({
        pageTitle,
        itemClicked: 'Modify Clause Rating',
      })
    }
    setLoading(false)
  }

  async function retry() {
    setLoading(true)
    selectedRating === originalRating ? await getRating() : await applyModification()
    formSubmitted({
      pageTitle,
      itemClicked: 'Retry',
    })
    setLoading(false)
  }

  function revertRating() {
    setSliderValue(originalRating)
    setSelectedRating(originalRating)
  }

  function ratingToText(rating?: number) {
    return ratingMap[rating as keyof typeof ratingMap]
  }
}

export default ClauseAdviser

function CALabel(text: string) {
  return <h3 style={{ textDecoration: 'underline' }}>{text}</h3>
}
