import { Dropdown, type IDropdownOption, ResponsiveMode } from '@fluentui/react'

import { Document } from '@blaw/contracts-api-schema'
import { FormInputOnChange } from '@hooks/useContractFormData'
import { useTranslation } from '@hooks/useTranslation'

const options: IDropdownOption[] = Document.contentType.getValues().map(item => ({
  key: item.value,
  text: item.label,
}))

type Props = {
  change: FormInputOnChange
  disabled: boolean
  fileCategoryKey?: string
}

export default function FileCategory(props: Props) {
  const { change, disabled, fileCategoryKey } = props
  const { t } = useTranslation()
  const placeholder = fileCategoryKey
    ? Document.contentType.getLabel(fileCategoryKey) || t('placeholder.select-one')
    : t('placeholder.select-one')

  return (
    <Dropdown
      label={t('label.file-category')}
      placeholder={placeholder}
      options={options}
      onChange={(_, option) => {
        change('fileCategory', option?.key.toString() || '')
      }}
      responsiveMode={ResponsiveMode.unknown}
      dropdownWidth="auto"
      disabled={disabled}
    />
  )
}
