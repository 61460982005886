import { Redline } from '@modules/Redline'

export interface Props {
  markup: Redline
}

export default function RedlineContent(props: Props) {
  return (
    <span className="redline">
      {props.markup.map((token, key) => {
        const {
          type,
          data: { text },
        } = token
        return (
          <span key={key} className={type.toLowerCase()}>
            {text}
          </span>
        )
      })}
    </span>
  )
}
