import { useContext } from 'react'
import { DefaultButton, MessageBar, MessageBarType } from '@fluentui/react'

import { AnalysisToolsContext } from '@contexts/AnalysisToolsContext'
import { itemClicked } from '@modules/analytics'

type Props = {
  message: string
  showAnalyzeBtn: boolean
  pageTitle: string
}

const AnalysisStaleWarning: React.FC<Props> = ({
  pageTitle,
  message,
  showAnalyzeBtn = true,
}: Props) => {
  const { analysisIsStale, setAnalysisIsStale, runEnabledAnalyses } =
    useContext(AnalysisToolsContext)

  function reRunAnalysis() {
    setAnalysisIsStale(false)
    runEnabledAnalyses()
  }

  function renderReAnalyzeButton() {
    if (!showAnalyzeBtn) return
    return (
      <DefaultButton
        style={{ marginTop: '0.5em', width: '100%' }}
        onClick={() => {
          reRunAnalysis()
          itemClicked({
            pageTitle,
            itemClicked: 'Reanalyze',
            itemLocation: 'middle',
            itemType: 'Form Submit',
            isLoggedIn: true,
          })
        }}
      >
        Reanalyze
      </DefaultButton>
    )
  }

  return (
    <div style={{ display: analysisIsStale ? '' : 'none', marginBottom: '0.2em' }}>
      <MessageBar messageBarType={MessageBarType.warning}>{message}</MessageBar>
      {renderReAnalyzeButton()}
    </div>
  )
}

export default AnalysisStaleWarning
