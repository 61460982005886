import { useState, useContext } from 'react'
import { MessageBar, MessageBarType, Stack, ProgressIndicator } from '@fluentui/react'
import { NeutralColors } from '@fluentui/theme'

import { MY_CLAUSES, SimilarClauseItem } from '@modules/ClauseAnalyzer'
import { SimilarClausesContext } from '@contexts/SimilarClausesContext'
import ShowMore from '@components/ShowMore'
import { getRedline, Redline } from '@modules/Redline'
import Routes from '@modules/routes'
import { friendlyDate, navigateToExternalSite } from '@modules/utils'
import BoldField from '@components/BoldField'
import { webappDocumentView, webappTemplateView } from '@modules/WebappPaths'
import { insertText, insertDiff } from '@modules/wordDocument'
import RedlineContent from '@components/RedlineContent'
import useComparisonToolbar from '@hooks/useComparisonToolbar'
import { useTranslation } from '@hooks/useTranslation'
import LinkButton from '@components/LinkButton'
import { itemClicked } from '@modules/analytics'

const routes = new Routes()

interface SimilarClauseProps {
  clause: SimilarClauseItem
  type: string
}

export default function SimilarClause({ clause, type }: SimilarClauseProps) {
  const [redline, setRedline] = useState('')
  const [markup, setMarkup] = useState<Redline>([])
  const [error, setError] = useState('')
  const {
    redlineSelection,
    selection,
    clauseReplacementMade,
    setClauseReplacementMade,
    clauseSourceLabels,
  } = useContext(SimilarClausesContext)
  const { showComparison, ComparisonToolbar } = useComparisonToolbar()

  const loadingRedline = showComparison && !error && !redline
  const showRedline = showComparison && redline
  const thisClauseWasReplaced = clauseReplacementMade === clause.id
  const { t } = useTranslation()

  function trackClick(buttonClicked: string) {
    itemClicked({
      isLoggedIn: true,
      pageTitle: 'Similar Clauses',
      itemClicked: buttonClicked,
      itemLocation: 'middle',
      itemType: 'Form Submit',
    })
  }

  function replace() {
    trackClick('Replace Clause')
    showComparison ? insertDiff(markup) : insertText(clause.text)
    setClauseReplacementMade(clause.id)
  }

  const showViewFullDocument =
    type === 'EDGAR' ||
    clause?.metadata?.clauseSource === 'contract' ||
    clause?.metadata?.clauseSource === 'template'
  return (
    <>
      <Stack.Item>
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '0.6em', marginBottom: '0.6em' }}
        >
          <BoldField
            label="Contract Name: "
            value={type !== MY_CLAUSES ? '' : clause.metadata.contractName}
          />
          <BoldField label="Contract Type: " value={clause.metadata.docType} />
          <BoldField
            label={type === MY_CLAUSES ? 'Counterparty: ' : 'Parties: '}
            value={clause.metadata.allParties?.join(', ') || ''}
          />
          <BoldField
            label="Filed Date: "
            value={
              type === MY_CLAUSES || !clause.metadata.filedDate
                ? ''
                : friendlyDate(clause.metadata.filedDate)
            }
          />
          <BoldField
            label={t('label.clause-source')}
            value={
              type !== MY_CLAUSES || !clause.metadata.clauseSource
                ? ''
                : clauseSourceLabels[clause.metadata.clauseSource]
            }
          />
        </div>
        {showViewFullDocument && (
          <LinkButton
            onClick={() => goToDocumentLink(clause, type)}
            iconName="OpenInNewTab"
            buttonStyles={{ label: { paddingLeft: '0.1em' } }}
          >
            {t('button.View Full Document')}
          </LinkButton>
        )}
        <div
          style={{
            background: NeutralColors.gray20,
            marginTop: '0.3em',
            padding: '0.5em 0.1em 0 0.5em',
          }}
        >
          {loadingRedline && <ProgressIndicator />}
          {error && (
            <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setError('')}>
              {error}
            </MessageBar>
          )}
          {showRedline && <RedlineContent markup={markup} />}
          <ShowMore
            maxLength={142}
            content={clause.text}
            style={showRedline ? { display: 'none' } : {}}
          />
          <ComparisonToolbar
            onChange={displayRedline}
            replace={replace}
            disableReplace={!selection || (showComparison && !redline)}
            error={error}
          />
        </div>
      </Stack.Item>

      <Stack.Item style={{ display: thisClauseWasReplaced ? '' : 'none' }}>
        <MessageBar
          messageBarType={MessageBarType.success}
          onDismiss={() => setClauseReplacementMade(null)}
        >
          This clause has been replaced. Select a new clause in the document to analyze.
        </MessageBar>
      </Stack.Item>
    </>
  )

  function goToDocumentLink(clause: SimilarClauseItem, type: string) {
    let path
    if (type === MY_CLAUSES) {
      path =
        clause?.metadata?.clauseSource === 'template'
          ? webappTemplateView(clause.documentId)
          : webappDocumentView(clause.documentId)
    } else {
      path = routes.clausePath(clause.documentId)
    }

    navigateToExternalSite(path)
  }

  async function displayRedline(_ev: React.MouseEvent<HTMLElement>, show?: boolean) {
    setError('')
    if (show && !redline) {
      try {
        trackClick('Show Comparison')
        const { redline, markup } = await getRedline(redlineSelection, clause.id)
        setRedline(redline)
        setMarkup(markup)
      } catch (error) {
        console.log(error)
        setError((error as Error)?.message ?? 'An error occurred')
        setRedline('')
        setMarkup([])
      }
    }
  }
}
