import {
  useState,
  useEffect,
  createContext,
  PropsWithChildren,
  FC,
  useContext,
  Dispatch,
  SetStateAction,
  useRef,
  MutableRefObject,
} from 'react'
import { useNavigate } from 'react-router-dom'

import {
  clearDefinedTermsInDocument,
  DefinedTerm,
  Issues,
  highlightDefinedTermsInDocument,
  getReferencesInDefinitionBlocks,
  getFirstRefsInRefNotDefn,
  Reference,
  getFirstRefText,
  IndexedLocation,
  Definition,
  Location,
} from '@modules/DocumentDefinitions'
import { fetchDocumentAnalysis } from '@modules/DocumentAnalysisAsync'
import { AnalysisToolsContext } from '@contexts/AnalysisToolsContext'
import { getDocBodyText } from '@modules/wordDocument'
import useFunctionBlocker from '@hooks/useFunctionBlocker'
import { textChanged } from '@modules/differ'
import { itemClicked } from '@modules/analytics'

type Props = PropsWithChildren

export type DefexAnalysis = {
  extraction_results: ExtractionResult[]
  undefined_spans: IndexedLocation[]
  issues: Issues
}

export type ExtractionResult = {
  term: IndexedLocation
  definition: Location
  references: IndexedLocation[]
}

type DefexAnalysisReshaped = {
  definitions: Definition[]
  undefined_spans: IndexedLocation[]
  issues: Issues
}

export interface DefinitionsContextState {
  selectedDefinitions: DefinedTerm[]
  loading: boolean
  error: string
  selectDefinition: (definitionIndex: number) => void
  deselectDefinition: (definitionIndex: number) => void
  callGetAndDisplayDefinitions: () => Promise<void>
  callGetDefinitions: () => Promise<void>
  reset: () => Promise<void>
  showingDefinitions: boolean
  toggledDefinitions: boolean
  setToggledDefinitions: Dispatch<SetStateAction<boolean>>
  analysisIsStale: boolean
  setAnalysisIsStale: React.Dispatch<React.SetStateAction<boolean>>
  definitionIssues: Issues
  issueCount: number
  definitions: DefinedTerm[]
  firstRefs: Reference[]
  firstRefsContent: string[]
  setNavigateToDefinitions: React.Dispatch<React.SetStateAction<boolean>>
  undefSpans: IndexedLocation[]
  definitionsLoaded: boolean
  clearDefinitions: () => Promise<void>
  clearDefinedTerms: () => Promise<void>
  termsCleared: MutableRefObject<boolean>
}
export const DefinitionsContext = createContext({} as DefinitionsContextState)

const DefinitionsContextProvider: FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [definitions, setDefinitions] = useState<DefinedTerm[]>([])
  const [contentControlIDs, setContentControlIDs] = useState<Map<number, number>>(new Map())
  const [selected, setSelected] = useState<DefinedTerm[]>([])
  const [showingDefinitions, setShowingDefinitions] = useState(false)
  const [toggledDefinitions, setToggledDefinitions] = useState(false)
  const { analysisIsStale, setAnalysisIsStale, docBodyText, setDocBodyText } =
    useContext(AnalysisToolsContext)
  const [error, setError] = useState('')
  const [definitionIssues, setDefinitionIssues] = useState<Issues>(initialIssues())
  const [issueCount, setIssueCount] = useState(0)
  const [firstRefs, setFirstRefs] = useState<Reference[]>([])
  const [firstRefsContent, setFirstRefsContent] = useState<string[]>([])
  const [navigateToDefinitions, setNavigateToDefinitions] = useState(true)
  const [undefSpans, setUndefSpans] = useState<IndexedLocation[]>([])
  const { DocumentSelectionChanged } = Office.EventType
  const { addHandlerAsync, removeHandlerAsync } = Office.context.document
  const navigate = useNavigate()
  const { blockableFunction } = useFunctionBlocker()
  const definitionsLoaded = !loading && showingDefinitions
  const termsCleared = useRef(false)
  const lastSelectedDefinitionId = useRef<number>()

  async function clearDefinitions() {
    await clearDefinedTerms()
    setDefinitions([])
    setSelected([])
  }

  async function clearDefinedTerms() {
    termsCleared.current = true
    await clearDefinedTermsInDocument()
    setContentControlIDs(new Map())
  }

  async function reset() {
    const id = await getSelectedContentControlID()
    if (id) lastSelectedDefinitionId.current = contentControlIDs.get(id)

    await clearDefinitions()
    setShowingDefinitions(false)
    setLoading(false)
    setError('')
    setAnalysisIsStale(false)
  }

  async function callGetAndDisplayDefinitions() {
    setLoading(true)
    setShowingDefinitions(true)
    await blockableFunction('Definitions', getAndDisplayDefinitions)
  }

  async function callGetDefinitions() {
    setLoading(true)
    setShowingDefinitions(true)
    await blockableFunction('Definitions', getDefinitionIssues)
  }

  async function getDefinitionIssues() {
    try {
      await getAndSetDefinitionIssues()
    } catch (e) {
      console.error(e)
      await reset()
      setError((e as Error)?.message ?? 'An error occurred')
    } finally {
      setLoading(false)
    }
  }

  async function getAndSetDefinitionIssues() {
    const { analysis } = await fetchDocumentAnalysis<DefexAnalysis>('defex')
    const { definitions: defs, undefined_spans: undefSpans, issues } = enrichDefexResponse(analysis)
    const definitions = getReferencesInDefinitionBlocks(defs)
    const references = getFirstRefsInRefNotDefn(defs, issues.REF_BEFORE_DEFN)
    const firstRefText = await getFirstRefText(references)
    const docBodyText = await getDocBodyText()

    setDocBodyText(docBodyText)
    setIssueCount(calculateIssueCount(issues))
    setDefinitionIssues(issues)
    setUndefSpans(undefSpans)
    setDefinitions(definitions)
    setFirstRefs(references)
    setFirstRefsContent(firstRefText)

    return { defs, undefSpans, issues }
  }

  async function getAndDisplayDefinitions() {
    try {
      const { defs } = await getAndSetDefinitionIssues()
      const contentControlIdToDefinitionId = await highlightDefinedTermsInDocument(
        defs,
        termsCleared,
        reset,
      )

      setContentControlIDs(contentControlIdToDefinitionId)
    } catch (e) {
      console.error(e)
      await reset()
      setError((e as Error)?.message ?? 'An error occurred')
    } finally {
      setLoading(false)
    }
  }

  function initialIssues(): Issues {
    return {
      NO_REF: [],
      REDEFINED: [],
      REF_BEFORE_DEFN: [],
      REF_NOT_CAP: [],
      CAP_WORD_MISSING_DEFN: [],
    }
  }

  function calculateIssueCount(definitionIssues: Issues): number {
    return (
      definitionIssues.NO_REF.length +
      definitionIssues.REDEFINED.length +
      definitionIssues.REF_BEFORE_DEFN.length +
      definitionIssues.REF_NOT_CAP.length +
      definitionIssues.CAP_WORD_MISSING_DEFN.length
    )
  }

  function selectDefinition(definitionId: number | undefined) {
    if (definitionId === undefined) return // DO NOT CHANGE THIS LINE
    if (selected.find(s => s.id === definitionId)) {
      setSelected([definitions[definitionId], ...selected.filter(s => s.id !== definitionId)])
      return
    }

    setSelected([definitions[definitionId], ...selected])
  }

  function deselectDefinition(definitionId: number) {
    setSelected(selected.filter(x => x.id !== definitionId))
  }

  async function checkSelection() {
    if (!navigateToDefinitions) {
      setNavigateToDefinitions(true)
      return
    }
    if (!showingDefinitions || loading) return

    const newDocBodyText = await getDocBodyText()
    setAnalysisIsStale(textChanged(newDocBodyText, docBodyText))

    const id = await getSelectedContentControlID()
    if (!id) return
    itemClicked({
      pageTitle: 'Defined Terms',
      itemClicked: 'Term',
      itemLocation: 'Word Document',
      itemType: 'Form Submit',
      isLoggedIn: true,
    })

    const definitionId = contentControlIDs.get(id)
    if (definitionId === undefined) return (lastSelectedDefinitionId.current = undefined)

    if (lastSelectedDefinitionId.current === definitionId) {
      lastSelectedDefinitionId.current = undefined
      return
    }

    selectDefinition(definitionId)
    lastSelectedDefinitionId.current = undefined

    if (location.hash.includes('analysis')) navigate('/definitions')
  }

  useEffect(() => {
    addHandlerAsync(DocumentSelectionChanged, checkSelection)
    return () => {
      removeHandlerAsync(DocumentSelectionChanged, { handler: checkSelection })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentControlIDs, selected, loading, showingDefinitions, docBodyText, navigateToDefinitions])

  useEffect(() => {
    return () => {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const value = {
    selectedDefinitions: selected,
    loading,
    error,
    selectDefinition,
    deselectDefinition,
    callGetAndDisplayDefinitions,
    callGetDefinitions,
    reset,
    showingDefinitions,
    toggledDefinitions,
    setToggledDefinitions,
    clearDefinitions,
    clearDefinedTerms,
    analysisIsStale,
    setAnalysisIsStale,
    definitionIssues,
    issueCount,
    definitions,
    firstRefs,
    firstRefsContent,
    setNavigateToDefinitions,
    undefSpans,
    definitionsLoaded,
    termsCleared,
  }

  return <DefinitionsContext.Provider value={value}>{props.children}</DefinitionsContext.Provider>
}

async function getSelectedContentControlID(): Promise<number | undefined> {
  return Word.run(async context => {
    const selection = context.document.getSelection()
    // The following line fails an eslint rule but the fix for it fails a different rule.
    // eslint-disable-next-line office-addins/call-sync-before-read, office-addins/load-object-before-read
    const contentControl = selection.parentContentControlOrNullObject
    if (!contentControl) return undefined

    contentControl.load('id')
    await context.sync()

    return contentControl.id
  })
}

function enrichDefexResponse(defex: DefexAnalysis): DefexAnalysisReshaped {
  return {
    definitions: mapReferences(defex.extraction_results),
    undefined_spans: defex.undefined_spans,
    issues: defex.issues,
  }
}

function mapReferences(definedTerms: ExtractionResult[]) {
  return definedTerms.map((d, definitionId) => {
    return { ...d, references: d.references.map(r => ({ ...r, definitionId })) }
  })
}

export default DefinitionsContextProvider
