import {
  DefaultButton,
  Dialog,
  DialogFooter,
  Icon,
  ProgressIndicator,
  SharedColors,
} from '@fluentui/react'

type Props = {
  hidden: boolean
  text: string
  error: boolean
  onDismiss: () => void
}

export default function ModalProgress({ hidden, text, error, onDismiss }: Props) {
  return (
    <Dialog hidden={hidden} onDismiss={onDismiss} modalProps={{ isBlocking: Boolean(error) }}>
      {error ? (
        <>
          <div style={{ display: 'flex' }}>
            <Icon
              iconName="ErrorBadge"
              styles={{ root: { margin: '0.2em 0.5em 0 0', color: SharedColors.red10 } }}
            />
            <div>{text}</div>
          </div>
          <DialogFooter>
            <DefaultButton onClick={onDismiss} text="Dismiss" />
          </DialogFooter>
        </>
      ) : (
        <>
          <div>{text}</div>
          <ProgressIndicator />
        </>
      )}
    </Dialog>
  )
}
