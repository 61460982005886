import { useEffect, useRef, useState } from 'react'

import LocalStorage from '@modules/LocalStorage'
import { getContentHash } from '@modules/DocumentDefinitions'
import { friendlyDate } from '@modules/utils'

// Stores state in `localStorage` under the given key.
// A content hash will be loaded in case the key is null by the time `setLocalState` is called.
// If the key value doesn't load and the hash fails to load in time before the component calls
// `setLocalState`, the fallback key will be used.
export default function useLocalState<T extends string | any[]>(
  value: T,
  key: string | null | undefined,
  prefix: string,
) {
  const storage = LocalStorage()
  const fallback = `${prefix}-${friendlyDate(new Date())}`
  const storedState = loadLocalState(key ?? fallback)
  const [state, setState] = useState(storedState)
  const hash = useRef<string | null>(null)
  const storageKey = key ?? hash.current ?? fallback

  useEffect(() => {
    if (!key) {
      getContentHash()
        .then(h => (hash.current = h))
        .catch(console.error)
    }
  }, [])

  useEffect(() => {
    if (hash.current && !key) setLocalState(loadLocalState(storageKey))
  }, [hash.current])

  function setLocalState(newState: T) {
    setState(newState)
    storage.setItem(storageKey, JSON.stringify(newState))
  }

  function clear() {
    setState(value)
    storage.removeItem(storageKey)
  }

  return [state, setLocalState, clear] as const

  function loadLocalState(key: string): T {
    return JSON.parse(storage.getItem(key) ?? 'null') ?? value
  }
}
