const TOPICS = [
  {
    name: 'Alternative Business Entities',
    links: [
      {
        label: 'Limited Liability Companies',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_llc',
      },
      {
        label: 'Limited Partnerships',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_lp',
      },
      {
        label: 'Nonstock & Benefit Corporations',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_nonstockbenefit',
      },
      {
        label: 'General Partnerships',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_partnership',
      },
    ],
  },
  {
    name: 'Commercial Transactions',
    links: [
      {
        label: 'Confidentiality Agreements Toolkit',
        link: 'https://www.bloomberglaw.com/product/blpg/page/txl_confidentiality_toolkit',
      },
      {
        label: 'Credit & Operations Agreements',
        link: 'https://www.bloomberglaw.com/product/blpg/page/pg_credit_operations',
      },
      {
        label: 'Engagement & Services Agreements',
        link: 'https://www.bloomberglaw.com/product/blpg/page/pg_engagement_services',
      },
      {
        label: 'Force Majeure',
        link: 'https://www.bloomberglaw.com/product/blpg/page/pg_force_majeure',
      },
      {
        label: 'Franchising',
        link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_franchising',
      },
      {
        label: 'Information Technology Agreements',
        link: 'https://www.bloomberglaw.com/product/blpg/page/pg_information_technology',
      },
      {
        label: 'Joint Venture',
        link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_joint_venture',
      },
      {
        label: 'Production & Sales Agreements',
        link: 'https://www.bloomberglaw.com/product/blpg/page/pg_production_sales',
      },
    ],
  },
  {
    name: 'Compliance',
    links: [
      {
        label: 'AML Compliance',
        link: 'https://www.bloomberglaw.com/product/blpg/page/bf_pg_aml',
      },
      {
        label: 'Antitrust Compliance',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_antitrust',
      },
      {
        label: 'Broker-Dealer Compliance',
        link: 'https://www.bloomberglaw.com/product/blpg/page/se_pg_broker_dealers',
      },
      {
        label: 'Building a Compliance Program',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_program',
      },
      {
        label: 'Corporate Investigations',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_investigations',
      },
      {
        label: 'Corruption & Other Corporate Misconduct',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_corruption',
      },
      {
        label: 'Investment Adviser Compliance',
        link: 'https://www.bloomberglaw.com/product/blpg/page/se_pg_investment_advisers',
      },
      {
        label: 'Preventing Noncompliance',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_prevention',
      },
      {
        label: 'Reg BI Compliance',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_bi_compliance',
      },
    ],
  },
  {
    name: 'Corporate Transactions',
    links: [
      {
        name: 'Due Diligence',
        links: [
          {
            label: 'Due Diligence Toolkit',
            link: 'https://www.bloomberglaw.com/product/blpg/page/due_diligence',
          },
        ],
      },
      {
        name: 'Mergers & Acquisitions',
        links: [
          {
            label: 'Going Private',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_going_private',
          },
          {
            label: 'M&A Auction',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_ma_auction',
          },
          {
            label: 'In-House M&A Toolkit',
            link: 'https://www.bloomberglaw.com/product/blpg/page/txl_inhouse_ma_toolkit',
          },
          {
            label: 'Pre-Sale Preparation',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_ma_presale',
          },
          {
            label: 'Private Asset Purchase',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_private_asset_purchase',
          },
          {
            label: 'Private Merger',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_private_merger',
          },
          {
            label: 'Public Asset Purchase',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_public_asset_purchase',
          },
          {
            label: 'Public Merger',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_public_merger',
          },
          {
            label: 'Spinoff',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_spinoff',
          },
          {
            label: 'Stock Purchase',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_stock_purchase',
          },
          {
            label: 'Takeover/Tender Offer',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_takeover_tender',
          },
        ],
      },
      {
        name: 'Finance',
        links: [
          {
            label: 'Acquisition Finance',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_acquisition_finance',
          },
          {
            label: 'Capital Equipment Leasing',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_capital_equipment_leasing',
          },
          {
            label: 'Energy Project Finance',
            link: 'https://www.bloomberglaw.com/product/blpg/page/txl_stepxstep_energy_finance',
          },
          {
            label: 'Equipment Leveraged Leasing',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_equipment_leveraged_leasing',
          },
          {
            label: 'Equipment Sale Leaseback',
            link: 'https://www.bloomberglaw.com/product/BTIC/page/txl_pg_equipment_leaseback',
          },
          {
            label: 'Follow-On Offering',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_followon_offering',
          },
          {
            label: 'Mezzanine Finance',
            link: 'https://www.bloomberglaw.com/product/blpg/page/txl_stepxstep_mezzanine_finance',
          },
          {
            label: 'Real Estate Project Finance',
            link: 'https://www.bloomberglaw.com/product/blpg/page/txl_stepxstep_real_estate_finance',
          },
          {
            label: 'Secured Revolving Credit Facility',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_secured_revolving_credit_facility',
          },
          {
            label: 'Secured Term Loan',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_secured_term_loan',
          },
          {
            label: 'Unsecured Revolving Credit Facility',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_unsecured_revolving_credit_facility',
          },
          {
            label: 'Unsecured Term Loan',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_unsecured_term_loan',
          },
        ],
      },
      {
        name: 'Registered Securities Offerings',
        links: [
          {
            label: 'At the Market Offerings',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_at_the_market_offerings',
          },
          {
            label: 'Direct Listing',
            link: 'https://www.bloomberglaw.com/product/blpg/page/txl_stepxstep_direct_listing',
          },
          {
            label: 'Initial Public Offering (IPO)',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_ipo',
          },
          {
            label: 'Registered Debt Offering',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_debt_offering',
          },
          {
            label: 'Registered High-Yield Debt Offering',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_high_yield_debt',
          },
          {
            label: 'Shelf Filing',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_shelf_filing',
          },
          {
            label: 'Shelf Takedown',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_shelf_takedown',
          },
          {
            label: 'WKSI Shelf Filing',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_wksi_shelf',
          },
          {
            label: 'WKSI Shelf Takedown - Debt',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_wksi_shelf_debt',
          },
          {
            label: 'WKSI Shelf Takedown - Equity',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_wksi_shelf_equity',
          },
        ],
      },
      {
        name: 'Unregistered Securities Offerings',
        links: [
          {
            label: 'Initial Coin Offering (ICO)',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_ico',
          },
          {
            label: 'Regulation A Offering',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_regulation_a_offering',
          },
          {
            label: 'Regulation CF Offering',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_regulation_cf_offering',
          },
          {
            label: 'Rule 144A Debt Offering',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_144a_debt_offering',
          },
          {
            label: 'Rule 144A High-Yield Debt Offering',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_144a_high_yield_debt',
          },
          {
            label: 'Rule 144A/Reg S Debt Offering',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_rule144a_regs_debt_offering',
          },
          {
            label: 'Regulation D',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_unregistered_offering',
          },
        ],
      },
      {
        name: 'Private Funds',
        links: [
          {
            label: 'Hedge Fund Formation',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_hedge_fund_formation',
          },
          {
            label: 'Investing in a Hedge Fund',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_investing_hedge_fund',
          },
          {
            label: 'IPO Exit',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_ipo_exit',
          },
          {
            label: 'Private Equity Fund Formation',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_fund_formation',
          },
          {
            label: 'Private Equity Leveraged Buyout',
            link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_leveraged_buyout_transactions',
          },
        ],
      },
    ],
  },
  {
    name: 'Employment',
    links: [
      {
        name: 'Hiring',
        links: [
          {
            label: 'Recruiting',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_recruiting',
          },
          {
            label: 'Immigration Compliance',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_immigration',
          },
          {
            label: 'New Hires Toolkit',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_new_hires',
          },
          {
            label: 'Pre-Hire Screening',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_pre_hire_screening',
          },
          {
            label: 'Restrictive Covenant Agreements',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_Restrictive_Covenant',
          },
        ],
      },
      {
        name: 'Employee Discipline & Termination',
        links: [
          {
            label: 'Alternative Dispute Resolution',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_adr',
          },
          {
            label: 'Termination Toolkit',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_termination',
          },
        ],
      },
      {
        name: 'Employee Rules & Conduct',
        links: [
          {
            label: 'Employee Handbook Toolkit',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_handbook_toolkit',
          },
          {
            label: 'Return-to-Office Toolkit',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_rto_toolkit',
          },
          {
            label: 'Workplace Conflicts of Interest',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_conflicts_interest',
          },
        ],
      },
      {
        name: 'Equal Employment Opportunity (EEO)',
        links: [
          {
            label: 'Workplace Nondiscrimination Toolkit',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_bias_discrimination',
          },
          {
            label: 'Affirmative Action',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_affirmative_action',
          },
          {
            label: 'Disability Discrimination & Accommodation',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_disability',
          },
          {
            label: 'EEOC Charges',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_eeoc_charges',
          },
          {
            label: 'New & Expecting Parents',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_new_parents',
          },
          {
            label: 'Sexual Harassment',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_sexual_harassment',
          },
          {
            label: 'Sexual Orientation & Gender Identity',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_orientation_identity',
          },
          {
            label: 'Whistleblowers & Retaliation',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_wb_retaliation',
          },
        ],
      },
      {
        name: 'Safety & Health',
        links: [
          {
            label: 'Employee Drug & Alcohol Testing',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_drug_alcohol',
          },
          {
            label: 'OSHA Compliance',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_osha',
          },
          {
            label: 'Workplace Emergencies & Violence',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_emergencies_violence',
          },
        ],
      },
      {
        name: 'Wages, Hours & Leave',
        links: [
          {
            label: 'Breaks & Schedules',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_breaks_schedules',
          },
          {
            label: 'Employee Leave',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_leave',
          },
          {
            label: 'Independent Contractors',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_independent_contractors',
          },
          {
            label: 'Wage & Overtime',
            link: 'https://www.bloomberglaw.com/product/blpg/page/le_pg_wage_overtime',
          },
        ],
      },
    ],
  },
  {
    name: 'Environmental, Social & Governance (ESG)',
    links: [
      {
        label: 'ESG Stakeholders, Frameworks & Regulation',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_stakeholders_frameworks_regulation',
      },
      {
        label: 'Identifying ESG Risks',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_esg_risks',
      },
      {
        label: 'ESG Issue Management',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_esg_management',
      },
      {
        label: 'Sustainable Finance',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_sustainable_finance',
      },
      {
        label: 'Financial Services Industry ESG Toolkit',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_esg_financial',
      },
      {
        label: 'Health Industry ESG Toolkit',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_esg_health',
      },
      {
        label: 'Manufacturing Industry ESG Toolkit',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_esg_manufacturing',
      },
      {
        label: 'Retail Industry ESG Toolkit',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_esg_retail',
      },
      {
        label: 'Technology Industry ESG Toolkit',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_esg_tech',
      },
    ],
  },
  {
    name: 'Executive Compensation & Benefits',
    links: [
      {
        label: 'ACA Compliance',
        link: 'https://www.bloomberglaw.com/product/blpg/page/bec_pg_aca',
      },
      {
        label: 'COBRA Compliance',
        link: 'https://www.bloomberglaw.com/product/blpg/page/bec_pg_cobra',
      },
      {
        label: 'Executive Compensation (Governance)',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pgtopic_compensation',
      },
      {
        label: 'HIPAA Compliance for Benefit Plans',
        link: 'https://www.bloomberglaw.com/product/blpg/page/bec_pg_hipaa',
      },
      {
        label: 'Nondiscrimination Rules & Testing for Benefit Plans',
        link: 'https://www.bloomberglaw.com/product/blpg/page/bec_pg_nondiscrimination',
      },
      {
        label: 'Nonqualified Deferred Compensation',
        link: 'https://www.bloomberglaw.com/product/blpg/page/bec_pg_nqdc',
      },
    ],
  },
  {
    name: 'Legal Department & Operations',
    links: [
      {
        label: 'AI Legal Issues Toolkit',
        link: 'https://www.bloomberglaw.com/product/blpg/page/ai_legal_issues_toolkit',
      },
      {
        label: 'Ethics & Social Responsibility',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_ethics',
      },
      {
        label: 'General Counsel Toolkit',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_general_counsel_toolkit',
      },
      {
        label: 'Knowledge Management & Record Retention',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_records_knowledge_mgmt',
      },
      {
        label: 'Legal Department Management & Optimization',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_legal_dept_mgmt',
      },
      {
        label: 'Legal Technology',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_legal_tech',
      },
      {
        label: 'Outside Legal Services',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_outside_legal_services',
      },
      {
        label: 'Practice Groups',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_practicegroups',
      },
      {
        label: 'Project Management for Legal Operations',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_project_mgmt',
      },
      {
        label: 'Vendor Management Toolkit',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pg_vendor_mgmt_toolkit',
      },
    ],
  },
  {
    name: 'Governance',
    links: [
      {
        label: 'Board of Directors',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_board',
      },
      {
        label: 'Board Committees',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_committees',
      },
      {
        label: 'Corporate Secretary & Meetings',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_secretary',
      },
      {
        label: 'Officers',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_directorsofficers',
      },
      {
        label: 'Entity Formation Toolkit',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_formation_toolkit',
      },
      {
        label: 'Executive Compensation',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_pgtopic_compensation',
      },
      {
        label: 'Insolvency & Dissolution',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_standing',
      },
      {
        label: 'Issuing Stock, Dividends & Repurchases',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_stock',
      },
      {
        label: 'Post-IPO Corporate Housekeeping',
        link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_housekeeping',
      },
      {
        label: 'SEC Periodic Reporting',
        link: 'https://www.bloomberglaw.com/product/blpg/page/stepxstep_periodic_reporting',
      },
      {
        label: 'Shareholders',
        link: 'https://www.bloomberglaw.com/product/blpg/page/cp_inpractice_shareholders',
      },
    ],
  },
]

export default TOPICS
