import { Outlet } from 'react-router-dom'
import { FC } from 'react'
import { ContentContainer, FlexContainer, LayoutContainer } from './Layout'

const HomepageLayout: FC = () => {
  return (
    <LayoutContainer>
      <FlexContainer>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </FlexContainer>
    </LayoutContainer>
  )
}

export default HomepageLayout
