export const DISPLAYED_RESOURCETYPES = [
  'contract',
  'folder',
  'document',
  'template_document',
  'template_folder',
  'primary_document',
  'library_clause',
  'extracted_clause',
  'project',
  'envelope',
  'envelope_document',
  'my_company_clause',
  'library_playbook',
  'company_playbook',
]
