import {
  IIconProps,
  SharedColors,
  ActionButton,
  IButtonStyles,
  IStyle,
  IButtonProps,
} from '@fluentui/react'
import { MouseEventHandler, PropsWithChildren } from 'react'

type Props = PropsWithChildren &
  IButtonProps & {
    href?: string
    target?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
    iconName?: string
    iconColor?: string
    buttonStyles?: IButtonStyles
  }

const rootStyles: IStyle & object = {
  textAlign: 'left',
  height: 'auto',
  margin: 0,
  padding: '0 0 0.3em 0',
  color: SharedColors.cyanBlue10,
}

export default function LinkButton({
  href,
  target,
  children,
  iconName,
  iconColor,
  buttonStyles,
  onClick,
  ...props
}: Props) {
  const styles = { ...rootStyles, ...(buttonStyles?.root as object) }
  const iconProps: IIconProps = { iconName }

  return (
    <ActionButton
      role="link"
      href={href}
      target={target}
      onClick={onClick}
      data-testid={iconName}
      iconProps={{ styles: { root: { marginLeft: 0, color: iconColor } }, ...iconProps }}
      {...props}
      styles={{
        ...buttonStyles,
        root: styles,
        label: { margin: 0 },
        labelHovered: { textDecoration: 'underline' },
      }}
    >
      {children}
    </ActionButton>
  )
}
