import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react'

import { type CustomFieldValueType, type FieldProps } from './ContractCustomFields'
import { useState } from 'react'

export const yes = 'Yes'
export const no = 'No'
const booleanChoices: IChoiceGroupOption[] = [
  { key: yes, text: yes },
  { key: no, text: no },
]

export default function CustomBooleanField({
  name,
  value,
  prefix,
  disabled,
  onChange,
}: FieldProps) {
  const [valueState, setValueState] = useState(value)
  const selected = getSelected(valueState.value)

  return (
    <ChoiceGroup
      data-testid={name}
      options={booleanChoices}
      value={selected}
      selectedKey={selected}
      disabled={disabled}
      onChange={(_e, option) => {
        const newState = { ...valueState, value: option?.key === yes }
        setValueState(newState)
        onChange(prefix, newState)
      }}
    />
  )

  function getSelected(v: CustomFieldValueType) {
    if (typeof v === 'boolean') return v ? yes : no
  }
}
