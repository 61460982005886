export interface ClauseForm {
  [key: string]: string
  clauseText: string
  clauseType: string
  contractType: string
  riskLevel: string
}

export const clauseFormFieldDefaults: Required<ClauseForm> = {
  clauseText: '',
  clauseType: '',
  contractType: '',
  riskLevel: '',
}
