/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 70
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/bindg/Global%20Product%20Workspace/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser'

export type Environment =
  | 'developmentbloomberglaw'
  | 'productionbloomberglaw'
  | 'developmentbloombergtax'
  | 'productionbloombergtax'
  | 'developmentbloomberggovernment'
  | 'productionbloomberggovernment'
  | 'developmentcustomertools'
  | 'developmentnewsstandalone'
  | 'productionnewsstandalone'
  | 'developmenttaxsoftware'
  | 'productiontaxsoftware'
  | 'productioncustomertools'

export const ApiKey: Record<Environment, string> = {
  developmentbloomberglaw: '3d8b8ba9d78634dbf8a667fcec511e5d',
  productionbloomberglaw: '1e1ad7505970cfda0d7c016085cafe2a',
  developmentbloombergtax: 'fd2835efceef98efe31953a5bfffc673',
  productionbloombergtax: 'a86b3ce6846defb68cfe0634a7f8c3c3',
  developmentbloomberggovernment: 'ba56ea4c7e7340a0f80f3526aa20ab6f',
  productionbloomberggovernment: '32777361ca58c27790e22eb09107aa88',
  developmentcustomertools: '701f4c5a60f2e050cd9bf2331e79b3c1',
  developmentnewsstandalone: '1b7306bc3dc43a809f154b3277911d13',
  productionnewsstandalone: '6c4173f7a8e22f7ffbf24eb3303a9b8b',
  developmenttaxsoftware: '43e1e8a85091c4c89690c08473a5c32b',
  productiontaxsoftware: '9319e11fe19f02df34806897aff50147',
  productioncustomertools: 'd5246b8788a94bd934b2c712a9a1419e',
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '70',
    branch: 'main',
    source: 'web',
    versionId: 'cda83e5f-c429-4205-9bf8-b2234b85b67e',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
}

export interface LoadOptionsBase {
  disabled?: boolean
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment
  client?: { configuration?: BrowserOptions }
}
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions }
}
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient }
}

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance

export interface IdentifyProperties {
  /**
   * 1/1/22, 1/2/22
   */
  accountCreation?: string
  /**
   * * EMPL - EMPLOYEE
   *
   * * LFSM - LAW FIRM - SMALL
   *
   * * LFMD - LAW FIRM - MEDIUM
   *
   * * SCRP - CORPORATION - STRATEGIC
   *
   * * LSCH - LAW SCHOOL
   *
   * * TNAC - TAX & ACCOUNTING - NOT STRATEGIC
   *
   * * CORP - CORPORATION - NOT STRATEGIC
   *
   * * STAC - TAX & ACCOUNTING - STRATEGIC
   *
   * * LFVL - LAW FIRM - VERY LARGE
   *
   * * LFLG - LAW FIRM - LARGE
   *
   * * ASSN - ASSOCIATION - NOT STRATEGIC
   *
   * * NOT_AVAILABLE - NOT AVAILABLE
   *
   * * FGOV - FEDERAL GOVERNMENT
   *
   * * UNIV - UNIVERSITY
   *
   * * BSCH - BUSINESS SCHOOL
   *
   * * Terminal - TERMINAL
   *
   * * SASN - ASSOCIATION - STRATEGIC
   *
   * * LS2 - LAW SCHOOL
   *
   * * SGOV - STATE/LOCAL GOVERNMENT
   *
   * * WLTH - ??
   *
   * * SUBA - SUBSCRIPTION AGENCY
   *
   * * SCRT - STATE COURTS
   *
   * * TAX - STRATEGIC CORP - TAX
   *
   * * LFS - SMALL LAW FIRMS
   *
   * * SCP - STRATEGIC CORP
   *
   * * CPA - TAX/ACCOUNTING FIRMS
   *
   * * COR - CORPORATIONS NON STRATEGIC
   *
   * * FG - ??
   *
   * * LS3 - LAW SCHOOL
   *
   * * SL3 - STATE COURTS CONSULTING
   */
  accountType?: string
  authenticationMethod?: string
  /**
   * i.e., **BINDG Device Identifier**
   *
   * This is a cookie that is set across all INDG domains, currently it's used for compliance and cross-product access.  Supported domains are:
   *
   * * BloombergIndustry.com
   *
   * * BloombergLaw.com
   *
   * * BloombergTax.com
   *
   * * BNA.com
   *
   * BGOV.com will be added soon.
   */
  bid?: string
  /**
   * Bloomberg LP Firm ID
   */
  blpFirmId?: any
  /**
   * UUID
   */
  blpUserId?: string
  /**
   * Need to verify, I believe the Customer Number
   *
   * ***Should always exist if the user is logged in***
   */
  customerNumber?: any
  /**
   * Google campaign manager Click Identifier
   */
  dclid?: string
  /**
   * Facebook Click Identifier from URL parameters
   */
  fbclid?: string
  /**
   * Google Click Identifier for iOS device from Web to App
   */
  gbraid?: string
  /**
   * Google Click Identifier from URL parameters
   */
  gclid?: string
  indgUnitNumber?: any
  /**
   * INDG User ID
   */
  indgUserId?: string
  /**
   * Initial Google campaign manager Click Identifier
   */
  initial_dclid?: string
  /**
   * Initial Facebook Click Identifier from URL parameters
   */
  initial_fbclid?: string
  /**
   * Initial Google Click Identifier for iOS device from Web to App
   */
  initial_gbraid?: string
  /**
   * Initial Google Click Identifier from URL parameters
   */
  initial_gclid?: string
  /**
   * Initial Kochava Click Identifier from URL parameters
   */
  initial_ko_click_id?: string
  /**
   * Initial Linkedin Click Identifier from URL parameters.
   *
   * It is a member indirect identifier for Members for conversion tracking, retargeting, and analytics.
   */
  initial_li_fat_id?: string
  /**
   * Initial Microsoft Click Identifier
   */
  initial_msclkid?: string
  initial_referrer?: string
  initial_referring_domain?: string
  /**
   * Initial TikTok Click Identifier
   */
  initial_rtd_cid?: string
  /**
   * Initial TikTok Click Identifier
   */
  initial_ttclid?: string
  /**
   * Initial Twitter Click Identifier from URL parameter
   */
  initial_twclid?: string
  initial_utm_campaign?: string
  initial_utm_content?: string
  /**
   * An optional parameter for tracking unique IDs or transaction IDs associated with the link.
   */
  initial_utm_id?: string
  initial_utm_medium?: string
  initial_utm_source?: string
  initial_utm_term?: string
  /**
   * Initial Google Click Identifier for iOS device from App to Web
   */
  initial_wbraid?: string
  internalUserFlag?: string
  /**
   * Kochava Click Identifier from URL parameters
   */
  ko_click_id?: string
  /**
   * first time, 1/1/22, 1/2/22
   */
  lastLogin?: string
  /**
   * Microsoft Click Identifier
   */
  msclkid?: string
  /**
   * What subscriptions does a user have.  Each user may have one or more entitlements and these entitlements can change.  We are adding these to help provide direction, but for detailed and more exact usage you should query someone on the product analytics team.
   *
   *  In the user tables, this is the "item" code and will look like:
   *
   *  BLAW## or BTAX## where ## is a 2 digit number.
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  productEntitlement?: string[]
  referrer?: string
  referring_domain?: string
  /**
   * Trial, Firm, Anonymous, Free Trial - Where "Free Trial" are people who get a trial account via our webstore.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Trial, Firm, Anonymous, Free Trial |
   */
  subscriptionType?: 'Trial' | 'Firm' | 'Anonymous' | 'Free Trial'
  /**
   * TikTok Click Identifier
   */
  ttclid?: string
  /**
   * Twitter Click Identifier from URL parameter
   */
  twclid?: string
  userAgent?: string
  /**
   * librarian, attorney, etc
   */
  userRole?: string
  utm_campaign?: string
  utm_content?: string
  utm_medium?: string
  utm_source?: string
  utm_term?: string
  /**
   * Google Click Identifier for iOS device from App to Web
   */
  wbraid?: string
}

export interface GroupProperties {
  '[Amplitude] Group ID'?: any
  '[Amplitude] Group name'?: any
  /**
   * * EMPL - EMPLOYEE
   *
   * * LFSM - LAW FIRM - SMALL
   *
   * * LFMD - LAW FIRM - MEDIUM
   *
   * * SCRP - CORPORATION - STRATEGIC
   *
   * * LSCH - LAW SCHOOL
   *
   * * TNAC - TAX & ACCOUNTING - NOT STRATEGIC
   *
   * * CORP - CORPORATION - NOT STRATEGIC
   *
   * * STAC - TAX & ACCOUNTING - STRATEGIC
   *
   * * LFVL - LAW FIRM - VERY LARGE
   *
   * * LFLG - LAW FIRM - LARGE
   *
   * * ASSN - ASSOCIATION - NOT STRATEGIC
   *
   * * NOT_AVAILABLE - NOT AVAILABLE
   *
   * * FGOV - FEDERAL GOVERNMENT
   *
   * * UNIV - UNIVERSITY
   *
   * * BSCH - BUSINESS SCHOOL
   *
   * * Terminal - TERMINAL
   *
   * * SASN - ASSOCIATION - STRATEGIC
   *
   * * LS2 - LAW SCHOOL
   *
   * * SGOV - STATE/LOCAL GOVERNMENT
   *
   * * WLTH - ??
   *
   * * SUBA - SUBSCRIPTION AGENCY
   *
   * * SCRT - STATE COURTS
   *
   * * TAX - STRATEGIC CORP - TAX
   *
   * * LFS - SMALL LAW FIRMS
   *
   * * SCP - STRATEGIC CORP
   *
   * * CPA - TAX/ACCOUNTING FIRMS
   *
   * * COR - CORPORATIONS NON STRATEGIC
   *
   * * FG - ??
   *
   * * LS3 - LAW SCHOOL
   *
   * * SL3 - STATE COURTS CONSULTING
   */
  accountType?: string
  /**
   * By Default this should be set to "Anonymous" for users who aren't logged in.. For users who are logged in this will be set to their corresponding ATI/CTI value, except instead of using the acronym, it will be the full name... So "EMPL" will be "Employee".
   */
  accountTypeName?: string
  /**
   * Bloomberg LP Firm ID
   */
  blpFirmId?: string
  /**
   * The physical name of the company - based off of Unit Number
   */
  companyName?: string
  /**
   * Need to verify, I believe the Customer Number
   *
   * ***Should always exist if the user is logged in***
   */
  customerNumber?: string
  /**
   * The INDG Firm ID
   *
   * ***Should always exist if the user is logged in***
   */
  indgUnitNumber?: string
}

export interface AmplitudePageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Page Domain'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Page Location'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Page Path'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Page Title'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Page URL'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string
}

export interface AcademicRegistrationProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This should be the label of the specific item clicked
   */
  itemClicked: string
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Region on the page  where the click event occurs
   */
  itemLocation: string
  /**
   * This should be used to determine if it was:
   *
   * * Advertisement
   *
   * * Request Demo
   *
   * * Informational
   *
   * * Help
   *
   * * Qualtrics
   *
   * * etc...
   */
  itemType: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface AddedToWorkspaceProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
  /**
   * full url for the page
   */
  url: string
}

export interface AlertsInboxViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface AlertsManagementViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ArticleExportedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Stores the size of the item downloaded in KB
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  downloadSize?: number
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ArticleListenClosedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface ArticleListenOpenedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface ArticleListenPausedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ArticleListenPlayedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface ArticleListenSkippedBackProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface ArticleListenSkippedForwardProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface ArticleSavedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface ArticleSharedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Stores the size of the item downloaded in KB
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  downloadSize?: number
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface ArticleViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface BriefAnalyzerUsedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface BudgetToSpendUsedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface CalendarEventViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ChartBuilderViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   */
  chartGroup?: string
  /**
   * Numeric value of chart ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  chartId?: number
  /**
   * List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartJurisdictions?: string[]
  /**
   * Granular action taken to modify chart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Filter Chart, Update Date Range, Update Chart Type, Add Chart Options, Remove Chart Options, Modify Column Header, Column Header - Topics, Column Header - Jurisdictions, Answer - short, Answer - summary, Answer - full |
   */
  chartModifyAction?:
    | 'Filter Chart'
    | 'Update Date Range'
    | 'Update Chart Type'
    | 'Add Chart Options'
    | 'Remove Chart Options'
    | 'Modify Column Header'
    | 'Column Header - Topics'
    | 'Column Header - Jurisdictions'
    | 'Answer - short'
    | 'Answer - summary'
    | 'Answer - full'
  /**
   * Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartOptions?: string[]
  /**
   * Indicates whether the chart being viewed was just created, just modified, or was previously saved.
   *
   * Example: new, modified, saved
   */
  chartStatus?: string
  /**
   * List of selected topics when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartTopics?: string[]
  /**
   * The type of chart associated with the Chart Builder or Chart itself
   *
   * Chart Builder example: pass-through entity, excise tax
   *
   * Historical Spend example: Line
   */
  chartType?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * How was the table modified
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Add Table Fields, Remove Table Fields, Reorder Table Fields, Reset Table Fields, Set Table Limits |
   */
  tableModifyAction?:
    | 'Add Table Fields'
    | 'Remove Table Fields'
    | 'Reorder Table Fields'
    | 'Reset Table Fields'
    | 'Set Table Limits'
  /**
   * Array of table fields
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tableOptions?: string[]
  /**
   * Count of options selected to build chart
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tableOptionsCount?: number
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ChartDisplayModifiedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   */
  chartGroup?: string
  /**
   * Numeric value of chart ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  chartId?: number
  /**
   * List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartJurisdictions?: string[]
  /**
   * Granular action taken to modify chart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Filter Chart, Update Date Range, Update Chart Type, Add Chart Options, Remove Chart Options, Modify Column Header, Column Header - Topics, Column Header - Jurisdictions, Answer - short, Answer - summary, Answer - full |
   */
  chartModifyAction?:
    | 'Filter Chart'
    | 'Update Date Range'
    | 'Update Chart Type'
    | 'Add Chart Options'
    | 'Remove Chart Options'
    | 'Modify Column Header'
    | 'Column Header - Topics'
    | 'Column Header - Jurisdictions'
    | 'Answer - short'
    | 'Answer - summary'
    | 'Answer - full'
  /**
   * Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartOptions?: string[]
  /**
   * Indicates whether the chart being viewed was just created, just modified, or was previously saved.
   *
   * Example: new, modified, saved
   */
  chartStatus?: string
  /**
   * List of selected topics when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartTopics?: string[]
  /**
   * The type of chart associated with the Chart Builder or Chart itself
   *
   * Chart Builder example: pass-through entity, excise tax
   *
   * Historical Spend example: Line
   */
  chartType?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * How was the table modified
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Add Table Fields, Remove Table Fields, Reorder Table Fields, Reset Table Fields, Set Table Limits |
   */
  tableModifyAction?:
    | 'Add Table Fields'
    | 'Remove Table Fields'
    | 'Reorder Table Fields'
    | 'Reset Table Fields'
    | 'Set Table Limits'
  /**
   * Array of table fields
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tableOptions?: string[]
  /**
   * Count of options selected to build chart
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tableOptionsCount?: number
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ChartExportedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   */
  chartGroup?: string
  /**
   * Numeric value of chart ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  chartId?: number
  /**
   * List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartJurisdictions?: string[]
  /**
   * Granular action taken to modify chart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Filter Chart, Update Date Range, Update Chart Type, Add Chart Options, Remove Chart Options, Modify Column Header, Column Header - Topics, Column Header - Jurisdictions, Answer - short, Answer - summary, Answer - full |
   */
  chartModifyAction?:
    | 'Filter Chart'
    | 'Update Date Range'
    | 'Update Chart Type'
    | 'Add Chart Options'
    | 'Remove Chart Options'
    | 'Modify Column Header'
    | 'Column Header - Topics'
    | 'Column Header - Jurisdictions'
    | 'Answer - short'
    | 'Answer - summary'
    | 'Answer - full'
  /**
   * Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartOptions?: string[]
  /**
   * Indicates whether the chart being viewed was just created, just modified, or was previously saved.
   *
   * Example: new, modified, saved
   */
  chartStatus?: string
  /**
   * List of selected topics when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartTopics?: string[]
  /**
   * The type of chart associated with the Chart Builder or Chart itself
   *
   * Chart Builder example: pass-through entity, excise tax
   *
   * Historical Spend example: Line
   */
  chartType?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Stores the size of the item downloaded in KB
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  downloadSize?: number
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * How was the table modified
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Add Table Fields, Remove Table Fields, Reorder Table Fields, Reset Table Fields, Set Table Limits |
   */
  tableModifyAction?:
    | 'Add Table Fields'
    | 'Remove Table Fields'
    | 'Reorder Table Fields'
    | 'Reset Table Fields'
    | 'Set Table Limits'
  /**
   * Array of table fields
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tableOptions?: string[]
  /**
   * Count of options selected to build chart
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tableOptionsCount?: number
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ChartFavoritedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   */
  chartGroup?: string
  /**
   * Numeric value of chart ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  chartId?: number
  /**
   * List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartJurisdictions?: string[]
  /**
   * Granular action taken to modify chart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Filter Chart, Update Date Range, Update Chart Type, Add Chart Options, Remove Chart Options, Modify Column Header, Column Header - Topics, Column Header - Jurisdictions, Answer - short, Answer - summary, Answer - full |
   */
  chartModifyAction?:
    | 'Filter Chart'
    | 'Update Date Range'
    | 'Update Chart Type'
    | 'Add Chart Options'
    | 'Remove Chart Options'
    | 'Modify Column Header'
    | 'Column Header - Topics'
    | 'Column Header - Jurisdictions'
    | 'Answer - short'
    | 'Answer - summary'
    | 'Answer - full'
  /**
   * Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartOptions?: string[]
  /**
   * Indicates whether the chart being viewed was just created, just modified, or was previously saved.
   *
   * Example: new, modified, saved
   */
  chartStatus?: string
  /**
   * List of selected topics when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartTopics?: string[]
  /**
   * The type of chart associated with the Chart Builder or Chart itself
   *
   * Chart Builder example: pass-through entity, excise tax
   *
   * Historical Spend example: Line
   */
  chartType?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * How was the table modified
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Add Table Fields, Remove Table Fields, Reorder Table Fields, Reset Table Fields, Set Table Limits |
   */
  tableModifyAction?:
    | 'Add Table Fields'
    | 'Remove Table Fields'
    | 'Reorder Table Fields'
    | 'Reset Table Fields'
    | 'Set Table Limits'
  /**
   * Array of table fields
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tableOptions?: string[]
  /**
   * Count of options selected to build chart
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tableOptionsCount?: number
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ChartHiddenProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   */
  chartGroup?: string
  /**
   * Numeric value of chart ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  chartId?: number
  /**
   * List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartJurisdictions?: string[]
  /**
   * Granular action taken to modify chart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Filter Chart, Update Date Range, Update Chart Type, Add Chart Options, Remove Chart Options, Modify Column Header, Column Header - Topics, Column Header - Jurisdictions, Answer - short, Answer - summary, Answer - full |
   */
  chartModifyAction?:
    | 'Filter Chart'
    | 'Update Date Range'
    | 'Update Chart Type'
    | 'Add Chart Options'
    | 'Remove Chart Options'
    | 'Modify Column Header'
    | 'Column Header - Topics'
    | 'Column Header - Jurisdictions'
    | 'Answer - short'
    | 'Answer - summary'
    | 'Answer - full'
  /**
   * Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartOptions?: string[]
  /**
   * Indicates whether the chart being viewed was just created, just modified, or was previously saved.
   *
   * Example: new, modified, saved
   */
  chartStatus?: string
  /**
   * List of selected topics when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartTopics?: string[]
  /**
   * The type of chart associated with the Chart Builder or Chart itself
   *
   * Chart Builder example: pass-through entity, excise tax
   *
   * Historical Spend example: Line
   */
  chartType?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * How was the table modified
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Add Table Fields, Remove Table Fields, Reorder Table Fields, Reset Table Fields, Set Table Limits |
   */
  tableModifyAction?:
    | 'Add Table Fields'
    | 'Remove Table Fields'
    | 'Reorder Table Fields'
    | 'Reset Table Fields'
    | 'Set Table Limits'
  /**
   * Array of table fields
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tableOptions?: string[]
  /**
   * Count of options selected to build chart
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tableOptionsCount?: number
  /**
   * full url for the page
   */
  url: string
}

export interface ChartShownProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   */
  chartGroup?: string
  /**
   * Numeric value of chart ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  chartId?: number
  /**
   * List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartJurisdictions?: string[]
  /**
   * Granular action taken to modify chart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Filter Chart, Update Date Range, Update Chart Type, Add Chart Options, Remove Chart Options, Modify Column Header, Column Header - Topics, Column Header - Jurisdictions, Answer - short, Answer - summary, Answer - full |
   */
  chartModifyAction?:
    | 'Filter Chart'
    | 'Update Date Range'
    | 'Update Chart Type'
    | 'Add Chart Options'
    | 'Remove Chart Options'
    | 'Modify Column Header'
    | 'Column Header - Topics'
    | 'Column Header - Jurisdictions'
    | 'Answer - short'
    | 'Answer - summary'
    | 'Answer - full'
  /**
   * Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartOptions?: string[]
  /**
   * Indicates whether the chart being viewed was just created, just modified, or was previously saved.
   *
   * Example: new, modified, saved
   */
  chartStatus?: string
  /**
   * List of selected topics when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartTopics?: string[]
  /**
   * The type of chart associated with the Chart Builder or Chart itself
   *
   * Chart Builder example: pass-through entity, excise tax
   *
   * Historical Spend example: Line
   */
  chartType?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * How was the table modified
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Add Table Fields, Remove Table Fields, Reorder Table Fields, Reset Table Fields, Set Table Limits |
   */
  tableModifyAction?:
    | 'Add Table Fields'
    | 'Remove Table Fields'
    | 'Reorder Table Fields'
    | 'Reset Table Fields'
    | 'Set Table Limits'
  /**
   * Array of table fields
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tableOptions?: string[]
  /**
   * Count of options selected to build chart
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tableOptionsCount?: number
  /**
   * full url for the page
   */
  url: string
}

export interface ChartViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   */
  chartGroup?: string
  /**
   * Numeric value of chart ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  chartId?: number
  /**
   * List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartJurisdictions?: string[]
  /**
   * Granular action taken to modify chart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Filter Chart, Update Date Range, Update Chart Type, Add Chart Options, Remove Chart Options, Modify Column Header, Column Header - Topics, Column Header - Jurisdictions, Answer - short, Answer - summary, Answer - full |
   */
  chartModifyAction?:
    | 'Filter Chart'
    | 'Update Date Range'
    | 'Update Chart Type'
    | 'Add Chart Options'
    | 'Remove Chart Options'
    | 'Modify Column Header'
    | 'Column Header - Topics'
    | 'Column Header - Jurisdictions'
    | 'Answer - short'
    | 'Answer - summary'
    | 'Answer - full'
  /**
   * Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartOptions?: string[]
  /**
   * Indicates whether the chart being viewed was just created, just modified, or was previously saved.
   *
   * Example: new, modified, saved
   */
  chartStatus?: string
  /**
   * List of selected topics when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartTopics?: string[]
  /**
   * The type of chart associated with the Chart Builder or Chart itself
   *
   * Chart Builder example: pass-through entity, excise tax
   *
   * Historical Spend example: Line
   */
  chartType?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * How was the table modified
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Add Table Fields, Remove Table Fields, Reorder Table Fields, Reset Table Fields, Set Table Limits |
   */
  tableModifyAction?:
    | 'Add Table Fields'
    | 'Remove Table Fields'
    | 'Reorder Table Fields'
    | 'Reset Table Fields'
    | 'Set Table Limits'
  /**
   * Array of table fields
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tableOptions?: string[]
  /**
   * Count of options selected to build chart
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tableOptionsCount?: number
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ConfirmationFormViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ContentExportedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Stores the size of the item downloaded in KB
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  downloadSize?: number
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ContentFavoritedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * This is the title or label for content that has been favorited
   */
  contentTitle: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ContractTaskPaneViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DashboardViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DirectoryViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Comma delimited list of content types included in the search results
   */
  resultContentKinds?: string
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * This is meant exclusively for INDG News articles
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchArticleType?: string[]
  /**
   * This call be all dates, to the range of one or more dates
   */
  searchDateRange?: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   */
  searchResponseType?: string
  /**
   * the search results page number where an action occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsPage?: number
  /**
   * Numeric indicator of how many search results are visible on a page.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsVisible?: number
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Sort by Date or Relevance
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | date, relevance, court, most cited, table of contents |
   */
  searchSortBy?: 'date' | 'relevance' | 'court' | 'most cited' | 'table of contents'
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DocketEntriesFilteredProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  caseType?: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * court name, eg. United States District Court For The Southern District Of Texas
   */
  court?: string
  /**
   * state or federal
   */
  courtJurisdiction?: string
  /**
   * The date the docket entry was filed
   */
  dateFiled?: string
  /**
   * complaint, notice, summon
   */
  docketKeyType?: string
  /**
   * The Docket No...  ie... 2:16-cv-11082
   */
  docketNumber?: string
  /**
   * Federal, State, and Pleading
   */
  docketType?: string
  /**
   * regular, udv
   */
  docketViewer?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Date selected for [Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Entry Filter
   */
  entryDate: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * Filing Type for [Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Entry Filter
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filingType: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * Personal Injury - Other
   */
  natureOfSuit?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * EXT ID number / Entry number
   */
  pleadingId?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DocketTrackAlertCreatedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   */
  alertType: string
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  caseType?: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * Is there anything listed in the "Client Matter" box?
   */
  clientMatterExists?: boolean
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * court name, eg. United States District Court For The Southern District Of Texas
   */
  court?: string
  /**
   * state or federal
   */
  courtJurisdiction?: string
  /**
   * The date the docket entry was filed
   */
  dateFiled?: string
  /**
   * Description Field in an Alert/Docket Track has been used.
   */
  descriptionExists: boolean
  /**
   * The number of recipients added to an email (including the sender)... This number could be 0.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  distributedCount: number
  /**
   * complaint, notice, summon
   */
  docketKeyType?: string
  /**
   * The Docket No...  ie... 2:16-cv-11082
   */
  docketNumber?: string
  /**
   * Federal, State, and Pleading
   */
  docketType?: string
  /**
   * regular, udv
   */
  docketViewer?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Alert Managment - Do not send a copy to the person setting up the alert.
   */
  doNotCopy: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * How often should the alert/docket track be sent
   */
  frequency?: string
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Personal Injury - Other
   */
  natureOfSuit?: string
  /**
   * Alerts/Docket Track - Send email even when there is no activity
   */
  noActivity: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  originalRecipientId?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * EXT ID number / Entry number
   */
  pleadingId?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * The ID of the original subscriber of the email.
   */
  senderId: string
  sortedBy?: string
  /**
   * User generated tags added to an alert/docket track
   */
  tagsExist: boolean
  /**
   * full url for the page
   */
  url: string
}

export interface DocketTrackDeletedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DocketTrackModalViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DocketTrackModifiedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   */
  alertType: string
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * Is there anything listed in the "Client Matter" box?
   */
  clientMatterExists?: boolean
  codeBase?: string
  /**
   * Description Field in an Alert/Docket Track has been used.
   */
  descriptionExists: boolean
  /**
   * The number of recipients added to an email (including the sender)... This number could be 0.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  distributedCount: number
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Alert Managment - Do not send a copy to the person setting up the alert.
   */
  doNotCopy: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * How often should the alert/docket track be sent
   */
  frequency?: string
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Alerts/Docket Track - Send email even when there is no activity
   */
  noActivity: string
  /**
   * An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  originalRecipientId?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * The ID of the original subscriber of the email.
   */
  senderId: string
  sortedBy?: string
  /**
   * User generated tags added to an alert/docket track
   */
  tagsExist: boolean
  /**
   * full url for the page
   */
  url: string
}

export interface DocketUpdateAuthorizationProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  caseType?: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * court name, eg. United States District Court For The Southern District Of Texas
   */
  court?: string
  /**
   * state or federal
   */
  courtJurisdiction?: string
  /**
   * The date the docket entry was filed
   */
  dateFiled?: string
  /**
   * complaint, notice, summon
   */
  docketKeyType?: string
  /**
   * The Docket No...  ie... 2:16-cv-11082
   */
  docketNumber?: string
  /**
   * Federal, State, and Pleading
   */
  docketType?: string
  /**
   * regular, udv
   */
  docketViewer?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Personal Injury - Other
   */
  natureOfSuit?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * EXT ID number / Entry number
   */
  pleadingId?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DocketUpdateSubmittedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  caseType?: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * court name, eg. United States District Court For The Southern District Of Texas
   */
  court?: string
  /**
   * state or federal
   */
  courtJurisdiction?: string
  /**
   * The date the docket entry was filed
   */
  dateFiled?: string
  /**
   * complaint, notice, summon
   */
  docketKeyType?: string
  /**
   * The Docket No...  ie... 2:16-cv-11082
   */
  docketNumber?: string
  /**
   * Federal, State, and Pleading
   */
  docketType?: string
  /**
   * regular, udv
   */
  docketViewer?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Personal Injury - Other
   */
  natureOfSuit?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * EXT ID number / Entry number
   */
  pleadingId?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DocumentComparedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | preview, click through |
   */
  clickThruType?: 'preview' | 'click through'
  codeBase?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Indicates if an event action includes a recommended document or other entity (recommended via ML model)
   */
  inclRecommended: boolean
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This should be the label of the specific item clicked
   */
  itemClicked: string
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Region on the page  where the click event occurs
   */
  itemLocation: string
  /**
   * This should be used to determine if it was:
   *
   * * Advertisement
   *
   * * Request Demo
   *
   * * Informational
   *
   * * Help
   *
   * * Qualtrics
   *
   * * etc...
   */
  itemType: string
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * the index of the result that was clicked
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsIndex?: number
  /**
   * Used to identify the secondary document ID for documents in a document compare event.
   */
  secondaryDocumentId?: string
  sortedBy?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DocumentExportedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | preview, click through |
   */
  clickThruType?: 'preview' | 'click through'
  codeBase?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Stores the size of the item downloaded in KB
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  downloadSize?: number
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * the index of the result that was clicked
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsIndex?: number
  sortedBy?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DocumentFavoritedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  caseType?: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * court name, eg. United States District Court For The Southern District Of Texas
   */
  court?: string
  /**
   * state or federal
   */
  courtJurisdiction?: string
  /**
   * The date the docket entry was filed
   */
  dateFiled?: string
  /**
   * complaint, notice, summon
   */
  docketKeyType?: string
  /**
   * The Docket No...  ie... 2:16-cv-11082
   */
  docketNumber?: string
  /**
   * Federal, State, and Pleading
   */
  docketType?: string
  /**
   * regular, udv
   */
  docketViewer?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Personal Injury - Other
   */
  natureOfSuit?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * EXT ID number / Entry number
   */
  pleadingId?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DocumentSavedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DocumentSharedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  caseType?: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | preview, click through |
   */
  clickThruType?: 'preview' | 'click through'
  codeBase?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * court name, eg. United States District Court For The Southern District Of Texas
   */
  court?: string
  /**
   * state or federal
   */
  courtJurisdiction?: string
  /**
   * The date the docket entry was filed
   */
  dateFiled?: string
  /**
   * complaint, notice, summon
   */
  docketKeyType?: string
  /**
   * The Docket No...  ie... 2:16-cv-11082
   */
  docketNumber?: string
  /**
   * Federal, State, and Pleading
   */
  docketType?: string
  /**
   * regular, udv
   */
  docketViewer?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Stores the size of the item downloaded in KB
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  downloadSize?: number
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * Personal Injury - Other
   */
  natureOfSuit?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * EXT ID number / Entry number
   */
  pleadingId?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * the index of the result that was clicked
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsIndex?: number
  sortedBy?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DocumentViewedProperties {
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  caseType?: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * court name, eg. United States District Court For The Southern District Of Texas
   */
  court?: string
  /**
   * state or federal
   */
  courtJurisdiction?: string
  /**
   * The date the docket entry was filed
   */
  dateFiled?: string
  /**
   * complaint, notice, summon
   */
  docketKeyType?: string
  /**
   * The Docket No...  ie... 2:16-cv-11082
   */
  docketNumber?: string
  /**
   * Federal, State, and Pleading
   */
  docketType?: string
  /**
   * regular, udv
   */
  docketViewer?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Personal Injury - Other
   */
  natureOfSuit?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * EXT ID number / Entry number
   */
  pleadingId?: string
  /**
   * Area metadata used within Practical Guidance documents in BLAW/BTAX.
   */
  practicalGuidanceArea?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface DraftAnalyzerUsedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface EmbeddedContentProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * This is the title or label for content that has been favorited
   */
  contentTitle: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ErrorViewedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * descriptor that provides context on the event/action the triggered a notice or error event to fire.  Helps differentiate between sources of friction that may be occuring on the same page/event
   */
  noticeDetails?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface FeedbackFormSubmittedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * identifying title for the feedback form. E.g. 'state leg 2.0' or 'custom bill compare'
   */
  feedbackFormTitle: string
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface FeedbackFormViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * identifying title for the feedback form. E.g. 'state leg 2.0' or 'custom bill compare'
   */
  feedbackFormTitle: string
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface FileUploadedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * To be used to specify the type of file, such as CSV, XLSX, DOCX, PDF, etc...  Should just be the extention without a period and all upper case 3-4 characters.  Should NOT be the filename.
   *
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   * | Max Length | 4 |
   */
  fileType: string
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * To specify the type of Upload, did someone browse for a file or did someone drag and drop a file
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Browse, Drag & Drop |
   */
  upLoadType: 'Browse' | 'Drag & Drop'
  /**
   * full url for the page
   */
  url: string
}

export interface FindAlertCreatedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * Differentiates between a track, find, News, or grouped alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | find, track, news, grouped, docket, bill citation reff, bna news, citation ref, deal maker, docket track, edgar, generic law, generic news, leg watch, opinion, patent, patent assignment, reg watch, tax |
   */
  alertClass?:
    | 'find'
    | 'track'
    | 'news'
    | 'grouped'
    | 'docket'
    | 'bill citation reff'
    | 'bna news'
    | 'citation ref'
    | 'deal maker'
    | 'docket track'
    | 'edgar'
    | 'generic law'
    | 'generic news'
    | 'leg watch'
    | 'opinion'
    | 'patent'
    | 'patent assignment'
    | 'reg watch'
    | 'tax'
  /**
   * The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   */
  alertType: string
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * Is there anything listed in the "Client Matter" box?
   */
  clientMatterExists?: boolean
  codeBase?: string
  /**
   * Email Format "Detailed" or ""Tabular"
   *
   * Alerts
   */
  displayFormat?: string
  /**
   * The number of recipients added to an email (including the sender)... This number could be 0.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  distributedCount: number
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * How often should the alert/docket track be sent
   */
  frequency?: string
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  originalRecipientId?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * The ID of the original subscriber of the email.
   */
  senderId: string
  sortedBy?: string
  /**
   * Alert ID - Assuming this is available when the alert has been created, ideally we'd see this from alert creation to alert click through.
   */
  ssid?: string
  /**
   * full url for the page
   */
  url: string
}

export interface FindAlertDeletedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * Differentiates between a track, find, News, or grouped alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | find, track, news, grouped, docket, bill citation reff, bna news, citation ref, deal maker, docket track, edgar, generic law, generic news, leg watch, opinion, patent, patent assignment, reg watch, tax |
   */
  alertClass?:
    | 'find'
    | 'track'
    | 'news'
    | 'grouped'
    | 'docket'
    | 'bill citation reff'
    | 'bna news'
    | 'citation ref'
    | 'deal maker'
    | 'docket track'
    | 'edgar'
    | 'generic law'
    | 'generic news'
    | 'leg watch'
    | 'opinion'
    | 'patent'
    | 'patent assignment'
    | 'reg watch'
    | 'tax'
  /**
   * The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   */
  alertType: string
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * Is there anything listed in the "Client Matter" box?
   */
  clientMatterExists?: boolean
  codeBase?: string
  /**
   * Email Format "Detailed" or ""Tabular"
   *
   * Alerts
   */
  displayFormat?: string
  /**
   * The number of recipients added to an email (including the sender)... This number could be 0.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  distributedCount: number
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * How often should the alert/docket track be sent
   */
  frequency?: string
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  originalRecipientId?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * The ID of the original subscriber of the email.
   */
  senderId: string
  sortedBy?: string
  /**
   * Alert ID - Assuming this is available when the alert has been created, ideally we'd see this from alert creation to alert click through.
   */
  ssid?: string
  /**
   * full url for the page
   */
  url: string
}

export interface FindAlertModifiedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * Differentiates between a track, find, News, or grouped alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | find, track, news, grouped, docket, bill citation reff, bna news, citation ref, deal maker, docket track, edgar, generic law, generic news, leg watch, opinion, patent, patent assignment, reg watch, tax |
   */
  alertClass?:
    | 'find'
    | 'track'
    | 'news'
    | 'grouped'
    | 'docket'
    | 'bill citation reff'
    | 'bna news'
    | 'citation ref'
    | 'deal maker'
    | 'docket track'
    | 'edgar'
    | 'generic law'
    | 'generic news'
    | 'leg watch'
    | 'opinion'
    | 'patent'
    | 'patent assignment'
    | 'reg watch'
    | 'tax'
  /**
   * The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   */
  alertType: string
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * Is there anything listed in the "Client Matter" box?
   */
  clientMatterExists?: boolean
  codeBase?: string
  /**
   * Email Format "Detailed" or ""Tabular"
   *
   * Alerts
   */
  displayFormat?: string
  /**
   * The number of recipients added to an email (including the sender)... This number could be 0.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  distributedCount: number
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * How often should the alert/docket track be sent
   */
  frequency?: string
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  originalRecipientId?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * The ID of the original subscriber of the email.
   */
  senderId: string
  sortedBy?: string
  /**
   * Alert ID - Assuming this is available when the alert has been created, ideally we'd see this from alert creation to alert click through.
   */
  ssid?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ForgotUsernamePasswordProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This should be the label of the specific item clicked
   */
  itemClicked: string
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Region on the page  where the click event occurs
   */
  itemLocation: string
  /**
   * This should be used to determine if it was:
   *
   * * Advertisement
   *
   * * Request Demo
   *
   * * Informational
   *
   * * Help
   *
   * * Qualtrics
   *
   * * etc...
   */
  itemType: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface FormSubmittedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  formTitle: string
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface FormViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  formTitle: string
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface GroupedAlertCreatedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * Differentiates between a track, find, News, or grouped alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | find, track, news, grouped, docket, bill citation reff, bna news, citation ref, deal maker, docket track, edgar, generic law, generic news, leg watch, opinion, patent, patent assignment, reg watch, tax |
   */
  alertClass?:
    | 'find'
    | 'track'
    | 'news'
    | 'grouped'
    | 'docket'
    | 'bill citation reff'
    | 'bna news'
    | 'citation ref'
    | 'deal maker'
    | 'docket track'
    | 'edgar'
    | 'generic law'
    | 'generic news'
    | 'leg watch'
    | 'opinion'
    | 'patent'
    | 'patent assignment'
    | 'reg watch'
    | 'tax'
  /**
   * The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   */
  alertType: string
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * Is there anything listed in the "Client Matter" box?
   */
  clientMatterExists?: boolean
  codeBase?: string
  /**
   * Email Format "Detailed" or ""Tabular"
   *
   * Alerts
   */
  displayFormat?: string
  /**
   * The number of recipients added to an email (including the sender)... This number could be 0.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  distributedCount: number
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * How often should the alert/docket track be sent
   */
  frequency?: string
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  originalRecipientId?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * The ID of the original subscriber of the email.
   */
  senderId: string
  sortedBy?: string
  /**
   * Alert ID - Assuming this is available when the alert has been created, ideally we'd see this from alert creation to alert click through.
   */
  ssid?: string
  /**
   * full url for the page
   */
  url: string
}

export interface HistoricalSpendViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface HomepageViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface InteractiveClickProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This should be the label of the specific item clicked
   */
  itemClicked: string
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Region on the page  where the click event occurs
   */
  itemLocation: string
  /**
   * This should be used to determine if it was:
   *
   * * Advertisement
   *
   * * Request Demo
   *
   * * Informational
   *
   * * Help
   *
   * * Qualtrics
   *
   * * etc...
   */
  itemType: string
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface InteractiveStoryViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface JobRunFormViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface JobRunSubmittedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface LandingViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface LearnMoreProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This should be the label of the specific item clicked
   */
  itemClicked: string
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Region on the page  where the click event occurs
   */
  itemLocation: string
  /**
   * This should be used to determine if it was:
   *
   * * Advertisement
   *
   * * Request Demo
   *
   * * Informational
   *
   * * Help
   *
   * * Qualtrics
   *
   * * etc...
   */
  itemType: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface LitigationAnalyticsUsedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface LoadMoreClickedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Comma delimited list of content types included in the search results
   */
  resultContentKinds?: string
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * This is meant exclusively for INDG News articles
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchArticleType?: string[]
  /**
   * This call be all dates, to the range of one or more dates
   */
  searchDateRange?: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   */
  searchResponseType?: string
  /**
   * the search results page number where an action occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsPage?: number
  /**
   * Numeric indicator of how many search results are visible on a page.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsVisible?: number
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Sort by Date or Relevance
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | date, relevance, court, most cited, table of contents |
   */
  searchSortBy?: 'date' | 'relevance' | 'court' | 'most cited' | 'table of contents'
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface LoginSubmittedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This should be the label of the specific item clicked
   */
  itemClicked: string
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Region on the page  where the click event occurs
   */
  itemLocation: string
  /**
   * This should be used to determine if it was:
   *
   * * Advertisement
   *
   * * Request Demo
   *
   * * Informational
   *
   * * Help
   *
   * * Qualtrics
   *
   * * etc...
   */
  itemType: string
  /**
   * The location on the page that the login action was taken.
   */
  loginClickLocation?: string
  /**
   * Login Response from the login page
   */
  loginResponse?: string
  /**
   * Is the login inline or modal
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | Modal|Inline |
   */
  loginType: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Remember Me on the login page
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | Yes|No|N/A |
   */
  rememberMe: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface LoginViewProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * The location on the page that the login action was taken.
   */
  loginClickLocation?: string
  /**
   * Login Response from the login page
   */
  loginResponse?: string
  /**
   * Is the login inline or modal
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | Modal|Inline |
   */
  loginType: string
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Remember Me on the login page
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | Yes|No|N/A |
   */
  rememberMe: string
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface MultiFactorAuthenticationSubmittedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * This is the current value on the countdown clock
   */
  mfaCountDown?: string
  /**
   * If there is a failure for the MFA submission, then it should be listed here.
   */
  mfaFailtureReason: string
  mfaInitialSetup: boolean
  /**
   * Can only be email or sms (lowercase)
   */
  mfaMethod?: string
  /**
   * Whether the MFA was a success or failure
   */
  mfaSuccess: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface MultiFactorAuthenticationViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * This is the current value on the countdown clock
   */
  mfaCountDown?: string
  /**
   * If there is a failure for the MFA submission, then it should be listed here.
   */
  mfaFailtureReason: string
  mfaInitialSetup: boolean
  /**
   * Can only be email or sms (lowercase)
   */
  mfaMethod?: string
  /**
   * Whether the MFA was a success or failure
   */
  mfaSuccess: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface NavClickedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * A high level indicator of which navigation section the clicked item was located in
   */
  navGroup: string
  /**
   * The specific item that was clicked: Click Text on a navigational element, or the link that was clicked, or the name of the sub-nav item when used with Sub-Nav_Opened, article title
   */
  navItem: string
  /**
   * A categorization of the navigational elements a descriptive 'where' of the link was clicked or type of menu opened. If I open a sub-nav, it would be 'Sub-Nav', if I click on a link within a sub-nav it would also be 'Sub-Nav'. If I click on a link within or at the bottom of an article it might be Content Nav, or click on a category or tag group on the right hand side, 'Topics'.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Primary Nav, Sub Nav, Content Nav, Left Bar, Right Bar, Anthology, Text Link, Home Page Module, Article Sidebar, User Workflow, Internal Ad, Document Sidebar, Table |
   */
  navType:
    | 'Primary Nav'
    | 'Sub Nav'
    | 'Content Nav'
    | 'Left Bar'
    | 'Right Bar'
    | 'Anthology'
    | 'Text Link'
    | 'Home Page Module'
    | 'Article Sidebar'
    | 'User Workflow'
    | 'Internal Ad'
    | 'Document Sidebar'
    | 'Table'
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface NavClosedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * A high level indicator of which navigation section the clicked item was located in
   */
  navGroup: string
  /**
   * The specific item that was clicked: Click Text on a navigational element, or the link that was clicked, or the name of the sub-nav item when used with Sub-Nav_Opened, article title
   */
  navItem: string
  /**
   * A categorization of the navigational elements a descriptive 'where' of the link was clicked or type of menu opened. If I open a sub-nav, it would be 'Sub-Nav', if I click on a link within a sub-nav it would also be 'Sub-Nav'. If I click on a link within or at the bottom of an article it might be Content Nav, or click on a category or tag group on the right hand side, 'Topics'.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Primary Nav, Sub Nav, Content Nav, Left Bar, Right Bar, Anthology, Text Link, Home Page Module, Article Sidebar, User Workflow, Internal Ad, Document Sidebar, Table |
   */
  navType:
    | 'Primary Nav'
    | 'Sub Nav'
    | 'Content Nav'
    | 'Left Bar'
    | 'Right Bar'
    | 'Anthology'
    | 'Text Link'
    | 'Home Page Module'
    | 'Article Sidebar'
    | 'User Workflow'
    | 'Internal Ad'
    | 'Document Sidebar'
    | 'Table'
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface NavOpenedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * A high level indicator of which navigation section the clicked item was located in
   */
  navGroup: string
  /**
   * The specific item that was clicked: Click Text on a navigational element, or the link that was clicked, or the name of the sub-nav item when used with Sub-Nav_Opened, article title
   */
  navItem: string
  /**
   * A categorization of the navigational elements a descriptive 'where' of the link was clicked or type of menu opened. If I open a sub-nav, it would be 'Sub-Nav', if I click on a link within a sub-nav it would also be 'Sub-Nav'. If I click on a link within or at the bottom of an article it might be Content Nav, or click on a category or tag group on the right hand side, 'Topics'.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Primary Nav, Sub Nav, Content Nav, Left Bar, Right Bar, Anthology, Text Link, Home Page Module, Article Sidebar, User Workflow, Internal Ad, Document Sidebar, Table |
   */
  navType:
    | 'Primary Nav'
    | 'Sub Nav'
    | 'Content Nav'
    | 'Left Bar'
    | 'Right Bar'
    | 'Anthology'
    | 'Text Link'
    | 'Home Page Module'
    | 'Article Sidebar'
    | 'User Workflow'
    | 'Internal Ad'
    | 'Document Sidebar'
    | 'Table'
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface NewsletterFormClosedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface NewsletterFormViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface NewsletterSignupSubmittedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Denotes whether a newsletter is free or only available to subscribers
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | free, subscriber |
   */
  newsletterAccessType: 'free' | 'subscriber'
  /**
   * array of articles selected
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  newslettersSelected?: string[]
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface NewsletterSignupViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Denotes whether a newsletter is free or only available to subscribers
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | free, subscriber |
   */
  newsletterAccessType: 'free' | 'subscriber'
  /**
   * array of articles selected
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  newslettersSelected?: string[]
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface NewsletterSubscribedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Denotes whether a newsletter is free or only available to subscribers
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | free, subscriber |
   */
  newsletterAccessType: 'free' | 'subscriber'
  /**
   * array of articles selected
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  newslettersSelected?: string[]
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface NewsletterViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface NextResultNavigatedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface NoteAddedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * Array stores values to indicate of notes, tags, comments, etc. included in an annotation action are new or existing or both.
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 1 |
   * | Unique Items | true |
   * | Item Type | string |
   *
   * @minItems 1
   */
  annotationStatus: [string, ...string[]]
  /**
   * Array stores values to indicate if annotation action includes multiple types of annotationType
   *
   *
   * Possible values include:
   *
   * * bulk
   *
   * * shared
   *
   * * private
   *
   * * ...
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 1 |
   * | Unique Items | true |
   * | Item Type | string |
   *
   * @minItems 1
   */
  annotationType: [string, ...string[]]
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | preview, click through |
   */
  clickThruType?: 'preview' | 'click through'
  codeBase?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * the index of the result that was clicked
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsIndex?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface NoteDeletedProperties {
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * Array stores values to indicate of notes, tags, comments, etc. included in an annotation action are new or existing or both.
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 1 |
   * | Unique Items | true |
   * | Item Type | string |
   *
   * @minItems 1
   */
  annotationStatus: [string, ...string[]]
  /**
   * Array stores values to indicate if annotation action includes multiple types of annotationType
   *
   *
   * Possible values include:
   *
   * * bulk
   *
   * * shared
   *
   * * private
   *
   * * ...
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 1 |
   * | Unique Items | true |
   * | Item Type | string |
   *
   * @minItems 1
   */
  annotationType: [string, ...string[]]
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface NoticeViewedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * descriptor that provides context on the event/action the triggered a notice or error event to fire.  Helps differentiate between sources of friction that may be occuring on the same page/event
   */
  noticeDetails?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface OnboardingSubmittedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  onboardingFormTitle: string
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface OnboardingViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  onboardingFormTitle: string
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface PageModifiedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface PersonalizationModalViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface PlayDepthProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * usually quartiles, the % of the audio or video that has been played
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 25, 50, 75, 100 |
   */
  playDepth: '25' | '50' | '75' | '100'
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface PleadingRequestAuthorizationProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  caseType?: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * court name, eg. United States District Court For The Southern District Of Texas
   */
  court?: string
  /**
   * state or federal
   */
  courtJurisdiction?: string
  /**
   * The date the docket entry was filed
   */
  dateFiled?: string
  /**
   * complaint, notice, summon
   */
  docketKeyType?: string
  /**
   * The Docket No...  ie... 2:16-cv-11082
   */
  docketNumber?: string
  /**
   * Federal, State, and Pleading
   */
  docketType?: string
  /**
   * regular, udv
   */
  docketViewer?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Personal Injury - Other
   */
  natureOfSuit?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * EXT ID number / Entry number
   */
  pleadingId?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface PleadingRequestSubmittedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  caseType?: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * court name, eg. United States District Court For The Southern District Of Texas
   */
  court?: string
  /**
   * state or federal
   */
  courtJurisdiction?: string
  /**
   * The date the docket entry was filed
   */
  dateFiled?: string
  /**
   * complaint, notice, summon
   */
  docketKeyType?: string
  /**
   * The Docket No...  ie... 2:16-cv-11082
   */
  docketNumber?: string
  /**
   * Federal, State, and Pleading
   */
  docketType?: string
  /**
   * regular, udv
   */
  docketViewer?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * When a user has successfully submitted a docket request
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | success, failure |
   */
  entryRequestStatus: 'success' | 'failure'
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Personal Injury - Other
   */
  natureOfSuit?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * EXT ID number / Entry number
   */
  pleadingId?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface PodcastEpisodePlayedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * The title of a podcast eposide.
   */
  podcastEpisodeTitle?: string
  /**
   * apple|google|stitcher|spotify
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | apple, google, stitcher, spotify |
   */
  podcastPlatform?: 'apple' | 'google' | 'stitcher' | 'spotify'
  /**
   * ID for the Podcast Series
   */
  podcastSeriesId?: string
  /**
   * The title of a podcast series.
   */
  podcastSeriesTitle?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface PodcastSeriesLandingViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * The title of a podcast eposide.
   */
  podcastEpisodeTitle?: string
  /**
   * apple|google|stitcher|spotify
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | apple, google, stitcher, spotify |
   */
  podcastPlatform?: 'apple' | 'google' | 'stitcher' | 'spotify'
  /**
   * ID for the Podcast Series
   */
  podcastSeriesId?: string
  /**
   * The title of a podcast series.
   */
  podcastSeriesTitle?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface PodcastSeriesSubscribedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * The title of a podcast eposide.
   */
  podcastEpisodeTitle?: string
  /**
   * apple|google|stitcher|spotify
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | apple, google, stitcher, spotify |
   */
  podcastPlatform?: 'apple' | 'google' | 'stitcher' | 'spotify'
  /**
   * ID for the Podcast Series
   */
  podcastSeriesId?: string
  /**
   * The title of a podcast series.
   */
  podcastSeriesTitle?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface PodcastSeriesViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * The title of a podcast eposide.
   */
  podcastEpisodeTitle?: string
  /**
   * apple|google|stitcher|spotify
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | apple, google, stitcher, spotify |
   */
  podcastPlatform?: 'apple' | 'google' | 'stitcher' | 'spotify'
  /**
   * ID for the Podcast Series
   */
  podcastSeriesId?: string
  /**
   * The title of a podcast series.
   */
  podcastSeriesTitle?: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface PracticeToolUsedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface PreviousResultNavigatedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ProfileViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ReadNowProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This should be the label of the specific item clicked
   */
  itemClicked: string
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Region on the page  where the click event occurs
   */
  itemLocation: string
  /**
   * This should be used to determine if it was:
   *
   * * Advertisement
   *
   * * Request Demo
   *
   * * Informational
   *
   * * Help
   *
   * * Qualtrics
   *
   * * etc...
   */
  itemType: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ReconciliationViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface RegistrationFormSubmittedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface RegistrationFormViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Number of Licenses Requested
   */
  licenseRequestedAmount?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface RememberMeProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This should be the label of the specific item clicked
   */
  itemClicked: string
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Region on the page  where the click event occurs
   */
  itemLocation: string
  /**
   * This should be used to determine if it was:
   *
   * * Advertisement
   *
   * * Request Demo
   *
   * * Informational
   *
   * * Help
   *
   * * Qualtrics
   *
   * * etc...
   */
  itemType: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ReportRequestedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   */
  chartGroup?: string
  /**
   * Numeric value of chart ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  chartId?: number
  /**
   * List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartJurisdictions?: string[]
  /**
   * Granular action taken to modify chart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Filter Chart, Update Date Range, Update Chart Type, Add Chart Options, Remove Chart Options, Modify Column Header, Column Header - Topics, Column Header - Jurisdictions, Answer - short, Answer - summary, Answer - full |
   */
  chartModifyAction?:
    | 'Filter Chart'
    | 'Update Date Range'
    | 'Update Chart Type'
    | 'Add Chart Options'
    | 'Remove Chart Options'
    | 'Modify Column Header'
    | 'Column Header - Topics'
    | 'Column Header - Jurisdictions'
    | 'Answer - short'
    | 'Answer - summary'
    | 'Answer - full'
  /**
   * Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartOptions?: string[]
  /**
   * Indicates whether the chart being viewed was just created, just modified, or was previously saved.
   *
   * Example: new, modified, saved
   */
  chartStatus?: string
  /**
   * List of selected topics when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartTopics?: string[]
  /**
   * The type of chart associated with the Chart Builder or Chart itself
   *
   * Chart Builder example: pass-through entity, excise tax
   *
   * Historical Spend example: Line
   */
  chartType?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Stores the size of the item downloaded in KB
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  downloadSize?: number
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * How was the table modified
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Add Table Fields, Remove Table Fields, Reorder Table Fields, Reset Table Fields, Set Table Limits |
   */
  tableModifyAction?:
    | 'Add Table Fields'
    | 'Remove Table Fields'
    | 'Reorder Table Fields'
    | 'Reset Table Fields'
    | 'Set Table Limits'
  /**
   * Array of table fields
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tableOptions?: string[]
  /**
   * Count of options selected to build chart
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tableOptionsCount?: number
  /**
   * full url for the page
   */
  url: string
}

export interface RequestDemoProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This should be the label of the specific item clicked
   */
  itemClicked: string
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Region on the page  where the click event occurs
   */
  itemLocation: string
  /**
   * This should be used to determine if it was:
   *
   * * Advertisement
   *
   * * Request Demo
   *
   * * Informational
   *
   * * Help
   *
   * * Qualtrics
   *
   * * etc...
   */
  itemType: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ResultsDrilldownClickedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   */
  chartGroup?: string
  /**
   * Numeric value of chart ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  chartId?: number
  /**
   * List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartJurisdictions?: string[]
  /**
   * Granular action taken to modify chart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Filter Chart, Update Date Range, Update Chart Type, Add Chart Options, Remove Chart Options, Modify Column Header, Column Header - Topics, Column Header - Jurisdictions, Answer - short, Answer - summary, Answer - full |
   */
  chartModifyAction?:
    | 'Filter Chart'
    | 'Update Date Range'
    | 'Update Chart Type'
    | 'Add Chart Options'
    | 'Remove Chart Options'
    | 'Modify Column Header'
    | 'Column Header - Topics'
    | 'Column Header - Jurisdictions'
    | 'Answer - short'
    | 'Answer - summary'
    | 'Answer - full'
  /**
   * Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartOptions?: string[]
  /**
   * Indicates whether the chart being viewed was just created, just modified, or was previously saved.
   *
   * Example: new, modified, saved
   */
  chartStatus?: string
  /**
   * List of selected topics when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartTopics?: string[]
  /**
   * The type of chart associated with the Chart Builder or Chart itself
   *
   * Chart Builder example: pass-through entity, excise tax
   *
   * Historical Spend example: Line
   */
  chartType?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * How was the table modified
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Add Table Fields, Remove Table Fields, Reorder Table Fields, Reset Table Fields, Set Table Limits |
   */
  tableModifyAction?:
    | 'Add Table Fields'
    | 'Remove Table Fields'
    | 'Reorder Table Fields'
    | 'Reset Table Fields'
    | 'Set Table Limits'
  /**
   * Array of table fields
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tableOptions?: string[]
  /**
   * Count of options selected to build chart
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tableOptionsCount?: number
  /**
   * full url for the page
   */
  url: string
}

export interface SavedForLaterProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SavedSearchesViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface ScrollDepthProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * usually quartiles, the % of the page that has been visible
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 25, 50, 75, 100 |
   */
  pageDepth: '25' | '50' | '75' | '100'
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SearchExportCustomizedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Stores the size of the item downloaded in KB
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  downloadSize?: number
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Comma delimited list of content types included in the search results
   */
  resultContentKinds?: string
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * This is meant exclusively for INDG News articles
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchArticleType?: string[]
  /**
   * This call be all dates, to the range of one or more dates
   */
  searchDateRange?: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   */
  searchResponseType?: string
  /**
   * the search results page number where an action occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsPage?: number
  /**
   * Numeric indicator of how many search results are visible on a page.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsVisible?: number
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Sort by Date or Relevance
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | date, relevance, court, most cited, table of contents |
   */
  searchSortBy?: 'date' | 'relevance' | 'court' | 'most cited' | 'table of contents'
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SearchFormClosedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SearchFormViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SearchResultHiddenProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Comma delimited list of content types included in the search results
   */
  resultContentKinds?: string
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * This is meant exclusively for INDG News articles
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchArticleType?: string[]
  /**
   * This call be all dates, to the range of one or more dates
   */
  searchDateRange?: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   */
  searchResponseType?: string
  /**
   * the search results page number where an action occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsPage?: number
  /**
   * Numeric indicator of how many search results are visible on a page.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsVisible?: number
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Sort by Date or Relevance
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | date, relevance, court, most cited, table of contents |
   */
  searchSortBy?: 'date' | 'relevance' | 'court' | 'most cited' | 'table of contents'
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SearchResultsClickedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | preview, click through |
   */
  clickThruType?: 'preview' | 'click through'
  codeBase?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Comma delimited list of content types included in the search results
   */
  resultContentKinds?: string
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * This is meant exclusively for INDG News articles
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchArticleType?: string[]
  /**
   * This call be all dates, to the range of one or more dates
   */
  searchDateRange?: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   */
  searchResponseType?: string
  /**
   * the index of the result that was clicked
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsIndex?: number
  /**
   * the search results page number where an action occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsPage?: number
  /**
   * Numeric indicator of how many search results are visible on a page.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsVisible?: number
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Sort by Date or Relevance
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | date, relevance, court, most cited, table of contents |
   */
  searchSortBy?: 'date' | 'relevance' | 'court' | 'most cited' | 'table of contents'
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface SearchResultsExportedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Stores the size of the item downloaded in KB
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  downloadSize?: number
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Comma delimited list of content types included in the search results
   */
  resultContentKinds?: string
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * This is meant exclusively for INDG News articles
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchArticleType?: string[]
  /**
   * This call be all dates, to the range of one or more dates
   */
  searchDateRange?: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   */
  searchResponseType?: string
  /**
   * the search results page number where an action occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsPage?: number
  /**
   * Numeric indicator of how many search results are visible on a page.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsVisible?: number
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Sort by Date or Relevance
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | date, relevance, court, most cited, table of contents |
   */
  searchSortBy?: 'date' | 'relevance' | 'court' | 'most cited' | 'table of contents'
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SearchResultsNavigatedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SearchResultsSortedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Comma delimited list of content types included in the search results
   */
  resultContentKinds?: string
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * This is meant exclusively for INDG News articles
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchArticleType?: string[]
  /**
   * This call be all dates, to the range of one or more dates
   */
  searchDateRange?: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   */
  searchResponseType?: string
  /**
   * the search results page number where an action occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsPage?: number
  /**
   * Numeric indicator of how many search results are visible on a page.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsVisible?: number
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Sort by Date or Relevance
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | date, relevance, court, most cited, table of contents |
   */
  searchSortBy?: 'date' | 'relevance' | 'court' | 'most cited' | 'table of contents'
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SearchResultsUnhiddenProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Comma delimited list of content types included in the search results
   */
  resultContentKinds?: string
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * This is meant exclusively for INDG News articles
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchArticleType?: string[]
  /**
   * This call be all dates, to the range of one or more dates
   */
  searchDateRange?: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   */
  searchResponseType?: string
  /**
   * the search results page number where an action occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsPage?: number
  /**
   * Numeric indicator of how many search results are visible on a page.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsVisible?: number
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Sort by Date or Relevance
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | date, relevance, court, most cited, table of contents |
   */
  searchSortBy?: 'date' | 'relevance' | 'court' | 'most cited' | 'table of contents'
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SearchResultsViewedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * When a docket number is recognized in the go bar... This is a boolean value.   The result would with the [docket intent box](https://www.bloomberglaw.com/product/blaw/search/results/c0c174f8dff6f509234c8e9c29d76e00?bc=W1siQmxvb21iZXJnIExhdyIsIi9wcm9kdWN0L2JsYXcvbm90aWZpY2F0aW9ucy9pdGVtcy9SRVNFQVJDSF9UUkFJTD9jb3VudD01MCZ0eXBlPVNFQVJDSCJdXQ--078704ad3141fd6e9876a60965ff7104e97c9377) showing on top of the search results.
   */
  docketIntent?: boolean
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Comma delimited list of content types included in the search results
   */
  resultContentKinds?: string
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * This is meant exclusively for INDG News articles
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchArticleType?: string[]
  /**
   * This call be all dates, to the range of one or more dates
   */
  searchDateRange?: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   */
  searchResponseType?: string
  /**
   * the search results page number where an action occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsPage?: number
  /**
   * Numeric indicator of how many search results are visible on a page.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsVisible?: number
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Sort by Date or Relevance
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | date, relevance, court, most cited, table of contents |
   */
  searchSortBy?: 'date' | 'relevance' | 'court' | 'most cited' | 'table of contents'
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SearchSavedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SearchSharedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Stores the size of the item downloaded in KB
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  downloadSize?: number
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * Comma delimited list of content types included in the search results
   */
  resultContentKinds?: string
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   */
  searchResponseType?: string
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SearchSubmittedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * When a docket number is recognized in the go bar... This is a boolean value.   The result would with the [docket intent box](https://www.bloomberglaw.com/product/blaw/search/results/c0c174f8dff6f509234c8e9c29d76e00?bc=W1siQmxvb21iZXJnIExhdyIsIi9wcm9kdWN0L2JsYXcvbm90aWZpY2F0aW9ucy9pdGVtcy9SRVNFQVJDSF9UUkFJTD9jb3VudD01MCZ0eXBlPVNFQVJDSCJdXQ--078704ad3141fd6e9876a60965ff7104e97c9377) showing on top of the search results.
   */
  docketIntent?: boolean
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  /**
   * array of the filters and keywords of the previous query. Used to help in analyzing and understanding the specific keyword or filter changes made when search action = refine.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  previousFilters?: string[]
  /**
   * stores the keywords from the previous search query.
   *
   *
   * In instances where the functional 'keywords' may be different than the visible keywords (e.g. with BGOV inferred filters), store the functional keywords.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  previousKeywords?: string[]
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Indicates whether there were any autosuggesions available (whether or not they were selected).
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchSuggestionAvailable?: ('inferred filter edited' | 'keyword edited')[]
  /**
   * array of modifications made to a search suggestion or AI supported search. Adds additional detail to 'search action = refined' that highlights the specific type of refinement.
   *
   *
   * sample values:
   *
   * * keyword edited
   *
   * * inferred filter added
   *
   * * inferred filter removed
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  searchSuggestionModified?: string[]
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
  sortedBy?: string
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SelectionsSavedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   */
  chartGroup?: string
  /**
   * Numeric value of chart ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  chartId?: number
  /**
   * List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartJurisdictions?: string[]
  /**
   * Granular action taken to modify chart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Filter Chart, Update Date Range, Update Chart Type, Add Chart Options, Remove Chart Options, Modify Column Header, Column Header - Topics, Column Header - Jurisdictions, Answer - short, Answer - summary, Answer - full |
   */
  chartModifyAction?:
    | 'Filter Chart'
    | 'Update Date Range'
    | 'Update Chart Type'
    | 'Add Chart Options'
    | 'Remove Chart Options'
    | 'Modify Column Header'
    | 'Column Header - Topics'
    | 'Column Header - Jurisdictions'
    | 'Answer - short'
    | 'Answer - summary'
    | 'Answer - full'
  /**
   * Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartOptions?: string[]
  /**
   * Indicates whether the chart being viewed was just created, just modified, or was previously saved.
   *
   * Example: new, modified, saved
   */
  chartStatus?: string
  /**
   * List of selected topics when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartTopics?: string[]
  /**
   * The type of chart associated with the Chart Builder or Chart itself
   *
   * Chart Builder example: pass-through entity, excise tax
   *
   * Historical Spend example: Line
   */
  chartType?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * How was the table modified
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Add Table Fields, Remove Table Fields, Reorder Table Fields, Reset Table Fields, Set Table Limits |
   */
  tableModifyAction?:
    | 'Add Table Fields'
    | 'Remove Table Fields'
    | 'Reorder Table Fields'
    | 'Reset Table Fields'
    | 'Set Table Limits'
  /**
   * Array of table fields
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tableOptions?: string[]
  /**
   * Count of options selected to build chart
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tableOptionsCount?: number
  /**
   * full url for the page
   */
  url: string
}

export interface SetAsHomeProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SidebarTabOpenProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sidebarIsOpen: boolean
  sidebarItem: string
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface SubscriberAddedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface TableResultsFormattedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   */
  chartGroup?: string
  /**
   * Numeric value of chart ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  chartId?: number
  /**
   * List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartJurisdictions?: string[]
  /**
   * Granular action taken to modify chart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Filter Chart, Update Date Range, Update Chart Type, Add Chart Options, Remove Chart Options, Modify Column Header, Column Header - Topics, Column Header - Jurisdictions, Answer - short, Answer - summary, Answer - full |
   */
  chartModifyAction?:
    | 'Filter Chart'
    | 'Update Date Range'
    | 'Update Chart Type'
    | 'Add Chart Options'
    | 'Remove Chart Options'
    | 'Modify Column Header'
    | 'Column Header - Topics'
    | 'Column Header - Jurisdictions'
    | 'Answer - short'
    | 'Answer - summary'
    | 'Answer - full'
  /**
   * Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartOptions?: string[]
  /**
   * Indicates whether the chart being viewed was just created, just modified, or was previously saved.
   *
   * Example: new, modified, saved
   */
  chartStatus?: string
  /**
   * List of selected topics when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartTopics?: string[]
  /**
   * The type of chart associated with the Chart Builder or Chart itself
   *
   * Chart Builder example: pass-through entity, excise tax
   *
   * Historical Spend example: Line
   */
  chartType?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * How was the table modified
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Add Table Fields, Remove Table Fields, Reorder Table Fields, Reset Table Fields, Set Table Limits |
   */
  tableModifyAction?:
    | 'Add Table Fields'
    | 'Remove Table Fields'
    | 'Reorder Table Fields'
    | 'Reset Table Fields'
    | 'Set Table Limits'
  /**
   * Array of table fields
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tableOptions?: string[]
  /**
   * Count of options selected to build chart
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tableOptionsCount?: number
  /**
   * full url for the page
   */
  url: string
}

export interface TagAddedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * Array stores values to indicate of notes, tags, comments, etc. included in an annotation action are new or existing or both.
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 1 |
   * | Unique Items | true |
   * | Item Type | string |
   *
   * @minItems 1
   */
  annotationStatus: [string, ...string[]]
  /**
   * Array stores values to indicate if annotation action includes multiple types of annotationType
   *
   *
   * Possible values include:
   *
   * * bulk
   *
   * * shared
   *
   * * private
   *
   * * ...
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 1 |
   * | Unique Items | true |
   * | Item Type | string |
   *
   * @minItems 1
   */
  annotationType: [string, ...string[]]
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | preview, click through |
   */
  clickThruType?: 'preview' | 'click through'
  codeBase?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * the index of the result that was clicked
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsIndex?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface TagDeletedProperties {
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * Array stores values to indicate of notes, tags, comments, etc. included in an annotation action are new or existing or both.
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 1 |
   * | Unique Items | true |
   * | Item Type | string |
   *
   * @minItems 1
   */
  annotationStatus: [string, ...string[]]
  /**
   * Array stores values to indicate if annotation action includes multiple types of annotationType
   *
   *
   * Possible values include:
   *
   * * bulk
   *
   * * shared
   *
   * * private
   *
   * * ...
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 1 |
   * | Unique Items | true |
   * | Item Type | string |
   *
   * @minItems 1
   */
  annotationType: [string, ...string[]]
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * full url for the page
   */
  url: string
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface TocExportedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface TocSharedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface TocViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface TourEndedProperties {
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG |
   */
  brand: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   */
  product?: string
  /**
   * Indicates the % completion of a tour. Should 100% when tourStatus = Completed otherwise should be a percentage between 0 and 1 if tourStatus = Exited
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tourDepth?: number
  /**
   * Describes the feature the tour is associated with. E.g. State Legislation Search Reinvent
   *
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   */
  tourName: string
  /**
   * Differentiates whether a tour was prompted automatically or via a user clicking some variation of 'tour'
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Auto Start, User Start |
   */
  tourStatus?: 'Auto Start' | 'User Start'
  /**
   * full url for the page
   */
  url: string
}

export interface TourViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * Indicates the % completion of a tour. Should 100% when tourStatus = Completed otherwise should be a percentage between 0 and 1 if tourStatus = Exited
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tourDepth?: number
  /**
   * Describes the feature the tour is associated with. E.g. State Legislation Search Reinvent
   *
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   */
  tourName: string
  /**
   * Differentiates whether a tour was prompted automatically or via a user clicking some variation of 'tour'
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Auto Start, User Start |
   */
  tourStatus?: 'Auto Start' | 'User Start'
  /**
   * full url for the page
   */
  url: string
}

export interface TrackAlertCreatedProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * Differentiates between a track, find, News, or grouped alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | find, track, news, grouped, docket, bill citation reff, bna news, citation ref, deal maker, docket track, edgar, generic law, generic news, leg watch, opinion, patent, patent assignment, reg watch, tax |
   */
  alertClass?:
    | 'find'
    | 'track'
    | 'news'
    | 'grouped'
    | 'docket'
    | 'bill citation reff'
    | 'bna news'
    | 'citation ref'
    | 'deal maker'
    | 'docket track'
    | 'edgar'
    | 'generic law'
    | 'generic news'
    | 'leg watch'
    | 'opinion'
    | 'patent'
    | 'patent assignment'
    | 'reg watch'
    | 'tax'
  /**
   * The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   */
  alertType: string
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  /**
   * used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | preview, click through |
   */
  clickThruType?: 'preview' | 'click through'
  /**
   * Is there anything listed in the "Client Matter" box?
   */
  clientMatterExists?: boolean
  codeBase?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Email Format "Detailed" or ""Tabular"
   *
   * Alerts
   */
  displayFormat?: string
  /**
   * The number of recipients added to an email (including the sender)... This number could be 0.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  distributedCount: number
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * How often should the alert/docket track be sent
   */
  frequency?: string
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  originalRecipientId?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  /**
   * the index of the result that was clicked
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsIndex?: number
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * The ID of the original subscriber of the email.
   */
  senderId: string
  sortedBy?: string
  /**
   * Alert ID - Assuming this is available when the alert has been created, ideally we'd see this from alert creation to alert click through.
   */
  ssid?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
  /**
   * full url for the page
   */
  url: string
}

export interface TrackAlertDeletedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface TrackAlertModifiedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface UpsellViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * This should determine the name of the content being accessed.
   */
  upsellContentName: string
  /**
   * This should  determine what type of content is being accessed.  Sample values would be:
   *
   * \* Document
   *
   * \* Practice Area
   *
   * \* Tool
   */
  upsellContentType: string
  /**
   * This should be the full URL of the content the user is trying to access.
   */
  upsellContentUrl: string
  /**
   * This is a controlled list of the types of Upsells. Currently, it should either be Modal or Page.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Modal, Page |
   */
  upsellType: 'Modal' | 'Page'
  /**
   * full url for the page
   */
  url: string
}

export interface UserWorkflowExportedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * Stores the size of the item downloaded in KB
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  downloadSize?: number
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * The publisher for a content collection
   */
  publisher?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface UserWorkflowViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
  /**
   * Research Trail, Download Center, Alert Management etc
   */
  workFlowName: string
}

export interface WorkflowViewedProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * total number of workflows on a page (BTAX Software)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  totalWorkflows?: number
  /**
   * full url for the page
   */
  url: string
}

export interface AlertManagementProperties {
  /**
   * Differentiates between a track, find, News, or grouped alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | find, track, news, grouped, docket, bill citation reff, bna news, citation ref, deal maker, docket track, edgar, generic law, generic news, leg watch, opinion, patent, patent assignment, reg watch, tax |
   */
  alertClass?:
    | 'find'
    | 'track'
    | 'news'
    | 'grouped'
    | 'docket'
    | 'bill citation reff'
    | 'bna news'
    | 'citation ref'
    | 'deal maker'
    | 'docket track'
    | 'edgar'
    | 'generic law'
    | 'generic news'
    | 'leg watch'
    | 'opinion'
    | 'patent'
    | 'patent assignment'
    | 'reg watch'
    | 'tax'
  /**
   * The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   */
  alertType: string
  /**
   * Is there anything listed in the "Client Matter" box?
   */
  clientMatterExists?: boolean
  /**
   * Email Format "Detailed" or ""Tabular"
   *
   * Alerts
   */
  displayFormat?: string
  /**
   * The number of recipients added to an email (including the sender)... This number could be 0.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  distributedCount: number
  /**
   * How often should the alert/docket track be sent
   */
  frequency?: string
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  originalRecipientId?: string[]
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * The ID of the original subscriber of the email.
   */
  senderId: string
  /**
   * Alert ID - Assuming this is available when the alert has been created, ideally we'd see this from alert creation to alert click through.
   */
  ssid?: string
}

export interface AnnotationsProperties {
  /**
   * Array stores values to indicate of notes, tags, comments, etc. included in an annotation action are new or existing or both.
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 1 |
   * | Unique Items | true |
   * | Item Type | string |
   *
   * @minItems 1
   */
  annotationStatus: [string, ...string[]]
  /**
   * Array stores values to indicate if annotation action includes multiple types of annotationType
   *
   *
   * Possible values include:
   *
   * * bulk
   *
   * * shared
   *
   * * private
   *
   * * ...
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 1 |
   * | Unique Items | true |
   * | Item Type | string |
   *
   * @minItems 1
   */
  annotationType: [string, ...string[]]
}

export interface ChartsTablesProperties {
  /**
   * The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   */
  chartGroup?: string
  /**
   * Numeric value of chart ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  chartId?: number
  /**
   * List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartJurisdictions?: string[]
  /**
   * Granular action taken to modify chart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Filter Chart, Update Date Range, Update Chart Type, Add Chart Options, Remove Chart Options, Modify Column Header, Column Header - Topics, Column Header - Jurisdictions, Answer - short, Answer - summary, Answer - full |
   */
  chartModifyAction?:
    | 'Filter Chart'
    | 'Update Date Range'
    | 'Update Chart Type'
    | 'Add Chart Options'
    | 'Remove Chart Options'
    | 'Modify Column Header'
    | 'Column Header - Topics'
    | 'Column Header - Jurisdictions'
    | 'Answer - short'
    | 'Answer - summary'
    | 'Answer - full'
  /**
   * Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartOptions?: string[]
  /**
   * Indicates whether the chart being viewed was just created, just modified, or was previously saved.
   *
   * Example: new, modified, saved
   */
  chartStatus?: string
  /**
   * List of selected topics when Chart Builder is submitted or Chart is exported
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartTopics?: string[]
  /**
   * The type of chart associated with the Chart Builder or Chart itself
   *
   * Chart Builder example: pass-through entity, excise tax
   *
   * Historical Spend example: Line
   */
  chartType?: string
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * How was the table modified
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Add Table Fields, Remove Table Fields, Reorder Table Fields, Reset Table Fields, Set Table Limits |
   */
  tableModifyAction?:
    | 'Add Table Fields'
    | 'Remove Table Fields'
    | 'Reorder Table Fields'
    | 'Reset Table Fields'
    | 'Set Table Limits'
  /**
   * Array of table fields
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tableOptions?: string[]
  /**
   * Count of options selected to build chart
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tableOptionsCount?: number
}

export interface ClickInteractionProperties {
  /**
   * This should be the label of the specific item clicked
   */
  itemClicked: string
  /**
   * Region on the page  where the click event occurs
   */
  itemLocation: string
  /**
   * This should be used to determine if it was:
   *
   * * Advertisement
   *
   * * Request Demo
   *
   * * Informational
   *
   * * Help
   *
   * * Qualtrics
   *
   * * etc...
   */
  itemType: string
}

export interface ContentExportOrSharedProperties {
  /**
   * Stores the size of the item downloaded in KB
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  downloadSize?: number
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
}

export interface DocketEntryFilterProperties {
  /**
   * Date selected for [Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Entry Filter
   */
  entryDate: string
  /**
   * Filing Type for [Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Entry Filter
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filingType: string[]
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
}

export interface DocketSearchProperties {
  /**
   * When a docket number is recognized in the go bar... This is a boolean value.   The result would with the [docket intent box](https://www.bloomberglaw.com/product/blaw/search/results/c0c174f8dff6f509234c8e9c29d76e00?bc=W1siQmxvb21iZXJnIExhdyIsIi9wcm9kdWN0L2JsYXcvbm90aWZpY2F0aW9ucy9pdGVtcy9SRVNFQVJDSF9UUkFJTD9jb3VudD01MCZ0eXBlPVNFQVJDSCJdXQ--078704ad3141fd6e9876a60965ff7104e97c9377) showing on top of the search results.
   */
  docketIntent?: boolean
}

export interface DocketTrackPropertiesProperties {
  /**
   * The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   */
  alertType: string
  /**
   * Is there anything listed in the "Client Matter" box?
   */
  clientMatterExists?: boolean
  /**
   * Description Field in an Alert/Docket Track has been used.
   */
  descriptionExists: boolean
  /**
   * The number of recipients added to an email (including the sender)... This number could be 0.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  distributedCount: number
  /**
   * Alert Managment - Do not send a copy to the person setting up the alert.
   */
  doNotCopy: string
  /**
   * How often should the alert/docket track be sent
   */
  frequency?: string
  /**
   * Alerts/Docket Track - Send email even when there is no activity
   */
  noActivity: string
  /**
   * An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  originalRecipientId?: string[]
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * The ID of the original subscriber of the email.
   */
  senderId: string
  /**
   * User generated tags added to an alert/docket track
   */
  tagsExist: boolean
}

export interface DocketsProperties {
  caseType?: string
  /**
   * court name, eg. United States District Court For The Southern District Of Texas
   */
  court?: string
  /**
   * state or federal
   */
  courtJurisdiction?: string
  /**
   * The date the docket entry was filed
   */
  dateFiled?: string
  /**
   * complaint, notice, summon
   */
  docketKeyType?: string
  /**
   * The Docket No...  ie... 2:16-cv-11082
   */
  docketNumber?: string
  /**
   * Federal, State, and Pleading
   */
  docketType?: string
  /**
   * regular, udv
   */
  docketViewer?: string
  /**
   * Personal Injury - Other
   */
  natureOfSuit?: string
  /**
   * EXT ID number / Entry number
   */
  pleadingId?: string
}

export interface DocumentsProperties {
  /**
   * High level grouping of content types
   *
   * * Legislation
   *
   * * Opportunities
   *
   * * Court Materials
   *
   * * Articles
   *
   * * etc.
   */
  contentGroup?: string
  /**
   * The categorization of the type of document.
   *
   * * Docket
   *
   * * Task Order
   *
   * * Solicitation
   *
   * * etc.
   */
  contentKind?: string
  /**
   * Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   */
  documentCollection?: string
  /**
   * String that uniquely identifies each document.
   */
  documentId?: string
  /**
   * Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   */
  documentIdLong?: string
  /**
   * Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search results, search auto suggest, navigation, direct link, User Workflow, First Draft, ai click |
   */
  documentViewSource?:
    | 'search results'
    | 'search auto suggest'
    | 'navigation'
    | 'direct link'
    | 'User Workflow'
    | 'First Draft'
    | 'ai click'
  /**
   * The Circuit Court
   *
   *
   * **Examples**:
   *
   * * D.C. Circuit Courts
   *
   * * U.S. Supreme Court
   *
   * * Federal Circuit Courts
   *
   * * First Circuit Courts
   *
   * * Second Circuit Courts
   *
   * * Third Circuit Courts
   *
   * * Fourth Circuit Courts
   *
   * * Fifth Circuit Courts
   *
   * * Sixth Circuit Courts
   *
   * * Seventh Circuit Courts
   *
   * * Eighth Circuit Courts
   *
   * * Ninth Circuit Courts
   *
   * * Tenth Circuit Courts
   *
   * * Eleventh Circuit Courts
   *
   * * Other Federal Courts
   */
  federalCircuit?: any
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nodeId?: number
  /**
   * The publisher for a content collection
   */
  publisher?: string
}

export interface DocumentsLegislationProperties {
  /**
   * stores the govt agency or private entity associated with a document.
   */
  agency?: string
  /**
   * Identifying Key for agency or other govt/private entity
   */
  agencyId?: string
  /**
   * Bill Number
   */
  bill?: string
  /**
   * Federal Congress Number or State Session Name
   */
  congressSession?: string
  /**
   * Full state name (e.g. Rhode Island or Utah)
   */
  stateName?: string
}

export interface EntryRequestProperties {
  /**
   * When a user has successfully submitted a docket request
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | success, failure |
   */
  entryRequestStatus: 'success' | 'failure'
}

export interface ErrorsAndNoticesProperties {
  /**
   * descriptor that provides context on the event/action the triggered a notice or error event to fire.  Helps differentiate between sources of friction that may be occuring on the same page/event
   */
  noticeDetails?: string
}

export interface EventCounterProperties {
  /**
   * Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  actionCount?: number
  /**
   * describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   */
  actionDetail?: string
  /**
   * unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   */
  iterationId?: string
}

export interface EverywhereProperties {
  /**
   * Indicates if a given action is related to an ai-powered tool or feature
   */
  aiEnabled?: boolean
  /**
   * The name of the Analytics app that's making the call
   */
  appName: string
  /**
   * The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   */
  appVersion: string
  /**
   * BLAW/BTAX/BGOVGA/BGOVGC/INDG
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | BLAW|BTAX|BGOVGA|BGOVGC|INDG|BGOV |
   */
  brand: string
  /**
   * News Channel Code
   *
   * * Daily Tax Report
   *
   * * Crypto
   *
   * * Business & Practice
   */
  channel?: string
  codeBase?: string
  /**
   * current domain for the page
   *
   * * bloomberglaw.com
   *
   * * bloombergtax.com
   *
   * * bloombergindustry.com
   *
   * * bgov.com
   *
   * * bna.com
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | bloomberglaw.com|bloombergtax.com|bloombergindustry.com|bgov.com|bna.com |
   */
  domain: string
  /**
   * this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  eventDetails?: string[]
  /**
   * This is the status code that the server returns, examples:
   *
   * * 200 - Normal
   *
   * * 404 - Page Not Found
   *
   * * 500 - Server Error
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 100 |
   * | Max Value | 999 |
   */
  httpStatusCode: number
  /**
   * Is the user currently logged in (Boolean)
   */
  isLoggedIn: boolean
  /**
   * This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   */
  itemFavorited?: boolean
  /**
   * Page Title
   */
  pageTitle: string
  /**
   * Page Type, Generally, Landing, Brand Landing, Article, etc...
   */
  pageType: string
  /**
   * Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.
   *
   * * Personalized Homepage
   */
  pageVersion?: string
  /**
   * INDG Platform
   *
   * BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   */
  platform: string
  previousPage?: string
  /**
   * Vertical Product Code
   *
   *
   * For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   */
  product?: string
  /**
   * Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)
   *
   * In the case of a single entity being associated, then the quantity should be 1
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  quantity?: number
  sortedBy?: string
  /**
   * full url for the page
   */
  url: string
}

export interface FavoritedEmbeddedContentProperties {
  /**
   * This is the title or label for content that has been favorited
   */
  contentTitle: string
}

export interface FeatureTourProperties {
  /**
   * Indicates the % completion of a tour. Should 100% when tourStatus = Completed otherwise should be a percentage between 0 and 1 if tourStatus = Exited
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tourDepth?: number
  /**
   * Describes the feature the tour is associated with. E.g. State Legislation Search Reinvent
   *
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   */
  tourName: string
  /**
   * Differentiates whether a tour was prompted automatically or via a user clicking some variation of 'tour'
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Auto Start, User Start |
   */
  tourStatus?: 'Auto Start' | 'User Start'
}

export interface FeedbackFormsProperties {
  /**
   * identifying title for the feedback form. E.g. 'state leg 2.0' or 'custom bill compare'
   */
  feedbackFormTitle: string
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
}

export interface FileUploadProperties {
  /**
   * To be used to specify the type of file, such as CSV, XLSX, DOCX, PDF, etc...  Should just be the extention without a period and all upper case 3-4 characters.  Should NOT be the filename.
   *
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   * | Max Length | 4 |
   */
  fileType: string
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
  /**
   * To specify the type of Upload, did someone browse for a file or did someone drag and drop a file
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Browse, Drag & Drop |
   */
  upLoadType: 'Browse' | 'Drag & Drop'
}

export interface FormsProperties {
  formTitle: string
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
}

export interface IndgNewsProperties {
  /**
   * This is the BWRITE Article ID
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \w+-\w+-\w+-\w+-\w+ |
   */
  articleId?: string
  /**
   * This should be the BWRITE Article Type
   *
   * * Analysis
   *
   * * Insight
   *
   * * News
   *
   * * etc...
   */
  articleType?: string
  /**
   * Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   */
  niCodes?: string
  /**
   * the name of the publication that the article was published
   */
  source?: string
  /**
   * The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.
   *
   *
   * NI Codes for BGOV Content
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  subscriptionIds?: string[]
  /**
   * the feed on which the article was published
   */
  wire?: string
}

export interface JobRunSubmittedProperties {
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
}

export interface LoginPropertiesProperties {
  /**
   * The location on the page that the login action was taken.
   */
  loginClickLocation?: string
  /**
   * Login Response from the login page
   */
  loginResponse?: string
  /**
   * Is the login inline or modal
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | Modal|Inline |
   */
  loginType: string
  /**
   * Remember Me on the login page
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | Yes|No|N/A |
   */
  rememberMe: string
}

export interface MfaPropertiesProperties {
  /**
   * This is the current value on the countdown clock
   */
  mfaCountDown?: string
  /**
   * If there is a failure for the MFA submission, then it should be listed here.
   */
  mfaFailtureReason: string
  mfaInitialSetup: boolean
  /**
   * Can only be email or sms (lowercase)
   */
  mfaMethod?: string
  /**
   * Whether the MFA was a success or failure
   */
  mfaSuccess: boolean
}

export interface NavigationProperties {
  /**
   * A high level indicator of which navigation section the clicked item was located in
   */
  navGroup: string
  /**
   * The specific item that was clicked: Click Text on a navigational element, or the link that was clicked, or the name of the sub-nav item when used with Sub-Nav_Opened, article title
   */
  navItem: string
  /**
   * A categorization of the navigational elements a descriptive 'where' of the link was clicked or type of menu opened. If I open a sub-nav, it would be 'Sub-Nav', if I click on a link within a sub-nav it would also be 'Sub-Nav'. If I click on a link within or at the bottom of an article it might be Content Nav, or click on a category or tag group on the right hand side, 'Topics'.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Primary Nav, Sub Nav, Content Nav, Left Bar, Right Bar, Anthology, Text Link, Home Page Module, Article Sidebar, User Workflow, Internal Ad, Document Sidebar, Table |
   */
  navType:
    | 'Primary Nav'
    | 'Sub Nav'
    | 'Content Nav'
    | 'Left Bar'
    | 'Right Bar'
    | 'Anthology'
    | 'Text Link'
    | 'Home Page Module'
    | 'Article Sidebar'
    | 'User Workflow'
    | 'Internal Ad'
    | 'Document Sidebar'
    | 'Table'
}

export interface NewsletterSignupProperties {
  /**
   * Denotes whether a newsletter is free or only available to subscribers
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | free, subscriber |
   */
  newsletterAccessType: 'free' | 'subscriber'
  /**
   * array of articles selected
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  newslettersSelected?: string[]
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
}

export interface OnboardingFormProperties {
  onboardingFormTitle: string
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
}

export interface PlayProgressProperties {
  /**
   * usually quartiles, the % of the audio or video that has been played
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 25, 50, 75, 100 |
   */
  playDepth: '25' | '50' | '75' | '100'
}

export interface PodcastsProperties {
  /**
   * The title of a podcast eposide.
   */
  podcastEpisodeTitle?: string
  /**
   * apple|google|stitcher|spotify
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | apple, google, stitcher, spotify |
   */
  podcastPlatform?: 'apple' | 'google' | 'stitcher' | 'spotify'
  /**
   * ID for the Podcast Series
   */
  podcastSeriesId?: string
  /**
   * The title of a podcast series.
   */
  podcastSeriesTitle?: string
}

export interface PracticalGuidanceProperties {
  /**
   * Area metadata used within Practical Guidance documents in BLAW/BTAX.
   */
  practicalGuidanceArea?: string
}

export interface RegistrationFormProperties {
  /**
   * Number of Licenses Requested
   */
  licenseRequestedAmount?: string
}

export interface ScrollProgressProperties {
  /**
   * usually quartiles, the % of the page that has been visible
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 25, 50, 75, 100 |
   */
  pageDepth: '25' | '50' | '75' | '100'
}

export interface SearchResultsProperties {
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * Comma delimited list of content types included in the search results
   */
  resultContentKinds?: string
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * This is meant exclusively for INDG News articles
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchArticleType?: string[]
  /**
   * This call be all dates, to the range of one or more dates
   */
  searchDateRange?: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   */
  searchResponseType?: string
  /**
   * the search results page number where an action occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsPage?: number
  /**
   * Numeric indicator of how many search results are visible on a page.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsVisible?: number
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Sort by Date or Relevance
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | date, relevance, court, most cited, table of contents |
   */
  searchSortBy?: 'date' | 'relevance' | 'court' | 'most cited' | 'table of contents'
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
}

export interface SearchResultsClickProperties {
  /**
   * used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | preview, click through |
   */
  clickThruType?: 'preview' | 'click through'
  /**
   * the index of the result that was clicked
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  searchResultsIndex?: number
}

export interface SearchSavedSharedProperties {
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * count of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  resultsCount: number
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
}

export interface SearchSubmittedProperties {
  /**
   * Keywords used to generate the search, this could be zero or more items
   */
  keywords?: string
  /**
   * array of the filters and keywords of the previous query. Used to help in analyzing and understanding the specific keyword or filter changes made when search action = refine.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  previousFilters?: string[]
  /**
   * stores the keywords from the previous search query.
   *
   *
   * In instances where the functional 'keywords' may be different than the visible keywords (e.g. with BGOV inferred filters), store the functional keywords.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  previousKeywords?: string[]
  /**
   * initial' search is the submission of a search (or arrival at search results via a link) via a search tool.
   *  'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)
   *  'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   */
  searchAction: string
  /**
   * Array of all filter selections applied to the search results.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchFilters?: string[]
  /**
   * The ID for the current search
   */
  searchId?: string
  /**
   * Version of the search model. Useful for monitoring performance of models underlying searches.
   */
  searchModel?: string
  /**
   * The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.
   *
   * example: dockets
   */
  searchScope: string
  /**
   * Indicates whether there were any autosuggesions available (whether or not they were selected).
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  searchSuggestionAvailable?: ('inferred filter edited' | 'keyword edited')[]
  /**
   * array of modifications made to a search suggestion or AI supported search. Adds additional detail to 'search action = refined' that highlights the specific type of refinement.
   *
   *
   * sample values:
   *
   * * keyword edited
   *
   * * inferred filter added
   *
   * * inferred filter removed
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  searchSuggestionModified?: string[]
  /**
   * Did someone use a keyword
   */
  searchTermsExist: boolean
  /**
   * \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | go bar, global, docket, filter, curated, direct link, navigation, news takeover, historical spend, state legislation, feature search, search all, search widget |
   */
  searchTool:
    | 'go bar'
    | 'global'
    | 'docket'
    | 'filter'
    | 'curated'
    | 'direct link'
    | 'navigation'
    | 'news takeover'
    | 'historical spend'
    | 'state legislation'
    | 'feature search'
    | 'search all'
    | 'search widget'
  /**
   * Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   */
  searchType: string
}

export interface SidebarTabProperties {
  sidebarIsOpen: boolean
  sidebarItem: string
}

export interface TocExportedOrSharedProperties {
  /**
   * Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  exportOptions?: string[]
  /**
   * Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document
   *
   * Sample values: pdf, csv, share link, email, print
   */
  method?: string
}

export interface ToolsProperties {
  /**
   * facet/aspect of a tool that was used
   */
  toolFeature?: string
  /**
   * name of the tool
   */
  toolName?: string
  toolScope?: string
}

export interface TrackAlertsProperties {
  /**
   * array of options
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  options?: string[]
}

export interface UpsellProperties {
  /**
   * This should determine the name of the content being accessed.
   */
  upsellContentName: string
  /**
   * This should  determine what type of content is being accessed.  Sample values would be:
   *
   * \* Document
   *
   * \* Practice Area
   *
   * \* Tool
   */
  upsellContentType: string
  /**
   * This should be the full URL of the content the user is trying to access.
   */
  upsellContentUrl: string
  /**
   * This is a controlled list of the types of Upsells. Currently, it should either be Modal or Page.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Modal, Page |
   */
  upsellType: 'Modal' | 'Page'
}

export interface UserWorkflowViewedProperties {
  /**
   * Research Trail, Download Center, Alert Management etc
   */
  workFlowName: string
}

export interface WorkflowViewedProperties {
  /**
   * total number of workflows on a page (BTAX Software)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  totalWorkflows?: number
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties
  }
}

export class Group implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.GROUP_IDENTIFY

  constructor(public event_properties?: GroupProperties) {
    this.event_properties = event_properties
  }
}

export class AmplitudeFormStarted implements BaseEvent {
  event_type = '[Amplitude] Form Started'
}

export class AmplitudePageViewed implements BaseEvent {
  event_type = '[Amplitude] Page Viewed'

  constructor(public event_properties?: AmplitudePageViewedProperties) {
    this.event_properties = event_properties
  }
}

export class AcademicRegistration implements BaseEvent {
  event_type = 'Academic Registration'

  constructor(public event_properties: AcademicRegistrationProperties) {
    this.event_properties = event_properties
  }
}

export class AddedToWorkspace implements BaseEvent {
  event_type = 'Added to Workspace'

  constructor(public event_properties: AddedToWorkspaceProperties) {
    this.event_properties = event_properties
  }
}

export class AlertsInboxViewed implements BaseEvent {
  event_type = 'Alerts Inbox Viewed'

  constructor(public event_properties: AlertsInboxViewedProperties) {
    this.event_properties = event_properties
  }
}

export class AlertsManagementViewed implements BaseEvent {
  event_type = 'Alerts Management Viewed'

  constructor(public event_properties: AlertsManagementViewedProperties) {
    this.event_properties = event_properties
  }
}

export class ArticleExported implements BaseEvent {
  event_type = 'Article Exported'

  constructor(public event_properties: ArticleExportedProperties) {
    this.event_properties = event_properties
  }
}

export class ArticleListenClosed implements BaseEvent {
  event_type = 'Article Listen Closed'

  constructor(public event_properties: ArticleListenClosedProperties) {
    this.event_properties = event_properties
  }
}

export class ArticleListenOpened implements BaseEvent {
  event_type = 'Article Listen Opened'

  constructor(public event_properties: ArticleListenOpenedProperties) {
    this.event_properties = event_properties
  }
}

export class ArticleListenPaused implements BaseEvent {
  event_type = 'Article Listen Paused'

  constructor(public event_properties: ArticleListenPausedProperties) {
    this.event_properties = event_properties
  }
}

export class ArticleListenPlayed implements BaseEvent {
  event_type = 'Article Listen Played'

  constructor(public event_properties: ArticleListenPlayedProperties) {
    this.event_properties = event_properties
  }
}

export class ArticleListenSkippedBack implements BaseEvent {
  event_type = 'Article Listen Skipped Back'

  constructor(public event_properties: ArticleListenSkippedBackProperties) {
    this.event_properties = event_properties
  }
}

export class ArticleListenSkippedForward implements BaseEvent {
  event_type = 'Article Listen Skipped Forward'

  constructor(public event_properties: ArticleListenSkippedForwardProperties) {
    this.event_properties = event_properties
  }
}

export class ArticleSaved implements BaseEvent {
  event_type = 'Article Saved'

  constructor(public event_properties: ArticleSavedProperties) {
    this.event_properties = event_properties
  }
}

export class ArticleShared implements BaseEvent {
  event_type = 'Article Shared'

  constructor(public event_properties: ArticleSharedProperties) {
    this.event_properties = event_properties
  }
}

export class ArticleViewed implements BaseEvent {
  event_type = 'Article Viewed'

  constructor(public event_properties: ArticleViewedProperties) {
    this.event_properties = event_properties
  }
}

export class BriefAnalyzerUsed implements BaseEvent {
  event_type = 'Brief Analyzer Used'

  constructor(public event_properties: BriefAnalyzerUsedProperties) {
    this.event_properties = event_properties
  }
}

export class BudgetToSpendUsed implements BaseEvent {
  event_type = 'Budget to Spend Used'

  constructor(public event_properties: BudgetToSpendUsedProperties) {
    this.event_properties = event_properties
  }
}

export class CalendarEventViewed implements BaseEvent {
  event_type = 'Calendar Event Viewed'

  constructor(public event_properties: CalendarEventViewedProperties) {
    this.event_properties = event_properties
  }
}

export class ChartBuilderViewed implements BaseEvent {
  event_type = 'Chart Builder Viewed'

  constructor(public event_properties: ChartBuilderViewedProperties) {
    this.event_properties = event_properties
  }
}

export class ChartDisplayModified implements BaseEvent {
  event_type = 'Chart Display Modified'

  constructor(public event_properties: ChartDisplayModifiedProperties) {
    this.event_properties = event_properties
  }
}

export class ChartExported implements BaseEvent {
  event_type = 'Chart Exported'

  constructor(public event_properties: ChartExportedProperties) {
    this.event_properties = event_properties
  }
}

export class ChartFavorited implements BaseEvent {
  event_type = 'Chart Favorited'

  constructor(public event_properties: ChartFavoritedProperties) {
    this.event_properties = event_properties
  }
}

export class ChartHidden implements BaseEvent {
  event_type = 'Chart Hidden'

  constructor(public event_properties: ChartHiddenProperties) {
    this.event_properties = event_properties
  }
}

export class ChartShown implements BaseEvent {
  event_type = 'Chart Shown'

  constructor(public event_properties: ChartShownProperties) {
    this.event_properties = event_properties
  }
}

export class ChartViewed implements BaseEvent {
  event_type = 'Chart Viewed'

  constructor(public event_properties: ChartViewedProperties) {
    this.event_properties = event_properties
  }
}

export class ConfirmationFormViewed implements BaseEvent {
  event_type = 'Confirmation Form Viewed'

  constructor(public event_properties: ConfirmationFormViewedProperties) {
    this.event_properties = event_properties
  }
}

export class ContentExported implements BaseEvent {
  event_type = 'Content Exported'

  constructor(public event_properties: ContentExportedProperties) {
    this.event_properties = event_properties
  }
}

export class ContentFavorited implements BaseEvent {
  event_type = 'Content Favorited'

  constructor(public event_properties: ContentFavoritedProperties) {
    this.event_properties = event_properties
  }
}

export class ContractTaskPaneViewed implements BaseEvent {
  event_type = 'Contract Task Pane Viewed'

  constructor(public event_properties: ContractTaskPaneViewedProperties) {
    this.event_properties = event_properties
  }
}

export class DashboardViewed implements BaseEvent {
  event_type = 'Dashboard Viewed'

  constructor(public event_properties: DashboardViewedProperties) {
    this.event_properties = event_properties
  }
}

export class DirectoryViewed implements BaseEvent {
  event_type = 'Directory Viewed'

  constructor(public event_properties: DirectoryViewedProperties) {
    this.event_properties = event_properties
  }
}

export class DocketEntriesFiltered implements BaseEvent {
  event_type = 'Docket Entries Filtered'

  constructor(public event_properties: DocketEntriesFilteredProperties) {
    this.event_properties = event_properties
  }
}

export class DocketTrackAlertCreated implements BaseEvent {
  event_type = 'Docket Track Alert Created'

  constructor(public event_properties: DocketTrackAlertCreatedProperties) {
    this.event_properties = event_properties
  }
}

export class DocketTrackDeleted implements BaseEvent {
  event_type = 'Docket Track Deleted'

  constructor(public event_properties: DocketTrackDeletedProperties) {
    this.event_properties = event_properties
  }
}

export class DocketTrackModalViewed implements BaseEvent {
  event_type = 'Docket Track Modal Viewed'

  constructor(public event_properties: DocketTrackModalViewedProperties) {
    this.event_properties = event_properties
  }
}

export class DocketTrackModified implements BaseEvent {
  event_type = 'Docket Track Modified'

  constructor(public event_properties: DocketTrackModifiedProperties) {
    this.event_properties = event_properties
  }
}

export class DocketUpdateAuthorization implements BaseEvent {
  event_type = 'Docket Update Authorization'

  constructor(public event_properties: DocketUpdateAuthorizationProperties) {
    this.event_properties = event_properties
  }
}

export class DocketUpdateSubmitted implements BaseEvent {
  event_type = 'Docket Update Submitted'

  constructor(public event_properties: DocketUpdateSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export class DocumentCompared implements BaseEvent {
  event_type = 'Document Compared'

  constructor(public event_properties: DocumentComparedProperties) {
    this.event_properties = event_properties
  }
}

export class DocumentExported implements BaseEvent {
  event_type = 'Document Exported'

  constructor(public event_properties: DocumentExportedProperties) {
    this.event_properties = event_properties
  }
}

export class DocumentFavorited implements BaseEvent {
  event_type = 'Document Favorited'

  constructor(public event_properties: DocumentFavoritedProperties) {
    this.event_properties = event_properties
  }
}

export class DocumentSaved implements BaseEvent {
  event_type = 'Document Saved'

  constructor(public event_properties: DocumentSavedProperties) {
    this.event_properties = event_properties
  }
}

export class DocumentShared implements BaseEvent {
  event_type = 'Document Shared'

  constructor(public event_properties: DocumentSharedProperties) {
    this.event_properties = event_properties
  }
}

export class DocumentViewed implements BaseEvent {
  event_type = 'Document Viewed'

  constructor(public event_properties: DocumentViewedProperties) {
    this.event_properties = event_properties
  }
}

export class DraftAnalyzerUsed implements BaseEvent {
  event_type = 'Draft Analyzer Used'

  constructor(public event_properties: DraftAnalyzerUsedProperties) {
    this.event_properties = event_properties
  }
}

export class EmbeddedContent implements BaseEvent {
  event_type = 'Embedded Content'

  constructor(public event_properties: EmbeddedContentProperties) {
    this.event_properties = event_properties
  }
}

export class ErrorViewed implements BaseEvent {
  event_type = 'Error Viewed'

  constructor(public event_properties: ErrorViewedProperties) {
    this.event_properties = event_properties
  }
}

export class FeedbackFormSubmitted implements BaseEvent {
  event_type = 'Feedback Form Submitted'

  constructor(public event_properties: FeedbackFormSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export class FeedbackFormViewed implements BaseEvent {
  event_type = 'Feedback Form Viewed'

  constructor(public event_properties: FeedbackFormViewedProperties) {
    this.event_properties = event_properties
  }
}

export class FileUploaded implements BaseEvent {
  event_type = 'File Uploaded'

  constructor(public event_properties: FileUploadedProperties) {
    this.event_properties = event_properties
  }
}

export class FindAlertCreated implements BaseEvent {
  event_type = 'Find Alert Created'

  constructor(public event_properties: FindAlertCreatedProperties) {
    this.event_properties = event_properties
  }
}

export class FindAlertDeleted implements BaseEvent {
  event_type = 'Find Alert Deleted'

  constructor(public event_properties: FindAlertDeletedProperties) {
    this.event_properties = event_properties
  }
}

export class FindAlertModified implements BaseEvent {
  event_type = 'Find Alert Modified'

  constructor(public event_properties: FindAlertModifiedProperties) {
    this.event_properties = event_properties
  }
}

export class ForgotUsernamePassword implements BaseEvent {
  event_type = 'Forgot Username-Password'

  constructor(public event_properties: ForgotUsernamePasswordProperties) {
    this.event_properties = event_properties
  }
}

export class FormSubmitted implements BaseEvent {
  event_type = 'Form Submitted'

  constructor(public event_properties: FormSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export class FormViewed implements BaseEvent {
  event_type = 'Form Viewed'

  constructor(public event_properties: FormViewedProperties) {
    this.event_properties = event_properties
  }
}

export class GroupedAlertCreated implements BaseEvent {
  event_type = 'Grouped Alert Created'

  constructor(public event_properties: GroupedAlertCreatedProperties) {
    this.event_properties = event_properties
  }
}

export class HistoricalSpendViewed implements BaseEvent {
  event_type = 'Historical Spend Viewed'

  constructor(public event_properties: HistoricalSpendViewedProperties) {
    this.event_properties = event_properties
  }
}

export class HomepageViewed implements BaseEvent {
  event_type = 'Homepage Viewed'

  constructor(public event_properties: HomepageViewedProperties) {
    this.event_properties = event_properties
  }
}

export class InteractiveClick implements BaseEvent {
  event_type = 'Interactive Click'

  constructor(public event_properties: InteractiveClickProperties) {
    this.event_properties = event_properties
  }
}

export class InteractiveStoryViewed implements BaseEvent {
  event_type = 'Interactive Story Viewed'

  constructor(public event_properties: InteractiveStoryViewedProperties) {
    this.event_properties = event_properties
  }
}

export class JobRunFormViewed implements BaseEvent {
  event_type = 'Job Run Form Viewed'

  constructor(public event_properties: JobRunFormViewedProperties) {
    this.event_properties = event_properties
  }
}

export class JobRunSubmitted implements BaseEvent {
  event_type = 'Job Run Submitted'

  constructor(public event_properties: JobRunSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export class LandingViewed implements BaseEvent {
  event_type = 'Landing Viewed'

  constructor(public event_properties: LandingViewedProperties) {
    this.event_properties = event_properties
  }
}

export class LearnMore implements BaseEvent {
  event_type = 'Learn More'

  constructor(public event_properties: LearnMoreProperties) {
    this.event_properties = event_properties
  }
}

export class LitigationAnalyticsUsed implements BaseEvent {
  event_type = 'Litigation Analytics Used'

  constructor(public event_properties: LitigationAnalyticsUsedProperties) {
    this.event_properties = event_properties
  }
}

export class LoadMoreClicked implements BaseEvent {
  event_type = 'Load More Clicked'

  constructor(public event_properties: LoadMoreClickedProperties) {
    this.event_properties = event_properties
  }
}

export class LoginSubmitted implements BaseEvent {
  event_type = 'Login Submitted'

  constructor(public event_properties: LoginSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export class LoginView implements BaseEvent {
  event_type = 'Login View'

  constructor(public event_properties: LoginViewProperties) {
    this.event_properties = event_properties
  }
}

export class MultiFactorAuthenticationSubmitted implements BaseEvent {
  event_type = 'Multi Factor Authentication Submitted'

  constructor(public event_properties: MultiFactorAuthenticationSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export class MultiFactorAuthenticationViewed implements BaseEvent {
  event_type = 'Multi Factor Authentication Viewed'

  constructor(public event_properties: MultiFactorAuthenticationViewedProperties) {
    this.event_properties = event_properties
  }
}

export class NavClicked implements BaseEvent {
  event_type = 'Nav Clicked'

  constructor(public event_properties: NavClickedProperties) {
    this.event_properties = event_properties
  }
}

export class NavClosed implements BaseEvent {
  event_type = 'Nav Closed'

  constructor(public event_properties: NavClosedProperties) {
    this.event_properties = event_properties
  }
}

export class NavOpened implements BaseEvent {
  event_type = 'Nav Opened'

  constructor(public event_properties: NavOpenedProperties) {
    this.event_properties = event_properties
  }
}

export class NewsletterFormClosed implements BaseEvent {
  event_type = 'Newsletter Form Closed'

  constructor(public event_properties: NewsletterFormClosedProperties) {
    this.event_properties = event_properties
  }
}

export class NewsletterFormViewed implements BaseEvent {
  event_type = 'Newsletter Form Viewed'

  constructor(public event_properties: NewsletterFormViewedProperties) {
    this.event_properties = event_properties
  }
}

export class NewsletterSignupSubmitted implements BaseEvent {
  event_type = 'Newsletter Signup Submitted'

  constructor(public event_properties: NewsletterSignupSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export class NewsletterSignupViewed implements BaseEvent {
  event_type = 'Newsletter Signup Viewed'

  constructor(public event_properties: NewsletterSignupViewedProperties) {
    this.event_properties = event_properties
  }
}

export class NewsletterSubscribed implements BaseEvent {
  event_type = 'Newsletter Subscribed'

  constructor(public event_properties: NewsletterSubscribedProperties) {
    this.event_properties = event_properties
  }
}

export class NewsletterViewed implements BaseEvent {
  event_type = 'Newsletter Viewed'

  constructor(public event_properties: NewsletterViewedProperties) {
    this.event_properties = event_properties
  }
}

export class NextResultNavigated implements BaseEvent {
  event_type = 'Next Result Navigated'

  constructor(public event_properties: NextResultNavigatedProperties) {
    this.event_properties = event_properties
  }
}

export class NoteAdded implements BaseEvent {
  event_type = 'Note Added'

  constructor(public event_properties: NoteAddedProperties) {
    this.event_properties = event_properties
  }
}

export class NoteDeleted implements BaseEvent {
  event_type = 'Note Deleted'

  constructor(public event_properties: NoteDeletedProperties) {
    this.event_properties = event_properties
  }
}

export class NoticeViewed implements BaseEvent {
  event_type = 'Notice Viewed'

  constructor(public event_properties: NoticeViewedProperties) {
    this.event_properties = event_properties
  }
}

export class OnboardingSubmitted implements BaseEvent {
  event_type = 'Onboarding Submitted'

  constructor(public event_properties: OnboardingSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export class OnboardingViewed implements BaseEvent {
  event_type = 'Onboarding Viewed'

  constructor(public event_properties: OnboardingViewedProperties) {
    this.event_properties = event_properties
  }
}

export class PageModified implements BaseEvent {
  event_type = 'Page Modified'

  constructor(public event_properties: PageModifiedProperties) {
    this.event_properties = event_properties
  }
}

export class PersonalizationModalViewed implements BaseEvent {
  event_type = 'Personalization Modal Viewed'

  constructor(public event_properties: PersonalizationModalViewedProperties) {
    this.event_properties = event_properties
  }
}

export class PlayDepth implements BaseEvent {
  event_type = 'Play Depth'

  constructor(public event_properties: PlayDepthProperties) {
    this.event_properties = event_properties
  }
}

export class PleadingRequestAuthorization implements BaseEvent {
  event_type = 'Pleading Request Authorization'

  constructor(public event_properties: PleadingRequestAuthorizationProperties) {
    this.event_properties = event_properties
  }
}

export class PleadingRequestSubmitted implements BaseEvent {
  event_type = 'Pleading Request Submitted'

  constructor(public event_properties: PleadingRequestSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export class PodcastEpisodePlayed implements BaseEvent {
  event_type = 'Podcast Episode Played'

  constructor(public event_properties: PodcastEpisodePlayedProperties) {
    this.event_properties = event_properties
  }
}

export class PodcastSeriesLandingViewed implements BaseEvent {
  event_type = 'Podcast Series Landing Viewed'

  constructor(public event_properties: PodcastSeriesLandingViewedProperties) {
    this.event_properties = event_properties
  }
}

export class PodcastSeriesSubscribed implements BaseEvent {
  event_type = 'Podcast Series Subscribed'

  constructor(public event_properties: PodcastSeriesSubscribedProperties) {
    this.event_properties = event_properties
  }
}

export class PodcastSeriesViewed implements BaseEvent {
  event_type = 'Podcast Series Viewed'

  constructor(public event_properties: PodcastSeriesViewedProperties) {
    this.event_properties = event_properties
  }
}

export class PracticeToolUsed implements BaseEvent {
  event_type = 'Practice Tool Used'

  constructor(public event_properties: PracticeToolUsedProperties) {
    this.event_properties = event_properties
  }
}

export class PreviousResultNavigated implements BaseEvent {
  event_type = 'Previous Result Navigated'

  constructor(public event_properties: PreviousResultNavigatedProperties) {
    this.event_properties = event_properties
  }
}

export class ProfileViewed implements BaseEvent {
  event_type = 'Profile Viewed'

  constructor(public event_properties: ProfileViewedProperties) {
    this.event_properties = event_properties
  }
}

export class ReadNow implements BaseEvent {
  event_type = 'Read Now'

  constructor(public event_properties: ReadNowProperties) {
    this.event_properties = event_properties
  }
}

export class ReconciliationViewed implements BaseEvent {
  event_type = 'Reconciliation Viewed'

  constructor(public event_properties: ReconciliationViewedProperties) {
    this.event_properties = event_properties
  }
}

export class RegistrationFormSubmitted implements BaseEvent {
  event_type = 'Registration Form Submitted'

  constructor(public event_properties: RegistrationFormSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export class RegistrationFormViewed implements BaseEvent {
  event_type = 'Registration Form Viewed'

  constructor(public event_properties: RegistrationFormViewedProperties) {
    this.event_properties = event_properties
  }
}

export class RememberMe implements BaseEvent {
  event_type = 'Remember Me'

  constructor(public event_properties: RememberMeProperties) {
    this.event_properties = event_properties
  }
}

export class ReportRequested implements BaseEvent {
  event_type = 'Report Requested'

  constructor(public event_properties: ReportRequestedProperties) {
    this.event_properties = event_properties
  }
}

export class RequestDemo implements BaseEvent {
  event_type = 'Request Demo'

  constructor(public event_properties: RequestDemoProperties) {
    this.event_properties = event_properties
  }
}

export class ResultsDrilldownClicked implements BaseEvent {
  event_type = 'Results Drilldown Clicked'

  constructor(public event_properties: ResultsDrilldownClickedProperties) {
    this.event_properties = event_properties
  }
}

export class SavedForLater implements BaseEvent {
  event_type = 'Saved for Later'

  constructor(public event_properties: SavedForLaterProperties) {
    this.event_properties = event_properties
  }
}

export class SavedSearchesViewed implements BaseEvent {
  event_type = 'Saved Searches Viewed'

  constructor(public event_properties: SavedSearchesViewedProperties) {
    this.event_properties = event_properties
  }
}

export class ScrollDepth implements BaseEvent {
  event_type = 'Scroll Depth'

  constructor(public event_properties: ScrollDepthProperties) {
    this.event_properties = event_properties
  }
}

export class SearchExportCustomized implements BaseEvent {
  event_type = 'Search Export Customized'

  constructor(public event_properties: SearchExportCustomizedProperties) {
    this.event_properties = event_properties
  }
}

export class SearchFormClosed implements BaseEvent {
  event_type = 'Search Form Closed'

  constructor(public event_properties: SearchFormClosedProperties) {
    this.event_properties = event_properties
  }
}

export class SearchFormViewed implements BaseEvent {
  event_type = 'Search Form Viewed'

  constructor(public event_properties: SearchFormViewedProperties) {
    this.event_properties = event_properties
  }
}

export class SearchResultHidden implements BaseEvent {
  event_type = 'Search Result Hidden'

  constructor(public event_properties: SearchResultHiddenProperties) {
    this.event_properties = event_properties
  }
}

export class SearchResultsClicked implements BaseEvent {
  event_type = 'Search Results Clicked'

  constructor(public event_properties: SearchResultsClickedProperties) {
    this.event_properties = event_properties
  }
}

export class SearchResultsExported implements BaseEvent {
  event_type = 'Search Results Exported'

  constructor(public event_properties: SearchResultsExportedProperties) {
    this.event_properties = event_properties
  }
}

export class SearchResultsNavigated implements BaseEvent {
  event_type = 'Search Results Navigated'

  constructor(public event_properties: SearchResultsNavigatedProperties) {
    this.event_properties = event_properties
  }
}

export class SearchResultsSorted implements BaseEvent {
  event_type = 'Search Results Sorted'

  constructor(public event_properties: SearchResultsSortedProperties) {
    this.event_properties = event_properties
  }
}

export class SearchResultsUnhidden implements BaseEvent {
  event_type = 'Search Results Unhidden'

  constructor(public event_properties: SearchResultsUnhiddenProperties) {
    this.event_properties = event_properties
  }
}

export class SearchResultsViewed implements BaseEvent {
  event_type = 'Search Results Viewed'

  constructor(public event_properties: SearchResultsViewedProperties) {
    this.event_properties = event_properties
  }
}

export class SearchSaved implements BaseEvent {
  event_type = 'Search Saved'

  constructor(public event_properties: SearchSavedProperties) {
    this.event_properties = event_properties
  }
}

export class SearchShared implements BaseEvent {
  event_type = 'Search Shared'

  constructor(public event_properties: SearchSharedProperties) {
    this.event_properties = event_properties
  }
}

export class SearchSubmitted implements BaseEvent {
  event_type = 'Search Submitted'

  constructor(public event_properties: SearchSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export class SelectionsSaved implements BaseEvent {
  event_type = 'Selections Saved'

  constructor(public event_properties: SelectionsSavedProperties) {
    this.event_properties = event_properties
  }
}

export class SetAsHome implements BaseEvent {
  event_type = 'Set as Home'

  constructor(public event_properties: SetAsHomeProperties) {
    this.event_properties = event_properties
  }
}

export class SidebarTabOpen implements BaseEvent {
  event_type = 'Sidebar Tab Open'

  constructor(public event_properties: SidebarTabOpenProperties) {
    this.event_properties = event_properties
  }
}

export class SubscriberAdded implements BaseEvent {
  event_type = 'Subscriber Added'

  constructor(public event_properties: SubscriberAddedProperties) {
    this.event_properties = event_properties
  }
}

export class TableResultsFormatted implements BaseEvent {
  event_type = 'Table Results Formatted'

  constructor(public event_properties: TableResultsFormattedProperties) {
    this.event_properties = event_properties
  }
}

export class TagAdded implements BaseEvent {
  event_type = 'Tag Added'

  constructor(public event_properties: TagAddedProperties) {
    this.event_properties = event_properties
  }
}

export class TagDeleted implements BaseEvent {
  event_type = 'Tag Deleted'

  constructor(public event_properties: TagDeletedProperties) {
    this.event_properties = event_properties
  }
}

export class TocExported implements BaseEvent {
  event_type = 'TOC Exported'

  constructor(public event_properties: TocExportedProperties) {
    this.event_properties = event_properties
  }
}

export class TocShared implements BaseEvent {
  event_type = 'TOC Shared'

  constructor(public event_properties: TocSharedProperties) {
    this.event_properties = event_properties
  }
}

export class TocViewed implements BaseEvent {
  event_type = 'TOC Viewed'

  constructor(public event_properties: TocViewedProperties) {
    this.event_properties = event_properties
  }
}

export class TourEnded implements BaseEvent {
  event_type = 'Tour Ended'

  constructor(public event_properties: TourEndedProperties) {
    this.event_properties = event_properties
  }
}

export class TourViewed implements BaseEvent {
  event_type = 'Tour Viewed'

  constructor(public event_properties: TourViewedProperties) {
    this.event_properties = event_properties
  }
}

export class TrackAlertCreated implements BaseEvent {
  event_type = 'Track Alert Created'

  constructor(public event_properties: TrackAlertCreatedProperties) {
    this.event_properties = event_properties
  }
}

export class TrackAlertDeleted implements BaseEvent {
  event_type = 'Track Alert Deleted'

  constructor(public event_properties: TrackAlertDeletedProperties) {
    this.event_properties = event_properties
  }
}

export class TrackAlertModified implements BaseEvent {
  event_type = 'Track Alert Modified'

  constructor(public event_properties: TrackAlertModifiedProperties) {
    this.event_properties = event_properties
  }
}

export class UpsellViewed implements BaseEvent {
  event_type = 'Upsell Viewed'

  constructor(public event_properties: UpsellViewedProperties) {
    this.event_properties = event_properties
  }
}

export class UserWorkflowExported implements BaseEvent {
  event_type = 'User Workflow Exported'

  constructor(public event_properties: UserWorkflowExportedProperties) {
    this.event_properties = event_properties
  }
}

export class UserWorkflowViewed implements BaseEvent {
  event_type = 'User Workflow Viewed'

  constructor(public event_properties: UserWorkflowViewedProperties) {
    this.event_properties = event_properties
  }
}

export class WorkflowViewed implements BaseEvent {
  event_type = 'Workflow Viewed'

  constructor(public event_properties: WorkflowViewedProperties) {
    this.event_properties = event_properties
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> }

const getVoidPromiseResult = () => ({ promise: Promise.resolve() })

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

  /**
   * Set Group for the current user
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param options Optional event options.
   */
  setGroup(groupType: string, groupName: string | string[], options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.setGroup(groupType, groupName, options);
  }

  /**
   * Identify a group and set group properties.
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param properties The group properties.
   * @param options Optional event options.
   */
  groupIdentify(
    groupType: string,
    groupName: string | string[],
    properties?: GroupProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.groupIdentify(groupType, groupName, amplitudeIdentify, options);
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * [Amplitude] Form Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/%5BAmplitude%5D%20Form%20Started)
   *
   * Owner: Philip Ramsey
   *
   * @param options Amplitude event options.
   */
  amplitudeFormStarted(
    options?: EventOptions,
  ) {
    return this.track(new AmplitudeFormStarted(), options);
  }

  /**
   * [Amplitude] Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/%5BAmplitude%5D%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. [Amplitude] Page Domain)
   * @param options Amplitude event options.
   */
  amplitudePageViewed(
    properties?: AmplitudePageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudePageViewed(properties), options);
  }

  /**
   * Academic Registration
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Academic%20Registration)
   *
   * [Login Page]()
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  academicRegistration(
    properties: AcademicRegistrationProperties,
    options?: EventOptions,
  ) {
    return this.track(new AcademicRegistration(properties), options);
  }

  /**
   * Added to Workspace
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Added%20to%20Workspace)
   *
   * User adds a search or a document to a workspace. Carries properties of the specific item (document, search, etc) added
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  addedToWorkspace(
    properties: AddedToWorkspaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddedToWorkspace(properties), options);
  }

  /**
   * Alerts Inbox Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Alerts%20Inbox%20Viewed)
   *
   * [Alerts Inbox](https://www.bloomberglaw.com/product/blaw/alerts/inbox)
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  alertsInboxViewed(
    properties: AlertsInboxViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AlertsInboxViewed(properties), options);
  }

  /**
   * Alerts Management Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Alerts%20Management%20Viewed)
   *
   * Alerts Management Viewed
   *
   * * [BLAW](https://www.bloomberglaw.com/product/blaw/alerts/management)
   *
   * * [BTAX]()
   *
   * * [BGOV]()
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  alertsManagementViewed(
    properties: AlertsManagementViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AlertsManagementViewed(properties), options);
  }

  /**
   * Article Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Exported)
   *
   * User exports an article, usually using download or print links.
   *
   * Owner: rcarmichael@vendor.bloombergindustry.com
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  articleExported(
    properties: ArticleExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ArticleExported(properties), options);
  }

  /**
   * Article Listen Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Closed)
   *
   * Fire when the user clicks the "X" to close the listen UI.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  articleListenClosed(
    properties: ArticleListenClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ArticleListenClosed(properties), options);
  }

  /**
   * Article Listen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Opened)
   *
   * User clicks on the Article Listen button either on the email or web page.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  articleListenOpened(
    properties: ArticleListenOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ArticleListenOpened(properties), options);
  }

  /**
   * Article Listen Paused
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Paused)
   *
   * User presses the "pause" button in the audio player UI
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  articleListenPaused(
    properties: ArticleListenPausedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ArticleListenPaused(properties), options);
  }

  /**
   * Article Listen Played
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Played)
   *
   * User presses the "play" button in the article listen UI.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  articleListenPlayed(
    properties: ArticleListenPlayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ArticleListenPlayed(properties), options);
  }

  /**
   * Article Listen Skipped Back
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Skipped%20Back)
   *
   * Fire when the user skips backward in article listen UI.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  articleListenSkippedBack(
    properties: ArticleListenSkippedBackProperties,
    options?: EventOptions,
  ) {
    return this.track(new ArticleListenSkippedBack(properties), options);
  }

  /**
   * Article Listen Skipped Forward
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Skipped%20Forward)
   *
   * Fire when the user skips forward in an article listen.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  articleListenSkippedForward(
    properties: ArticleListenSkippedForwardProperties,
    options?: EventOptions,
  ) {
    return this.track(new ArticleListenSkippedForward(properties), options);
  }

  /**
   * Article Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Saved)
   *
   * User saves an article.
   *
   * Owner: rcarmichael@vendor.bloombergindustry.com
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  articleSaved(
    properties: ArticleSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ArticleSaved(properties), options);
  }

  /**
   * Article Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Shared)
   *
   * When a user shares an article via email or a social platform.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  articleShared(
    properties: ArticleSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ArticleShared(properties), options);
  }

  /**
   * Article Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Viewed)
   *
   * Fire when the user views a bnews article (usually on page load).
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  articleViewed(
    properties: ArticleViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ArticleViewed(properties), options);
  }

  /**
   * Brief Analyzer Used
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Brief%20Analyzer%20Used)
   *
   * Event: Brief Analyzer Used
   *
   * Description: This event tracks the usage of the Brief Analyzer feature on Bloomberg Law. It captures when users utilize the Brief Analyzer tool to analyze legal briefs and documents, providing insights into how often and in what context this feature is utilized
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  briefAnalyzerUsed(
    properties: BriefAnalyzerUsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BriefAnalyzerUsed(properties), options);
  }

  /**
   * Budget to Spend Used
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Budget%20to%20Spend%20Used)
   *
   * fires when a user loads budget to spend in BGOV
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  budgetToSpendUsed(
    properties: BudgetToSpendUsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BudgetToSpendUsed(properties), options);
  }

  /**
   * Calendar Event Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Calendar%20Event%20Viewed)
   *
   * This event tracks when a user views a calendar event
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  calendarEventViewed(
    properties: CalendarEventViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarEventViewed(properties), options);
  }

  /**
   * Chart Builder Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Builder%20Viewed)
   *
   * Fire when users are shown the chart builder page to build a chart (includes edit or modify)
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  chartBuilderViewed(
    properties: ChartBuilderViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChartBuilderViewed(properties), options);
  }

  /**
   * Chart Display Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Display%20Modified)
   *
   * Fire when users change display options such as column headers, answer length, highlight by timeframe, etc.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  chartDisplayModified(
    properties: ChartDisplayModifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChartDisplayModified(properties), options);
  }

  /**
   * Chart Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Exported)
   *
   * Fire when users export chart (include current options in parameters)
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  chartExported(
    properties: ChartExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChartExported(properties), options);
  }

  /**
   * Chart Favorited
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Favorited)
   *
   * Fire when users favorite chart options
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  chartFavorited(
    properties: ChartFavoritedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChartFavorited(properties), options);
  }

  /**
   * Chart Hidden
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Hidden)
   *
   * Fire when users toggle the show chart CTA to hide chart (e.g. historical spend)
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  chartHidden(
    properties: ChartHiddenProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChartHidden(properties), options);
  }

  /**
   * Chart Shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Shown)
   *
   * Fire when users toggle the Show Chart CTA to show chart (Historical Spend)
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  chartShown(
    properties: ChartShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChartShown(properties), options);
  }

  /**
   * Chart Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Viewed)
   *
   * Fire when users are shown a chart from chart builder whether new, saved, or modified.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  chartViewed(
    properties: ChartViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChartViewed(properties), options);
  }

  /**
   * Confirmation Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Confirmation%20Form%20Viewed)
   *
   * This event tracks when a user views the confirmation form during the sales process
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  confirmationFormViewed(
    properties: ConfirmationFormViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConfirmationFormViewed(properties), options);
  }

  /**
   * Content Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Content%20Exported)
   *
   * This event tracks when users export generic content
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  contentExported(
    properties: ContentExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContentExported(properties), options);
  }

  /**
   * Content Favorited
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Content%20Favorited)
   *
   * When a user "Favorites" non-document content, content modules or tools. 
   *
   * Example: News Channel Landing Pages on BLAW: https://www.bloomberglaw.com/bloomberglawnews/capital-markets
   *
   *
   * For documents - use Document Favorited
   *
   * For Charts - use Chart Favorited
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  contentFavorited(
    properties: ContentFavoritedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContentFavorited(properties), options);
  }

  /**
   * Contract Task Pane Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Contract%20Task%20Pane%20Viewed)
   *
   * This event tracks when a user views the contract task pane, which displays relevant tasks and information related to a specific contract.... This is specific for the BLAW Contracts Tool
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  contractTaskPaneViewed(
    properties: ContractTaskPaneViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContractTaskPaneViewed(properties), options);
  }

  /**
   * Dashboard Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Dashboard%20Viewed)
   *
   * This event tracks when a user views a dashboard
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  dashboardViewed(
    properties: DashboardViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardViewed(properties), options);
  }

  /**
   * Directory Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Directory%20Viewed)
   *
   * This event tracks when a user views a directory within the Bloomberg Government platform. It provides insights into user engagement with directories, helping to understand which directories are most frequently accessed and informing decisions on directory organization and content
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  directoryViewed(
    properties: DirectoryViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DirectoryViewed(properties), options);
  }

  /**
   * Docket Entries Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Entries%20Filtered)
   *
   * Have the docket entries been [filtered](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) on the Dockets page?
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  docketEntriesFiltered(
    properties: DocketEntriesFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocketEntriesFiltered(properties), options);
  }

  /**
   * Docket Track Alert Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Track%20Alert%20Created)
   *
   * A [Docket Track](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) has been created...
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  docketTrackAlertCreated(
    properties: DocketTrackAlertCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocketTrackAlertCreated(properties), options);
  }

  /**
   * Docket Track Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Track%20Deleted)
   *
   * The Docket Track was deleted by the user.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  docketTrackDeleted(
    properties: DocketTrackDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocketTrackDeleted(properties), options);
  }

  /**
   * Docket Track Modal Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Track%20Modal%20Viewed)
   *
   * Impressions of the Docket Track Modal, This can be viewed on [docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) or in [Custom Alerts](https://www.bloomberglaw.com/product/blaw/alerts/management)
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  docketTrackModalViewed(
    properties: DocketTrackModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocketTrackModalViewed(properties), options);
  }

  /**
   * Docket Track Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Track%20Modified)
   *
   * This is only if the docket track has been [modified](https://www.bloomberglaw.com/product/blaw/alerts/management)
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  docketTrackModified(
    properties: DocketTrackModifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocketTrackModified(properties), options);
  }

  /**
   * Docket Update Authorization
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Update%20Authorization)
   *
   * [Update Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Modal, when it has been viewed
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  docketUpdateAuthorization(
    properties: DocketUpdateAuthorizationProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocketUpdateAuthorization(properties), options);
  }

  /**
   * Docket Update Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Update%20Submitted)
   *
   * Triggers when a user has successfully submitted a docket update from. the [Update Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Modal
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  docketUpdateSubmitted(
    properties: DocketUpdateSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocketUpdateSubmitted(properties), options);
  }

  /**
   * Document Compared
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Compared)
   *
   * This event tracks when a document is compared to another document, providing insights into user behavior and document usage patterns
   *
   *
   * NOTE:
   *
   * Document properties should be associated with the primary document being compared
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  documentCompared(
    properties: DocumentComparedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentCompared(properties), options);
  }

  /**
   * Document Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Exported)
   *
   * Fire when users export a document
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  documentExported(
    properties: DocumentExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentExported(properties), options);
  }

  /**
   * Document Favorited
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Favorited)
   *
   * When someone "Favorites" a document
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  documentFavorited(
    properties: DocumentFavoritedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentFavorited(properties), options);
  }

  /**
   * Document Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Saved)
   *
   * Fires when a user uses the "Saved for Later" functionality
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  documentSaved(
    properties: DocumentSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentSaved(properties), options);
  }

  /**
   * Document Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Shared)
   *
   * A document has been shared by email
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  documentShared(
    properties: DocumentSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentShared(properties), options);
  }

  /**
   * Document Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Viewed)
   *
   * When a document has been viewed
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. agency)
   * @param options Amplitude event options.
   */
  documentViewed(
    properties: DocumentViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentViewed(properties), options);
  }

  /**
   * Draft Analyzer Used
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Draft%20Analyzer%20Used)
   *
   * Event: Draft Analyzer Used
   *
   * Description: This event tracks the usage of the Draft Analyzer feature on Bloomberg Law. It captures when users utilize the Draft Analyzer tool to analyze and improve their legal drafts. The event provides insights into how often and in what context the Draft Analyzer is utilized, helping to understand user engagement and the value of this feature
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  draftAnalyzerUsed(
    properties: DraftAnalyzerUsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DraftAnalyzerUsed(properties), options);
  }

  /**
   * Embedded Content
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Embedded%20Content)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  embeddedContent(
    properties: EmbeddedContentProperties,
    options?: EventOptions,
  ) {
    return this.track(new EmbeddedContent(properties), options);
  }

  /**
   * Error Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Error%20Viewed)
   *
   * User views a page that returns a server error (like a 404), be sure to update the httpStatusCode to match the error.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  errorViewed(
    properties: ErrorViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ErrorViewed(properties), options);
  }

  /**
   * Feedback Form Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Feedback%20Form%20Submitted)
   *
   * Fires when a user submits a feedback form
   *
   * Owner: Kyle Contrata
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  feedbackFormSubmitted(
    properties: FeedbackFormSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeedbackFormSubmitted(properties), options);
  }

  /**
   * Feedback Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Feedback%20Form%20Viewed)
   *
   * Fires when a user views or is shown a feedback form.
   *
   * Owner: Kyle Contrata
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  feedbackFormViewed(
    properties: FeedbackFormViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeedbackFormViewed(properties), options);
  }

  /**
   * File Uploaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/File%20Uploaded)
   *
   * This event tracks when a file is uploaded to the system
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  fileUploaded(
    properties: FileUploadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FileUploaded(properties), options);
  }

  /**
   * Find Alert Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Find%20Alert%20Created)
   *
   * This event tracks the creation of a new alert in the Alert Management system.  A Find Alert is associated with the user setting an alert based on a search they have performed.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  findAlertCreated(
    properties: FindAlertCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FindAlertCreated(properties), options);
  }

  /**
   * Find Alert Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Find%20Alert%20Deleted)
   *
   * The alert was deleted by the user
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  findAlertDeleted(
    properties: FindAlertDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FindAlertDeleted(properties), options);
  }

  /**
   * Find Alert Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Find%20Alert%20Modified)
   *
   * This would be fired when the "Save Search to Alert" is updated... While editing an Alert
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  findAlertModified(
    properties: FindAlertModifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FindAlertModified(properties), options);
  }

  /**
   * Forgot Username-Password
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Forgot%20Username-Password)
   *
   * Clicking on Forgot Username/Password on [login page]().
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  forgotUsernamePassword(
    properties: ForgotUsernamePasswordProperties,
    options?: EventOptions,
  ) {
    return this.track(new ForgotUsernamePassword(properties), options);
  }

  /**
   * Form Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Form%20Submitted)
   *
   * This event tracks when a form is submitted by a user. It provides insights into user engagement and conversion rates for forms on our platform
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  formSubmitted(
    properties: FormSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormSubmitted(properties), options);
  }

  /**
   * Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Form%20Viewed)
   *
   * This event tracks when a user views a form. It provides insights into user engagement with forms on our platform
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  formViewed(
    properties: FormViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormViewed(properties), options);
  }

  /**
   * Grouped Alert Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Grouped%20Alert%20Created)
   *
   * This event tracks the creation of a grouped alert, which is a customized notification that combines multiple individual alerts into a single group
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  groupedAlertCreated(
    properties: GroupedAlertCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GroupedAlertCreated(properties), options);
  }

  /**
   * Historical Spend Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Historical%20Spend%20Viewed)
   *
   * When user loads the Historical Spend Page
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  historicalSpendViewed(
    properties: HistoricalSpendViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HistoricalSpendViewed(properties), options);
  }

  /**
   * Homepage Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Homepage%20Viewed)
   *
   * User views the homepage
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  homepageViewed(
    properties: HomepageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomepageViewed(properties), options);
  }

  /**
   * Interactive Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Interactive%20Click)
   *
   * This should be used for any important interaction on a page or modal, that hasn't justified a specific event and doesn't cause a new page load (not a navigation). An example would be a click that shows a modal or modifies a table layout.
   *
   * The concept is if these items become valuable, we'll want to more specifically define them and track them.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  interactiveClick(
    properties: InteractiveClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new InteractiveClick(properties), options);
  }

  /**
   * Interactive Story Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Interactive%20Story%20Viewed)
   *
   * Fire when the user views an interactice story on bnews  (usually on page load).
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  interactiveStoryViewed(
    properties: InteractiveStoryViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InteractiveStoryViewed(properties), options);
  }

  /**
   * Job Run Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Job%20Run%20Form%20Viewed)
   *
   * This event tracks when a user views the job run form in the BTAX Software Workflows
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  jobRunFormViewed(
    properties: JobRunFormViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new JobRunFormViewed(properties), options);
  }

  /**
   * Job Run Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Job%20Run%20Submitted)
   *
   * This event tracks the submission of job runs within the BTAX Software Workflows, providing insights into the frequency and usage patterns of this feature
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  jobRunSubmitted(
    properties: JobRunSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new JobRunSubmitted(properties), options);
  }

  /**
   * Landing Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Landing%20Viewed)
   *
   * News Landing Page (homepage or channel page) Viewed - Specific to INDG News from BWRITE.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  landingViewed(
    properties: LandingViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandingViewed(properties), options);
  }

  /**
   * Learn More
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Learn%20More)
   *
   * Learn More from [Login Page]()
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  learnMore(
    properties: LearnMoreProperties,
    options?: EventOptions,
  ) {
    return this.track(new LearnMore(properties), options);
  }

  /**
   * Litigation Analytics Used
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Litigation%20Analytics%20Used)
   *
   * This event tracks the usage of litigation analytics within the Bloomberg Government platform. It provides insights into how often and in what context users are utilizing the litigation analytics feature
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  litigationAnalyticsUsed(
    properties: LitigationAnalyticsUsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LitigationAnalyticsUsed(properties), options);
  }

  /**
   * Load More Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Load%20More%20Clicked)
   *
   * Load More on a search results page
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  loadMoreClicked(
    properties: LoadMoreClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoadMoreClicked(properties), options);
  }

  /**
   * Login Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Login%20Submitted)
   *
   * [Login Page](https://www.bloomberglaw.com/signin) Submitted
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  loginSubmitted(
    properties: LoginSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginSubmitted(properties), options);
  }

  /**
   * Login View
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Login%20View)
   *
   * The [login page](https://www.bloomberglaw.com/signin) has been viewed
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  loginView(
    properties: LoginViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginView(properties), options);
  }

  /**
   * Multi Factor Authentication Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Multi%20Factor%20Authentication%20Submitted)
   *
   * This event fires when a user submits their multi-factor authentication information during the login process.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  multiFactorAuthenticationSubmitted(
    properties: MultiFactorAuthenticationSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MultiFactorAuthenticationSubmitted(properties), options);
  }

  /**
   * Multi Factor Authentication Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Multi%20Factor%20Authentication%20Viewed)
   *
   * This event fires when users view the multi-factor authentication page on the login page.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  multiFactorAuthenticationViewed(
    properties: MultiFactorAuthenticationViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MultiFactorAuthenticationViewed(properties), options);
  }

  /**
   * Nav Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Nav%20Clicked)
   *
   * Trigger when a user actually uses a navigation (clicks a link to a new page)
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  navClicked(
    properties: NavClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavClicked(properties), options);
  }

  /**
   * Nav Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Nav%20Closed)
   *
   * Trigger when a user closes a navigational menu.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  navClosed(
    properties: NavClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavClosed(properties), options);
  }

  /**
   * Nav Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Nav%20Opened)
   *
   * Trigger when user opens a navigational menu of some sort, a hamburger, a drop down, etc.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  navOpened(
    properties: NavOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavOpened(properties), options);
  }

  /**
   * Newsletter Form Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Form%20Closed)
   *
   * Newsletter Signup form is closed
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  newsletterFormClosed(
    properties: NewsletterFormClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewsletterFormClosed(properties), options);
  }

  /**
   * Newsletter Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Form%20Viewed)
   *
   * Trigger when form is viewed to subscribe to newsletters.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  newsletterFormViewed(
    properties: NewsletterFormViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewsletterFormViewed(properties), options);
  }

  /**
   * Newsletter Signup Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Signup%20Submitted)
   *
   * Submit sign up form for newsletter. This is the form where user enters contact info, not the form where they select which newsletter to subscribe to.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  newsletterSignupSubmitted(
    properties: NewsletterSignupSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewsletterSignupSubmitted(properties), options);
  }

  /**
   * Newsletter Signup Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Signup%20Viewed)
   *
   * Viewed the signup form for newsletters. This is the form where the user enters contact info (not the form where they select newsletter subscriptions).
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  newsletterSignupViewed(
    properties: NewsletterSignupViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewsletterSignupViewed(properties), options);
  }

  /**
   * Newsletter Subscribed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Subscribed)
   *
   * User has subscribed to a newsletter(s)
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  newsletterSubscribed(
    properties: NewsletterSubscribedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewsletterSubscribed(properties), options);
  }

  /**
   * Newsletter Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Viewed)
   *
   * This event tracks when a user views a newsletter - This is the "View Online" version of the newsletter for email.
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  newsletterViewed(
    properties: NewsletterViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewsletterViewed(properties), options);
  }

  /**
   * Next Result Navigated
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Next%20Result%20Navigated)
   *
   * Click on the Next Result CTA on a [document](https://www.bloomberglaw.com/product/blaw/document/X1JQ07JTG000N?criteria\_id=84c1018ec813139841de1aa2f1912b2d&navCriteriaId=3d6cce9c45c74c474f47647a70e68a3e&searchGuid=7f0cad2c-be82-4cc7-ac6c-3b50b2c78b6b&search32=H4g51LVe0iM0p08RRFKLsA%3D%3DRYD33FJ\_mhvHoY2LKNiKiiq3JRBTESO6h3zCMx9wZZkEdpkGaFUoYq7zp2az7ldtVf0oFM7QtuA0lE80TgXIcMJ84XGFh-nt\_GolpFoLz6L2qD2B0pd4AOWnfmQP4Ith).
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  nextResultNavigated(
    properties: NextResultNavigatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NextResultNavigated(properties), options);
  }

  /**
   * Note Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Note%20Added)
   *
   * This event is triggered when a note is added to a specific document or entity. This event helps track and analyze user engagement and collaboration within the platform
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  noteAdded(
    properties: NoteAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NoteAdded(properties), options);
  }

  /**
   * Note Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Note%20Deleted)
   *
   * This event tracks when a note is deleted within the platform
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. agency)
   * @param options Amplitude event options.
   */
  noteDeleted(
    properties: NoteDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NoteDeleted(properties), options);
  }

  /**
   * Notice Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Notice%20Viewed)
   *
   * Fires when notice for sanctioned countries is viewed
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  noticeViewed(
    properties: NoticeViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NoticeViewed(properties), options);
  }

  /**
   * Onboarding Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Onboarding%20Submitted)
   *
   * This event fires when a user submits their onboarding information for personalization purposes.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  onboardingSubmitted(
    properties: OnboardingSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingSubmitted(properties), options);
  }

  /**
   * Onboarding Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Onboarding%20Viewed)
   *
   * This event fires when a user views the onboarding process for personalized content. i.e., https://www.bloomberglaw.com/onboarding
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  onboardingViewed(
    properties: OnboardingViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingViewed(properties), options);
  }

  /**
   * Page Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Page%20Modified)
   *
   * Fire when a user takes an action to alter how a page is displayed or laid out. For example choosing between a generic or personalised version.
   *
   * Owner: rcarmichael@vendor.bloombergindustry.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  pageModified(
    properties: PageModifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageModified(properties), options);
  }

  /**
   * Personalization Modal Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Personalization%20Modal%20Viewed)
   *
   * User views a personalization modal
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  personalizationModalViewed(
    properties: PersonalizationModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PersonalizationModalViewed(properties), options);
  }

  /**
   * Play Depth
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Play%20Depth)
   *
   * usually quartiles, the % of the audio or video that has been played
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  playDepth(
    properties: PlayDepthProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlayDepth(properties), options);
  }

  /**
   * Pleading Request Authorization
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Pleading%20Request%20Authorization)
   *
   * A [docket entry/pleading](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) has been approved (Accept has been pressed)
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  pleadingRequestAuthorization(
    properties: PleadingRequestAuthorizationProperties,
    options?: EventOptions,
  ) {
    return this.track(new PleadingRequestAuthorization(properties), options);
  }

  /**
   * Pleading Request Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Pleading%20Request%20Submitted)
   *
   * When a user has accepted and submitted the [docket entry/pleading request](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2)...
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  pleadingRequestSubmitted(
    properties: PleadingRequestSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PleadingRequestSubmitted(properties), options);
  }

  /**
   * Podcast Episode Played
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Podcast%20Episode%20Played)
   *
   * When the user clicks on the "Play" button for a podcast
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  podcastEpisodePlayed(
    properties: PodcastEpisodePlayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PodcastEpisodePlayed(properties), options);
  }

  /**
   * Podcast Series Landing Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Podcast%20Series%20Landing%20Viewed)
   *
   * Podcast Series Landing View
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  podcastSeriesLandingViewed(
    properties: PodcastSeriesLandingViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PodcastSeriesLandingViewed(properties), options);
  }

  /**
   * Podcast Series Subscribed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Podcast%20Series%20Subscribed)
   *
   * This event tracks when a user subscribes to a specific podcast series
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  podcastSeriesSubscribed(
    properties: PodcastSeriesSubscribedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PodcastSeriesSubscribed(properties), options);
  }

  /**
   * Podcast Series Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Podcast%20Series%20Viewed)
   *
   * Fire when a user loads a landing page for a specific podcast series.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  podcastSeriesViewed(
    properties: PodcastSeriesViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PodcastSeriesViewed(properties), options);
  }

  /**
   * Practice Tool Used
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Practice%20Tool%20Used)
   *
   * Tracks the usage of the practice tool, providing insights into how often and in what ways users engage with this tool
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  practiceToolUsed(
    properties: PracticeToolUsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PracticeToolUsed(properties), options);
  }

  /**
   * Previous Result Navigated
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Previous%20Result%20Navigated)
   *
   * Click on the Previous Result CTA on a [document](https://www.bloomberglaw.com/product/blaw/document/X1JQ07JTG000N?criteria\_id=84c1018ec813139841de1aa2f1912b2d&navCriteriaId=3d6cce9c45c74c474f47647a70e68a3e&searchGuid=7f0cad2c-be82-4cc7-ac6c-3b50b2c78b6b&search32=H4g51LVe0iM0p08RRFKLsA%3D%3DRYD33FJ\_mhvHoY2LKNiKiiq3JRBTESO6h3zCMx9wZZkEdpkGaFUoYq7zp2az7ldtVf0oFM7QtuA0lE80TgXIcMJ84XGFh-nt\_GolpFoLz6L2qD2B0pd4AOWnfmQP4Ith).
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  previousResultNavigated(
    properties: PreviousResultNavigatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreviousResultNavigated(properties), options);
  }

  /**
   * Profile Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Profile%20Viewed)
   *
   * This event tracks when a person or entity (company, agency, etc.) profile is viewed
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  profileViewed(
    properties: ProfileViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProfileViewed(properties), options);
  }

  /**
   * Read Now
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Read%20Now)
   *
   * [Login Page](https://www.bloomberglaw.com/signin)
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  readNow(
    properties: ReadNowProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReadNow(properties), options);
  }

  /**
   * Reconciliation Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Reconciliation%20Viewed)
   *
   * This event tracks when a user views a reconciliation in the BTAX Software
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  reconciliationViewed(
    properties: ReconciliationViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReconciliationViewed(properties), options);
  }

  /**
   * Registration Form Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Registration%20Form%20Submitted)
   *
   * This event tracks when a user submits a registration form for sales purposes
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  registrationFormSubmitted(
    properties: RegistrationFormSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationFormSubmitted(properties), options);
  }

  /**
   * Registration Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Registration%20Form%20Viewed)
   *
   * This event tracks when a user views the registration form for sales purposes
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  registrationFormViewed(
    properties: RegistrationFormViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationFormViewed(properties), options);
  }

  /**
   * Remember Me
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Remember%20Me)
   *
   * Remember me button for the [login](https://www.bloomberglaw.com/signin)
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  rememberMe(
    properties: RememberMeProperties,
    options?: EventOptions,
  ) {
    return this.track(new RememberMe(properties), options);
  }

  /**
   * Report Requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Report%20Requested)
   *
   * Fire when users successfully requested a report
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  reportRequested(
    properties: ReportRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReportRequested(properties), options);
  }

  /**
   * Request Demo
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Request%20Demo)
   *
   * Request Demo from [Login Page]()
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  requestDemo(
    properties: RequestDemoProperties,
    options?: EventOptions,
  ) {
    return this.track(new RequestDemo(properties), options);
  }

  /**
   * Results Drilldown Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Results%20Drilldown%20Clicked)
   *
   * Fire when users click an embedded link in the tool to drilldown on details
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  resultsDrilldownClicked(
    properties: ResultsDrilldownClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResultsDrilldownClicked(properties), options);
  }

  /**
   * Saved for Later
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Saved%20for%20Later)
   *
   * Fires when a user saves content for later
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  savedForLater(
    properties: SavedForLaterProperties,
    options?: EventOptions,
  ) {
    return this.track(new SavedForLater(properties), options);
  }

  /**
   * Saved Searches Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Saved%20Searches%20Viewed)
   *
   * User views list of saved searches
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  savedSearchesViewed(
    properties: SavedSearchesViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SavedSearchesViewed(properties), options);
  }

  /**
   * Scroll Depth
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Scroll%20Depth)
   *
   * Fire when the user scrolls down any page at 25% increments.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  scrollDepth(
    properties: ScrollDepthProperties,
    options?: EventOptions,
  ) {
    return this.track(new ScrollDepth(properties), options);
  }

  /**
   * Search Export Customized
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Export%20Customized)
   *
   * User customizes their search export
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  searchExportCustomized(
    properties: SearchExportCustomizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchExportCustomized(properties), options);
  }

  /**
   * Search Form Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Form%20Closed)
   *
   * When search forms or modals are closed without submitting.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  searchFormClosed(
    properties: SearchFormClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchFormClosed(properties), options);
  }

  /**
   * Search Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Form%20Viewed)
   *
   * Fire on any form or modal that serves as a search form or page devoted to initiating search (not search bars embedded in pages like the go bar) and include searchTool to name the specific search form.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  searchFormViewed(
    properties: SearchFormViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchFormViewed(properties), options);
  }

  /**
   * Search Result Hidden
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Result%20Hidden)
   *
   * User clicks to hide a search result - ideally includes document id
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  searchResultHidden(
    properties: SearchResultHiddenProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchResultHidden(properties), options);
  }

  /**
   * Search Results Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Clicked)
   *
   * User clicks through to a document from Search Results.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  searchResultsClicked(
    properties: SearchResultsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchResultsClicked(properties), options);
  }

  /**
   * Search Results Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Exported)
   *
   * User exports/downloads Search Results or When the user tries to export content from a [dockets search result]()...
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  searchResultsExported(
    properties: SearchResultsExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchResultsExported(properties), options);
  }

  /**
   * Search Results Navigated
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Navigated)
   *
   * Click on the Search Results CTA on a [document](https://www.bloomberglaw.com/product/blaw/document/X1JQ07JTG000N?criteria\_id=84c1018ec813139841de1aa2f1912b2d&navCriteriaId=3d6cce9c45c74c474f47647a70e68a3e&searchGuid=7f0cad2c-be82-4cc7-ac6c-3b50b2c78b6b&search32=H4g51LVe0iM0p08RRFKLsA%3D%3DRYD33FJ\_mhvHoY2LKNiKiiq3JRBTESO6h3zCMx9wZZkEdpkGaFUoYq7zp2az7ldtVf0oFM7QtuA0lE80TgXIcMJ84XGFh-nt\_GolpFoLz6L2qD2B0pd4AOWnfmQP4Ith).
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  searchResultsNavigated(
    properties: SearchResultsNavigatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchResultsNavigated(properties), options);
  }

  /**
   * Search Results Sorted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Sorted)
   *
   * User sorts their search from the search results page
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  searchResultsSorted(
    properties: SearchResultsSortedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchResultsSorted(properties), options);
  }

  /**
   * Search Results Unhidden
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Unhidden)
   *
   * User clicks to unhide a search result - ideally includes document id
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  searchResultsUnhidden(
    properties: SearchResultsUnhiddenProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchResultsUnhidden(properties), options);
  }

  /**
   * Search Results Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Viewed)
   *
   * Any load of search results on a search results page.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  searchResultsViewed(
    properties: SearchResultsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchResultsViewed(properties), options);
  }

  /**
   * Search Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Saved)
   *
   * User saves search
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  searchSaved(
    properties: SearchSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchSaved(properties), options);
  }

  /**
   * Search Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Shared)
   *
   * User clicks 'Share' (or email icon) above search results and copies the share link provided, or submits email recipients for the share. (use method)
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  searchShared(
    properties: SearchSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchShared(properties), options);
  }

  /**
   * Search Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Submitted)
   *
   * Fire when user submits a keyword search or selects/modifies filters or otherwise takes an action to load new search results.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  searchSubmitted(
    properties: SearchSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchSubmitted(properties), options);
  }

  /**
   * Selections Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Selections%20Saved)
   *
   * Fire when the user has saved 1 or more sets of selections. 
   *  Example: user selects a set of Jurisdictions in Chart Builder and then saves them for later use.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  selectionsSaved(
    properties: SelectionsSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectionsSaved(properties), options);
  }

  /**
   * Set as Home
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Set%20as%20Home)
   *
   * User clicks set as home to make a feature or section page their home page
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  setAsHome(
    properties: SetAsHomeProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetAsHome(properties), options);
  }

  /**
   * Sidebar Tab Open
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Sidebar%20Tab%20Open)
   *
   * The sidebar tab within a document
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  sidebarTabOpen(
    properties: SidebarTabOpenProperties,
    options?: EventOptions,
  ) {
    return this.track(new SidebarTabOpen(properties), options);
  }

  /**
   * Subscriber Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Subscriber%20Added)
   *
   * First login of a subscriber
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  subscriberAdded(
    properties: SubscriberAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriberAdded(properties), options);
  }

  /**
   * Table Results Formatted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Table%20Results%20Formatted)
   *
   * Fire when users use table field options to update pivot table results, such as adding, removing, reordering, resetting fields or applying table limits
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  tableResultsFormatted(
    properties: TableResultsFormattedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TableResultsFormatted(properties), options);
  }

  /**
   * Tag Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Tag%20Added)
   *
   * This event tracks when a tag is added to a workspace
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  tagAdded(
    properties: TagAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TagAdded(properties), options);
  }

  /**
   * Tag Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Tag%20Deleted)
   *
   * This event is triggered when a tag is deleted. It provides information on allowing for analysis of tag management and usage patterns
   *
   * Owner: Milad Amin
   *
   * @param properties The event's properties (e.g. agency)
   * @param options Amplitude event options.
   */
  tagDeleted(
    properties: TagDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TagDeleted(properties), options);
  }

  /**
   * TOC Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/TOC%20Exported)
   *
   * Fires when a user exports a Table of Contents
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  tocExported(
    properties: TocExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TocExported(properties), options);
  }

  /**
   * TOC Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/TOC%20Shared)
   *
   * Fires when a user shares a Table of Contents
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  tocShared(
    properties: TocSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TocShared(properties), options);
  }

  /**
   * TOC Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/TOC%20Viewed)
   *
   * Fires when a user views a Table of Contents
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  tocViewed(
    properties: TocViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TocViewed(properties), options);
  }

  /**
   * Tour Ended
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Tour%20Ended)
   *
   * This event signifies the completion of the product onboarding tour, indicating that a user has finished the guided tour designed to familiarize them with the product's features and functionality
   *
   * Owner: Kyle Contrata
   *
   * @param properties The event's properties (e.g. appName)
   * @param options Amplitude event options.
   */
  tourEnded(
    properties: TourEndedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TourEnded(properties), options);
  }

  /**
   * Tour Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Tour%20Viewed)
   *
   * This event tracks when a user views a tour during the product onboarding process
   *
   * Owner: Kyle Contrata
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  tourViewed(
    properties: TourViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TourViewed(properties), options);
  }

  /**
   * Track Alert Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Track%20Alert%20Created)
   *
   * This event tracks the creation of a new alert in the Alert Management system.  A Track Alert tracks changes to a document, such as a Bill.  Note that Dockets have their own specific event called Docket Track Created.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. actionCount)
   * @param options Amplitude event options.
   */
  trackAlertCreated(
    properties: TrackAlertCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TrackAlertCreated(properties), options);
  }

  /**
   * Track Alert Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Track%20Alert%20Deleted)
   *
   * When a user deletes a tracked alert
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  trackAlertDeleted(
    properties: TrackAlertDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TrackAlertDeleted(properties), options);
  }

  /**
   * Track Alert Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Track%20Alert%20Modified)
   *
   * When a user modifies a track alert
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  trackAlertModified(
    properties: TrackAlertModifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TrackAlertModified(properties), options);
  }

  /**
   * Upsell Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Upsell%20Viewed)
   *
   * This event fires when a user views an upsell offer (page or modal), regardless of where it occurs within the application. The Upsell page or Modal will be shown to users trying to click/access a part of the application that they do not have access to.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  upsellViewed(
    properties: UpsellViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpsellViewed(properties), options);
  }

  /**
   * User Workflow Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/User%20Workflow%20Exported)
   *
   * This event fires when the user downloads/exports Workflow Content and clicks on Excel Export, Download, etc.
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  userWorkflowExported(
    properties: UserWorkflowExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserWorkflowExported(properties), options);
  }

  /**
   * User Workflow Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/User%20Workflow%20Viewed)
   *
   * This event fires when visitors engage with User Workflow items such as Research Trai, Alert Management, and Download Center at the top of BLAW/BTAX and the top right corner of BGOV (after clicking the hamburger menu).
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  userWorkflowViewed(
    properties: UserWorkflowViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserWorkflowViewed(properties), options);
  }

  /**
   * Workflow Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Workflow%20Viewed)
   *
   * This event tracks when a user views a workflow in the BTAX Software
   *
   * Owner: Philip Ramsey
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  workflowViewed(
    properties: WorkflowViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowViewed(properties), options);
  }
}

export const ampli = new Ampli()

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions

export type BrowserClient = amplitude.Types.BrowserClient
export type BaseEvent = amplitude.Types.BaseEvent
export type IdentifyEvent = amplitude.Types.IdentifyEvent
export type GroupEvent = amplitude.Types.GroupIdentifyEvent
export type Event = amplitude.Types.Event
export type EventOptions = amplitude.Types.EventOptions
export type Result = amplitude.Types.Result
