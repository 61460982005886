import ApiClient from './ApiClient'
import { AnalysisType } from './DocumentAnalysisAsync'
import Routes from './routes'

export type AnalysisData<T> = {
  analysis: T
  state: string
}

const routes = new Routes()

export default class DocumentAnalysisClient {
  apiClient: ApiClient

  constructor() {
    this.apiClient = new ApiClient()
  }

  async getAnalysis<T>(prefix: string, type: AnalysisType) {
    const response = await this.apiClient.get<AnalysisData<T>>(
      routes.getDocumentAnalysisUrl(prefix, type),
    )
    return response
  }
}
