import { FC, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  DefaultButton,
  MessageBar,
  Stack,
  SharedColors,
  MessageBarType,
  FontSizes,
} from '@fluentui/react'

import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import KeyTermsList from '@components/KeyTermsList'
import AddKeyTermForm from '@components/AddKeyTermForm'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import EditKeyTermForm from '@components/EditKeyTermForm'
import DeleteKeyTermForm from '@components/DeleteKeyTermForm'
import LoadingShimmer from '@components/LoadingShimmer'
import KeyTermFormContextProvider from '@contexts/KeyTermFormContext'
import { LightTheme } from '@src/themes'
import QuickMessage from '@components/QuickMessage'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { ContractContext } from '@contexts/ContractContext'
import { StoreContext } from '@contexts/StoreContext'
import NotificationBadge from '@components/NotificationBadge'

const pageTitle = 'Key Terms'

const KeyTerms: FC = () => {
  const { toggleAddKeyTermModal, isViewingModal, metadataConfig, loadingMetadataConfig } =
    useContext(KeyTermsContext)
  const { id } = useParams()
  const { contract, loading, error, loadContract, isKeyTermsStatusHidden } =
    useContext(ContractContext)
  const { access, setPageTitle } = useContext(StoreContext)
  const [isNoKeyTermsMsgHidden, setIsNoKeyTermsMsgHidden] = useState(
    metadataConfig && contract?.numExistingKeyTerms(metadataConfig?.value) !== 0,
  )
  useEffect(() => {
    setPageTitle(pageTitle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const hasExistingTerms = metadataConfig && contract?.numExistingKeyTerms(metadataConfig.value)
  const details = contract?.details

  useEffect(() => {
    setIsNoKeyTermsMsgHidden(
      metadataConfig && contract?.numExistingKeyTerms(metadataConfig.value) !== 0,
    )
  }, [loadingMetadataConfig, contract, metadataConfig, hasExistingTerms])

  useContractTaskPaneViewed({
    pageTitle,
    eventDetails: [`id: ${id}`],
  })

  if (!id) throw Error('Missing id param')

  return (
    <div style={{ backgroundColor: LightTheme.palette.white }}>
      <TopNav title={title()} prevPath={`#/contracts/${id}`} />
      {renderStatus()}
      <StyledStack>
        {renderNoKeyTermsText()}
        <Stack.Item>{renderMain()}</Stack.Item>
      </StyledStack>
    </div>
  )

  function renderNoKeyTermsText() {
    if (isNoKeyTermsMsgHidden) return

    return <Stack.Item>No terms have been captured for this contract.</Stack.Item>
  }

  function renderStatus() {
    if (isKeyTermsStatusHidden || loading) return

    return (
      <Stack.Item>
        <MessageBar messageBarType={MessageBarType.info}>
          This contract is in progress and may have changed since these Key Terms were captured.
        </MessageBar>
      </Stack.Item>
    )
  }

  function renderMain() {
    if (error) return <QuickMessage msg={error} type="error" />
    if (loadingMetadataConfig || loading || !(contract && id) || !metadataConfig)
      return <LoadingShimmer />
    const canEditContractTerms = access.canEditContractTerms(contract.accessControls)

    return (
      <>
        <DefaultButton
          disabled={loading || isViewingModal}
          style={{
            display: canEditContractTerms ? 'block' : 'none',
            margin: '1em 0',
            width: 'fit-content',
            fontSize: FontSizes.size16,
          }}
          onClick={() => toggleAddKeyTermModal()}
        >
          Add Key Term
        </DefaultButton>
        <KeyTermsList
          keyTerms={contract.existingKeyTerms(metadataConfig.value)}
          details={details}
          enableEdit={canEditContractTerms}
        />
        <KeyTermFormContextProvider contractId={id} loadContract={loadContract}>
          <AddKeyTermForm />
          <EditKeyTermForm />
          <DeleteKeyTermForm />
        </KeyTermFormContextProvider>
      </>
    )
  }

  function title() {
    if (loadingMetadataConfig || !metadataConfig) return

    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {pageTitle}
        <NotificationBadge
          style={{
            marginLeft: '0.3em',
            backgroundColor: SharedColors.cyan10,
            borderColor: SharedColors.cyan10,
            display: loading ? 'none' : 'inline-block',
          }}
        >
          {contract?.numExistingKeyTerms(metadataConfig.value) || 0}
        </NotificationBadge>
      </span>
    )
  }
}

export default KeyTerms
