import FunctionBlocker from '@modules/FunctionBlocker'

const blocker = FunctionBlocker.getInstance()

// Hook for FunctionBlocker class to make usage of the class more "React"
export default function useFunctionBlocker() {
  async function blockableFunction(functionName: string, func: () => Promise<any>) {
    return blocker.blockableFunction(functionName, func)
  }

  function isBlocked(functionName: string) {
    return blocker.isBlocked(functionName)
  }

  function getCurrFuncName() {
    return blocker.getCurrFuncName()
  }

  return {
    blockableFunction,
    isBlocked,
    getCurrFuncName,
  }
}
