import { FormEvent, useContext, useState } from 'react'
import {
  ChoiceGroup,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
} from '@fluentui/react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { PlaybooksComplianceRequest, PlaybooksComplianceResponse } from '@blaw/contracts-api-schema'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { useTranslation } from '@hooks/useTranslation'
import AddAnotherTextField from '@components/AddAnotherTextField'
import ContractTitle from '@baseComponents/ContractTitle'
import StyledStack from '@components/StyledStack'
import TopNav from '@components/TopNav'
import ErrorMessage from '@components/ErrorMessage'
import { getDocBodyText } from '@modules/wordDocument'
import ApiClient from '@modules/ApiClient'
import { StoreContext } from '@contexts/StoreContext'
import BoldText from '@baseComponents/BoldText'
import { contractOriginBaseOptions } from '@modules/Contract'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import LoadingShimmer from '@components/LoadingShimmer'

const pageTitle = 'Compliance'

export default function NewPlaybookCompliance() {
  const { storeSessionInfo, checkingLoginStatus, routes } = useContext(StoreContext)
  const { loadingMetadataConfig, metadataConfig } = useContext(KeyTermsContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [customerInstructions, setCustomerInstructions] = useState([''])
  const [contractOrigin, setContractOrigin] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [params] = useSearchParams()
  const playbookId = useParams().playbookId ?? t('label.N/A')
  const playbookTitle = params.get('title') ?? t('label.N/A')
  const playbookType = params.get('type') ?? t('label.N/A')
  const partyRole = params.get('role') ?? t('label.N/A')
  const isLoading = submitting || loadingMetadataConfig

  useContractTaskPaneViewed({
    pageTitle,
    eventDetails: [playbookId, playbookTitle, playbookType],
  })

  return (
    <>
      <TopNav title={pageTitle} prevPath="#/playbooks" showAIGeneratedBadge />
      <MessageBar messageBarType={MessageBarType.info}>
        Verify if the document you are currently working on is compliant with approved company
        playbook.
      </MessageBar>
      <ErrorMessage message={error} styles={{ marginTop: '0.5em' }} />

      {loadingMetadataConfig && <LoadingShimmer size={5} style={{ margin: '1em 0.5em' }} />}
      {!loadingMetadataConfig && (
        <form onSubmit={submit}>
          <StyledStack>
            <Stack.Item>
              <ContractTitle title={playbookTitle} />
            </Stack.Item>

            <Stack.Item>
              <BoldText>{metadataConfig?.getLabel('contract_type')}: </BoldText>
              {playbookType}
            </Stack.Item>

            <Stack.Item>
              <BoldText>Party Role: </BoldText>
              {partyRole}
            </Stack.Item>

            <Stack.Item style={{ marginBottom: '0.5em' }}>
              <ChoiceGroup
                options={contractOriginBaseOptions}
                label={metadataConfig?.getLabel('first_draft_origin')}
                defaultSelectedKey={contractOrigin}
                value={contractOrigin}
                onChange={(_e, option) => setContractOrigin(option?.key ?? '')}
                disabled={isLoading}
              />
            </Stack.Item>

            <Stack.Item>
              <AddAnotherTextField
                autoFocus
                multiline
                disabled={isLoading}
                labelPrefix={t('label.Deal Specific Instructions')}
                addBtnLabel={t('label.Add another instruction')}
                placeholder={t('placeholder.Deal Specific Instructions')}
                values={customerInstructions}
                onValueChange={setCustomerInstructions}
              />
            </Stack.Item>

            <Stack.Item>
              <PrimaryButton type="submit" disabled={isLoading} style={{ width: '100%' }}>
                {t('button.Check Compliance')}
              </PrimaryButton>
              {submitting && <ProgressIndicator label={t('label.Checking Compliance')} />}
            </Stack.Item>
          </StyledStack>
        </form>
      )}
    </>
  )

  async function submit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!playbookId) return setError('Missing Playbook ID')

    try {
      const apiClient = new ApiClient(storeSessionInfo, setError, checkingLoginStatus)
      const contract = await getDocBodyText()
      // TODO: update request type in schema
      const payload: PlaybooksComplianceRequest & { partyRole?: string; contractOrigin: string } = {
        playbookId,
        contract,
        partyRole,
        contractOrigin,
        customerInstructions,
      }

      setSubmitting(true)
      setError('')

      const { data } = await apiClient.post<PlaybooksComplianceResponse>(
        routes.playbooksComplianceUrl,
        payload,
      )
      navigate('/playbooks/compliance/summary', { state: data })
    } catch (e) {
      console.error(e)
      setError((e as Error).message)
    } finally {
      setSubmitting(false)
    }
  }
}
