import { ITextFieldProps, TextField } from '@fluentui/react'

import useAutoFocus from '@hooks/useAutoFocus'

export type KeywordSearchProps = ITextFieldProps & {
  query: string
  loading: boolean
  placeholder: string
  search: (query: string) => Promise<void> | void
}

export default function KeywordSearch({
  query,
  loading,
  placeholder,
  search,
  ...props
}: KeywordSearchProps) {
  const inputRef = useAutoFocus(loading)

  return (
    <TextField
      // @ts-ignore ts complains about ref but it works.
      ref={inputRef}
      value={query}
      disabled={loading}
      placeholder={placeholder}
      iconProps={{ iconName: 'Search' }}
      onChange={(_, query) => search && search(query || '')}
      {...props}
    />
  )
}
