export type FeatureFlag = {
  creationDate: string
  description: string
  expirationDate: string
  id: number
  level: number
  name: string
  ownerId: number
  type: number
  value: any
  valueFilters?: any
}

export function featureFlagsMap(featureFlags: FeatureFlag[]): Record<string, string> {
  return featureFlags.reduce((map, { name, value }) => ({ ...map, [name]: value }), {})
}
