type Props = {
  title: string
  style?: React.CSSProperties
}

const ContractTitle: React.FC<Props> = ({ title, style }: Props) => {
  const defaultStyle = {
    margin: '0 0.6em 0.6em 0',
    fontWeight: 600,
  }

  return <h2 style={{ ...defaultStyle, ...style }}>{title}</h2>
}
export default ContractTitle
