import { NeutralColors, SharedColors } from '@fluentui/react'
import {
  AccessControlLevel,
  Contract as ContractModule,
  contractStatusConfig,
  getDefaultAccess,
  isPrivate,
  MIMETYPE_DOCX,
  MIMETYPE_PDF,
  Resource,
  type AccessControls,
  type ContractMetadata,
  type ContractResource,
  type MetadataConfigValue,
  type ResourceVersionMetadataDetails,
  type SystemMetadata,
} from '@blaw/contracts-api-schema'

import { KeyTerm, type KeyTermData } from '@modules/KeyTerm'
import { mapMetadataItemsToComboBoxOptions } from '@modules/KeyTermValueParsers'
import { filterEmptyKeyTerms, filterNonEmptyKeyTerms } from '@modules/KeyTermValueParsers'
import { extractFields, friendlyDate, friendlyDateTime } from '@modules/utils'
import { mergeCustomMonetaryFields } from '@modules/CustomFields'
import { ContractCustomFieldsData } from '@components/ContractCustomFields'

export const CREATE_CONTRACT_ACTION = 'createContract'
export const SAVE_NEW_DOCUMENT_VERSION_ACTION = 'saveNewDocumentVersion'

export const ICON_MAP = {
  [MIMETYPE_DOCX]: { iconName: 'WordDocument', iconColor: SharedColors.blue10 },
  [MIMETYPE_PDF]: { iconName: 'PDF', iconColor: SharedColors.red10 },
  default: { iconName: 'SurveyQuestions', iconColor: NeutralColors.gray110 },
}

export const BADGE_COLOR_MAP = {
  [contractStatusConfig.getContractStatusLabel('FINAL')]: SharedColors.greenCyan10,
  [contractStatusConfig.getContractStatusLabel('TERMINATED')]: SharedColors.red10,
  default: SharedColors.cyanBlue10,
}

export interface VersionFormFields {
  fileCategory?: string
  description?: string
}

export interface ContractFormFields extends UserMetadata {
  parentId?: string
  contractFolderName: string
  fileName: string
  statusIdx: string
  access: string
  contractType: string
  fileCategory?: string
  clientName: string[]
  counterParty: string[]
  description: string
  contractCategory: string
  transaction: string
  contractID: string
  companyStakeholder: string[]
  firstDraftOrigin: string
  department: string
  city: string
  state: string
  country: string
}
export type ContractField = keyof ContractFormFields

export const contractFormFieldDefaults: ContractFormFields = {
  parentId: '',
  contractFolderName: '',
  fileName: '',
  statusIdx: ContractModule.contractStatus.getValues()[0].value,
  access: AccessControlLevel.PRIVATE,
  contractType: '',
  fileCategory: '',
  counterParty: [''],
  clientName: [''],
  description: '',
  contractCategory: '',
  transaction: '',
  contractID: '',
  companyStakeholder: [''],
  firstDraftOrigin: '',
  department: '',
  city: '',
  state: '',
  country: '',
}

export const RequiredFields: Partial<ContractField[]> = [
  'contractFolderName',
  'contractType',
  'counterParty',
  'fileName',
]

export type UserMetadata = ContractMetadata['userMetadata']
export type CustomMetadata = ContractMetadata['customMetadata']

// TODO: extending ContractResource type here for testing. Need to eventually augment
// ContractResource in the contracts-api-schema repo.
export type ContractResourceWithDetails = ContractResource & {
  details: ResourceVersionMetadataDetails
  owner: string
}

// Transforms Contract data from the Contracts API into
// a simpler structure the app can use.
export default class Contract {
  id: string
  type: string
  title: string
  status: string
  statusIdx: string
  accessControls: AccessControls
  #children: Resource[]
  createdBy: string
  clientName: string[]
  counterParty: string[]
  description: string
  #dateCreated: string
  #dateModified: string
  #dateTimeCreated: string
  #dateTimeModified: string
  systemMetadata: SystemMetadata
  customMetadata: CustomMetadata
  category: string
  companyStakeholder: string[]
  transaction: string
  contractID: string
  documentID: string
  firstDraftOrigin: string
  firstDraftOriginReadable: string
  department: string
  city: string
  state: string
  country: string
  customFields: ContractCustomFieldsData
  parentName: string
  digests?: string[]
  mimeType: string
  details: ResourceVersionMetadataDetails
  owner: string

  static RESOURCE_TYPE = 'contract'

  constructor(resource: ContractResourceWithDetails) {
    const {
      systemMetadata,
      customMetadata,
      userMetadata: {
        description,
        party_name,
        contract_type,
        contract_status,
        contract_category,
        company_stakeholder,
        transaction,
        contract_id,
        first_draft_origin,
        department,
        city,
        state,
        region,
        client_name,
      },
    } = resource.metadata

    this.id = resource.contractId
    this.type = contract_type ?? ''
    this.title = resource.contractTitle
    this.statusIdx = contract_status || 'DRAFT'
    this.status = ContractModule.contractStatus.getLabel(this.statusIdx)
    this.accessControls = resource.accessControls || {
      readList: [],
      writeList: [],
      deleteList: [],
      forbiddenList: [],
    }
    this.#children = resource.children || []
    this.createdBy = resource.createdBy
    this.clientName = client_name?.length ? client_name : ['']
    this.counterParty = party_name || ['']
    this.description = description ?? ''
    this.#dateCreated = friendlyDate(resource.dateCreated)
    this.#dateModified = friendlyDate(resource.dateModified)
    this.#dateTimeCreated = friendlyDateTime(resource.dateCreated)
    this.#dateTimeModified = friendlyDateTime(resource.dateModified)
    this.systemMetadata = systemMetadata
    this.customMetadata = customMetadata
    this.category = contract_category ?? ''
    this.companyStakeholder = company_stakeholder?.length ? company_stakeholder : ['']
    this.transaction = transaction ?? ''
    this.contractID = contract_id ?? ''
    this.documentID = this.#children[0] ? this.#children[0].id : this.contractID
    this.firstDraftOrigin = first_draft_origin ?? ''
    this.firstDraftOriginReadable = first_draft_origin
      ? ContractModule.firstDraftOrigin.getLabel(first_draft_origin)
      : ''
    this.department = department ?? ''
    this.city = city ?? ''
    this.state = state ?? ''
    this.country = region ?? ''
    this.customFields = mergeCustomMonetaryFields(
      extractFields(resource.metadata.userMetadata, key => key.startsWith('custom')),
    )
    this.parentName = resource.parentName || ''
    this.digests = resource.digests?.length ? resource.digests.slice(0, 1) : ['']
    this.mimeType = resource.mimeType || ''
    this.details = resource.details
    this.owner = resource.owner
  }

  isFinalized() {
    return ContractModule.contractStatus.finalized(this.statusIdx)
  }

  date(checkField: string, date: string) {
    return checkField.includes('Invalid Date') ? null : date
  }

  get dateCreated() {
    return this.date(this.#dateTimeCreated, this.#dateCreated)
  }

  get dateModified() {
    return this.date(this.#dateTimeModified, this.#dateModified)
  }

  get dateTimeCreated() {
    return this.date(this.#dateTimeCreated, this.#dateTimeCreated)
  }

  get dateTimeModified() {
    return this.date(this.#dateTimeModified, this.#dateTimeModified)
  }

  get icon() {
    if (this.mimeType in ICON_MAP) {
      return ICON_MAP[this.mimeType as keyof typeof ICON_MAP]
    }
    return ICON_MAP.default
  }

  get badgeColor() {
    if (this.status in BADGE_COLOR_MAP) {
      return BADGE_COLOR_MAP[this.status as keyof typeof BADGE_COLOR_MAP]
    }
    return BADGE_COLOR_MAP.default
  }

  get children() {
    return this.#sortByOrder(this.#children)
  }

  get metadata(): ContractMetadata {
    return {
      userMetadata: this.userMetadata,
      customMetadata: this.customMetadata,
      systemMetadata: this.systemMetadata,
    }
  }

  get userMetadata(): UserMetadata {
    // @ts-ignore // TODO: update these from contracts-api-schema
    return {
      contract_type: this.type,
      contract_status: this.statusIdx,
      party_name: this.counterParty,
      client_name: this.clientName,
      description: this.description,
    }
  }

  updatedMetadata(formData: ContractFormFields): ContractMetadata {
    const updatedUserMetadata = {
      ...this.userMetadata,
      ...formToContract(formData),
    }

    return { ...this.metadata, userMetadata: updatedUserMetadata }
  }

  keyTermsArray(metadataConfig: MetadataConfigValue[]) {
    return Object.values(this.keyTerms(metadataConfig)) as KeyTerm[]
  }

  // Some contracts will not have all terms in their metadata so we fill it in so
  // we can show which ones are empty in the ui and in the Add Key Term dialog
  keyTerms(metadataConfig: MetadataConfigValue[]) {
    const contractTerms = this.customMetadata.terms || {}
    const keyTerms = metadataConfig.reduce((acc, configVal) => {
      const key = configVal.id
      if (!key)
        throw new Error('Update fixture: metadataConfig.json - Copy the response from GET /configs')
      //@ts-ignore TODO: fix type issue below
      acc[key] = {
        //@ts-ignore TODO: fix type issue below
        value: contractTerms[key]?.value,
        type: configVal.type,
        label: configVal.label,
        items: mapMetadataItemsToComboBoxOptions(configVal.items || []),
        //@ts-ignore TODO: fix type issue below
        data: contractTerms[key]?.data || { notes: '', block: '', text: '' },
        key,
      }
      return acc
    }, {} as KeyTerm[])
    //@ts-ignore TODO: fix type issue below
    return keyTerms
  }

  existingKeyTerms(metadataConfig: MetadataConfigValue[]): KeyTerm[] {
    return filterNonEmptyKeyTerms(Object.values(this.keyTerms(metadataConfig) || {}) as KeyTerm[])
  }

  emptyKeyTerms(metadataConfig: MetadataConfigValue[]) {
    return filterEmptyKeyTerms(this.keyTermsArray(metadataConfig))
  }

  numExistingKeyTerms(metadataConfig: MetadataConfigValue[]) {
    return this.existingKeyTerms(metadataConfig).length
  }

  findDocument(docId: string) {
    return this.children.find(doc => doc.id === docId)
  }

  getKeyTerm(key: string | null, metadataConfig: MetadataConfigValue[]) {
    if (!key) return null
    return this.keyTermsArray(metadataConfig).find(d => d.key === key)
  }

  editKeyTerm(newKeyTerm: KeyTerm, metadataConfig: MetadataConfigValue[]) {
    if (!newKeyTerm?.key) throw Error(`Invalid new key term: ${newKeyTerm.key}`)
    const keyTerm = this.getKeyTerm(newKeyTerm.key, metadataConfig)
    if (!keyTerm?.key) throw Error(`Key term not found: ${newKeyTerm.key}`)

    keyTerm.value = newKeyTerm.value
    keyTerm.data = keyTerm.data || ({} as KeyTermData)
    keyTerm.data.notes = newKeyTerm.data?.notes || ''

    // Make a copy of metadata so key terms list will not update if API call fails
    const termsCopy = JSON.parse(
      JSON.stringify({
        ...this.customMetadata.terms,
        [keyTerm.key]: {
          value: keyTerm.value,
          data: keyTerm.data,
        },
      }),
    )
    const metadataCopy = JSON.parse(JSON.stringify(this.metadata))
    metadataCopy.systemMetadata = {}
    metadataCopy.userMetadata = {}
    metadataCopy.customMetadata.terms = termsCopy
    return metadataCopy
  }

  deleteKeyTerm(oldKeyTerm: KeyTerm, metadataConfig: MetadataConfigValue[]) {
    if (!oldKeyTerm?.key) throw Error(`Invalid key term: ${oldKeyTerm?.key}`)
    const keyTerm = this.getKeyTerm(oldKeyTerm.key, metadataConfig)
    if (!keyTerm?.key) throw Error(`Key term not found: ${oldKeyTerm.key}`)

    return keyTerm
  }

  #sortByOrder(children: Resource[]) {
    return children.sort((a, b) => {
      const orderA = a.metadata?.userMetadata.contract_document_order ?? children.length
      const orderB = b.metadata?.userMetadata.contract_document_order ?? children.length
      return orderA - orderB
    })
  }
}

export function contractToForm(contract?: Contract | null): ContractFormFields {
  if (!contract) return {} as ContractFormFields

  return {
    clientName: contract.clientName,
    fileName: contract.title,
    companyStakeholder: contract.companyStakeholder,
    contractCategory: contract.category,
    contractFolderName: contract.title,
    contractID: contract.contractID,
    contractType: contract.type,
    statusIdx: contract.statusIdx,
    access: isPrivate(contract.accessControls) ? 'private' : 'public',
    counterParty: contract.counterParty,
    description: contract.description,
    firstDraftOrigin: contract.firstDraftOrigin,
    transaction: contract.transaction,
    department: contract.department,
    city: contract.city,
    state: contract.state,
    country: contract.country,
    ...contract.customFields,
  }
}

export function formToContract(formData: ContractFormFields) {
  return {
    party_name: formData.counterParty,
    company_stakeholder: formData.companyStakeholder,
    contract_category: formData.contractCategory,
    contract_folder_name: formData.contractFolderName,
    contract_id: formData.contractID,
    contract_type: formData.contractType,
    content_type: formData.fileCategory,
    contract_status: formData.statusIdx,
    description: formData.description,
    first_draft_origin: formData.firstDraftOrigin,
    client_name: formData.clientName,
    transaction: formData.transaction,
    department: formData.department,
    city: formData.city,
    state: formData.state,
    region: formData.country,
    ...extractFields<Partial<ContractFormFields>>(formData, key => key.startsWith('custom')),
  }
}

export function formToCreateContractParams(formData: ContractFormFields, uuid?: string) {
  const params = {
    name: formData.contractFolderName || formData.fileName.replace('.docx', ''),
    documentName: formData.fileName,
    fileName: formData.fileName,
    parentId: formData.parentId,
    resourceType: 'contract',
    metadata: {
      ...defaultContractMetaData,
      userMetadata: formToContract(formData),
    },
  }
  return {
    ...params,
    ...(uuid && { accessControls: getDefaultAccess(formData.access as AccessControlLevel, uuid) }),
  }
}
export type CreateContractParams = ReturnType<typeof formToCreateContractParams>

export function formToSaveNewVersionParams(
  contract: Contract,
  formData: VersionFormFields,
  documentId: string,
) {
  return {
    name: contract.title,
    documentName: contract.title,
    fileName: contract.title,
    parentId: documentId,
    resourceType: 'document',
    metadata: {
      userMetadata: {
        description: formData.description,
      },
    },
  }
}
export type SaveNewVersionParams = ReturnType<typeof formToSaveNewVersionParams>

export const defaultContractMetaData: ContractMetadata = {
  systemMetadata: {} as SystemMetadata,
  userMetadata: {} as UserMetadata,
  customMetadata: {
    terms: {},
  },
}

export function contractWorkflow(status?: string) {
  const statusItems = ContractModule.contractStatus.getValues()
  return statusItems.find(statusItem => statusItem.value === status)?.workflow
}

export const contractOriginBaseOptions = ContractModule.firstDraftOrigin
  .getValues()
  .map(item => ({ key: item.value, text: item.label }))

export const contractOriginOptions = contractOriginBaseOptions.concat({
  key: '',
  text: 'None Provided',
})

interface FiletypeIconStyle {
  iconName: string
  iconColor: string
}

export const getFiletypeIconStyle = (mimeType: string): FiletypeIconStyle => {
  let iconName, iconColor
  if (mimeType === MIMETYPE_DOCX) {
    iconName = ICON_MAP[MIMETYPE_DOCX].iconName
    iconColor = ICON_MAP[MIMETYPE_DOCX].iconColor
  } else if (mimeType === MIMETYPE_PDF) {
    iconName = ICON_MAP[MIMETYPE_PDF].iconName
    iconColor = ICON_MAP[MIMETYPE_PDF].iconColor
  } else {
    iconName = ICON_MAP.default.iconName
    iconColor = ICON_MAP.default.iconColor
  }
  return { iconName, iconColor } as FiletypeIconStyle
}
