import { FormEvent } from 'react'
import {
  ChoiceGroup,
  IChoiceGroupOption,
  IMessageBarStyles,
  Label,
  MessageBar,
  MessageBarType,
  Stack,
} from '@fluentui/react'

import { AccessControlLevel } from '@blaw/contracts-api-schema'
import { ContractFormFields } from '@modules/Contract'

interface Props {
  formData: ContractFormFields
  updateAccess:
    | ((
        ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined,
        option?: IChoiceGroupOption | undefined,
      ) => void)
    | undefined
  disabled: boolean
}

const privateStyles: IMessageBarStyles = {
  root: { width: '100%', marginLeft: '1em' },
  innerText: { whiteSpace: 'wrap' },
}
const publicStyles: IMessageBarStyles = {
  root: { width: '100%', margin: '-1em 0em -1em 1em' },
  innerText: { whiteSpace: 'wrap' },
}
const accessLevels = [
  {
    key: AccessControlLevel.PRIVATE,
    text: 'Private',
    help: 'Accessible only by you.',
    type: MessageBarType.info,
    style: {
      root: { display: 'flex', alignItems: 'center', minHeight: '32px' },
      help: privateStyles,
    },
  },
  {
    key: AccessControlLevel.PUBLIC,
    text: 'Public',
    help: 'Accessible by everyone in your organization.',
    type: MessageBarType.warning,
    style: { root: { display: 'flex' }, help: publicStyles },
  },
]

const ContractStatusMenu = (props: Props) => {
  const { formData, updateAccess, disabled } = props
  const accessOptions: IChoiceGroupOption[] = accessLevels.map(
    ({ key, text, help, type, style }) => {
      return {
        key: key,
        text: text,
        onRenderField: (props, render) => {
          return (
            <div style={style.root}>
              {render!(props)}
              <MessageBar
                messageBarType={type}
                styles={formData.access === key ? style.help : { root: { display: 'none' } }}
              >
                {help}
              </MessageBar>
            </div>
          )
        },
        styles: { choiceFieldWrapper: { width: '100%' } },
      }
    },
  )

  return (
    <Stack.Item>
      <Label required={true}>Access</Label>
      <ChoiceGroup
        options={accessOptions}
        defaultSelectedKey={formData.access}
        value={formData.access}
        onChange={updateAccess}
        disabled={disabled}
        styles={{
          root: { display: 'inline-block', width: '100%' },
          flexContainer: { whiteSpace: 'nowrap' },
        }}
      />
    </Stack.Item>
  )
}

export default ContractStatusMenu
