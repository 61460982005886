import { Metadata, MetadataTypes, type OptionValue, type Options } from '@blaw/contracts-api-schema'
import CustomFieldGroup from './CustomFieldGroup'
import CustomTextField from './CustomTextField'
import CustomMonetaryField from './CustomMonetaryField'
import CustomBooleanField from './CustomBooleanField'
import {
  type CustomFieldCategoryLabel,
  customFieldCategories,
  mergeCustomMonetaryFields,
  monetaryFieldPrefix,
  monetaryValueKeyFromKey,
  monetaryUnitKeyFromKey,
} from '@modules/CustomFields'
import { type UserMetadata } from '@modules/Contract'
import { ITextFieldProps } from '@fluentui/react'

export type CustomFieldType = keyof typeof fieldComponents
export type CustomFieldValueNames = `${CustomFieldCategoryLabel} ${number}`
export type CustomFieldValueKeys = Metadata.CustomFieldKeys
export type ContractCustomFieldsData = Pick<UserMetadata, CustomFieldValueKeys>
export type CustomFieldValueType = string | number | boolean | string[] | null | undefined

type BaseProps = {
  type: MetadataTypes
  prefix: OptionValue
  disabled: boolean
  fieldData?: ContractCustomFieldsData
  onChange: OnCustomFieldChangeEvent
}

export type FieldGroupProps = BaseProps & {
  label: typeof fieldGroupLabel
  name: CustomFieldCategoryLabel
  fields: Options
  Component: CustomFieldComponent
}

export type FieldProps = BaseProps & {
  label: CustomFieldCategoryLabel
  name: CustomFieldValueNames
  value: CustomFieldState
  inputType?: string
  textFieldProps?: ITextFieldProps
}

export type CustomFieldComponent = (p: FieldProps) => JSX.Element

export type CustomFieldState = {
  value?: CustomFieldValueType
  unit?: CustomFieldValueType
}

export type OnCustomFieldChangeEvent = (key: OptionValue, value: CustomFieldState) => void

type Props = {
  disabled: boolean
  onChange: (k: string, v: CustomFieldValueType) => void
  fieldData?: ContractCustomFieldsData
}

const fieldGroupLabel = 'Custom Field'
const fieldComponents = {
  custom_text: CustomTextField,
  custom_monetary: CustomMonetaryField,
  custom_dates: (p: FieldProps) => <CustomTextField {...p} inputType="date" />,
  custom_duration_days: (p: FieldProps) => <CustomTextField {...p} inputType="number" />,
  custom_duration_months: (p: FieldProps) => <CustomTextField {...p} inputType="number" />,
  custom_boolean: CustomBooleanField,
}

export default function ContractCustomFields({ disabled, onChange, fieldData }: Props) {
  return (
    <>
      {Object.entries(fieldComponents).map(([componentType, render]) => {
        const { type, categoryLabel, fields, prefix } =
          customFieldCategories[componentType as CustomFieldType]

        return (
          <CustomFieldGroup
            key={prefix}
            name={categoryLabel}
            label={fieldGroupLabel}
            fields={fields}
            fieldData={fieldData}
            type={type as MetadataTypes}
            prefix={prefix as CustomFieldType}
            disabled={disabled}
            Component={render}
            onChange={(key, state) => change(key.toString(), state)}
          />
        )
      })}
    </>
  )

  function change(key: string, state: CustomFieldState) {
    if (key.startsWith(monetaryFieldPrefix)) return synchronizeMonetaryFields(key, state)

    onChange(key, state.value)
  }

  function synchronizeMonetaryFields(monetaryKey: string, { value, unit }: CustomFieldState) {
    const valueKey = monetaryValueKeyFromKey(monetaryKey)
    const unitKey = monetaryUnitKeyFromKey(monetaryKey)
    const merged = value ? mergeCustomMonetaryFields({ [valueKey]: value, [unitKey]: unit }) : {}

    onChange(unitKey, unit)
    onChange(valueKey, merged[valueKey] as CustomFieldValueType)
  }
}
