import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react'
import { PropsWithChildren } from 'react'
import { ProgressIndicator, Spinner, Stack } from '@fluentui/react'
import ConfirmDialog from '@components/ConfirmDialog'
import { useDataInjection } from '@hooks/useDataInjection'
import useSaveNewVersion from '@hooks/useSaveNewVersion'
import useVersions from '@hooks/useVersions'
import ErrorMessage from '@components/ErrorMessage'
import { useNavigate } from 'react-router-dom'
import StyledStack from '@components/StyledStack'
import QuickMessage from '@components/QuickMessage'
import { ContractContext } from '@contexts/ContractContext'
import { useTranslation } from '@hooks/useTranslation'
import { StoreContext } from '@contexts/StoreContext'

type Props = PropsWithChildren & {
  showSaveCurrentVersionModal: boolean
  setShowSaveCurrentVersionModal: Dispatch<SetStateAction<boolean>>
}

const SaveVersionDialog: FC<Props> = ({
  showSaveCurrentVersionModal,
  setShowSaveCurrentVersionModal,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [saveSucceeded, setSaveSucceeded] = useState(false)

  const { resourceId, documentId, versionId } = useDataInjection()
  // @ts-ignore TODO: documentId was previously typed as string but that was a mistake,
  // it's not guaranteed to exist. Need to handle this case.
  const { loadDocumentVersions, documentName, setDocumentName, versions } = useVersions(documentId)

  const { access } = useContext(StoreContext)
  const { contract, loadContract } = useContext(ContractContext)

  let versionDescription = t('label.SaveVersionDialog.version-description')
  let latestVersionId = versionId
  if (versions) {
    const curVer = versions[0]
    if (curVer) {
      latestVersionId = curVer.version
      versionDescription = curVer.description
    }
  }
  const {
    submitNewVersion,
    submitting,
    percentUploaded,
    setPercentUploaded,
    setSubmitting,
    uploadError,
    setUploadError,
    // @ts-ignore TODO: resourceId was previously typed as string but that was a mistake,
    // it's not guaranteed to exist. Need to handle this case.
  } = useSaveNewVersion(
    documentName,
    resourceId ?? '',
    documentId ?? '',
    versionDescription,
    undefined,
    latestVersionId,
  )
  useEffect(() => {
    if (documentId) loadDocumentVersions()
    // Note: the following eslint is disabled because the suggested fix causes a render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceId, documentId, versionId])

  useEffect(() => {
    if (contract && documentId) {
      const document = contract.findDocument(documentId)
      if (document) {
        setDocumentName(document.name)
        setLoading(false)
      } else {
        loadContract()
      }
    }
  }, [contract, documentId, setDocumentName, versionId, loadContract])

  const handleSaveCurrentDismiss = () => {
    setShowSaveCurrentVersionModal(false)
    setPercentUploaded(0)
    setSubmitting(false)
    setUploadError('')
  }

  const handleSaveCurrentConfirm = async () => {
    setUploadError('')
    const result = await submitNewVersion()
    if (result) {
      setPercentUploaded(100)
      setSaveSucceeded(true)
      setTimeout(() => {
        setSaveSucceeded(false)
        handleSaveCurrentDismiss()
      }, 3000)
    } else {
      setPercentUploaded(0)
      setSubmitting(false)
    }
  }

  const handleAddNewConfirm = () => {
    navigate('/contracts/new')
    setShowSaveCurrentVersionModal(false)
  }

  if (versionId) {
    // This file is added to the system and has a version, Overwrite current is possible
    return (
      <ConfirmDialog
        title={t('label.SaveVersionDialog.update-dialog-title')}
        hidden={!showSaveCurrentVersionModal}
        onDismiss={handleSaveCurrentDismiss}
        onConfirm={handleSaveCurrentConfirm}
        confirmBtnTitle={t('button.Save')}
        confirm={t('label.SaveVersionDialog.update-dialog-title')}
        disableBtn={loading || submitting}
      >
        <div style={{ width: '100%', maxHeight: '160px' }}>
          {loading && <Spinner data-testid="spinner" />}
          {!loading && (
            <div>
              {saveSucceeded && t('label.SaveVersionDialog.save-succeeded')}
              {!saveSucceeded && (
                <>
                  {t('label.SaveVersionDialog.save-question')}
                  <QuickMessage
                    type="warning"
                    msg={t('label.SaveVersionDialog.save-warning')}
                    style={{ margin: '15px 0' }}
                  />
                </>
              )}
            </div>
          )}

          {submitting && (
            <ProgressIndicator
              label={`${t('label.uploading')} ${percentUploaded}%`}
              percentComplete={percentUploaded * 0.01}
            />
          )}
          <ErrorMessage message={uploadError} />
        </div>
      </ConfirmDialog>
    )
  } else {
    // This file isn't saved to the system and thus has no version
    // We can't overwrite version, but can ask the user if they want to add it as a new contract
    return (
      <ConfirmDialog
        title={t('label.SaveVersionDialog.contract-not-found')}
        hidden={!showSaveCurrentVersionModal}
        onDismiss={() => setShowSaveCurrentVersionModal(false)}
        onConfirm={handleAddNewConfirm}
        confirmBtnTitle={t('button.Save As New Contract')}
        confirm={t('button.Save As New Contract')}
        btnStyles={{ root: { display: access.canCreateContract() ? 'flex' : 'none' } }}
      >
        <StyledStack>
          <Stack.Item>
            <QuickMessage
              type="warning"
              msg={t(
                access.canCreateContract()
                  ? 'label.contract-not-added'
                  : 'label.contract-not-added-cannot-save',
              )}
            />
          </Stack.Item>
        </StyledStack>
      </ConfirmDialog>
    )
  }
}

export default SaveVersionDialog
