import ApiClient from '@modules/ApiClient'
import Routes from './routes'
import { extractResourceNameFromUrl } from './utils'

const apiClient = new ApiClient()
const routes = new Routes()

type DocContentResponse = {
  content: string
  title: string
  documentType: string
}

type LinkResponse = {
  url: string
  mime_type: string
}

// Fetch a document's raw content from Contracts API
export async function getDocumentContent(
  documentId: string,
  contractId: string,
  versionId = 'latest',
  resourceType = 'document',
): Promise<DocContentResponse> {
  const { url, mime_type } = await getDocumentContentLink(
    documentId,
    contractId,
    versionId,
    resourceType,
  )
  const title = extractResourceNameFromUrl(url)
  const response = await apiClient.publicGet<string>(url, {
    responseType: 'arraybuffer', // these options tell axios to handle binary correctly.
    ...{ responseEncoding: 'binary' }, // spreading this one to workaround ts error
  })
  const content = response.data
  const documentType = mime_type

  return { content, title, documentType }
}

// Fetch a link to a document's raw content from Contracts API
export async function getDocumentContentLink(
  documentId: string,
  contractId: string,
  versionId = 'latest',
  resourceType = 'document',
): Promise<LinkResponse> {
  const contentUrl = `${routes.contractsApiUrl}/contract/${contractId}/documents/${documentId}/versions/${versionId}/content`
  const params = { resourceType }
  const { data } = await apiClient.get<LinkResponse>(contentUrl, { params })

  return data
}
