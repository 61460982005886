import { Icon } from '@fluentui/react'

import { Resource } from '@blaw/contracts-api-schema'
import Text from '@baseComponents/Text'
import { getFiletypeIconStyle } from '@modules/Contract'
import { CSSProperties } from 'react'

type Props = {
  item: Resource
  textStyle?: CSSProperties
}

export default function ResourceTitle({ item, textStyle }: Props) {
  const icon = getFiletypeIconStyle(item.mimeType)

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon
        iconName={icon.iconName}
        data-testid={icon.iconName}
        style={{ color: icon.iconColor }}
      />
      <Text style={{ paddingLeft: '0.3em', ...textStyle }}>{item.name}</Text>
    </div>
  )
}
