import { AxiosProgressEvent } from 'axios'

import { getContentHash } from '@modules/DocumentDefinitions'
import DocumentAnalysisClient from '@modules/DocumentAnalysisClient'
import { GetAnalysisUploadLink } from './GetResourceUploadLink'
import { PutDocumentToS3 } from './PutDocumentToS3'
import { GetWordDocumentContentRaw } from './GetWordDocumentContent'

export type AnalysisType = 'defex' | 'srf'

const client = new DocumentAnalysisClient()

export async function fetchDocumentAnalysis<T>(type: AnalysisType) {
  const prefix = await getContentHash()

  try {
    const { data } = await client.getAnalysis<T>(prefix, type)

    if (data?.analysis) return data
  } catch (e: any) {
    if (e?.response?.status === 404 || e?.response?.data?.state === `FAILED`) {
      await postDocumentUpload(prefix)
    } else throw e
  }
  return pollRetryFailures<T>(prefix, type)
}
function s3ProgressToPercentUploaded(progressEvent: AxiosProgressEvent) {
  console.log(
    `Defex upload: ${Math.round(
      (100 * progressEvent.loaded) / (progressEvent.total || progressEvent.loaded),
    )}% complete`,
  )
}

async function postDocumentUpload(contentHash: string): Promise<void> {
  const uploadlink = await GetAnalysisUploadLink(contentHash)
  const docData = await GetWordDocumentContentRaw()

  await PutDocumentToS3(uploadlink, docData, s3ProgressToPercentUploaded)
}

async function getAnalysis<T>(contentHash: string, type: AnalysisType) {
  console.log(`Polling get ${type} analysis for file key: ${contentHash}`)
  return client.getAnalysis<T>(contentHash, type)
}

async function pollRetryFailures<T>(contentHash: string, type: AnalysisType) {
  const MAX_ATTEMPTS = 3

  for (let attempt = 1; attempt <= MAX_ATTEMPTS; ++attempt) {
    try {
      return await pollForAnalysis<T>(contentHash, type)
    } catch (e: any) {
      if (e?.response?.data?.state === 'FAILED') {
        console.log(e?.response?.data?.message)
        if (attempt != MAX_ATTEMPTS) await postDocumentUpload(contentHash)
      } else {
        throw e
      }
    }
  }
  throw new Error(`${type} analysis failed. Retried ${MAX_ATTEMPTS} times.`)
}

async function pollForAnalysis<T>(contentHash: string, type: AnalysisType) {
  const MAX_ATTEMPTS = 10

  for (let attempt = 1; attempt <= MAX_ATTEMPTS; ++attempt) {
    const { status, data } = await getAnalysis<T>(contentHash, type)

    if (status === 200 && !data?.analysis) {
      console.log(`Document is still being processed. Current status is: ${data.state}`)
      await sleep(100 * Math.pow(2, attempt))
    }
    if (status === 200 && data?.analysis) {
      console.log(`Successfully fetched ${type} analysis`)
      return data
    }
  }
  throw new Error(`Timed out finding ${type} analysis`)
}

function sleep(delay: number) {
  return new Promise(resolve => {
    setTimeout(resolve, delay)
  })
}
