import { PropsWithChildren } from 'react'
import { useState } from 'react'
import { ActionButton, Spinner, SpinnerSize } from '@fluentui/react'

import { MorePagesIconName, LastPageIconName } from '@components/Icons'
import LoadMoreButton from '@components/LoadMoreButton'
import { TFunc, useTranslation } from '@hooks/useTranslation'

export type PaginationFooterProps = {
  numHitsRangeLabel: string
  loading: boolean
  totalHits: number
  onLastPage: boolean
  numHitsOnNextPage: number
  morePagesIcon: string
  loadMore: () => void
  scrollToTop: () => void
}
type PaginationProps = PropsWithChildren & {
  Footer?: (p: PaginationFooterProps) => JSX.Element | null
}
export type PaginationComponent = (p: PaginationProps) => JSX.Element

export type PaginationMeta = { totalPages?: number; totalHits?: number }
export type PaginationComponentProps = PropsWithChildren & { loading?: boolean }
type PaginationLoader = (...args: any) => Promise<PaginationMeta>
type AsyncFunc = (...args: any) => Promise<void>

type Props = {
  loadPage?: PaginationLoader
  loadNextPage: AsyncFunc
  hitsPerPage: number
  scrollToTop: () => void
}

export default function usePagination({ loadPage, loadNextPage, hitsPerPage, scrollToTop }: Props) {
  const [pageNum, setPageNum] = useState(0)
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [onLastPage, setOnLastPage] = useState(false)
  const [totalHits, setTotalHits] = useState(0)
  const [numHitsOnNextPage, setNumHitsOnNextPage] = useState(0)
  const [numHitsRangeLabel, setNumHitsRangeLabel] = useState('')
  const [morePagesIcon, setMorePagesIcon] = useState(MorePagesIconName)
  const { t } = useTranslation()

  const Pagination: PaginationComponent = ({
    children,
    Footer = DefaultFooter,
  }: PaginationProps) => {
    return (
      <>
        {children}
        <Footer
          numHitsRangeLabel={numHitsRangeLabel}
          loading={loading}
          totalHits={totalHits}
          onLastPage={onLastPage}
          numHitsOnNextPage={numHitsOnNextPage}
          morePagesIcon={morePagesIcon}
          loadMore={loadMore}
          scrollToTop={scrollToTop}
        />
      </>
    )
  }

  return {
    loadFirstPage,
    loading,
    Pagination,
    pageNum,
  }

  async function loadFirstPage(...args: any) {
    if (!loadPage) return

    setLoading(true)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const meta = await loadPage(...args)
    const totalPages = meta?.totalPages || 1
    const totalHits = meta?.totalHits || 0
    const lastPage = 1 === totalPages

    setLoading(false)
    setPageNum(0)
    setTotalPages(totalPages)
    setTotalHits(totalHits)
    setOnLastPage(lastPage)
    setMorePagesIcon(lastPage ? LastPageIconName : MorePagesIconName)
    setNumHitsRangeLabel(newShowMoreLabelWithCount(1, totalPages, totalHits, hitsPerPage, false, t))
    setNumHitsOnNextPage(findNumHitsOnNextPage(1, totalPages, totalHits, hitsPerPage))
  }

  async function loadMore() {
    if (onLastPage) return

    const nextPage = pageNum + 1
    const lastPage = nextPage + 1 === totalPages

    setLoading(true)
    setNumHitsRangeLabel(
      newShowMoreLabelWithCount(nextPage + 1, totalPages, totalHits, hitsPerPage, true, t),
    )
    await loadNextPage(nextPage)

    setLoading(false)
    setPageNum(nextPage)
    setTotalPages(totalPages)
    setOnLastPage(lastPage)
    setMorePagesIcon(lastPage ? LastPageIconName : MorePagesIconName)
    setNumHitsRangeLabel(
      newShowMoreLabelWithCount(nextPage + 1, totalPages, totalHits, hitsPerPage, false, t),
    )
    setNumHitsOnNextPage(findNumHitsOnNextPage(nextPage + 1, totalPages, totalHits, hitsPerPage))
  }
}

function DefaultFooter({
  numHitsRangeLabel,
  loading,
  totalHits,
  onLastPage,
  numHitsOnNextPage,
  morePagesIcon,
  loadMore,
  scrollToTop,
}: PaginationFooterProps) {
  const { t } = useTranslation()
  return (
    <>
      <span
        style={{
          display: 'inline-block',
          textAlign: 'center',
          width: '100%',
          margin: '0 0 0.5em 0',
        }}
      >
        {numHitsRangeLabel}
      </span>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {loading && <Spinner size={SpinnerSize.small} />}

        <LoadMoreButton
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            loadMore()
            return false
          }}
          label={loadMoreBtnContent(loading, totalHits, onLastPage, numHitsOnNextPage, t)}
          iconName={loading ? '' : morePagesIcon}
          disabled={onLastPage || loading}
          aria-label={loading ? t('label.loading') : t('label.viewing')}
        />
      </div>
      <ActionButton
        style={{ display: 'block', margin: '0 auto' }}
        disabled={loading}
        onClick={scrollToTop}
        iconProps={{ iconName: 'ChevronUpEnd6' }}
      >
        Return to Top
      </ActionButton>
    </>
  )
}

function loadMoreBtnContent(
  loading: boolean | undefined,
  totalHits: number,
  onLastPage: boolean,
  numHitsOnNextPage: number,
  t: TFunc,
) {
  if (loading && totalHits === 0) return t('label.loading')
  if (onLastPage) return `Showing all ${totalHits} Results`
  return `Show the Next ${numHitsOnNextPage} Results`
}

function newShowMoreLabelWithCount(
  pageNum: number,
  totalPages: number,
  totalHits: number,
  hitsPerPage: number,
  loading = false,
  t: TFunc,
) {
  const currentNumHits = pageNum === totalPages ? totalHits : pageNum * hitsPerPage
  const hitsRange = `${currentNumHits} of ${totalHits}`

  return loading ? t('label.loading') : `${t('label.viewing')} ${hitsRange}`
}

function findNumHitsOnNextPage(
  pageNum: number,
  totalPages: number,
  totalHits: number,
  hitsPerPage: number,
) {
  if (pageNum === totalPages) return 0
  if (pageNum + 1 !== totalPages) return hitsPerPage

  return totalHits - hitsPerPage * pageNum
}
