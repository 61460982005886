import { PropsWithChildren } from 'react'
import { FontWeights, SharedColors } from '@fluentui/react'
import styled from 'styled-components'

type Props = PropsWithChildren & {
  style?: React.CSSProperties
}

const StyledSpan = styled.span`
  color: white;
  background-color: ${SharedColors.cyan10};
  display: inline-block;
  border-radius: 50%;
  font-size: 12px;
  width: max-content;
  min-width: 20px;
  height: 20px;
  line-height: 1.6em;
  font-weight: ${FontWeights.semibold};
  text-align: center;
`

export const NotificationBadge: React.FC<Props> = (props: Props) => {
  return <StyledSpan style={props.style}>{props.children}</StyledSpan>
}
