import { CSSProperties } from 'react'
import { FontSizes, SharedColors } from '@fluentui/react'

import TextBadge from '@components/TextBadge'

type Props = {
  show: boolean
}

const badgeStyle: CSSProperties = {
  padding: '0.1em 0.3em',
  margin: '0.3em 0.5em 0em',
  fontSize: FontSizes.size10,
  backgroundColor: SharedColors.cyan20,
  borderColor: SharedColors.cyan20,
  whiteSpace: 'nowrap',
}

export default function BetaBadge({ show }: Props) {
  return (
    <TextBadge style={{ ...badgeStyle, display: show ? 'inline-block' : 'none' }}>Beta</TextBadge>
  )
}
