import * as React from 'react'
import { DefaultEffects } from '@fluentui/react'

type Props = {
  show: boolean
  style?: React.CSSProperties
  children: React.ReactNode
}

// Useful for messages, statuses, or error messages in the Taskpane.
// Will show up at the bottom over other content.
const FloatingMessage: React.FC<Props> = props => {
  if (!props.show) return null

  return (
    <div
      style={{
        zIndex: 100,
        left: '1.1em',
        right: '1.1em',
        bottom: '1.1em',
        position: 'fixed',
        boxShadow: DefaultEffects.elevation8,
        ...props.style,
      }}
    >
      {props.children}
    </div>
  )
}

export default FloatingMessage
