import styled from 'styled-components'
import { NeutralColors } from '@fluentui/theme'
import { DefaultEffects } from '@fluentui/react'

export const StyledDivider = styled.div`
  color: ${NeutralColors.gray60};
  margin: 0 0 0.4em 0;
  box-shadow: ${DefaultEffects.elevation4};
  height: 1px;
`

export const StyledDividerWithoutMargin = styled(StyledDivider)`
  color: ${NeutralColors.gray60};
  margin: 0;
`
