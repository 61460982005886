import { useContext, useState, PropsWithChildren, useEffect } from 'react'

import { StoreContext } from '@contexts/StoreContext'
import { getSessionId, SessionInfo, formatUserInfo } from '@modules/SessionInfoStorage'
import ApiClient from '@modules/ApiClient'
import Login, { renderWaitingForLogin } from '@components/Login'
import ErrorMessage from '@components/ErrorMessage'
import { useTranslation } from '@hooks/useTranslation'
import Routes from '@modules/routes'
import { Navigate, useLocation } from 'react-router-dom'
import { hardReloadPage } from '@modules/utils'
import { loginViewed } from '@modules/analytics'

type Props = PropsWithChildren & {
  required?: boolean
}

export default function Authentication({ required, children }: Props) {
  const { storeSessionInfo, getSession, access } = useContext(StoreContext)
  const [closing, setClosing] = useState(false)
  const [error, setError] = useState('')
  const [checkingSession, setCheckingSession] = useState(false)
  const [refreshed, setRefreshed] = useState(false)
  const { accessTime } = getSession()
  const apiClient = new ApiClient(storeSessionInfo, setError, checkingSession)
  const { messageParent } = Office.context.ui
  const { t } = useTranslation()
  const routes = new Routes()
  const location = useLocation()

  useEffect(() => {
    if (!required) return

    setCheckingSession(true)
    apiClient
      .refresh()
      .then(refreshed => {
        console.debug({ refreshed })
        setRefreshed(refreshed)
        if (!refreshed) {
          loginViewed({
            isLoggedIn: false,
            pageTitle: 'Login',
            loginType: 'Modal',
            rememberMe: 'N/A',
          })
          Login(storeSessionInfo, setError, checkingSession)
        } else if (!getSession().userRole) setStoreSessionInfo()
      })
      .catch(e => console.debug('not refreshable', e))
      .finally(() => setCheckingSession(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children])

  if (accessTime && location.pathname !== '/' && !access.canViewRoute(location.pathname)) {
    return <Navigate to="/" replace />
  }

  if (returnPath()) {
    handleLoginRedirect()
    return renderWaitingForLogin()
  }

  if (error) return <ErrorMessage message={error} />
  if (closing) return null
  if (required && accessTime && !refreshed) return null
  if (required && !accessTime && !refreshed) return renderWaitingForLogin(error)

  try {
    window.localStorage
  } catch (e) {
    const err =
      'Unable to start session. Could be due to cookies being disabled or using incognito mode in some browsers.'
    setError(err)
    console.error((e as Error).message)
    return <ErrorMessage message={err} />
  }

  return <>{children}</>

  function returnPath() {
    return getParam('rp')
  }

  function bffErrorParam() {
    return getParam('bff-error')
  }

  async function getUserInfo() {
    try {
      const { data } = await apiClient.get<{ payload: SessionInfo }>(routes.userInfoUrl)
      return data.payload
    } catch (e) {
      console.error((e as Error).message ?? 'Unknown error')
      return null
    }
  }

  // After a user logs in, Auth service will redirect back to us,
  // including a returnPath in the query params.
  // When this happens, we are being rendered in the Dialog
  // that was opened in login() and we're now closing this Dialog.
  async function handleLoginRedirect() {
    const bffError = bffErrorParam()
    if (bffError) {
      messageParent(JSON.stringify({ loginError: t('errors.login') }))
      return messageParent('close')
    }

    const sessionCookie = getSessionId()
    if (!sessionCookie) return setError('Invalid Session: missing cookie')

    const userInfo = await getUserInfo()
    if (!userInfo) return setError('Invalid Session: missing session data')

    const formattedUserInfo = formatUserInfo(sessionCookie, userInfo, returnPath())
    const data = JSON.stringify({ formattedUserInfo })

    setClosing(true)
    messageParent(data)
    messageParent('close')
  }

  async function setStoreSessionInfo() {
    const sessionCookie = getSessionId()
    if (!sessionCookie) return setError('Invalid Session: missing cookie')

    const userInfo = await getUserInfo()
    if (!userInfo) return setError('Invalid Session: missing session data')

    storeSessionInfo(formatUserInfo(sessionCookie, userInfo))
    hardReloadPage(`/index.html#${location.pathname}`)
    // add-in crashes in windows when we call location.reload immediately after hardReloadPage
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  function getParam(param: string, url = window.location.href) {
    return new URL(url).searchParams.get(param)
  }
}
