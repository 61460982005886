import { useContext } from 'react'

import StyledTextField from '@components/StyledTextField'
import useKeyTermForm from '@hooks/useKeyTermForm'
import { useTranslation } from '@hooks/useTranslation'
import { removeNonNumberValues } from '@modules/utils'
import { KeyTermFormProps } from '@modules/KeyTermForm'
import { KeyTermFormContext } from '@contexts/KeyTermFormContext'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'

const DurationDaysKeyTermForm: React.FC<KeyTermFormProps> = ({ keyTermType }: KeyTermFormProps) => {
  const { newKeyTerm, updateKeyTermNotes, updateNumericKeyTerm, copyFromSelection } =
    useKeyTermForm(keyTermType, 'DURATION_DAYS')
  const { validationError, copySelectionDisabled } = useContext(KeyTermFormContext)
  const { t } = useTranslation()

  if (!newKeyTerm) return null

  return (
    <>
      <StyledTextField
        label={keyTermType}
        name="number"
        suffix="days"
        onChange={updateNumericKeyTerm}
        required
        type="number"
        min="0"
        value={removeNonNumberValues(newKeyTerm.value)}
        errorMessage={validationError}
      />
      <StyledTextField
        label={t('label.notes')}
        multiline
        name="notes"
        onChange={updateKeyTermNotes}
        value={newKeyTerm?.data?.notes}
      />
      <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} />
    </>
  )
}

export default DurationDaysKeyTermForm
