import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useRef,
  MutableRefObject,
  PropsWithChildren,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { config, Contract } from '@blaw/contracts-api-schema'

import useSimilarClauses from '@hooks/useSimilarClauses'
import { addToArray, removeFromArray } from '@modules/utils'
import { AnalysisContext } from '@contexts/AnalysisContext'
import { FilterState, SimilarClausesResponse } from '@modules/ClauseAnalyzer'
import useScrollToTop, { TopOfPageRef } from '@hooks/useScrollToTop'
import usePagination from '@hooks/usePagination'

type Props = React.PropsWithChildren

type ClauseReplacement = null | number // clause id that was replaced

const sortOrder = [
  'filterOwner',
  'docType',
  'filterStatus',
  'filterIndustry',
  'filterGoverningLaw',
  'filterParty',
  'filterLawFirm',
  'filterYear',
]

export const clauseSourceLabels = {
  contract: 'Contract Clauses',
  my_company_clause: 'Company Favorites',
  library_clause: 'My Favorites',
  template: 'Template Clauses',
}

export type ClauseSourceLabels = typeof clauseSourceLabels

const customFields: { [key: string]: string } = {}
// @ts-ignore TODO: fix type error with config, some fields missing, needs update
Contract.getCustomFieldCategories(config).custom_text.fields.forEach((field, idx) => {
  const key = `customField${idx + 1}`
  const value = field.label
  customFields[key] = value
})

const facetLabels: { [key: string]: string } = {
  filterIndustry: 'Industry',
  filterLawFirm: 'Law Firm',
  filterYear: 'Year Finalized',
  docType: 'Contract Type',
  filterGoverningLaw: 'Governing Law',
  filterParty: 'Counterparty Name',
  filterOwner: 'Owner',
  filterStatus: 'Contract Status',
  clauseSource: 'Clause Source',
  clauseType: 'Clause Type',
  riskLevel: 'Risk Level',
  contractOrigin: 'Contract Origin',
  companyStakeholder: 'Company Stakeholder',
  contractCategory: 'Contract Category',
  transaction: 'Transaction',
  pubContractId: 'Contract ID',
  clientName: 'My Company',
  ...customFields,
}

const filterType = 'Clause'
const title = 'Filter Similar Clauses'
const path = '/clauses/similar'
const hitsPerPage = 20

export interface SimilarContextState {
  filterType: string
  title: string
  sortOrder: string[]
  facetLabels: { [key: string]: string }
  cancelFilters: () => void
  error: string | null
  filterState: FilterState
  handleFiltering: (facetName: string, facetItem: string, checked: boolean) => void
  loading: boolean
  numFilters: () => number
  selection: string
  redlineSelection: string
  items: SimilarClausesResponse
  runAnalysis: () => void
  clauseReplacementMade: ClauseReplacement
  setClauseReplacementMade: Dispatch<SetStateAction<ClauseReplacement>>
  topOfPageRef: TopOfPageRef
  scrollToTop: () => void
  path: string
  fetchingItems: MutableRefObject<boolean>
  Pagination: React.FC<PropsWithChildren>
  clauseSourceLabels: ClauseSourceLabels
}

export const SimilarClausesContext = createContext({} as SimilarContextState)

const SimilarClausesContextProvider: FC<Props> = (props: Props) => {
  const { analysisType } = useContext(AnalysisContext)
  const [prevFilterState, setPrevFilterState] = useState<FilterState>({})
  const [filterState, setFilterState] = useState<FilterState>({})
  const [redlineSelection, setRedlineSelection] = useState('')
  const [clauseReplacementMade, setClauseReplacementMade] = useState<ClauseReplacement>(null)
  const { error, loading, items, selection, runAnalysis, loadPage, loadNextPage } =
    useSimilarClauses(analysisType, filterState, setFilterState, hitsPerPage)
  const { topOfPageRef, scrollToTop } = useScrollToTop()
  const { Pagination, loadFirstPage } = usePagination({
    loadPage,
    loadNextPage,
    hitsPerPage,
    scrollToTop,
  })
  const location = useLocation()
  const navigate = useNavigate()
  const fetchingItems = useRef(false)

  useEffect(() => {
    if (selection && selection.length > 0) {
      setRedlineSelection(selection)
      scrollToTop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection])

  useEffect(() => {
    if (location.pathname.startsWith('/clauses/similar')) {
      loadFirstPage(true)
      if (location.pathname === '/clauses/similar/filters') navigate('/clauses/similar')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection, analysisType])

  useEffect(() => {
    if (
      location.pathname === '/clauses/similar/filters' &&
      JSON.stringify(filterState) !== JSON.stringify(prevFilterState)
    ) {
      loadFirstPage(false)
      setPrevFilterState({ ...filterState })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState])

  function handleFiltering(facetName: string, facetItem: string, checked: boolean) {
    setFilterState(prevState => {
      let previousFacetName = prevState[facetName]
      if (!previousFacetName) {
        previousFacetName = []
      }
      return {
        ...prevState,
        [facetName]: checked
          ? addToArray(previousFacetName, facetItem)
          : removeFromArray(previousFacetName, facetItem),
      }
    })
  }

  function numFilters() {
    return Object.values(filterState).reduce(
      (prevValue, filterArray) => prevValue + filterArray.length,
      0,
    )
  }

  function cancelFilters() {
    setFilterState({})
  }

  const value = {
    filterType,
    title,
    sortOrder,
    facetLabels,
    cancelFilters,
    error,
    filterState,
    handleFiltering,
    loading,
    fetchingItems,
    numFilters,
    selection,
    redlineSelection,
    items,
    runAnalysis,
    clauseReplacementMade,
    setClauseReplacementMade,
    topOfPageRef,
    scrollToTop,
    path,
    Pagination,
    clauseSourceLabels,
  }

  return (
    <SimilarClausesContext.Provider value={value}>{props.children}</SimilarClausesContext.Provider>
  )
}

export default SimilarClausesContextProvider
