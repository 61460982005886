import { CSSProperties } from 'react'
import { FontSizes, SharedColors } from '@fluentui/react'

import TextBadge from '@components/TextBadge'
import { MetadataDetail } from '@modules/ContractDetail'
import { useTranslation } from '@hooks/useTranslation'

type Props = { detail?: MetadataDetail }

const badgeStyle: CSSProperties = {
  display: 'block',
  padding: '0.1em 0.3em',
  marginTop: '0.3em',
  fontSize: FontSizes.size12,
  backgroundColor: SharedColors.magenta10,
  borderColor: SharedColors.magenta10,
}

export default function DetailBadge({ detail }: Props) {
  const { t } = useTranslation()

  if (!detail?.system_extracted) return <></>

  return <TextBadge style={badgeStyle}>{t('label.system-extracted')}</TextBadge>
}
