import { useState, useContext, useEffect, FormEvent, Dispatch, SetStateAction } from 'react'
import {
  Stack,
  MessageBar,
  IStackTokens,
  MessageBarType,
  ChoiceGroup,
  IChoiceGroupOption,
} from '@fluentui/react'

import { ClauseForm, clauseFormFieldDefaults } from '@modules/Clause'
import { CompanyClausesTabIndex, TabInfo, TabName } from '@pages/ClauseLibrary/ClauseLibrary'
import { entries } from '@modules/utils'
import { StoreContext } from '@contexts/StoreContext'
import { ClausesContext, TabIndex } from '@contexts/ClausesContext'
import ApiClient from '@modules/ApiClient'
import Clause from '@components/Clause'
import ConfirmDialog from '@components/ConfirmDialog'
import StyledTextField from '@components/StyledTextField'
import { createClause } from '@modules/ClauseResource'

const largeStackTokens: IStackTokens = {
  childrenGap: '1em',
}

const messageBarStyles = {
  fontSize: '1.1em',
}

interface Props {
  hidden: boolean
  toggleHidden: () => void
  targetTab: TabIndex
  selectedClause: Clause | null
  setSelectedClause: Dispatch<SetStateAction<Clause | null>>
}

type GenericEvent = { preventDefault: () => void }

function AddNewClauseForm({
  hidden,
  selectedClause,
  targetTab,
  setSelectedClause,
  toggleHidden,
}: Props) {
  const apiClient = new ApiClient()
  const { filterState, cancelFilters, activeTab, setQuery, loadFirstPage, setActiveTab } =
    useContext(ClausesContext)
  const [firstEmptyField, setFirstEmptyField] = useState('')
  const [loading, setLoading] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [error, setError] = useState('')
  const [formData, setFormData] = useState(clauseFormFieldDefaults)
  const [submitBtnTitle, setSubmitBtnTitle] = useState(
    'Fill out all fields before adding a new Clause',
  )
  const riskLevelOptions: IChoiceGroupOption[] = [
    { key: 'High', text: 'High' },
    { key: 'Medium', text: 'Medium' },
    { key: 'Low', text: 'Low' },
    { key: '', text: 'None Provided' },
  ]

  useEffect(() => {
    const preFilledData: Required<ClauseForm> = {
      title: selectedClause?.title || '',
      clauseText: selectedClause?.clauseText || '',
      clauseType: selectedClause?.clauseType || '',
      contractType: selectedClause?.contractType || '',
      riskLevel: selectedClause?.riskLevel || '',
      resourceType: targetTab === CompanyClausesTabIndex ? 'my_company_clause' : '',
      counterParty: '',
      dealSide: selectedClause?.dealSide || '0',
    }
    const isFormValid = preFilledData.clauseText

    setFormData(preFilledData)
    setFormValid(!!isFormValid)
  }, [selectedClause, targetTab])

  useEffect(() => {
    if (!hidden) {
      selectFirstEmptyField()
    } else {
      setFirstEmptyField('')
      setError('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden])

  const targetTabLabel = TabInfo[targetTab as TabName].label

  return (
    <ConfirmDialog
      title={`Add to ${targetTabLabel}`}
      hidden={hidden}
      disableBtn={!formValid || loading}
      confirm={loading ? 'Adding...' : 'Add New Clause'}
      onConfirm={submit}
      onDismiss={() => {
        toggleHidden()
        clearForm()
      }}
      confirmBtnTitle={submitBtnTitle}
    >
      <form onSubmit={submit}>
        <Stack tokens={largeStackTokens}>
          <Stack.Item>
            {error && renderError()}

            <MessageBar style={messageBarStyles}>
              {`Newly added Clause will be available in the "${targetTabLabel}" tab.`}
            </MessageBar>
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              required
              multiline
              autoAdjustHeight
              label="Clause Text"
              name="clauseText"
              value={formData.clauseText}
              onChange={onChangeText}
              disabled={loading}
              autoFocus={firstEmptyField === 'clauseText'}
            />
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              label="Clause Type"
              name="clauseType"
              value={formData.clauseType}
              onChange={onChangeText}
              disabled={loading}
              autoFocus={firstEmptyField === 'clauseType'}
            />
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              label="Contract Type"
              name="contractType"
              value={formData.contractType}
              onChange={onChangeText}
              disabled={loading}
              autoFocus={firstEmptyField === 'contractType'}
            />
          </Stack.Item>
          <Stack.Item>
            <ChoiceGroup
              options={riskLevelOptions}
              label="Risk Level"
              defaultSelectedKey={formData.riskLevel}
              value={formData.riskLevel}
              onChange={(_, option) => {
                onChangeText('riskLevel', option?.key)
              }}
              disabled={loading}
            />
          </Stack.Item>
        </Stack>

        <input type="submit" style={{ display: 'none' }} />
      </form>
    </ConfirmDialog>
  )

  function renderError() {
    return (
      <MessageBar style={messageBarStyles} messageBarType={MessageBarType.error}>
        {error}
      </MessageBar>
    )
  }

  function selectFirstEmptyField() {
    const field = entries(formData).find(([_, val]) => !val.length)
    if (field) setFirstEmptyField(field[0])
  }

  async function submit(e: GenericEvent) {
    e?.preventDefault()

    setError('')
    setLoading(true)

    try {
      const { status, data } = await createClause(formData, apiClient)

      if (status === 201) {
        if (activeTab !== targetTab) {
          setActiveTab(targetTab)
        } else if (Object.keys(filterState).length !== 0) {
          cancelFilters()
        } else {
          await loadFirstPage('', targetTab)
        }

        setQuery('')
        clearForm()
        toggleHidden()
      } else {
        setError(`${status} ${JSON.stringify(data)}`)
      }
    } catch (e) {
      setError((e as Error).message)
      window.scroll(0, 0)
    }
    setLoading(false)
  }

  function clearForm() {
    setFormData(clauseFormFieldDefaults)
    setSelectedClause(null)
    setFormValid(false)
  }

  function onChangeText(
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement> | string,
    value: string | undefined,
  ) {
    if (!event) return
    const field = typeof event === 'string' ? event : event.currentTarget.name
    const newData: ClauseForm = { ...formData, [field]: value || '' }

    const isFormValid = newData.clauseText
    setFormValid(!!isFormValid)

    if (isFormValid) {
      setSubmitBtnTitle('Add this Clause to My Clauses')
    } else {
      setSubmitBtnTitle('Fill out all fields before adding a new Clause')
    }

    setFormData(newData)
  }
}

export default AddNewClauseForm
