import { useState } from 'react'
import ApiClient from '@modules/ApiClient'

const apiClient = new ApiClient()

// Use in a global scope to keep the auth token fresh
export default function useTokenAutoRefresh(interval = 120_000) {
  const [id, setId] = useState<any>()

  return function tokenAutoRefresh() {
    if (id) clearInterval(id)
    setId(setInterval(() => apiClient.refresh(), interval))
  }
}
