import { useState, useEffect } from 'react'
import GrabTextSelection from '@modules/GrabTextSelection'

// hook will check if any part of document is selected when a component first loads
// and set an event listener to fire when selection changes
export default function useDocumentSelection() {
  const [selection, setSelection] = useState('')
  const [loadingDocumentSelection, setLoadingDocumentSelection] = useState(false)
  const { addHandlerAsync, removeHandlerAsync } = Office.context.document
  const { DocumentSelectionChanged } = Office.EventType

  const grabSelection = async () => {
    const newSelection = await GrabTextSelection()
    console.debug({ newSelection }) // debug
    setSelection(newSelection)
  }

  useEffect(() => {
    grabSelection()
    addHandlerAsync(DocumentSelectionChanged, checkSelection)
    return () => removeHandlerAsync(DocumentSelectionChanged, { handler: checkSelection })
  }, [])

  return selection

  async function checkSelection() {
    if (loadingDocumentSelection) return
    setLoadingDocumentSelection(true)
    await grabSelection()
    setLoadingDocumentSelection(false)
  }
}
