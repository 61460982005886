import React, { CSSProperties, PropsWithChildren } from 'react'

type Props = PropsWithChildren & {
  style?: CSSProperties
}

const UnstyledList: React.FC<Props> = ({ children, style = {} }) => {
  return <ul style={{ listStyle: 'none', paddingLeft: 0, marginTop: 0, ...style }}>{children}</ul>
}

export default UnstyledList
