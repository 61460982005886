import { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import { Dialog, DialogFooter, PrimaryButton } from '@fluentui/react'
import { StoreContext } from '@contexts/StoreContext'
import { useNavigate } from 'react-router'
import { useTranslation } from '@hooks/useTranslation'

type Props = {
  showClauseAdviserDisabledModal: boolean
  setShowClauseAdviserDisabledModal: Dispatch<SetStateAction<boolean>>
}

export default function ClauseAdviserDisabledModal(props: Props) {
  const { showClauseAdviserDisabledModal, setShowClauseAdviserDisabledModal } = props
  const { t } = useTranslation()

  const closeDialog = () => {
    setShowClauseAdviserDisabledModal(false)
  }

  useEffect(() => {
    if (isClauseAdviserEnabled) {
      navigate('/clauseAdviser')
    }
  }, [])

  const navigate = useNavigate()
  const { isClauseAdviserEnabled } = useContext(StoreContext)
  if (isClauseAdviserEnabled) {
    closeDialog()
    return null
  }

  return (
    <Dialog
      hidden={!showClauseAdviserDisabledModal}
      onDismiss={closeDialog}
      dialogContentProps={{
        title: t('modal.Clause Adviser.coming soon'),
      }}
      modalProps={{
        isBlocking: true,
      }}
      styles={{ main: { minHeight: 'initial' } }}
    >
      <DialogFooter>
        <PrimaryButton onClick={closeDialog} title={t('modal.Clause Adviser.okay')}>
          {t('modal.Clause Adviser.okay')}
        </PrimaryButton>
      </DialogFooter>
    </Dialog>
  )
}
