import { useEffect } from 'react'
import {
  ampli,
  ContractTaskPaneViewedProperties,
  InteractiveClickProperties,
  LandingViewedProperties,
  LoginViewProperties,
  NavClickedProperties,
  SearchResultsViewedProperties,
  SearchSubmittedProperties,
  TagAddedProperties,
  TagDeletedProperties,
} from './ampli'

import {
  EverywhereProperties,
  defaultEverywhereProperties,
  loadAnalytics as init,
} from '@blaw/contracts-analytics'

import { AMPLITUDE_ENV, AMPLITUDE_API_KEY, AMPLITUDE_API_URL } from '@src/constants'
import { getSession } from '@modules/SessionInfoStorage'

const testEnv = process.env.NODE_ENV === 'test'

export function loadAnalytics() {
  init({
    ampli,
    env: AMPLITUDE_ENV,
    apiKey: AMPLITUDE_API_KEY,
    apiUrl: AMPLITUDE_API_URL,
    userInfo: getSession(),
  })
}

// Event tracking

type FormSubmittedPropertiesSubset = Omit<
  InteractiveClickPropertiesSubset,
  'itemLocation' | 'itemType' | 'isLoggedIn'
>

export function formSubmitted(props: FormSubmittedPropertiesSubset) {
  itemClicked({
    itemLocation: 'Taskpane',
    itemType: 'Form Submit',
    isLoggedIn: true,
    ...props,
  })
}

type InteractiveClickPropertiesSubset = Omit<InteractiveClickProperties, keyof EverywhereProperties>

export function itemClicked(props: InteractiveClickPropertiesSubset) {
  if (testEnv) return

  ampli.interactiveClick({
    ...everywhereProperties(),
    ...props,
  })
}

type TagAddedPropertiesSubset = Omit<TagAddedProperties, keyof EverywhereProperties>

export function tagAdded(props: TagAddedPropertiesSubset) {
  if (testEnv) return

  ampli.tagAdded({
    ...everywhereProperties(),
    ...props,
  })
}

type TagDeletedPropertiesSubset = Omit<TagDeletedProperties, keyof EverywhereProperties>

export function tagDeleted(props: TagDeletedPropertiesSubset) {
  if (testEnv) return

  ampli.tagDeleted({
    ...everywhereProperties(),
    ...props,
  })
}

type NavClickedPropertiesSubset = Omit<NavClickedProperties, keyof EverywhereProperties>

export function navClicked(props: NavClickedPropertiesSubset) {
  if (testEnv) return

  ampli.navClicked({
    ...everywhereProperties(),
    ...props,
  })
}

type SearchSubmittedPropertiesSubset = Omit<SearchSubmittedProperties, keyof EverywhereProperties>

export function searchSubmitted(props: SearchSubmittedPropertiesSubset) {
  if (testEnv) return

  ampli.searchSubmitted({
    ...everywhereProperties(),
    ...props,
  })
}

type SearchResultsViewedPropertiesSubset = Omit<
  SearchResultsViewedProperties,
  keyof EverywhereProperties
>

export function searchResultsViewed(props: SearchResultsViewedPropertiesSubset) {
  if (testEnv) return

  ampli.searchResultsViewed({
    ...everywhereProperties(),
    ...props,
  })
}

type ContractTaskPaneViewedPropertiesSubset = Omit<
  ContractTaskPaneViewedProperties,
  keyof EverywhereProperties | 'isLoggedIn'
>

export function contractTaskPaneViewed(props: ContractTaskPaneViewedPropertiesSubset) {
  if (testEnv) return

  ampli.contractTaskPaneViewed({
    ...everywhereProperties(),
    ...props,
    isLoggedIn: true,
  })
}

// For use in the body of Page components. Meant to only run on mount.
export function useContractTaskPaneViewed(props: ContractTaskPaneViewedPropertiesSubset) {
  useEffect(() => {
    contractTaskPaneViewed(props)
  }, [props.pageTitle])
}

type LandingViewedPropertiesSubset = Omit<LandingViewedProperties, keyof EverywhereProperties>

export function homepageViewed(props: LandingViewedPropertiesSubset) {
  if (testEnv) return

  ampli.homepageViewed({
    ...everywhereProperties(),
    ...props,
  })
}

// For use in the body of Page components. Meant to only run on mount.
export function useHomepageViewed(props: LandingViewedPropertiesSubset) {
  useEffect(() => {
    homepageViewed(props)
  }, [props])
}

type LoginViewPropertiesSubset = Omit<LoginViewProperties, keyof EverywhereProperties>

export function loginViewed(props: LoginViewPropertiesSubset) {
  if (testEnv) return

  ampli.loginView({
    ...everywhereProperties(),
    ...props,
  })
}

// Helpers

function everywhereProperties(): EverywhereProperties {
  return defaultEverywhereProperties({
    appName: 'Contracts Word Add-in',
    pageType: 'Taskpane',
    appVersion: '1',
  })
}
