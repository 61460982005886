import { SharedColors, Slider } from '@fluentui/react'

interface Props {
  min: number
  max: number
  step: number
  value: number | undefined
  disabled: boolean
  onChange: (
    value: number,
    range?: [number, number],
    event?: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent | React.KeyboardEvent,
  ) => void
  sliderColor: string
}

function StyledSlider(props: Props) {
  const { min, max, step, value, disabled, onChange, sliderColor } = props

  const sliderStyles = `
    .styledSlider > div > div {
      padding: 0 0.25em;
    }

    .styledSlider > div > div > div span:nth-child(1) {
      background-color: ${sliderColor};
      border-color: ${sliderColor};
      z-index: 100;
    }

    .styledSlider:hover > div > div > div span:nth-child(1) {
      background-color: ${sliderColor};
      border-color: ${sliderColor};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
    }

    .styledSlider:active > div > div > div span:nth-child(1) {
      background-color: ${sliderColor};
      border-color: ${sliderColor};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
    }

    .styledSlider > div > div > div span:nth-child(3) {
      background-color: ${sliderColor};
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .styledSlider:hover > div > div > div span:nth-child(3) {
      background-color: ${sliderColor};
    }

    .styledSlider:active > div > div > div span:nth-child(3) {
      background-color: ${sliderColor};
    }

    .styledSlider > div > div > div span:nth-child(4) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  `

  return (
    <div style={{ margin: '-15px auto 0', width: '75%' }}>
      {renderSliderTicks(max)}
      <Slider
        min={min}
        max={max}
        step={step}
        value={value}
        showValue={false}
        disabled={disabled}
        onChange={onChange}
        className="styledSlider"
      />
      <style>{sliderStyles}</style>
    </div>
  )
}

function renderSliderTicks(numTicks: number) {
  const tickNumbers = Array(numTicks)
    .fill(0)
    .map((_, idx) => idx)
  const increment = 95 / (numTicks - 1)

  const ticks = tickNumbers.map(tickNum => {
    const left = tickNum * increment
    return (
      <div
        key={tickNum}
        style={{
          height: '4px',
          width: '4px',
          backgroundColor: SharedColors.gray10,
          borderColor: SharedColors.gray10,
          position: 'absolute',
          top: '16px',
          bottom: '0',
          left: `${left}%`,
          zIndex: '10',
        }}
      ></div>
    )
  })

  return (
    <div
      style={{
        display: 'inline-flex',
        justifyContent: 'space-between',
        padding: '0 0.25em',
        position: 'relative',
        width: '100%',
      }}
    >
      {ticks}
    </div>
  )
}

export default StyledSlider
