import { ActionButton } from '@fluentui/react'

interface Props {
  renderBackIcon: boolean
  onClick?: () => void
  href?: string
}

const BackButton = (props: Props) => {
  const { renderBackIcon, onClick, href } = props

  return (
    <ActionButton
      href={href}
      onClick={onClick}
      iconProps={
        renderBackIcon
          ? {
              iconName: 'Back',
              style: {
                marginTop: '0.2em',
              },
            }
          : { iconName: 'Back' }
      }
      style={{ verticalAlign: 'bottom' }}
      ariaLabel="Go back"
    />
  )
}

export default BackButton
