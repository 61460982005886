import { type FormEvent } from 'react'
import { Dropdown, type IDropdownOption, ResponsiveMode } from '@fluentui/react'

import { Contract } from '@blaw/contracts-api-schema'

interface Props {
  contractStatusIdx?: string
  setSelectedStatusIdx: React.Dispatch<React.SetStateAction<string>>
  disabled: boolean
}

const ContractStatusMenu = (props: Props) => {
  const { contractStatusIdx, setSelectedStatusIdx, disabled } = props
  const statusItems = Contract.contractStatus.getValues()
  const placeholder = contractStatusIdx
    ? Contract.contractStatus.getLabel(contractStatusIdx) || 'Select one'
    : 'Select one'
  const options: IDropdownOption[] = statusItems.map(item => ({
    key: item.value,
    text: item.label,
  }))

  const onChange = (
    _event: FormEvent<HTMLDivElement>,
    item: IDropdownOption<any> | undefined,
  ): void => {
    if (item) setSelectedStatusIdx(item.key.toString())
  }

  return (
    <Dropdown
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      responsiveMode={ResponsiveMode.unknown}
      dropdownWidth="auto"
      disabled={disabled}
    />
  )
}

export default ContractStatusMenu
