import { MessageBar, MessageBarType } from '@fluentui/react'

interface Props {
  actionMessage?: string
  errorMessage?: string
  msgType?: keyof typeof MessageBarType
}

export default function ClauseMessage(props: Props) {
  const { actionMessage, errorMessage, msgType = 'success' } = props

  if (msgType === 'success' && !actionMessage) return null
  if (msgType !== 'success' && !errorMessage) return null

  return (
    <MessageBar messageBarType={MessageBarType[msgType]}>
      {actionMessage || errorMessage}
    </MessageBar>
  )
}
