import { useContext, Context, FC } from 'react'
import { ActionButton, FontWeights, PrimaryButton } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'

import TopNav from '@components/TopNav'
import { pluralizedText } from '@modules/utils'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { FacetListItem, FilterState } from '@modules/ClauseAnalyzer'
import { useTranslation } from '@hooks/useTranslation'
import FacetGroups from '@components/FacetGroups'
import useSortedFacetGroups from '@hooks/useSortedFacetGroups'

export type FilterContext = {
  filterType: string
  title: string
  path: string
  sortOrder: string[]
  facetLabels: { [key: string]: string }
  loading: boolean
  handleFiltering: (facetName: string, facetItem: string, checked: boolean) => void
  cancelFilters: () => void
  filterState: FilterState
  numFilters: () => number
  items: { facets: FacetListItem[]; count: number }
}

type Props = {
  // TODO: figure out how to strongly type this context so that it can take other contexts; error when passing in SimilarClausesContext/ContractsContext
  context: Context<any>
}

const FilterMenu: FC<Props> = (props: Props) => {
  const navigate = useNavigate()
  const contextVals = useContext(props.context)
  const {
    filterType,
    title,
    path,
    sortOrder,
    facetLabels,
    cancelFilters,
    filterState,
    numFilters,
    loading,
    items: { facets, count },
  } = contextVals
  const { t } = useTranslation()
  const sortedFacets = useSortedFacetGroups(facets, facetLabels, filterState, sortOrder)

  useContractTaskPaneViewed({ pageTitle: title })

  return (
    <div
      className="FilterMenu"
      style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
    >
      <TopNav title={title} prevPath={`#${path}`} showCloseIcon />
      <FacetGroups
        group={sortedFacets}
        facetLabels={facetLabels}
        filterState={filterState}
        context={props.context}
        loading={loading}
      />
      {renderBottomButtons()}
    </div>
  )

  function renderBottomButtons() {
    return (
      <footer
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          padding: '1em 0',
          backgroundColor: 'white',
        }}
      >
        <ActionButton
          style={{ fontWeight: FontWeights.semibold }}
          disabled={loading || !hasFilters()}
          onClick={() => {
            cancelFilters()
          }}
        >
          Clear {pluralizedText(numFilters(), 'Filter')}
        </ActionButton>

        <MainButton />
      </footer>
    )
  }

  function MainButton() {
    const text = loading ? t('label.loading') : `Show ${pluralizedText(count ?? 0, filterType)}`

    return <PrimaryButton onClick={() => navigate(path)}>{text}</PrimaryButton>
  }

  function hasFilters() {
    return Object.values(filterState as FilterState).some(filterArray => filterArray.length)
  }
}

export default FilterMenu
