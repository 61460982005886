import { type AxiosProgressEvent } from 'axios'

import { MIMETYPE_DOCX } from '@blaw/contracts-api-schema'
import ApiClient from './ApiClient'
import { ResourceUploadLink, S3Fields } from './GetResourceUploadLink'
import Routes from './routes'
import { CreateContractParams } from './Contract'
import { AddNewTemplateParams } from './Template'

const apiClient = new ApiClient()
const routes = new Routes()

export type PostContractResponse = {
  contractId: string
  docId: string
  version: string
}

export type PostDocumentResponse = {
  id: string
  version: string
}

export type PostTemplateResponse = {
  id: string
  version: string
}

export async function PostDocumentToS3(
  signedS3LinkData: ResourceUploadLink,
  file: Blob,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  }
  return await apiClient.publicPost(
    signedS3LinkData.url,
    buildFormData(file, signedS3LinkData.fields),
    config,
  )
}

function buildFormData(file: Blob, fields: S3Fields) {
  const payload = { ...fields, 'Content-Type': MIMETYPE_DOCX, file }

  return Object.entries(payload).reduce((data, [k, v]) => {
    data.append(k, v)
    return data
  }, new FormData())
}

export async function postContractToApi(body: any) {
  const url = routes.createContractUrl
  const { data } = await apiClient.post<PostContractResponse>(url, body)
  return data
}

export async function postTemplateToApi(body: any) {
  const url = routes.resourcesUrl
  const { data } = await apiClient.post<PostTemplateResponse>(url, body)
  return data
}

export async function postAddDocumentToApi(body: any) {
  const url = routes.resourcesUrl
  const { data } = await apiClient.post<PostDocumentResponse>(url, body)
  return data
}

export function formatAddDocumentBody(s3Key: string, params: CreateContractParams) {
  return {
    s3Key,
    metadata: params.metadata,
    name: params.name,
    resourceType: 'document',
    mimeType: MIMETYPE_DOCX,
    parentId: params.parentId,
  }
}

export function formatCreateContractBody(params: CreateContractParams, s3Key?: string) {
  return {
    contract: {
      ...params,
      ...(s3Key && { s3Key }),
      mimeType: MIMETYPE_DOCX,
    },
  }
}

export function formatCreateTemplateBody(s3Key: string, params: AddNewTemplateParams) {
  return { ...params, s3Key: s3Key }
}
