/*
If a file has already been retrieved using Office.getFileAsync, attempting to retrieve it again before closing it with File.closeAsync will result in a "Memory Limit Exceeded" error.
FunctionBlocker was introduced to prevent this error by following the singleton design pattern and keeping track of whether it is currently executing a function.
If it is currently executing a function when a new function request is made, it blocks and queues the new function. Otherwise, it executes the new function.
*/

export default class FunctionBlocker {
  private static instance: FunctionBlocker
  private runningFunction: string
  private blockedFunctionQueue: [string, () => Promise<void>, (value: void) => void, () => void][]

  private constructor() {
    this.runningFunction = ''
    this.blockedFunctionQueue = []
  }

  public static getInstance(): FunctionBlocker {
    if (!FunctionBlocker.instance) {
      FunctionBlocker.instance = new FunctionBlocker()
    }
    return FunctionBlocker.instance
  }

  getCurrFuncName() {
    return this.runningFunction
  }

  isBlocked(functionName: string) {
    return this.blockedFunctionQueue.some(([k, _]) => k === functionName)
  }

  blockableFunction(functionName: string, func: () => Promise<any>) {
    return new Promise<void>((resolve, reject) => {
      console.debug('[FunctionBlocker] isRunning:', this.runningFunction)
      if (this.runningFunction) {
        console.debug('[FunctionBlocker] blocked', functionName)
        if (!this.blockedFunctionQueue.some(([k, _]) => k === functionName)) {
          this.blockedFunctionQueue.push([functionName, func, resolve, reject])
          console.debug('[FunctionBlocker] pushed', functionName)
          console.debug(
            '[FunctionBlocker] blocked functions',
            this.blockedFunctionQueue.map(([k, _]) => k),
          )
        } else {
          resolve()
        }
      } else {
        console.debug('[FunctionBlocker] go ahead', functionName)
        this.executeFunction(functionName, func, resolve, reject)
      }
    })
  }

  async executeFunction(
    functionName: string,
    func: () => Promise<any>,
    resolve: (value: void) => void,
    reject: () => void,
  ) {
    this.runningFunction = functionName
    func()
      .catch(e => {
        console.error(e)
        reject()
      })
      .finally(() => {
        console.debug('[FunctionBlocker] done', functionName)
        resolve()
        this.runningFunction = ''
        const [blockedFuncName, blockedFunc, blockedResolve, blockedReject] =
          this.blockedFunctionQueue.shift() ?? []
        if (blockedFuncName && blockedFunc && blockedResolve && blockedReject) {
          console.debug('[FunctionBlocker] next', blockedFuncName)
          this.executeFunction(blockedFuncName, blockedFunc, blockedResolve, blockedReject)
        }
      })
  }
}
