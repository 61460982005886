import { useState } from 'react'
import { Label, mergeStyles, MessageBar, NeutralColors, Stack, TextField } from '@fluentui/react'

import StyledStack from '@components/StyledStack'
import TopNav from '@components/TopNav'
import guidance from '@data/guidance'
import CollapsibleItem from '@components/CollapsibleItem'
import { navigateToExternalSite } from '@modules/utils'
import { navClicked, searchSubmitted, useContractTaskPaneViewed } from '@modules/analytics'
import UnstyledList from '@baseComponents/UnstyledList'
import LinkButton from '@components/LinkButton'

mergeStyles({
  ':global(.subTopic)': {
    paddingBottom: '0.6em',
  },
  ':global(.subTopic:last-child)': {
    paddingBottom: 0,
  },
})

interface GuidanceLink {
  link: string
  label: string
  name: string
  links: GuidanceLink[]
}

interface GuidanceTopic {
  name: string
  active: boolean
  links: GuidanceLink[]
  key: string
}

const pageTitle = 'Practical Guidance'

export default function PracticalGuidance() {
  const [filteredTopics, setFilteredTopics] = useState(initialTopics)

  useContractTaskPaneViewed({ pageTitle })

  return (
    <div>
      <TopNav title={pageTitle} />

      <MessageBar>
        Need help while drafting your document?
        <br /> Here are some helpful links to Bloomberg Law Practical Guidance.
      </MessageBar>

      <StyledStack style={{ paddingTop: '0.3em' }}>
        <Stack.Item grow={1}>
          <Label>Search Topics</Label>
        </Stack.Item>
        <Stack.Item grow={1} style={{ marginBottom: '0.5em' }}>
          <TextField
            aria-label="Search Topics"
            placeholder="Enter Keywords"
            iconProps={{ iconName: 'Search' }}
            autoComplete="off"
            onChange={e => {
              handleSearch(e.currentTarget.value)
              searchSubmitted({
                isLoggedIn: true,
                pageTitle,
                searchScope: pageTitle,
                searchAction: 'initial',
                searchTool: 'filter',
                searchTermsExist: true,
                searchType: 'feature search',
                keywords: e.currentTarget.value,
              })
            }}
            autoFocus
          />
        </Stack.Item>
        <Stack.Item style={{ padding: 0 }}>
          <UnstyledList>
            {filteredTopics.map(topic => {
              return (
                <CollapsibleItem
                  key={topic.name + topic.links.length + topic.active}
                  item={topic}
                  itemHeader={topic => topic.name}
                  itemContent={renderTopicContent}
                />
              )
            })}
          </UnstyledList>
        </Stack.Item>
      </StyledStack>
    </div>
  )

  function renderLink({ label, link }: GuidanceLink) {
    return (
      <LinkButton
        key={link}
        iconName="OpenInNewTab"
        onClick={() => {
          navigateToExternalSite(link)
          navClicked({
            isLoggedIn: true,
            pageTitle,
            navGroup: '',
            navItem: label,
            navType: 'Right Bar',
          })
        }}
        buttonStyles={{
          root: {
            margin: '0 0.3em',
            padding: '0.5em 0.3em',
            textAlign: 'left',
            width: '100%',
            display: 'block',
          },
          rootHovered: { backgroundColor: NeutralColors.gray20 },
        }}
      >
        {label}
      </LinkButton>
    )
  }

  function renderTopicContent(topic: GuidanceTopic) {
    return (
      <UnstyledList style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.6em' }}>
        {topic.links.map(link =>
          link.links ? renderSubTopic(link.name, link.links) : renderLink(link),
        )}
      </UnstyledList>
    )
  }

  function renderSubTopic(subtopicCategory: string, links: GuidanceLink[]) {
    return (
      <div
        className="subTopic"
        key={subtopicCategory}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Label>{subtopicCategory}</Label>
        <UnstyledList>{links.map(link => renderLink(link))}</UnstyledList>
      </div>
    )
  }

  function initialTopics(): GuidanceTopic[] {
    const deepCopy = JSON.parse(JSON.stringify(guidance)) as GuidanceTopic[]
    return deepCopy.map(topic => ({ ...topic, active: false }))
  }

  /*
  If the topic has no subtopics:
  - Show all matching labels under the topic
  - If there are no matching labels but the topic matches, show all labels under the topic

  If the topic has subtopics:
  - Show all matching labels
  - If there are no matching labels but the subtopic matches, show the subtopic and all labels under the subtopic (e.g. "securities" for Corporate Transactions)
  - If there are no matching labels or subtopics but the topic matches, show all subtopics and their labels (e.g. "corporate" for Corporate Transactions)
  */

  function filterTopics(query: string, topicName: string, links: GuidanceLink[]) {
    const matchTopicName = topicName.toLowerCase().includes(query)
    const filteredTopicLinks = links.filter(link => {
      if (link.label) {
        return link.label.toLowerCase().includes(query)
      } else {
        const filteredSubTopicLinks = link.links.filter(link =>
          link.label.toLowerCase().includes(query),
        )
        const matchOnlySubTopicName =
          link.name.toLowerCase().includes(query) && !filteredSubTopicLinks.length
        link.links =
          matchOnlySubTopicName || (!filteredSubTopicLinks.length && matchTopicName)
            ? link.links
            : filteredSubTopicLinks
        return link.links.length
      }
    })
    const matchOnlyTopicName = matchTopicName && !filteredTopicLinks.length
    const filteredResult = matchOnlyTopicName ? links : filteredTopicLinks
    return filteredResult
  }

  function handleSearch(query: string) {
    const formattedQuery = query.toLowerCase().trim()

    if (!formattedQuery.length) {
      setFilteredTopics(initialTopics())
      return
    }
    const filtered = initialTopics().filter(topic => {
      topic.links = filterTopics(formattedQuery, topic.name, topic.links)
      const matches = topic.links.length
      if (matches) topic.active = true
      return matches
    })
    setFilteredTopics(filtered)
  }
}
