export default function LocalStorage(): Storage {
  try {
    return window.localStorage
  } catch (e) {
    console.error(e)

    return {
      key: () => '',
      length: 0,
      clear: () => null,
      getItem: () => {
        console.warn('getItem called on null Storage')
        return ''
      },
      setItem: () => {
        console.warn('setItem called on null Storage')
      },
      removeItem: () => {
        console.warn('removeItem called on null Storage')
      },
    }
  }
}
