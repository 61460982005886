import { useContext } from 'react'
import { FontWeights, Icon } from '@fluentui/react'

import ShowMore from '@components/ShowMore'
import { parseKeyTermValue } from '@modules/KeyTermValueParsers'
import { KeyTerm } from '@modules/KeyTerm'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import DetailBadge from '../DetailBadge'
import { MetadataDetail } from '@modules/ContractDetail'
import ViewExtractedDataPointBtn from '@components/ViewExtractedDataPointBtn'
import { ContractContext } from '@contexts/ContractContext'

interface Props {
  keyTerm: KeyTerm
  detail?: MetadataDetail
  enableEdit?: boolean
}

const showMoreStyles = {
  fontSize: '1.075em',
}

const KeyTermItem = ({ keyTerm, detail, enableEdit }: Props) => {
  const { deleteKeyTermModalActive, setSelectedKeyTerm, toggleEditKeyTermModal } =
    useContext(KeyTermsContext)
  const { contract } = useContext(ContractContext)

  const editKeyTermIcon = !deleteKeyTermModalActive && (
    <Icon
      iconName="Edit"
      style={{
        display: enableEdit ? 'inline-block' : 'none',
        marginLeft: '0.5em',
        fontSize: '1.1em',
        cursor: 'pointer',
      }}
      role="button"
      aria-label="editIcon"
      onClick={() => {
        setSelectedKeyTerm(keyTerm)
        toggleEditKeyTermModal()
      }}
    />
  )

  return (
    <li style={{ display: 'flex', flexDirection: 'column', marginBottom: '2em' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ fontWeight: FontWeights.semibold, fontSize: '1.15em' }}>
          {keyTerm.label}
        </span>
        {editKeyTermIcon}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ShowMore content={parseKeyTermValue(keyTerm)} maxLength={142} style={showMoreStyles} />
        <ViewExtractedDataPointBtn label={keyTerm.label} detail={detail} contract={contract} />
      </div>
      <DetailBadge detail={detail} />
    </li>
  )
}

export default KeyTermItem
