import { useContext } from 'react'

import KeyTermFormFooter from '@components/KeyTermFormFooter'
import KeyTermItem from '@components/KeyTermItem'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import FormDialog from '@components/FormDialog'
import useKeyTermForm from '@hooks/useKeyTermForm'
import ErrorMessage from '@components/ErrorMessage'
import { ContractContext } from '@contexts/ContractContext'

const DeleteKeyTermForm: React.FC = () => {
  const {
    formValid,
    selectedKeyTerm,
    deleteKeyTermModalActive,
    submittingKeyTerm,
    metadataConfig,
  } = useContext(KeyTermsContext)
  const { deleteKeyTerm, contract } = useContext(ContractContext)
  const { handleDeleteKeyTerm, formError } = useKeyTermForm()

  const footerBtnLabel = submittingKeyTerm ? 'Deleting...' : 'Delete'
  const footerBtnTitle = submittingKeyTerm ? 'Deleting Key Term' : 'Delete Key Term'

  if (!(deleteKeyTermModalActive && contract && selectedKeyTerm && metadataConfig)) return null

  return (
    <FormDialog title="Delete Key Term">
      <form
        onSubmit={e =>
          handleDeleteKeyTerm(
            contract.deleteKeyTerm(selectedKeyTerm, metadataConfig.value),
            e,
            deleteKeyTerm,
          )
        }
      >
        <ErrorMessage message={formError} />
        <KeyTermItem keyTerm={selectedKeyTerm} />
        <KeyTermFormFooter
          formValid={formValid}
          primaryBtnLabel={footerBtnLabel}
          primaryBtnTitle={footerBtnTitle}
          disabled={submittingKeyTerm}
        />
      </form>
    </FormDialog>
  )
}

export default DeleteKeyTermForm
