import BoldText from '@baseComponents/BoldText'

interface Props {
  label: string
  value?: string
}

export default function BoldField(props: Props) {
  const { label, value } = props

  if (!value) return null

  return (
    <div>
      <BoldText>{label}</BoldText>
      {value}
    </div>
  )
}
