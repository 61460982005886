import * as React from 'react'
import { FormEvent, useState } from 'react'
import { ActionButton, ITextFieldProps, Label } from '@fluentui/react'

import StyledTextField from '@components/StyledTextField'
import { entries } from '@modules/utils'

export interface MultiTextFieldProps extends ITextFieldProps {
  values?: string[]
  onJoinValues: (e: FormEvent<HTMLInputElement>, values: string[]) => void
  label: string
  required?: boolean
  disable?: boolean
  name: string
  placeholder?: string
}

type ValueStates = Record<string, string>

// Renders multiple text fields for array type contract metadata fields
const MultiTextFields: React.FC<MultiTextFieldProps> = props => {
  const { name, values = [''], onJoinValues, label, required, disable, placeholder } = props
  const [valueStates, setValueStates] = useState(buildValueStates())
  const [focus, setFocus] = useState(false)

  return (
    <>
      <Label htmlFor={`${name}-0`} required={required}>
        {label}
      </Label>
      {renderFields()}
      <ActionButton
        iconProps={{ iconName: 'Add' }}
        onClick={addAnother}
        disabled={someValueEmpty()}
      >
        Add {label}
      </ActionButton>
    </>
  )

  function buildValueStates(): ValueStates {
    const ensureAtleastEmptyStr = !values.length ? [''] : [...values]

    return ensureAtleastEmptyStr.reduce((states, value, i) => {
      states[`${name}-${i}`] = value
      return states
    }, {} as ValueStates)
  }

  function renderFields() {
    return entries(valueStates).map(([key, value], i) => {
      let marginBottom = ''

      if (i === 0) {
        marginBottom = '0.45em'
      } else if (i < numValues() - 1) {
        marginBottom = '0.23em'
      }

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom,
          }}
          key={key}
        >
          <StyledTextField
            id={`${name}-${i}`}
            name={key}
            value={value}
            onChange={joinValues}
            autoFocus={focus}
            styles={{
              root: {
                flexGrow: 1,
              },
            }}
            disabled={disable}
            placeholder={placeholder}
          />

          <ActionButton
            iconProps={{ iconName: 'cancel' }}
            title="Remove this field"
            onClick={() => removeValue(key)}
            style={{ display: i > 0 ? 'block' : 'none' }}
          />
        </div>
      )
    })
  }

  function joinValues(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) {
    if (!value) value = ''

    const newValues = { ...valueStates, [e.currentTarget.name]: value }
    const newValue: string[] = Object.values(newValues)

    setValueStates(newValues)
    onJoinValues({ currentTarget: { name } } as FormEvent<HTMLInputElement>, newValue)
  }

  function addAnother() {
    setFocus(true)
    setValueStates({ ...valueStates, [`${name}-${numValues()}`]: '' })
  }

  function numValues() {
    return entries(valueStates).length
  }

  function removeValue(key: string) {
    delete valueStates[key]
    setValueStates({ ...valueStates })
  }

  function someValueEmpty() {
    return entries(valueStates).some(([_, value]) => value.length === 0)
  }
}

export default MultiTextFields
