import { useLocation } from 'react-router'
import { Stack } from '@fluentui/react'

import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import { useContractTaskPaneViewed } from '@modules/analytics'
import ReactMarkdown from 'react-markdown'
import ContractTitle from '@baseComponents/ContractTitle'
import { useTranslation } from '@hooks/useTranslation'

const pageTitle = 'Playbooks'

export default function PlaybooksComplianceSummary() {
  const { state } = useLocation()
  const playbooksResponse = state.response.value
  const { t } = useTranslation()
  const subtitle = t('page.Playbooks Compliance Summary.subtitle')

  useContractTaskPaneViewed({ pageTitle: `${pageTitle} ${subtitle}` })

  return (
    <>
      <TopNav title={pageTitle} prevPath="#/playbooks/compliance" showAIGeneratedBadge />
      <StyledStack>
        <ContractTitle title={subtitle} style={{ margin: 0 }} />
        <Stack.Item>
          <ReactMarkdown>{playbooksResponse}</ReactMarkdown>
        </Stack.Item>
      </StyledStack>
    </>
  )
}
