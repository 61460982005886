export default function UnsupportedError() {
  return (
    <div
      style={{
        padding: '1em',
      }}
    >
      <p>Something went wrong. Please ensure that you are using a supported version of Word.</p>
      <p>Versions Supported:</p>
      <ul style={{ paddingLeft: '1.3em' }}>
        <li>Word 2016 or later on Mac</li>
        <li>Word 2019 or later on Windows</li>
        <li>Word 2019 or later on Mac</li>
        <li>Word on the web</li>
        <li>Word on Mac (Microsoft 365)</li>
        <li>Word on Windows (Microsoft 365)</li>
      </ul>
    </div>
  )
}
