import { PropsWithChildren, ReactNode } from 'react'
import {
  ActivityItem,
  FontSizes,
  IActivityItemStyles,
  Icon,
  SharedColors,
  Text,
} from '@fluentui/react'
import { t } from 'i18next'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import BoldText from '@baseComponents/BoldText'
import LastSeen from '@components/LastSeen'
import { selectSectionInDocument } from '@modules/wordDocument'

export type ChatMessageProps = PropsWithChildren & {
  from?: string
  activity?: string | ReactNode | ReactNode[]
  timeStamp?: Date
  markdown?: string
  isCompact?: boolean
  iconName?: string
  iconSize?: string
  styles?: IActivityItemStyles
  isContractChatXmlInputEnabled?: boolean
}

export const chatbotName = t('page.Chat with Contract.Chatbot Name')

export default function ChatMessage({
  styles,
  children,
  isCompact,
  timeStamp,
  markdown,
  from = chatbotName,
  activity = 'commented',
  iconName = 'ChatBot',
  iconSize = FontSizes.xxLarge,
  isContractChatXmlInputEnabled,
}: ChatMessageProps) {
  const date = typeof timeStamp === 'string' ? new Date(Date.parse(timeStamp)) : timeStamp

  const transformHyperlinks = (text?: string) => {
    return text?.replace(/\|([^|]*<paragraph idx=\d+>[^|]*)\|/g, (_, reference) => {
      return `<button data-reference="${reference}">${reference}</button>`
    })
  }
  const transformedMarkdown = transformHyperlinks(markdown)

  return (
    <ActivityItem
      activityIcon={
        <Icon iconName={iconName} style={{ fontSize: iconSize, color: SharedColors.cyanBlue10 }} />
      }
      activityDescription={
        <>
          <BoldText>{from} </BoldText>
          <Text variant="small">{activity}</Text>
        </>
      }
      comments={renderComments()}
      timeStamp={date && <LastSeen date={date} />}
      isCompact={isCompact}
      style={{ marginBottom: '1em' }}
      styles={styles}
    />
  )

  function renderComments() {
    if (markdown)
      return (
        <Text aria-label="chat message">
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
              p: 'span',
              button: ({ ...props }) => (
                <button
                  {...props}
                  onClick={event => {
                    event.preventDefault()

                    const reference = (props as any)['data-reference']
                    const match = reference.match(/idx=(\d+)/)
                    if (match) {
                      const paragraphIdx = parseInt(match[1])
                      selectSectionInDocument(paragraphIdx)
                    } else {
                      throw new Error('idx not found in the text')
                    }
                  }}
                  style={{
                    background: 'none',
                    color: 'blue',
                    textDecoration: 'underline',
                    border: 'none',
                    padding: 0,
                    cursor: 'pointer',
                    font: 'inherit',
                  }}
                />
              ),
            }}
          >
            {isContractChatXmlInputEnabled ? transformedMarkdown : markdown}
          </ReactMarkdown>
        </Text>
      )

    return <Text aria-label="chat message">{children}</Text>
  }
}
