import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

import { fetchDocumentAnalysis } from '@modules/DocumentAnalysisAsync'
import { AnalysisToolsContext } from './AnalysisToolsContext'
import useFunctionBlocker from '@hooks/useFunctionBlocker'
import { textChanged } from '@modules/differ'

export type Issue = {
  block_id: string
  text: string
  length: number
  offset: number
  paragraph_index: number
}
type SectionIssueType = 'gap' | 'duplication' | 'invalid_start'
export type SectionIssueListItem = {
  key: SectionIssueType
  issues: SectionIssuesList
  active: boolean
}
type SrfAnalysis = {
  issues: SectionIssueListItem[]
}
type DuplicationIssueValue = Issue[][]
type GapIssuesValue = GapIssue[]
export type GapIssue = { begin: Issue; end: Issue }
export type InvalidStartIssuesValue = Issue[]
type SectionIssuesList = DuplicationIssueValue | GapIssuesValue | InvalidStartIssuesValue

type Props = PropsWithChildren

export interface SectionIssuesContextState {
  error?: string
  loading: boolean
  spinner: boolean
  issues: SectionIssueListItem[]
  numIssues?: number
  callLoadIssues: () => Promise<void>
  setIssues: Dispatch<SetStateAction<SectionIssueListItem[]>>
  setError: Dispatch<SetStateAction<string | undefined>>
  clearIssues: () => void
}

export const SectionIssuesContext = createContext({} as SectionIssuesContextState)

const SectionIssuesContextProvider: FC<Props> = ({ children }) => {
  const [numIssues, setNumIssues] = useState<number>()
  const [issues, setIssues] = useState<SectionIssueListItem[]>([])
  const [loading, setLoading] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [error, setError] = useState<string>()
  const { setAnalysisIsStale, docBodyText, setDocBodyText, getDocBodyText } =
    useContext(AnalysisToolsContext)
  const { DocumentSelectionChanged } = Office.EventType
  const { addHandlerAsync, removeHandlerAsync } = Office.context.document
  const { blockableFunction } = useFunctionBlocker()

  const setIssuesFromApi = (data: any) => {
    const parsed = parseIssues(data)
    const count = issueCount(parsed)

    if (!sanityCheck(count)) {
      console.warn(`SRF sanity check failed. Num issues: ${count}`)
      setNumIssues(undefined)
      setIssues([])
      return
    }

    setIssues(mergeIssuesState(issues, parsed))
    setNumIssues(count)
  }

  async function checkSelection() {
    if (loading) return

    const newText = await getDocBodyText()
    setAnalysisIsStale(textChanged(newText, docBodyText))
  }

  useEffect(() => {
    addHandlerAsync(DocumentSelectionChanged, checkSelection)
    return () => {
      removeHandlerAsync(DocumentSelectionChanged, { handler: checkSelection })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docBodyText, loading])

  const value = {
    error,
    setError,
    loading,
    spinner,
    issues,
    numIssues,
    callLoadIssues,
    setIssues,
    clearIssues,
  }
  return <SectionIssuesContext.Provider value={value}>{children}</SectionIssuesContext.Provider>

  async function callLoadIssues() {
    setLoading(true)
    setSpinner(true)
    setError(undefined)
    await blockableFunction('Issues', loadIssues)
  }

  async function loadIssues() {
    try {
      const {
        analysis: { issues },
      } = await fetchDocumentAnalysis<SrfAnalysis>('srf')
      const newText = await getDocBodyText()
      setDocBodyText(newText)
      setIssuesFromApi(issues)
    } catch (e) {
      console.error(e)
      setError('Failed to get Section Issues. Please try again.')
    } finally {
      setSpinner(false)
      setLoading(false)
    }
  }

  function clearIssues() {
    setIssuesFromApi([])
    setAnalysisIsStale(false)
  }
}

export default SectionIssuesContextProvider

export function parseIssues(data: any): SectionIssueListItem[] {
  return Object.entries(data).map(([key, issues]) => ({
    key: key as SectionIssueType,
    issues: issues as SectionIssuesList,
    active: false,
  }))
}

function mergeIssuesState(
  currentIssues: SectionIssueListItem[],
  incomingIssues: SectionIssueListItem[],
) {
  return incomingIssues.reduce((merged, incomingIssue) => {
    const currentIssue = currentIssues.find(({ key }) => key === incomingIssue.key)

    if (currentIssue) incomingIssue.active = currentIssue.active
    merged.push(incomingIssue)

    return merged
  }, [] as SectionIssueListItem[])
}

function sanityCheck(count: number) {
  return count <= 5
}

function issueCount(parsedIssues: SectionIssueListItem[]) {
  return parsedIssues.reduce((sum, { issues }) => sum + issues.length, 0)
}
