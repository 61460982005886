import { useEffect, useState } from 'react'
import ApiClient from '@modules/ApiClient'

const apiClient = new ApiClient()

export function useCheckUserLoginStatus(hasSessionId = false) {
  const [loggedIn, setLoggedIn] = useState(hasSessionId)
  const [checkingLoginStatus, setCheckingLoginStatus] = useState(true)

  useEffect(() => {
    setCheckingLoginStatus(true)

    apiClient
      .refresh(true)
      .then(() => {
        setLoggedIn(true)
      })
      .catch(e => {
        console.error(e)
        setLoggedIn(false)
      })
      .finally(() => setCheckingLoginStatus(false))
  }, [])

  return { loggedIn, setLoggedIn, checkingLoginStatus }
}
