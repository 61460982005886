import * as React from 'react'

import StyledTextField from '@components/StyledTextField'
import useKeyTermForm from '@hooks/useKeyTermForm'
import MultiTextFields from '@components/MultiTextFields'
interface Props {
  keyTermType: string
}

const MultiTextKeyTermForm = (props: Props) => {
  const { keyTermType } = props
  const { newKeyTerm, updateKeyTermNotes, updateMultiTextField } = useKeyTermForm(
    keyTermType,
    'MULTI_TEXT',
  )
  const data = newKeyTerm?.value

  if (!Array.isArray(data)) return <></>

  return (
    <>
      <MultiTextFields
        required={true}
        name={keyTermType}
        label={keyTermType}
        values={data}
        onJoinValues={updateMultiTextField}
      />
      <StyledTextField
        label="Notes"
        value={newKeyTerm?.data ? newKeyTerm?.data.notes : ''}
        name="notes"
        onChange={updateKeyTermNotes}
        multiline
      />
    </>
  )
}

export default MultiTextKeyTermForm
