import {
  SearchResult,
  type SearchResults,
  type constraintsType,
  type searchKeyWordArgs,
} from '@blaw/contracts-api-schema'
import Clause from '@components/Clause'
import { PaginationMeta } from '@hooks/usePagination'
import ApiClient from '@modules/ApiClient'
import { ClauseForm } from '@modules/Clause'
import { FacetListItem, FilterState } from '@modules/ClauseAnalyzer'
import Routes from '@modules/routes'

export type ClausesResult = {
  clauses: Clause[]
  meta: PaginationMeta
  facets: FacetListItem[]
  warn?: string
  error?: string
}

export const ClausesPerPage = 20
const routes = new Routes()

export async function createClause(clause: ClauseForm, apiClient: ApiClient) {
  return await apiClient.post<{ clause: Clause }>(routes.clausesUrl, { clause })
}

export async function searchClauses(
  page: number,
  query: string,
  context: string,
  facets: string[],
  filters: FilterState,
  constraints: constraintsType[],
  apiClient: ApiClient,
) {
  const result = emptyClauseSearchResults()
  const params = buildClauseParams(page, query, context, facets, filters, constraints)

  const { data } = await apiClient.post<SearchResults>(routes.searchUrl, params)
  result.meta = {
    totalPages: Math.floor(data.totalHits / ClausesPerPage),
    totalHits: data.totalHits,
  }

  if (!data.results?.length) return result

  result.clauses = data.results.map(searchResultToClause)
  result.facets = data.facets.map(
    ({ name, values, type }) =>
      ({
        field: name,
        values: values.map(({ label, value, count }) => ({ label, value, count, type })),
      } as FacetListItem),
  )
  return result
}

function buildClauseParams(
  page: number,
  query: string,
  context: string,
  facets: string[],
  filters: FilterState,
  constraints: constraintsType[],
): searchKeyWordArgs {
  filters = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v.length))

  return {
    userQuery: query,
    systemQuery: [],
    facetMaxValuesPerFacet: 1000,
    facetSortOrder: 'COUNT_DESCENDING',
    context: context,
    sortField: {
      fieldName: query ? 'relevance' : 'last_updated_date',
      Direction: 'desc',
    },
    maxDigests: 0,
    facetsResultType: 'BY_NAME',
    offset: page * ClausesPerPage,
    maxResults: ClausesPerPage,
    facets: Object.entries(filters).map(
      entry => ({ key: entry[0], value: entry[1] } as constraintsType),
    ),
    ranges: [],
    fetchFields: [],
    facetsNames: facets,
    constraints: constraints,
  }
}

export function emptyClauseSearchResults(): ClausesResult {
  return {
    clauses: [],
    meta: { totalPages: 0, totalHits: 0 },
    facets: [],
  }
}

function searchResultToClause(searchResult: SearchResult): Clause {
  const { metadata } = searchResult
  return {
    title: searchResult.title,
    clauseType: metadata.clause_type?.value,
    clauseText: searchResult.clauseText,
    documentName: searchResult.documentName,
    contractType: metadata.contract_type?.value,
    dealSide: metadata.deal_side?.value,
    dateModified: metadata.last_updated_date?.value || '',
    dateCreated: searchResult.dateCreated || '',
    parentId: searchResult.parentId,
    riskLevel: metadata.risk_level?.value,
  }
}
