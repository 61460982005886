import { ActionButton, IButtonStyles, SharedColors } from '@fluentui/react'
import { MouseEventHandler } from 'react'

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>
  styles?: IButtonStyles
  text?: string
  showBtn?: boolean
}

export default function DeleteActionBtn({ onClick, styles, text, showBtn }: Props) {
  return (
    <ActionButton
      iconProps={{ iconName: 'Delete' }}
      styles={{
        rootHovered: { color: SharedColors.red10 },
        rootPressed: { color: SharedColors.red20 },
        icon: { color: SharedColors.red10 },
        iconHovered: { color: SharedColors.red10 },
        iconPressed: { color: SharedColors.red20 },
        ...styles,
      }}
      style={{ display: showBtn ? 'inline-block' : 'none' }}
      onClick={onClick}
    >
      {text || 'Delete'}
    </ActionButton>
  )
}
