import { createContext, useEffect, useState } from 'react'
import useDocumentSelection from '@hooks/useDocumentSelection'

interface Props {
  children?: JSX.Element | JSX.Element[]
  contractId: string
  loadContract: () => Promise<void>
}

interface KeyTermFormContextState {
  contractId: string
  loadContract: () => Promise<void>
  copySelectionDisabled: boolean
  formError: string
  setFormError: React.Dispatch<React.SetStateAction<string>>
  validationError: string
  setValidationError: React.Dispatch<React.SetStateAction<string>>
}

export const KeyTermFormContext = createContext({} as KeyTermFormContextState)

export default function KeyTermFormContextProvider(props: Props) {
  const selection = useDocumentSelection()

  const [copySelectionDisabled, setCopySelectionDisabled] = useState(true)
  const [formError, setFormError] = useState('')
  const [validationError, setValidationError] = useState('')

  useEffect(() => {
    setCopySelectionDisabled(!selection.length)
  }, [selection])

  const value = {
    contractId: props.contractId,
    loadContract: props.loadContract,
    copySelectionDisabled,
    formError,
    setFormError,
    validationError,
    setValidationError,
  }

  return <KeyTermFormContext.Provider value={value}>{props.children}</KeyTermFormContext.Provider>
}
