import * as React from 'react'
import { useContext } from 'react'
import {
  ActionButton,
  DialogFooter,
  IButtonStyles,
  PrimaryButton,
  SharedColors,
  Stack,
} from '@fluentui/react'
import { IDialogFooterStyles } from 'office-ui-fabric-react'

import { KeyTermsContext } from '@contexts/KeyTermsContext'
import DeleteIcon from '@baseComponents/DeleteIcon'

interface Props {
  formValid: boolean
  primaryBtnLabel: string
  primaryBtnTitle: string
  disabled: boolean
}

const dialogFooterStyles: IDialogFooterStyles = {
  actions: {
    lineHeight: 0,
  },
  action: '',
  actionsRight: '',
}

const primaryBtnDeleteStyles: IButtonStyles = {
  root: {
    backgroundColor: SharedColors.red10,
    borderColor: SharedColors.red10,
  },
  rootHovered: {
    backgroundColor: SharedColors.red20,
    borderColor: SharedColors.red20,
  },
  rootPressed: {
    backgroundColor: SharedColors.pinkRed10,
    borderColor: SharedColors.pinkRed10,
  },
}

const KeyTermFormFooter: React.FC<Props> = (props: Props) => {
  const { formValid, primaryBtnLabel, primaryBtnTitle, disabled } = props
  const {
    deleteKeyTermModalActive,
    editKeyTermModalActive,
    toggleDeleteKeyTermModal,
    toggleEditKeyTermModal,
    dismissKeyTermForm,
  } = useContext(KeyTermsContext)

  return (
    <DialogFooter styles={dialogFooterStyles}>
      <Stack
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 5fr',
          alignItems: 'center',
        }}
      >
        <Stack.Item
          style={{
            visibility: editKeyTermModalActive ? 'visible' : 'hidden',
            justifySelf: 'baseline',
          }}
        >
          <DeleteIcon
            title="Delete Key Term"
            onClick={() => {
              toggleDeleteKeyTermModal()
              toggleEditKeyTermModal()
            }}
            disabled={disabled}
          />
        </Stack.Item>
        <Stack.Item>
          <PrimaryButton
            type="submit"
            disabled={!formValid || disabled}
            title={primaryBtnTitle}
            styles={deleteKeyTermModalActive ? primaryBtnDeleteStyles : {}}
          >
            {primaryBtnLabel}
          </PrimaryButton>
          <ActionButton title="Cancel" onClick={dismissKeyTermForm}>
            Cancel
          </ActionButton>
        </Stack.Item>
      </Stack>
    </DialogFooter>
  )
}

export default KeyTermFormFooter
