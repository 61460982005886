import { CSSProperties, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  List,
  NeutralColors,
  IContextualMenuItem,
  FontWeights,
  mergeStyleSets,
} from '@fluentui/react'
import { t } from 'i18next'

import { StyledDivider } from '@baseComponents/StyledDivider'
import { PlaybookResource } from '@blaw/contracts-api-schema'
import ActionsMenu from '@components/ActionsMenu'
import BoldField from '@components/BoldField'
import LoadingShimmer from '@components/LoadingShimmer'
import QuickMessage from '@components/QuickMessage'
import { friendlyDateTime } from '@modules/utils'
import { DISPLAYED_RESOURCETYPES } from '@src/data/displayedResourceTypes'
import { PlaybooksContext } from '@contexts/PlaybooksContext'
import DeletePlaybookForm from '@components/DeletePlaybookForm'
import LinkButton from '@components/LinkButton'

type Props = {
  resources: PlaybookResource[]
  style?: CSSProperties
  onRenderResource?: (item?: PlaybookResource) => JSX.Element | null
}

const playbookActions: IContextualMenuItem[] = [
  {
    key: 'comply',
    text: t('button.Check Compliance'),
    iconProps: { iconName: 'WaitlistConfirm' },
  },
  {
    key: 'download',
    text: 'Download',
    iconProps: { iconName: 'Download' },
  },
  {
    key: 'edit',
    text: 'Edit',
    iconProps: { iconName: 'PageEdit' },
  },
  {
    key: 'delete',
    text: 'Delete',
    iconProps: { iconName: 'Delete' },
  },
]

export default function PlaybooksList({ resources, style }: Props) {
  const { error, loading, setDeleteModalHidden } = useContext(PlaybooksContext)
  const navigate = useNavigate()

  if (loading) return <LoadingShimmer />
  if (error) return <QuickMessage msg={error} type="error" />

  return (
    <div style={{ marginBottom: '2em' }}>
      <List
        items={resources}
        onShouldVirtualize={() => false}
        onRenderCell={item => onRenderResource(item)}
      />
    </div>
  )

  function onRenderResource(item?: PlaybookResource) {
    if (!item) return null
    if (!DISPLAYED_RESOURCETYPES.includes(item.resourceType)) return null

    const newCompliancePath = `/playbooks/${item.playbookId}/compliance/new?title=${item.playbookTitle}&type=${item.metadata.userMetadata.contract_type}&role=${item.metadata.userMetadata.playbook_party_role}`

    return (
      <>
        <div
          style={{
            padding: '0.5em 0 1em 0',
            ...style,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <LinkButton
              href={`#${newCompliancePath}`}
              iconName="WaitlistConfirm"
              buttonStyles={{
                root: { fontWeight: FontWeights.semibold },
              }}
            >
              {item.playbookTitle}
            </LinkButton>

            <div className={additionalStyles.hoverStyle}>
              <ActionsMenu
                items={playbookActions}
                onItemClick={onItemClick}
                iconStyles={{
                  border: `1px solid ${NeutralColors.gray60}`,
                  borderRadius: '0.2em',
                  padding: '0.1em 0.2em',
                }}
              />
            </div>
          </div>

          <BoldField
            label={`${t('label.ContractMetadata.last-modified')}: `}
            value={item.dateModified ? `${friendlyDateTime(item.dateModified)}` : ''}
          />
        </div>
        <DeletePlaybookForm id={item.playbookId} name={item.playbookTitle} />
        <StyledDivider />
      </>
    )

    function onItemClick(action: IContextualMenuItem) {
      switch (action.key) {
        case 'comply':
          navigate(newCompliancePath)
          break
        case 'download':
          console.error('Playbook download click unimplemented')
          break
        case 'edit':
          console.error('Playbook edit click unimplemented')
          break
        case 'delete':
          setDeleteModalHidden(false)
          break
        default:
          throw Error(`Invalid playbook action: ${action.key}`)
      }
    }
  }
}

const additionalStyles = mergeStyleSets({
  hoverStyle: {
    selectors: {
      i: {
        ':hover': {
          background: NeutralColors.gray30,
        },
      },
    },
  },
})
