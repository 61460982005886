import { useState } from 'react'

const AUTO_SHOW_KEY = 'Office.AutoShowTaskpaneWithDocument'

function setStartupBehavior(autoOpenEnabled: boolean) {
  Office.addin.setStartupBehavior(
    autoOpenEnabled ? Office.StartupBehavior.load : Office.StartupBehavior.none,
  )
}

// Updates the autoshow setting in a Word document which controls whether
// the taskpane opens when the document loads.
export function useAutoOpenTaskpane() {
  const {
    get = () => false,
    set,
    saveAsync,
    refreshAsync,
  } = Office.context.document?.settings ?? {}
  const [autoOpenEnabled, setAutoOpenEnabled] = useState(get(AUTO_SHOW_KEY))
  refreshAsync(() => {
    const val = get(AUTO_SHOW_KEY)
    if (autoOpenEnabled !== val) {
      setAutoOpenEnabled(val)
    }
  })
  setStartupBehavior(autoOpenEnabled)

  return {
    autoOpenEnabled,
    toggleAutoOpen: () => {
      set(AUTO_SHOW_KEY, !autoOpenEnabled)
      saveAsync(console.log)
      setAutoOpenEnabled(!autoOpenEnabled)
      setStartupBehavior(autoOpenEnabled)
    },
  }
}
