import { useState, createContext, PropsWithChildren, FC, useEffect } from 'react'

import { KeyTerm } from '@modules/KeyTerm'
import { generateNewKeyTerm } from '@modules/KeyTermValueParsers'
import { KeyTermFormProps } from '@modules/KeyTermForm'
import { getMetadataConfig, MetadataConfig } from '@modules/MetadataConfig'

export type FilterState = {
  [key: string]: string[]
}

interface Props extends PropsWithChildren {
  showAddKeyTermModal?: boolean
}

interface KeyTermsContextState {
  addKeyTermModalActive: boolean
  currentFormComponent: CurrentFormComponentState
  deleteKeyTermModalActive: boolean
  dismissKeyTermForm: () => void
  editKeyTermModalActive: boolean
  formValid: boolean
  newKeyTerm?: KeyTerm
  selectedKeyTerm?: KeyTerm
  setAddKeyTermModalActive: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentFormComponent: React.Dispatch<any>
  setDeleteKeyTermModalActive: React.Dispatch<React.SetStateAction<boolean>>
  setEditKeyTermModalActive: React.Dispatch<React.SetStateAction<boolean>>
  setFormValid: React.Dispatch<React.SetStateAction<boolean>>
  setNewKeyTerm: React.Dispatch<React.SetStateAction<KeyTerm | undefined>>
  setSelectedKeyTerm: React.Dispatch<React.SetStateAction<KeyTerm | undefined>>
  toggleAddKeyTermModal: () => any
  toggleDeleteKeyTermModal: () => void
  toggleEditKeyTermModal: () => void
  submittingKeyTerm: boolean
  setSubmittingKeyTerm: React.Dispatch<React.SetStateAction<boolean>>
  isViewingModal: boolean
  metadataConfig?: MetadataConfig
  loadingMetadataConfig: boolean
}

export type CurrentFormComponentState = [React.FC<KeyTermFormProps>, string] | []

export const KeyTermsContext = createContext({} as KeyTermsContextState)

const KeyTermsContextProvider: FC<Props> = (props: Props) => {
  const [selectedKeyTerm, setSelectedKeyTerm] = useState<KeyTerm | undefined>()
  const [currentFormComponent, setCurrentFormComponent] = useState<CurrentFormComponentState>([])
  const [addKeyTermModalActive, setAddKeyTermModalActive] = useState<boolean>(false)
  const [deleteKeyTermModalActive, setDeleteKeyTermModalActive] = useState<boolean>(false)
  const [editKeyTermModalActive, setEditKeyTermModalActive] = useState<boolean>(false)
  const [formValid, setFormValid] = useState<boolean>(false)
  const [newKeyTerm, setNewKeyTerm] = useState<KeyTerm>()
  const [submittingKeyTerm, setSubmittingKeyTerm] = useState<boolean>(false)
  const [metadataConfig, setMetadataConfig] = useState<MetadataConfig>()
  const [loadingMetadataConfig, setLoadingMetadataConfig] = useState<boolean>(true)

  const toggleAddKeyTermModal = () => setAddKeyTermModalActive(!addKeyTermModalActive)
  const toggleEditKeyTermModal = () => setEditKeyTermModalActive(!editKeyTermModalActive)
  const toggleDeleteKeyTermModal = () => {
    setDeleteKeyTermModalActive(!deleteKeyTermModalActive)
    setFormValid(true)
  }

  const dismissKeyTermForm = () => {
    setEditKeyTermModalActive(false)
    setDeleteKeyTermModalActive(false)
    setAddKeyTermModalActive(false)
    setCurrentFormComponent([])
    setSelectedKeyTerm(undefined)
    setFormValid(false)
  }

  const isViewingModal = addKeyTermModalActive || editKeyTermModalActive || deleteKeyTermModalActive

  useEffect(() => {
    // TODO: look into caching this to improve perf
    getMetadataConfig()
      .then(config => {
        setMetadataConfig(config)
        setNewKeyTerm(generateNewKeyTerm(config.value))
      })
      .catch(e => console.error(e))
      .finally(() => setLoadingMetadataConfig(false))
  }, [])

  const value = {
    addKeyTermModalActive,
    currentFormComponent,
    deleteKeyTermModalActive,
    dismissKeyTermForm,
    editKeyTermModalActive,
    formValid,
    newKeyTerm,
    selectedKeyTerm,
    setAddKeyTermModalActive,
    setCurrentFormComponent,
    setDeleteKeyTermModalActive,
    setEditKeyTermModalActive,
    setFormValid,
    setNewKeyTerm,
    setSelectedKeyTerm,
    toggleAddKeyTermModal,
    toggleDeleteKeyTermModal,
    toggleEditKeyTermModal,
    submittingKeyTerm,
    setSubmittingKeyTerm,
    isViewingModal,
    metadataConfig,
    loadingMetadataConfig,
  }

  return <KeyTermsContext.Provider value={value}>{props.children}</KeyTermsContext.Provider>
}

export default KeyTermsContextProvider
