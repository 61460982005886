import React from 'react'
import { PropsWithChildren, useContext } from 'react'
import { Dialog } from '@fluentui/react'

import { KeyTermsContext } from '@contexts/KeyTermsContext'

const styles = { main: { top: '260px' } }

type Props = {
  title: string
}

const FormDialog: React.FC<Props & PropsWithChildren> = props => {
  const { title, children } = props
  const {
    dismissKeyTermForm,
    addKeyTermModalActive,
    editKeyTermModalActive,
    deleteKeyTermModalActive,
  } = useContext(KeyTermsContext)

  return (
    <Dialog
      hidden={!addKeyTermModalActive && !editKeyTermModalActive && !deleteKeyTermModalActive}
      onDismiss={dismissKeyTermForm}
      modalProps={{
        isBlocking: true,
        topOffsetFixed: true,
        styles,
      }}
      dialogContentProps={{ title }}
    >
      {children}
    </Dialog>
  )
}

export default FormDialog
