import { Environment } from '@modules/ampli'

declare const $STAGE: string
declare const $LOGIN_URL: string
declare const $LOGOUT_BFF_URL: string
declare const $LOGOUT_GK_BLAW_URL: string
declare const $LOGOUT_GK_INDUSTRY_URL: string
declare const $ADD_IN_HOST: string
declare const $WEBAPP_HOST: string
declare const $AUTH_API_HOST: string
declare const $ECDS_HOST: string
declare const $CONTRACTS_API_HOST: string
declare const $CONTRACTS_API_STAGE: string
declare const $SERVICES_API_HOST: string
declare const $SERVICES_API_STAGE: string
declare const $AMPLITUDE_API_URL: string
declare const $AMPLITUDE_API_KEY: string
declare const $AMPLITUDE_ENV: Environment
declare const $VERSION_ENV_VAR: string
declare const $ADD_IN_VERSION: string

// $ names will be replaced by the build's `define` option
const _STAGE = $STAGE
const _LOGIN_URL = $LOGIN_URL
const _LOGOUT_BFF_URL = $LOGOUT_BFF_URL
const _LOGOUT_GK_BLAW_URL = $LOGOUT_GK_BLAW_URL
const _LOGOUT_GK_INDUSTRY_URL = $LOGOUT_GK_INDUSTRY_URL
const _ADD_IN_HOST = $ADD_IN_HOST
const _WEBAPP_HOST = $WEBAPP_HOST
const _ECDS_HOST = $ECDS_HOST
const _AUTH_API_HOST = $AUTH_API_HOST
const _CONTRACTS_API_HOST = $CONTRACTS_API_HOST
const _CONTRACTS_API_STAGE = $CONTRACTS_API_STAGE
const _SERVICES_API_HOST = $SERVICES_API_HOST
const _SERVICES_API_STAGE = $SERVICES_API_STAGE
const _AMPLITUDE_API_URL = $AMPLITUDE_API_URL
const _AMPLITUDE_API_KEY = $AMPLITUDE_API_KEY
const _AMPLITUDE_ENV = $AMPLITUDE_ENV
const _VERSION_ENV_VAR = $VERSION_ENV_VAR
const _ADD_IN_VERSION = $ADD_IN_VERSION

export { _STAGE as STAGE }
export { _LOGIN_URL as LOGIN_URL }
export { _LOGOUT_BFF_URL as LOGOUT_BFF_URL }
export { _LOGOUT_GK_BLAW_URL as LOGOUT_GK_BLAW_URL }
export { _LOGOUT_GK_INDUSTRY_URL as LOGOUT_GK_INDUSTRY_URL }
export { _ADD_IN_HOST as ADD_IN_HOST }
export { _WEBAPP_HOST as WEBAPP_HOST }
export { _ECDS_HOST as ECDS_HOST }
export { _AUTH_API_HOST as AUTH_API_HOST }
export { _CONTRACTS_API_HOST as CONTRACTS_API_HOST }
export { _CONTRACTS_API_STAGE as CONTRACTS_API_STAGE }
export { _SERVICES_API_HOST as SERVICES_API_HOST }
export { _SERVICES_API_STAGE as SERVICES_API_STAGE }
export { _AMPLITUDE_API_URL as AMPLITUDE_API_URL }
export { _AMPLITUDE_API_KEY as AMPLITUDE_API_KEY }
export { _AMPLITUDE_ENV as AMPLITUDE_ENV }
export { _VERSION_ENV_VAR as VERSION_ENV_VAR }
export { _ADD_IN_VERSION as ADD_IN_VERSION }
