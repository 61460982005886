import * as React from 'react'
import { MessageBar, MessageBarType } from '@fluentui/react'

export interface QuickMessageProps {
  msg: string
  show?: boolean
  type?: 'success' | 'warning' | 'error' | 'info'
  style?: React.CSSProperties
}

export default function QuickMessage({
  msg,
  show = true,
  type = 'success',
  style = {},
}: QuickMessageProps) {
  if (!show) return null

  return (
    <div style={style}>
      <MessageBar messageBarType={MessageBarType[type]}>{msg}</MessageBar>
    </div>
  )
}
