import * as React from 'react'
import { Stack, IStackTokens, IStackProps } from '@fluentui/react'

const stackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
}

interface Props extends IStackProps {
  children: React.ReactNode
  tokens?: IStackTokens
}

const StyledStack: React.FunctionComponent<Props> = props => {
  return (
    <Stack tokens={props.tokens || stackTokens} {...props}>
      {props.children}
    </Stack>
  )
}

export default StyledStack
