import { MIMETYPE_DOCX } from '@blaw/contracts-api-schema'

import { getDocumentContent } from './getDocumentContent'
import { b64Encode } from './utils'

// Given a document id, downloads it from contracts-api and opens it in a new Word window.
// Note: this creates a new document in the user's Office account.
export default async function openDocument(
  documentId: string,
  contractId: string,
  versionId?: string,
  resourceType?: string,
) {
  const { content, documentType } = await getDocumentContent(
    documentId,
    contractId,
    versionId,
    resourceType,
  )

  if (documentType !== MIMETYPE_DOCX) throw new Error('Document type not supported')

  return await Word.run(async context => {
    const encoded = b64Encode(content)
    const doc = context.application.createDocument(encoded)
    context.load(doc)
    doc.open()
    await context.sync()
  })
}
