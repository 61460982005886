import UnstyledButton from '@baseComponents/UnstyledButton'

type Props = {
  show: boolean
}

export default function AIGenerated({ show }: Props) {
  return (
    <UnstyledButton
      href="https://www.bloombergindustry.com/ai-notice/"
      target="_blank"
      iconProps={{ iconName: 'AIGenerated' }}
      ariaLabel="AI Generated"
      className="on-hover-fade-75 on-active-fade-50"
      style={{
        translate: '-0.7em 0.3em',
        display: show ? 'inline-block' : 'none',
      }}
    />
  )
}
