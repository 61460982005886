import { CSSProperties, useState } from 'react'
import {
  ActionButton,
  IIconProps,
  IContextualMenuItem,
  IContextualMenuProps,
  mergeStyleSets,
  NeutralColors,
} from '@fluentui/react'

interface Props {
  items: IContextualMenuItem[]
  onItemClick?: (action: IContextualMenuItem) => void
  menuIconProps?: any
  iconStyles?: CSSProperties
  styles?: CSSProperties
}

const ActionsMenu: React.FunctionComponent<Props> = (props: Props) => {
  const { items, onItemClick, menuIconProps, iconStyles, styles } = props
  const [showMenu, setShowMenu] = useState(false)
  const menuProps: IContextualMenuProps = {
    items,
    hidden: !showMenu,
    onItemClick: (_e, actionItem) => {
      setShowMenu(false)
      onItemClick && actionItem && onItemClick(actionItem)
    },
    onDismiss: () => setShowMenu(false),
    doNotLayer: true,
  }
  const iconProps: IIconProps = {
    iconName: 'More',
    style: {
      fontSize: '1.5em',
      fontWeight: 800,
      ...iconStyles,
    },
    ...menuIconProps,
  }
  return (
    <div className={additionalStyles.hover} style={{ display: 'inline-block' }}>
      <ActionButton
        menuProps={menuProps}
        menuIconProps={iconProps}
        onClick={() => setShowMenu(true)}
        style={{
          color: '#106dbe',
          marginLeft: '0.5em',
          paddingRight: '0.5em',
          ...styles,
        }}
        role="menu"
      />
    </div>
  )
}

const additionalStyles = mergeStyleSets({
  hover: {
    selectors: {
      i: {
        ':hover': {
          background: NeutralColors.gray30,
        },
      },
    },
  },
})

export default ActionsMenu
