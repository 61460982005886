import React from 'react'
import { Stack } from '@fluentui/react'
import { NeutralColors, SharedColors } from '@fluentui/theme'
import UnstyledButton from '@baseComponents/UnstyledButton'

type LinkContentFooterProps = {
  href: string
  href_name: string
}

const LinkContentFooter: React.FC<LinkContentFooterProps> = ({ href, href_name }) => {
  return (
    <Stack
      style={{ display: 'flex', flexDirection: 'column', backgroundColor: NeutralColors.gray20 }}
    >
      <Stack.Item>
        <div
          style={{
            display: 'grid',
            marginTop: '0.5em',
            marginBottom: '0.5em',
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
          }}
        >
          <UnstyledButton
            style={{ gridColumn: '2/2', color: SharedColors.cyanBlue10 }}
            href={href}
            iconProps={{ iconName: 'OpenInNewTab' }}
            target="_blank"
          >
            {href_name}
          </UnstyledButton>
        </div>
      </Stack.Item>
    </Stack>
  )
}

export default LinkContentFooter
