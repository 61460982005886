import { FontSizes, IButtonStyles, IIconProps, IconButton, SharedColors } from '@fluentui/react'

type Props = {
  title?: string
  onClick?: () => void
  disabled?: boolean
}

const deleteIconProps: IIconProps = { iconName: 'Delete' }
const deleteIconStyles: IButtonStyles = {
  icon: {
    cursor: 'pointer',
    color: SharedColors.red10,
    fontSize: FontSizes.size20,
  },
}

export default function DeleteIcon({ title, onClick, disabled }: Props) {
  return (
    <IconButton
      title={title}
      iconProps={deleteIconProps}
      styles={deleteIconStyles}
      onClick={onClick}
      disabled={disabled}
    />
  )
}
