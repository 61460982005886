import { useContext, useState } from 'react'
import { Text, Stack, ActionButton, NeutralColors } from '@fluentui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import TopNav from '@components/TopNav'
import Nav from './Nav'
import { StoreContext } from '@contexts/StoreContext'
import { useAutoOpenTaskpane } from '@hooks/useAutoShowTaskpane'
import { useHomepageViewed } from '@modules/analytics'
import ErrorMessage from '@components/ErrorMessage'
import { useTranslation } from '@hooks/useTranslation'
import { getSession } from '@modules/SessionInfoStorage'
import BoldText from '@baseComponents/BoldText'
import { ADD_IN_VERSION, WEBAPP_HOST } from '@src/constants'
import { StyledDivider } from '@baseComponents/StyledDivider'
import SideCallout from '@components/SideCallout'
import LinkButton from '@components/LinkButton'

type Props = {
  versionLabel?: string
  webAppHost?: string
}

export default function Home({ versionLabel = ADD_IN_VERSION, webAppHost = WEBAPP_HOST }: Props) {
  const { loggedIn, checkingLoginStatus, triggerLogout, access } = useContext(StoreContext)
  const { autoOpenEnabled, toggleAutoOpen } = useAutoOpenTaskpane()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loggingOut, setLoggingOut] = useState(false)
  const [searchParams] = useSearchParams(window.location.search)
  const userInfo = getSession()
  const authorizerToAppRoleMapping = {
    admin: 'Admin',
    business: 'Business User',
    lawyer: 'Legal User',
  }

  useHomepageViewed({
    pageTitle: 'Get Started',
    isLoggedIn: loggedIn,
  })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <TopNav />
      <ErrorMessage message={searchParams.get('loginError')} />
      <ErrorMessage
        message={loggedIn && !access.canViewRoute('/') ? t('label.business-cannot-access') : ''}
      />

      <Stack
        verticalAlign="space-between"
        style={{ padding: '1.5em 1.0em 0em 1.0em', flexGrow: 2 }}
      >
        <MainContent />
        <SecondaryContent />
      </Stack>

      <FooterContent />
      {access.canViewCallout() && (
        <SideCallout
          buttonLabel={t('label.Bloomberg Law')}
          calloutTitle={t('label.Callout.more-resources')}
          calloutText={t('label.Callout.more-resources-message')}
          calloutLink="https://www.bloomberglaw.com/home"
          calloutLinkText={t('label.Callout.visit-resources')}
        />
      )}
    </div>
  )

  function MainContent() {
    if (loggedIn && !access.canViewRoute('/')) return null

    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1em' }}>
        <Text style={{ marginBottom: '2em' }}>{t('page.Home.blurb')}</Text>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
          <Nav />

          <ActionButton
            href="https://pro.bloomberglaw.com/the-complete-legal-research-resource/"
            target="_blank"
            iconProps={{ iconName: 'OpenInNewTab' }}
            styles={{
              flexContainer: { justifyContent: 'center' },
              labelHovered: { textDecoration: 'underline' },
            }}
          >
            {t('button.Learn More')}
          </ActionButton>
        </div>
      </div>
    )
  }

  function SecondaryContent() {
    return (
      <div>
        <div style={{ display: loggedIn ? 'flex' : 'none', justifyContent: 'right' }}>
          {userInfo.firstName} ({roleUiMapping(userInfo.userRole)})
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <ActionButton
            onClick={toggleAutoOpen}
            iconProps={{
              iconName: 'OpenPane',
              styles: { root: { fontSize: '1.5em' } },
            }}
            style={{ visibility: !loggedIn || access.canViewRoute('/') ? 'visible' : 'hidden' }}
          >
            {t(`button.Auto Open ${autoOpenEnabled ? 'En' : 'Dis'}abled`)}
          </ActionButton>

          <ActionButton
            onClick={loginAction}
            iconProps={{ iconName: 'SignOut' }}
            disabled={loggingOut}
            style={{ visibility: checkingLoginStatus ? 'hidden' : 'visible' }}
          >
            {loginBtnText()}
          </ActionButton>
        </div>
      </div>
    )
  }

  function FooterContent() {
    return (
      <Stack
        style={{ display: 'flex', flexDirection: 'column', backgroundColor: NeutralColors.gray20 }}
      >
        <Stack.Item>
          <div
            style={{
              display: 'grid',
              marginTop: '0.5em',
              marginBottom: '0.5em',
              paddingTop: '0.5em',
              paddingBottom: '0.5em',
              gridTemplateColumns: '20% auto',
            }}
          >
            <BoldText style={{ gridColumn: '1/2', marginLeft: '1em' }}>
              {t('page.Home.help')}
            </BoldText>
            <LinkButton
              buttonStyles={{ root: { gridColumn: '2/2' } }}
              href="mailto:blcshelp@bloomberglaw.com"
              iconName="Mail"
            >
              blcshelp@bloomberglaw.com
            </LinkButton>
            <LinkButton
              buttonStyles={{
                root: { gridColumn: '2/2', marginTop: '0.7em' },
              }}
              href="https://www.bloomberglaw.com"
              iconName="OpenInNewTab"
              target="_blank"
            >
              Bloomberg Law Research
            </LinkButton>
          </div>
        </Stack.Item>

        {access.canViewWebAppFooter() && (
          <Stack.Item style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <StyledDivider />
              <LinkButton
                buttonStyles={{ root: { marginTop: '0.7em' } }}
                href={webAppHost}
                iconName="OpenInNewTab"
                target="_blank"
              >
                {t('label.webapp_name')}
              </LinkButton>
            </div>
          </Stack.Item>
        )}

        <Stack.Item style={{ display: 'flex', justifyContent: 'end', margin: '0 .5em 0em 0' }}>
          <Text variant="small">{versionLabel}</Text>
        </Stack.Item>
      </Stack>
    )
  }

  async function loginAction() {
    if (!loggedIn) return navigate('/contracts')

    setLoggingOut(true)
    await triggerLogout()
    setLoggingOut(false)
  }

  function roleUiMapping(authorizerRole: string | null) {
    if (access.isTrial()) return 'Trial User'
    return (
      authorizerToAppRoleMapping[authorizerRole as keyof typeof authorizerToAppRoleMapping] || 'N/A'
    )
  }

  function loginBtnText() {
    if (loggingOut) return t('label.wait')
    return t(`button.Log ${loggedIn ? 'out' : 'in'}`)
  }
}
