import React from 'react'
import { FormInputOnChange } from '@hooks/useContractFormData'

// use in a text input's onBlur event to ensure the value ends in `ext`
export function useEnsureInputFileExt(ext: string, change: FormInputOnChange) {
  return (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: string = e.currentTarget.value.trim()
    const last = value.split('.').pop()

    if (last === ext) return
    if (value.length) change(e, `${value}.${ext}`)
  }
}
