import { useState } from 'react'

import { CreateContractParams } from '@modules/Contract'
import { MessageBar, MessageBarType } from '@fluentui/react'
import { GetResourceUploadLink } from '@modules/GetResourceUploadLink'
import { GetWordDocumentContent } from '@modules/GetWordDocumentContent'
import {
  PostDocumentToS3,
  formatAddDocumentBody,
  formatCreateContractBody,
  formatCreateTemplateBody,
  postAddDocumentToApi,
  postContractToApi,
  postTemplateToApi,
} from '@modules/PostDocumentToS3'
import { AxiosProgressEvent } from 'axios'
import { AddNewTemplateParams } from '@modules/Template'
import ApiClient from '@modules/ApiClient'
import Routes from '@modules/routes'
import { storePageToNavigateTo } from '@modules/ContractDetail'
import { b64Encode } from '@modules/utils'

export type UploadCompleteResponse = {
  contractId: string
  documentId: string
  versionId: string
}

export enum UploadType {
  createContract = 'CREATE_CONTRACT',
  addDocument = 'ADD_DOCUMENT',
  createTemplate = 'CREATE_TEMPLATE',
}

type UploadComplete = (contractResource: UploadCompleteResponse) => void | Promise<void>

type UploadContractFromTemplate = {
  url: string
  docId: string
  contractId: string
  version: string
}

// Used in the Taskpane to upload the current Word document to a presigned s3 url
export default function useSignedUpload() {
  const [uploadError, setUploadError] = useState<string | null>()
  const [uploading, setUploading] = useState(false)
  const [uploadPercent, setUploadPercent] = useState(0)
  const apiClient = new ApiClient()
  const routes = new Routes()

  return {
    uploading,
    uploadError,
    uploadPercent,
    uploadContractFromTemplate,
    uploadDocument,
    UploadStatus,
  }

  async function uploadContractFromTemplate(
    templateId: string,
    formData: CreateContractParams,
    uploadComplete: UploadComplete,
  ) {
    setUploading(true)
    try {
      const formattedContractFormData = formatCreateContractBody(formData)
      setUploadPercent(0.2)

      const { data } = await apiClient.post<UploadContractFromTemplate>(
        routes.createContractFromTemplateUrl(templateId),
        formattedContractFormData.contract,
      )
      setUploadPercent(0.6)

      uploadComplete({
        contractId: data.contractId,
        documentId: data.docId,
        versionId: data.version,
      })
      storePageToNavigateTo('/contracts/detect')

      const response = await apiClient.getBinaryContent<string>(data.url)
      const content = response.data
      setUploadPercent(0.9)

      await Word.run(async context => {
        const encoded = b64Encode(content)
        const doc = context.application.createDocument(encoded)
        context.load(doc)
        doc.open()
        await context.sync()
      })
    } catch (e: any) {
      handleFailure(e)
    } finally {
      setUploading(false)
    }
  }

  async function uploadDocument(
    formData: CreateContractParams | AddNewTemplateParams,
    uploadComplete: UploadComplete,
    uploadType: UploadType,
  ) {
    setUploading(true)
    try {
      const link = await GetResourceUploadLink()
      setUploadPercent(0.2)
      const docData = await GetWordDocumentContent()
      setUploadPercent(0.4)

      const s3Key = link.fields.key
      await PostDocumentToS3(link, docData, s3ProgressToPercentUploaded)

      setUploadPercent(0.9)
      switch (uploadType) {
        case UploadType.addDocument: {
          // adding file to existing contract
          const contractFormData = formData as CreateContractParams
          const addBody = formatAddDocumentBody(s3Key, contractFormData)
          const addPostResult = await postAddDocumentToApi(addBody)
          if (contractFormData.parentId) {
            uploadComplete({
              contractId: contractFormData.parentId,
              documentId: addPostResult.id,
              versionId: addPostResult.version,
            })
          } else {
            handleFailure(
              new Error('Must have a parent contract id to add a document to existing contract.'),
            )
          }
          break
        }
        case UploadType.createContract: {
          // creating new contract
          const contractFormData = formData as CreateContractParams
          const createBody = formatCreateContractBody(contractFormData, s3Key)
          const createPostResult = await postContractToApi(createBody)
          uploadComplete({
            contractId: createPostResult.contractId,
            documentId: createPostResult.docId,
            versionId: createPostResult.version,
          })
          break
        }
        case UploadType.createTemplate: {
          // creating new template
          const createTemplateBody = formatCreateTemplateBody(
            s3Key,
            formData as AddNewTemplateParams,
          )
          const createTemplatePostResult = await postTemplateToApi(createTemplateBody)
          uploadComplete({
            contractId: '',
            documentId: createTemplatePostResult.id,
            versionId: createTemplatePostResult.version,
          })
          break
        }
        default:
          handleFailure(new Error(`Unkown upload type: ${uploadType}`))
      }
      setUploadPercent(1.0)
    } catch (e: any) {
      handleFailure(e)
    } finally {
      setUploading(false)
    }
  }

  function s3ProgressToPercentUploaded(progressEvent: AxiosProgressEvent) {
    // We can't give a perfectly accurate progress indicator because the whole process consists of multiple HTTP requests
    // most of which do not report progress, but this assumes the actual upload is about 50% of the total time
    // 0-20% = getting presigned url, 20-40 = getting doc contents, 40-90 = upload, 90-100 = API call to register new document
    const updatedPercent =
      0.4 + 0.5 * Math.round(progressEvent.loaded / (progressEvent.total || progressEvent.loaded))
    setUploadPercent(updatedPercent)
  }

  function handleFailure(e: Error) {
    setUploadPercent(0)
    setUploadError('Could not upload document. Please try again later.')
    console.error(e)
  }

  function UploadStatus() {
    return (
      <div style={{ flexGrow: 1 }}>
        {!uploading && uploadError && renderUploadMessage(uploadError, 'error')}
      </div>
    )
  }

  function renderUploadMessage(status: string, type = 'success') {
    return (
      <MessageBar
        messageBarType={Object.values(MessageBarType).indexOf(type)}
        style={{ fontSize: '1.1em' }}
      >
        {status}
      </MessageBar>
    )
  }
}
