import { useContext, Dispatch, SetStateAction, useState } from 'react'
import { SharedColors } from '@fluentui/react'

import {
  SectionIssuesContext,
  GapIssue,
  InvalidStartIssuesValue,
  Issue,
  SectionIssueListItem,
} from '@contexts/SectionIssuesContext'
import { titleize } from '@modules/utils'
import { selectSectionInDocument } from '@modules/wordDocument'
import BoldText from '@baseComponents/BoldText'
import OrderedList from '@baseComponents/OrderedList'
import UnstyledList from '@baseComponents/UnstyledList'
import CollapsibleItem from '@components/CollapsibleItem'
import IssueCard from '@components/IssueCard'
import LoadingShimmer from '@components/LoadingShimmer'
import NotificationBadge from '@components/NotificationBadge'
import QuickMessage from '@components/QuickMessage'
import { selectedLeftBorderStyle, unselectedLeftBorderStyle } from '@modules/sharedStyles'
import UnstyledActionButton from '@baseComponents/UnstyledActionButton'

function SectionIssuesContent() {
  const { error, loading, issues, setIssues } = useContext(SectionIssuesContext)
  const [selected, setSelected] = useState('')

  if (loading) return <LoadingShimmer />
  if (error) return <QuickMessage msg={error} type="error" />

  return <UnstyledList>{list(issues)}</UnstyledList>

  function list(issues: SectionIssueListItem[]) {
    return issues.map(item => {
      return (
        <CollapsibleItem
          key={item.key}
          item={item}
          itemHeader={i => issueHeader(i)}
          itemContent={i => issueContent(i)}
          onToggleItem={i => updateLocalIssueItem(i, issues, setIssues)}
        />
      )
    })
  }

  function issueHeader({ key, issues }: SectionIssueListItem) {
    return (
      <span style={{ position: 'relative' }}>
        <BoldText style={{ marginRight: '0.3em' }}>{titleize(key)}</BoldText>
        <NotificationBadge style={{ backgroundColor: SharedColors.cyan10 }}>
          {issues.length}
        </NotificationBadge>
      </span>
    )
  }

  function issueContent({ key, issues }: SectionIssueListItem) {
    return (
      <OrderedList>
        {issues.map((issue, i) => {
          return <li key={i}>{renderIssue(key, issue, i)}</li>
        })}
      </OrderedList>
    )
  }

  function renderIssue(key: string, issue: Issue | Issue[] | GapIssue, index: number) {
    switch (key) {
      case 'duplication':
        return duplication(issue as Issue[], index)
      case 'gap':
        return gap(issue as GapIssue, index)
      case 'invalid_start':
        return invalidStart(issue as Issue, index)
      default:
        throw Error(`Unknown Issue type: ${key}`)
    }
  }

  function getIssueIndexAndStyle(issueType: string, index: number) {
    const issueIndex = issueType + index
    const issueStyle = issueIndex === selected ? selectedLeftBorderStyle : unselectedLeftBorderStyle

    return { issueIndex, issueStyle }
  }

  function duplication([target, source]: Issue[], index: number) {
    const { issueIndex, issueStyle } = getIssueIndexAndStyle('duplication', index)

    return (
      <IssueCard style={issueStyle} footer={duplicationActions(target, source, issueIndex)}>
        <BoldText>Duplicate Section: {target.text}</BoldText>
      </IssueCard>
    )
  }

  function gap({ begin, end }: GapIssue, index: number) {
    const { issueIndex, issueStyle } = getIssueIndexAndStyle('gap', index)

    return (
      <IssueCard style={issueStyle} footer={gapActions(begin, end, issueIndex)}>
        <BoldText>
          Gap between Section {begin.text} and Section {end.text}
        </BoldText>
      </IssueCard>
    )
  }

  function invalidStart(item: InvalidStartIssuesValue[0], index: number) {
    const { issueIndex, issueStyle } = getIssueIndexAndStyle('invalid_start', index)

    return (
      <IssueCard style={issueStyle} footer={invalidStartActions(item, issueIndex)}>
        <BoldText>Invalid starting Section {item.text}</BoldText>
      </IssueCard>
    )
  }

  function handleClick(issueIndex: string, paragraphIndex: number) {
    setSelected(issueIndex)
    selectSectionInDocument(paragraphIndex)
  }

  function duplicationActions(target: Issue, source: Issue, issueIndex: string) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <UnstyledActionButton
          iconProps={{ iconName: 'DocumentSearch' }}
          onClick={() => handleClick(issueIndex, target.paragraph_index)}
        >
          View First
        </UnstyledActionButton>
        <UnstyledActionButton
          iconProps={{ iconName: 'DocumentSearch' }}
          onClick={() => handleClick(issueIndex, source.paragraph_index)}
        >
          View Duplicate
        </UnstyledActionButton>
      </div>
    )
  }

  function gapActions(begin: Issue, end: Issue, issueIndex: string) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <UnstyledActionButton
          iconProps={{ iconName: 'DocumentSearch' }}
          onClick={() => handleClick(issueIndex, begin.paragraph_index)}
        >
          View Section {begin.text}
        </UnstyledActionButton>
        <UnstyledActionButton
          iconProps={{ iconName: 'DocumentSearch' }}
          onClick={() => handleClick(issueIndex, end.paragraph_index)}
        >
          View Section {end.text}
        </UnstyledActionButton>
      </div>
    )
  }

  function invalidStartActions(item: Issue, issueIndex: string) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <UnstyledActionButton
          iconProps={{ iconName: 'DocumentSearch' }}
          onClick={() => handleClick(issueIndex, item.paragraph_index)}
        >
          View Section {item.text}
        </UnstyledActionButton>
      </div>
    )
  }

  function updateLocalIssueItem(
    newState: SectionIssueListItem,
    issues: SectionIssueListItem[],
    setIssues: Dispatch<SetStateAction<SectionIssueListItem[]>>,
  ) {
    const issue = issues.find(({ key }) => key === newState.key)
    if (!issue) return

    issue.active = newState.active
    setIssues([...issues])
  }
}

export default SectionIssuesContent
