import { NavItem } from '@src/data/navItems'
import useNavItems from '@hooks/useNavItems'

export default function Nav() {
  const { mainNavItems, actionNavItems, analysisNavItems } = useNavItems()

  return (
    <>
      {renderItems(mainNavItems)}
      <div />
      {renderItems(analysisNavItems)}
      <div />
      {renderItems(actionNavItems)}
    </>
  )
}

function renderItems(items: NavItem[]) {
  return items.map(
    ({ Button, key, href, text }) =>
      Button && (
        <Button key={key} href={href}>
          {text}
        </Button>
      ),
  )
}
