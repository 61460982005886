import { useState } from 'react'
import { ActionButton, ITextFieldProps, Stack, TextField } from '@fluentui/react'

import SmallButton from '@components/SmallButton'
import BoldText from '@baseComponents/BoldText'
import useLoadingFadeStyle from '@hooks/useLoadingFadeStyle'

type Props = ITextFieldProps & {
  labelPrefix: string
  values: string[]
  onValueChange: (values: string[]) => void
  addBtnLabel?: string
  loading?: boolean
}

export default function AddAnotherTextField({
  labelPrefix,
  values,
  loading,
  disabled,
  placeholder,
  onValueChange,
  addBtnLabel = 'Add another',
  ...textFieldProps
}: Props) {
  const [inputs, setInputs] = useState(values.length ? values : [''])
  const loadingFade = useLoadingFadeStyle(loading || disabled)

  return (
    <>
      {inputs.map((value, i) => {
        const label = `${labelPrefix} ${i + 1}`
        return (
          <div key={i} style={{ ...loadingFade, marginTop: i > 0 ? '1em' : '' }}>
            <Stack horizontal style={{ justifyContent: 'space-between', marginBottom: '0.3em' }}>
              <BoldText>{label}</BoldText>
              {i > 0 && (
                <SmallButton
                  iconName="Cancel"
                  variant="destructive"
                  title={`Remove ${label}`}
                  onClick={() => {
                    const newInputs = structuredClone(inputs)
                    newInputs.splice(i, 1)
                    setInputs(newInputs)
                    onValueChange(newInputs)
                  }}
                />
              )}
            </Stack>

            <TextField
              value={value}
              disabled={loading}
              placeholder={placeholder}
              onChange={(_, value) => {
                const newInputs = structuredClone(inputs)
                newInputs.splice(i, 1, value ?? '')
                setInputs(newInputs)
                onValueChange(newInputs)
              }}
              {...textFieldProps}
            />
          </div>
        )
      })}

      <ActionButton
        styles={{ root: { ...loadingFade, paddingLeft: 0 } }}
        iconProps={{ iconName: 'Add' }}
        onClick={() => setInputs([...inputs, ''])}
      >
        {addBtnLabel}
      </ActionButton>
    </>
  )
}
