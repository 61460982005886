import { FC } from 'react'
import { IComboBoxOption } from '@fluentui/react'

import { type MetadataConfigValue } from '@blaw/contracts-api-schema'
import StringKeyTermForm from '@components/StringKeyTermForm'
import CurrencyKeyTermForm from '@components/CurrencyKeyTermForm'
import DateKeyTermForm from '@components/DateKeyTermForm'
import MultiTextKeyTermForm from '@components/MultiTextKeyTermForm'
import DurationMonthsKeyTermForm from '@components/DurationMonthsKeyTermForm'
import DurationDaysKeyTermForm from '@components/DurationDaysKeyTermForm'
import BooleanKeyTermForm from '@components/BooleanKeyTermForm'
import ChoiceTextKeyTermForm from '@components/ChoiceTextKeyTermForm'
import { KeyTermFormProps } from '@modules/KeyTermForm'

const keyTermTypeToFormComponent: {
  [key: string]: FC<KeyTermFormProps>
} = {
  MULTI_TEXT: MultiTextKeyTermForm,
  BOOLEAN: BooleanKeyTermForm,
  CURRENCY: CurrencyKeyTermForm,
  DATE: DateKeyTermForm,
  DURATION_MONTHS: DurationMonthsKeyTermForm,
  DURATION_DAYS: DurationDaysKeyTermForm,
  TEXT: StringKeyTermForm,
  CHOICE_TEXT: ChoiceTextKeyTermForm,
}

const dataType = [
  'TEXT',
  'MULTI_TEXT',
  'BOOLEAN',
  'DATE',
  'CURRENCY',
  'DURATION_MONTHS',
  'DURATION_DAYS',
  'CHOICE_TEXT',
] as const

export const defaultDataType = dataType[0]

export interface KeyTermData {
  notes: string
  block: string
  text: string
}

export type DataType = (typeof dataType)[number]

export type KeyTermValue = KeyTermData | string | string[] | boolean | null | undefined

export interface KeyTerm {
  key?: string
  label?: string
  type?: DataType
  items?: IComboBoxOption[]
  value?: KeyTermValue
  data?: KeyTermData
}

export const defaultKeyTerm: KeyTerm = {
  key: '',
  label: '',
  type: defaultDataType,
  value: '',
  data: {
    notes: '',
    block: '',
    text: '',
  },
}

export const keyTermTypeToForm = (dataType: DataType) => {
  return keyTermTypeToFormComponent[dataType]
}

export const getConfig = (
  keyTermKey: string | number,
  metadataConfig: MetadataConfigValue[],
): [FC<KeyTermFormProps>, string | undefined] => {
  const config = metadataConfig.find(config => keyTermKey === config.id)
  const formType = keyTermTypeToFormComponent[config?.type || defaultDataType]
  return [formType, config?.label]
}

export const keyTermFormSelectorOptions = (
  metadataConfig: MetadataConfigValue[],
): IComboBoxOption[] => {
  return metadataConfig.map(val => ({ key: val.id, text: val.label }))
}
