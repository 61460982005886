import { useContext } from 'react'
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react'

import useKeyTermForm from '@hooks/useKeyTermForm'
import { useTranslation } from '@hooks/useTranslation'
import StyledTextField from '@components/StyledTextField'
import { KeyTermFormProps } from '@modules/KeyTermForm'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'
import { KeyTermFormContext } from '@contexts/KeyTermFormContext'

const options: IChoiceGroupOption[] = [
  { key: 'Yes', text: 'Yes' },
  { key: 'No', text: 'No' },
]

const booleanToOptionMap = ['No', 'Yes']

const BooleanKeyTermForm: React.FC<KeyTermFormProps> = ({ keyTermType }: KeyTermFormProps) => {
  const { copySelectionDisabled } = useContext(KeyTermFormContext)
  const { newKeyTerm, updateKeyTermNotes, updateBooleanKeyTerm, copyFromSelection } =
    useKeyTermForm(keyTermType, 'BOOLEAN')
  const { t } = useTranslation()

  return (
    <>
      <ChoiceGroup
        styles={{
          flexContainer: { display: 'flex', justifyContent: 'space-between', width: '45%' },
        }}
        label={keyTermType}
        options={options}
        required
        onChange={updateBooleanKeyTerm}
        selectedKey={
          typeof newKeyTerm?.value === 'boolean'
            ? booleanToOptionMap[newKeyTerm.value ? 1 : 0]
            : null
        }
      />
      <StyledTextField
        label={t('label.notes')}
        value={newKeyTerm?.data ? newKeyTerm?.data.notes : ''}
        name="notes"
        onChange={updateKeyTermNotes}
        multiline
      />
      <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} />
    </>
  )
}

export default BooleanKeyTermForm
