import ContractTitle from '@baseComponents/ContractTitle'
import ConfirmDialog from '@components/ConfirmDialog'
import { Stack, MessageBar } from '@fluentui/react'
import StyledTextField from '@components/StyledTextField'
import useContractFormData from '@hooks/useContractFormData'
import { ContractField, formToCreateContractParams } from '@modules/Contract'
import { useContext } from 'react'
import { ContractContext } from '@contexts/ContractContext'
import FileCategory from '@components/FileCategory'
import useSignedUpload, { UploadCompleteResponse, UploadType } from '@hooks/useSignedUpload'
import { dataInject } from '@hooks/useDataInjection'
import { formSubmitted } from '@modules/analytics'
import { useEnsureInputFileExt } from '@hooks/useEnsureInputFileExt'
import { useTranslation } from '@hooks/useTranslation'

const requiredFields: Partial<ContractField[]> = ['fileName']
const pageTitle = 'Add File to Contract'

type Props = {
  contractId: string
  hidden: boolean
  toggleHidden: () => void
}

export default function AddFileForm({ contractId, hidden, toggleHidden }: Props) {
  const { title, loadContract } = useContext(ContractContext)
  const { valid, change, formData, reset } = useContractFormData(
    { parentId: contractId },
    requiredFields,
  )
  const { uploading, uploadDocument, UploadStatus } = useSignedUpload()
  const ensureEndsInDocx = useEnsureInputFileExt('docx', change)
  const { t } = useTranslation()

  return (
    <ConfirmDialog
      title={pageTitle}
      hidden={hidden}
      disableBtn={!valid || uploading}
      confirm={uploading ? t('modal.Adding') : t('modal.Add File.Add')}
      onConfirm={onSubmit}
      onDismiss={closeForm}
      confirmBtnTitle={valid ? t('modal.Add File.Is Valid') : t('modal.Is Invalid')}
    >
      <form onSubmit={onSubmit}>
        <ContractTitle title={title} />
        <UploadStatus />

        <Stack.Item>
          <MessageBar>{t('modal.Add File.Message')}</MessageBar>
        </Stack.Item>

        <Stack.Item>
          <StyledTextField
            required
            autoFocus
            name="fileName"
            label={t('label.file-name')}
            placeholder={t('placeholder.file-name')}
            value={formData.fileName}
            onChange={change}
            disabled={uploading}
            onBlur={ensureEndsInDocx}
          />
        </Stack.Item>
        <Stack.Item>
          <FileCategory change={change} disabled={uploading} />
        </Stack.Item>
        <Stack.Item>
          <StyledTextField
            multiline
            autoAdjustHeight
            name="description"
            label={t('label.file-description')}
            placeholder={t('placeholder.enter-description')}
            value={formData.description}
            onChange={change}
            disabled={uploading}
          />
        </Stack.Item>
      </form>
    </ConfirmDialog>
  )

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const params = formToCreateContractParams(formData)
    uploadDocument(params, onSuccess, UploadType.addDocument)
  }

  async function onSuccess({ contractId, documentId, versionId }: UploadCompleteResponse) {
    await dataInject({ resourceId: contractId, documentId, versionId })
    formSubmitted({
      pageTitle,
      itemClicked: 'Add File',
      eventDetails: [contractId, documentId, versionId],
    })
    loadContract()
    closeForm()
  }

  function closeForm() {
    reset()
    toggleHidden()
  }
}
