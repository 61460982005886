import { CSSProperties, PropsWithChildren } from 'react'

import BoldText from '@baseComponents/BoldText'
import { definitionFontColor, definitionHighlightColor } from '@modules/DocumentDefinitions'

type Props = PropsWithChildren & {
  styles?: {
    root?: CSSProperties
    text?: CSSProperties
  }
}

export default function Highlight({ children, styles }: Props) {
  const rootStyles = {
    display: 'inline-block',
    backgroundColor: definitionHighlightColor,
    ...styles?.root,
  }
  const textStyles = { color: definitionFontColor, ...styles?.text }

  return (
    <span style={rootStyles}>
      <BoldText style={textStyles}>{children}</BoldText>
    </span>
  )
}
