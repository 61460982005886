import { useContext } from 'react'
import { MessageBar, ProgressIndicator, Stack } from '@fluentui/react'

import TopNav from '@components/TopNav'
import SectionIssuesActions from '@components/SectionIssuesActions'
import StyledStack from '@components/StyledStack'
import { DefinitionsContext } from '@contexts/DefinitionsContext'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { useTranslation } from '@hooks/useTranslation'
import { SectionIssuesContext } from '@contexts/SectionIssuesContext'
import AnalysisToolsStaleWarning from '@components/AnalysisToolsStaleWarning'

export default function SectionNumbering() {
  const { analysisIsStale } = useContext(DefinitionsContext)
  const { loading } = useContext(SectionIssuesContext)
  const { t } = useTranslation()
  const pageTitle = t('nav.Section Numbering')

  useContractTaskPaneViewed({ pageTitle })

  return (
    <div>
      <TopNav title={pageTitle} showAIBadge />
      <MessageBar>{t('page.SectionNumbering.blurb')}</MessageBar>

      <StyledStack style={{ paddingTop: analysisIsStale ? '' : '0' }}>
        <Stack.Item>
          <AnalysisToolsStaleWarning pageTitle={pageTitle} />
        </Stack.Item>

        {loading && <ProgressIndicator label={t('label.Analyzing Sections')} />}
        <SectionIssuesActions />
      </StyledStack>
    </div>
  )
}
