import { useContext } from 'react'
import {
  Label,
  Stack,
  MessageBar,
  ChoiceGroup,
  ActionButton,
  PrimaryButton,
  IStackTokens,
  Text,
} from '@fluentui/react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  ContractField,
  contractFormFieldDefaults,
  contractOriginOptions,
  formToCreateContractParams,
} from '@modules/Contract'
import StyledTextField from '@components/StyledTextField'
import useContractFormData from '@hooks/useContractFormData'
import MultiCombobox from '@components/MultiCombobox'
import ErrorMessage from '@components/ErrorMessage'
import ContractStatusMenu from '@components/ContractStatusMenu'
import { useEnsureInputFileExt } from '@hooks/useEnsureInputFileExt'
import UploadProgress from '@components/UploadProgress'
import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import useSignedUpload, { UploadCompleteResponse, UploadType } from '@hooks/useSignedUpload'
import { dataInject } from '@hooks/useDataInjection'
import { formSubmitted, useContractTaskPaneViewed } from '@modules/analytics'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import { ContractsContext } from '@contexts/ContractsContext'
import ComboboxField from '@components/ComboboxField'
import { getSession } from '@modules/SessionInfoStorage'
import AccessField from '@components/AccessField'
import { StoreContext } from '@contexts/StoreContext'
import ContractCustomFields from '@components/ContractCustomFields'
import FileCategory from '@components/FileCategory'
import { divergeCustomMonetaryFields } from '@modules/CustomFields'
import { useTranslation } from '@hooks/useTranslation'
import BoldText from '@baseComponents/BoldText'

const hint = 'Add this Document to selected Contract'
const warn = 'Fill out all fields before uploading a new Document'
const largeStackTokens: IStackTokens = {
  childrenGap: 'l1',
}
const requiredFields: Partial<ContractField[]> = ['fileName', 'statusIdx']
const userInfo = getSession()

export default function CreateContract() {
  const navigate = useNavigate()
  const location = useLocation()
  const template = location.state?.template
  const { t } = useTranslation()
  const pageTitle = template
    ? t('page.CreateContract.Create Contract from Template')
    : t('page.CreateContract.Add Contract')
  const { uploading, uploadPercent, uploadContractFromTemplate, uploadDocument, UploadStatus } =
    useSignedUpload()
  const {
    valid,
    change,
    updateComboboxField,
    formData,
    formError,
    updateFirstDraftOrigin,
    updateAccess,
    selectedStatusIdx,
    setSelectedStatusIdx,
  } = useContractFormData(contractFormFieldDefaults, requiredFields)
  const ensureEndsInDocx = useEnsureInputFileExt('docx', change)
  const { fetchingItems, getFacetOptions } = useContext(ContractsContext)
  const { isResourceAccessEnabled } = useContext(StoreContext)
  const { loadingMetadataConfig, metadataConfig } = useContext(KeyTermsContext)

  useContractTaskPaneViewed({ pageTitle })

  if (loadingMetadataConfig || !metadataConfig) return null

  const { getLabel } = metadataConfig

  return (
    <div>
      <TopNav title={pageTitle} prevPath={() => navigate(-1)} />
      <StyledStack>
        <Stack.Item>
          <form onSubmit={onSubmit}>
            {renderHeader()}
            <ErrorMessage message={formError || ''} />
            <Stack tokens={largeStackTokens} style={{ marginTop: '1em' }}>
              <Stack.Item>
                <StyledTextField
                  required
                  name="fileName"
                  label="File / Contract Name"
                  placeholder="Enter file / contract name"
                  value={formData.fileName}
                  onChange={change}
                  disabled={uploading}
                  onBlur={ensureEndsInDocx}
                />
              </Stack.Item>
              <Stack.Item>
                <FileCategory change={change} disabled={uploading} />
              </Stack.Item>
              <Stack.Item>
                <ChoiceGroup
                  options={contractOriginOptions}
                  label={getLabel('first_draft_origin')}
                  defaultSelectedKey={formData.firstDraftOrigin}
                  value={formData.firstDraftOrigin}
                  onChange={updateFirstDraftOrigin}
                  disabled={uploading}
                />
              </Stack.Item>
              <Stack.Item>
                <ComboboxField
                  label={getLabel('contract_type')}
                  value={formData.contractType}
                  options={getFacetOptions('contract_type')}
                  onChange={(_event, _option, _index, value) =>
                    updateComboboxField('contractType', value)
                  }
                  disabled={uploading || fetchingItems.current}
                />
              </Stack.Item>
              <Stack.Item>
                <Label required={true}>{getLabel('contract_status')}</Label>
                <ContractStatusMenu
                  setSelectedStatusIdx={setSelectedStatusIdx}
                  contractStatusIdx={selectedStatusIdx}
                  disabled={uploading}
                />
              </Stack.Item>
              {isResourceAccessEnabled && (
                <AccessField formData={formData} updateAccess={updateAccess} disabled={uploading} />
              )}
              <Stack.Item>
                <MultiCombobox
                  name="counterParty"
                  label={getLabel('party_name')}
                  placeholder={'Enter ' + getLabel('party_name').toLowerCase()}
                  values={formData.counterParty}
                  options={getFacetOptions('party_name')}
                  onJoinValues={(_name, values) => updateComboboxField('counterParty', values)}
                  disable={uploading || fetchingItems.current}
                />
              </Stack.Item>
              <Stack.Item>
                <MultiCombobox
                  name="clientName"
                  label={getLabel('client_name')}
                  placeholder={'Enter ' + getLabel('client_name').toLowerCase()}
                  values={formData.clientName}
                  options={getFacetOptions('client_name')}
                  onJoinValues={(_name, values) => updateComboboxField('clientName', values)}
                  disable={uploading || fetchingItems.current}
                />
              </Stack.Item>
              <Stack.Item>
                <ComboboxField
                  label={getLabel('contract_category')}
                  value={formData.contractCategory}
                  options={getFacetOptions('contract_category')}
                  onChange={(_event, _option, _index, value) =>
                    updateComboboxField('contractCategory', value)
                  }
                  disabled={uploading || fetchingItems.current}
                />
              </Stack.Item>
              <Stack.Item>
                <StyledTextField
                  multiline
                  autoAdjustHeight
                  name="description"
                  label={getLabel('description')}
                  placeholder={'Enter ' + getLabel('description').toLowerCase()}
                  value={formData.description}
                  onChange={change}
                  disabled={uploading}
                />
              </Stack.Item>
              <Stack.Item>
                <MultiCombobox
                  name="companyStakeholder"
                  label={getLabel('company_stakeholder')}
                  placeholder={'Enter ' + getLabel('company_stakeholder').toLowerCase()}
                  values={formData.clientName}
                  options={getFacetOptions('company_stakeholder')}
                  onJoinValues={(_name, values) =>
                    updateComboboxField('companyStakeholder', values)
                  }
                  disable={uploading || fetchingItems.current}
                />
              </Stack.Item>
              <Stack.Item>
                <StyledTextField
                  name="department"
                  label={getLabel('department')}
                  placeholder={'Enter ' + getLabel('department').toLowerCase()}
                  value={formData.department}
                  onChange={change}
                  disabled={uploading}
                />
              </Stack.Item>
              <Stack.Item>
                <StyledTextField
                  name="city"
                  label={getLabel('city')}
                  placeholder={'Enter ' + getLabel('city').toLowerCase()}
                  value={formData.city}
                  onChange={change}
                  disabled={uploading}
                />
              </Stack.Item>
              <Stack.Item>
                <StyledTextField
                  name="state"
                  label={getLabel('state')}
                  placeholder={'Enter ' + getLabel('state').toLowerCase()}
                  value={formData.state}
                  onChange={change}
                  disabled={uploading}
                />
              </Stack.Item>
              <Stack.Item>
                <StyledTextField
                  name="country"
                  label={getLabel('region')}
                  placeholder={'Enter ' + getLabel('region').toLowerCase()}
                  value={formData.country}
                  onChange={change}
                  disabled={uploading}
                />
              </Stack.Item>
              <Stack.Item>
                <ComboboxField
                  label={getLabel('transaction')}
                  value={formData.transaction}
                  options={getFacetOptions('transaction')}
                  onChange={(_event, _option, _index, value) =>
                    updateComboboxField('transaction', value)
                  }
                  disabled={uploading || fetchingItems.current}
                />
              </Stack.Item>
              <Stack.Item>
                <ComboboxField
                  label={getLabel('contract_id')}
                  value={formData.contractID}
                  options={getFacetOptions('contract_id')}
                  onChange={(_event, _option, _index, value) =>
                    updateComboboxField('contractID', value)
                  }
                  disabled={uploading || fetchingItems.current}
                />
              </Stack.Item>

              <ContractCustomFields fieldData={formData} disabled={uploading} onChange={change} />

              {renderFooter()}
            </Stack>
          </form>
        </Stack.Item>
      </StyledStack>
    </div>
  )

  function renderHeader() {
    return template ? (
      <div>
        <MessageBar>{t('page.CreateContract.Using Template Message')}</MessageBar>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <BoldText style={{ margin: '1em 0 0.5em' }}>
            {t('page.CreateContract.Selected Template')}
          </BoldText>
          <Text>{template.name}</Text>
        </div>
      </div>
    ) : (
      <MessageBar>{t('page.CreateContract.Using File Message')}</MessageBar>
    )
  }

  function renderFooter() {
    if (uploading)
      return (
        <UploadProgress
          message={template ? t('label.creating') : t('label.uploading')}
          percentComplete={uploadPercent || 0}
        />
      )

    return (
      <>
        <UploadStatus />
        <Stack.Item style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5em' }}>
          <Stack.Item
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <PrimaryButton
              text={
                template
                  ? t('page.CreateContract.Create Contract')
                  : t('page.CreateContract.Upload Contract')
              }
              type="submit"
              disabled={!valid || uploading}
              title={valid ? hint : warn}
            />
          </Stack.Item>
          <Stack.Item
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ActionButton onClick={() => navigate(-1)}>Cancel</ActionButton>
          </Stack.Item>
        </Stack.Item>
      </>
    )
  }

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const data = { ...formData, ...divergeCustomMonetaryFields(formData) }
    const params = formToCreateContractParams(data, userInfo.uuid || '')
    template
      ? await uploadContractFromTemplate(template.id, params, onSuccessContractFromTemplate)
      : await uploadDocument(params, onSuccess, UploadType.createContract)
  }

  async function onSuccessContractFromTemplate({
    contractId,
    documentId,
    versionId,
  }: UploadCompleteResponse) {
    formSubmitted({
      pageTitle,
      itemClicked: 'Upload Contract from Template',
      eventDetails: [contractId, documentId, versionId],
    })
    navigate('/templates')
  }

  async function onSuccess({ contractId, documentId, versionId }: UploadCompleteResponse) {
    await dataInject({ resourceId: contractId, documentId, versionId })
    formSubmitted({
      pageTitle,
      itemClicked: 'Upload Contract',
      eventDetails: [contractId, documentId, versionId],
    })
    navigate(`/contracts/${contractId}`)
  }
}
