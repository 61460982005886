import { CSSProperties, PropsWithChildren } from 'react'

type Props = PropsWithChildren & {
  style?: CSSProperties
}

const OrderedList: React.FC<Props> = ({ style, children }: Props) => {
  return (
    <ol
      style={{
        ...{
          margin: 0,
          padding: 0,
        },
        ...style,
      }}
    >
      {children}
    </ol>
  )
}

export default OrderedList
