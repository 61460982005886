import { type TermsMetadata, type ResourceVersionMetadataDetails } from '@blaw/contracts-api-schema'
import KeyTermItem from '@components/KeyTermItem'
import { KeyTerm } from '@modules/KeyTerm'
import UnstyledList from '@baseComponents/UnstyledList'
import { MetadataDetail } from '@modules/ContractDetail'

interface Props {
  keyTerms: KeyTerm[] | TermsMetadata
  details?: ResourceVersionMetadataDetails
  enableEdit?: boolean
}

const KeyTermsList: React.FC<Props> = ({ keyTerms, details, enableEdit = true }: Props) => {
  const keyTermItems = (keyTerms as KeyTerm[]).map((keyTerm, idx) => {
    const detail = (
      keyTerm.key && details ? details[keyTerm.key as keyof typeof details] ?? {} : {}
    ) as MetadataDetail
    return <KeyTermItem key={idx} keyTerm={keyTerm} detail={detail} enableEdit={enableEdit} />
  })

  return <UnstyledList>{keyTermItems}</UnstyledList>
}

export default KeyTermsList
