import { useContext } from 'react'
import { MessageBar, MessageBarType, Stack, mergeStyles } from '@fluentui/react'

import TopNav from '@components/TopNav'
import SimilarClausesList from '@components/SimilarClausesList'
import { SimilarClausesContext } from '@contexts/SimilarClausesContext'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { AnalysisContext } from '@contexts/AnalysisContext'
import { useTranslation } from '@hooks/useTranslation'

// Render the Similar Clauses view that displays Clauses similar to
// the selected clause in the document

mergeStyles({
  ':global(.waiting, .waiting *)': {
    cursor: 'wait',
  },
})

const pageTitle = 'Similar Clauses'

export default function SimilarClauses() {
  const { loading, topOfPageRef, Pagination } = useContext(SimilarClausesContext)
  const { hasSelection } = useContext(AnalysisContext)
  const { t } = useTranslation()

  useContractTaskPaneViewed({ pageTitle })

  return (
    <div ref={topOfPageRef} className={loading && hasSelection ? 'waiting' : ''}>
      <TopNav title={pageTitle} showAIBadge />

      <MessageBar
        style={{ fontSize: '1.1em', marginBottom: '0.2em' }}
        styles={{ root: { display: hasSelection ? 'none' : undefined } }}
        messageBarType={MessageBarType.info}
      >
        {t('page.SimilarClauses.No Selection Warning')}
      </MessageBar>

      <Pagination>
        <SimilarClausesList />
      </Pagination>
    </div>
  )
}
