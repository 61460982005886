import { useContext, useEffect } from 'react'
import { IChoiceGroupOption } from '@fluentui/react'
import {
  dateValidator,
  multiTextValidator,
  positiveNumberValidator,
  textValidator,
  type ContractMetadata,
  type ValidatorValue,
  type ValidatorFunc,
} from '@blaw/contracts-api-schema'

import { KeyTerm, KeyTermData, DataType } from '@modules/KeyTerm'
import { parseShort, shortDate } from '@modules/utils'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import { generateNewKeyTerm } from '@modules/KeyTermValueParsers'
import { KeyTermFormContext } from '@contexts/KeyTermFormContext'
import useDocumentSelection from '@hooks/useDocumentSelection'
import { IComboBox, IComboBoxOption } from '@fluentui/react'
import { tagAdded, tagDeleted } from '@modules/analytics'

const useKeyTermForm = (keyTermType?: string, dataType?: DataType) => {
  const selection = useDocumentSelection()
  const {
    selectedKeyTerm,
    setFormValid,
    setNewKeyTerm,
    newKeyTerm,
    dismissKeyTermForm,
    setSubmittingKeyTerm,
    metadataConfig,
    loadingMetadataConfig,
  } = useContext(KeyTermsContext)
  const { loadContract, formError, setFormError, setValidationError } =
    useContext(KeyTermFormContext)

  useEffect(() => {
    if (!selectedKeyTerm && !loadingMetadataConfig && metadataConfig) {
      setNewKeyTerm(generateNewKeyTerm(metadataConfig.value, keyTermType, dataType))
    } else {
      setNewKeyTerm({ ...selectedKeyTerm })
      setFormValid(false)
    }

    setValidationError('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyTermType, selectedKeyTerm, loadingMetadataConfig])

  const copyFromSelection = () => {
    selectedKeyTerm && validateKeyTerm(selectedKeyTerm)

    if (dataType !== 'TEXT') {
      const newKeyTermData = newKeyTerm?.data
      const data = { ...newKeyTermData, notes: selection }
      setNewKeyTerm({ ...newKeyTerm, data } as KeyTerm)
      return
    }

    setNewKeyTerm({ ...newKeyTerm, value: selection } as KeyTerm)
    handleValidation<string>(selection, textValidator)
  }

  const updateStringKeyTerm = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.currentTarget.value

    setNewKeyTerm({ ...newKeyTerm, value } as KeyTerm)
    handleValidation<string>(value, textValidator)
  }

  const updateKeyTermNotes = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.currentTarget.value
    const updatedKeyTermData = { ...newKeyTerm?.data } as KeyTermData

    updatedKeyTermData.notes = newValue

    if (newKeyTerm) validateKeyTerm(newKeyTerm)
    setNewKeyTerm({ ...newKeyTerm, data: updatedKeyTermData } as KeyTerm)
  }

  const updateNumericKeyTerm = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.currentTarget.value

    setNewKeyTerm({ ...newKeyTerm, value } as KeyTerm)
    handleValidation<number>(parseInt(value), positiveNumberValidator)
  }

  const updateDateKeyTerm = (value: Date | null | undefined) => {
    setNewKeyTerm({ ...newKeyTerm, value: shortDate(value as Date) } as KeyTerm)
    handleValidation<Date>(value as Date, dateValidator)
  }

  const updateBooleanKeyTerm = (
    _event: React.FormEvent<HTMLInputElement | HTMLElement> | undefined,
    option: IChoiceGroupOption | undefined,
  ) => {
    let bool
    if (option?.key === 'Yes') bool = true
    if (option?.key === 'No') bool = false

    setNewKeyTerm({ ...newKeyTerm, value: bool } as KeyTerm)
    setFormValid(true)
  }

  const updateChoiceTextKeyTerm = (
    _event: React.FormEvent<IComboBox>,
    option: IComboBoxOption | undefined,
    _index: number | undefined,
    _value: string | undefined,
  ) => {
    setNewKeyTerm({ ...newKeyTerm, value: option?.key } as KeyTerm)
    setFormValid(!!(option && option?.key !== undefined))
  }

  const updateMultiTextField = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    values: string[],
  ) => {
    newKeyTerm && setNewKeyTerm({ ...newKeyTerm, value: values })
    handleValidation<string[]>(values, multiTextValidator)
  }

  function handleValidation<T extends ValidatorValue>(value: T, validator: ValidatorFunc<T>) {
    let isValid = false
    let errorMessage = ''

    try {
      isValid = validator(value)
    } catch (e) {
      errorMessage = (e as Error).message
    }

    setFormValid(isValid)
    console.debug({ isValid }) // debug
    setValidationError(errorMessage)
  }

  function validateKeyTerm(keyTerm: KeyTerm) {
    const type = keyTerm?.type
    let existingDate: Date | undefined

    switch (type) {
      case 'BOOLEAN':
      case 'CHOICE_TEXT':
        setFormValid(typeof keyTerm?.value !== 'undefined')
        return
      case 'DATE':
        existingDate =
          keyTerm?.type === 'DATE' && typeof keyTerm.value === 'string'
            ? parseShort(keyTerm.value)
            : undefined
        handleValidation<Date>(existingDate as Date, dateValidator)
        return
      case 'MULTI_TEXT':
        handleValidation<string[]>(keyTerm.value as string[], multiTextValidator)
        return
      case 'TEXT':
        handleValidation<string>(keyTerm.value as string, textValidator)
        return
      case 'CURRENCY':
      case 'DURATION_DAYS':
      case 'DURATION_MONTHS':
        handleValidation<number>(parseInt(keyTerm.value as string), positiveNumberValidator)
        return
      default:
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Unknown Component Type: ${type}`)
    }
  }

  const handleFormSubmit = async (
    formType: 'add' | 'edit',
    metadata: ContractMetadata,
    e: React.FormEvent<HTMLFormElement>,
    submit: (payload: ContractMetadata, e: React.FormEvent<HTMLFormElement>) => Promise<any>,
  ): Promise<void> => {
    tagAdded({
      isLoggedIn: true,
      pageTitle: 'Key Terms',
      annotationStatus: [formType === 'add' ? 'New' : 'Existing'],
      annotationType: ['Key Terms'],
    })

    try {
      setSubmittingKeyTerm(true)
      await submit(metadata, e)
      dismissKeyTermForm()
      loadContract()
    } catch (e) {
      console.error(e)
      setFormError(`Failed to update Key Terms: ${(e as Error).message}`)
    }
    setSubmittingKeyTerm(false)
  }

  const handleDeleteKeyTerm = async (
    term: KeyTerm,
    e: React.FormEvent<HTMLFormElement>,
    submit: (payload: KeyTerm, e: React.FormEvent<HTMLFormElement>) => Promise<any>,
  ): Promise<void> => {
    try {
      tagDeleted({
        isLoggedIn: true,
        pageTitle: 'Key Terms',
        annotationStatus: ['Existing'],
        annotationType: ['Key Terms'],
      })

      setSubmittingKeyTerm(true)
      await submit(term, e)
      dismissKeyTermForm()
      loadContract()
    } catch (e) {
      console.error(e)
      setFormError(`Failed to update Key Terms: ${(e as Error).message}`)
    }
    setSubmittingKeyTerm(false)
  }

  return {
    formError,
    handleFormSubmit,
    handleDeleteKeyTerm,
    newKeyTerm,
    setFormValid,
    updateBooleanKeyTerm,
    updateKeyTermNotes,
    updateDateKeyTerm,
    updateMultiTextField,
    updateStringKeyTerm,
    copyFromSelection,
    updateNumericKeyTerm,
    updateChoiceTextKeyTerm,
  }
}

export default useKeyTermForm
