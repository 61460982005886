import {
  Contract,
  type MetadataConfig as MetadataConfigInterface,
  type MetadataConfigValue,
} from '@blaw/contracts-api-schema'
import ApiClient from './ApiClient'
import Routes from './routes'
import { ADD_IN_HOST } from '@src/constants'

const routes = new Routes()

export interface MetadataConfig {
  error?: string
  value: MetadataConfigValue[]
  getLabel: (uiKey: string) => string
}

export async function getMetadataConfig(): Promise<MetadataConfig> {
  const apiClient = new ApiClient()
  let config = {} as MetadataConfigInterface
  let error = undefined

  try {
    const { data } = await apiClient.get<MetadataConfigInterface>(routes.metadataConfigUrl)
    config = data
  } catch (e) {
    error = (e as Error).message ?? 'Unknown error'

    // When running locally, the above config endpoint doesn't exist.
    // Provide a fallback in that case.
    if (error.includes('404') && ADD_IN_HOST.includes('local')) {
      const metadataConfig = await import('../../tests/fixtures/metadataConfig.json')
      // @ts-ignore
      config = metadataConfig
      error = undefined
    }
  }

  const value = Contract.getDataPointValues(config)

  return {
    error,
    value,
    getLabel(uiKey) {
      if (!(uiKey in config)) {
        return ''
      }
      return config[uiKey].label
    },
  }
}
