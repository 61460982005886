import { useState } from 'react'
import {
  Callout,
  mergeStyleSets,
  Text,
  DirectionalHint,
  ActionButton,
  IconButton,
  CommunicationColors,
  Stack,
} from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'
import { LightTheme } from '@src/themes'
import LinkButton from '@components/LinkButton'
import { navigateToExternalSite } from '@modules/utils'

type Props = {
  buttonLabel: string
  calloutTitle: string
  calloutText: string
  calloutLink: string
  calloutLinkText: string
}

export default function SideCallout({
  buttonLabel,
  calloutTitle,
  calloutText,
  calloutLink,
  calloutLinkText,
}: Props) {
  const [calloutHidden, setCalloutHidden] = useState(true)
  const buttonId = useId('callout-button')

  function onDismiss() {
    setCalloutHidden(!calloutHidden)
  }

  return (
    <>
      <ActionButton id={buttonId} onClick={onDismiss} className={styles.button}>
        {buttonLabel}
      </ActionButton>
      {!calloutHidden && (
        <Callout
          className={styles.callout}
          role="dialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={() => setCalloutHidden(true)}
          directionalHint={DirectionalHint.leftCenter}
        >
          <Stack>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginBottom: '5%',
              }}
            >
              <h4 className={styles.title}>{calloutTitle}</h4>
              <IconButton
                className={styles.close}
                iconProps={{ iconName: 'Cancel' }}
                onClick={onDismiss}
              />
            </div>
            <Text>{calloutText}</Text>
            <LinkButton
              onClick={() => navigateToExternalSite(calloutLink)}
              iconName="OpenInNewTab"
              buttonStyles={{ root: { marginTop: '10%' } }}
            >
              {calloutLinkText}
            </LinkButton>
          </Stack>
        </Callout>
      )}
    </>
  )
}

const styles = mergeStyleSets({
  button: {
    display: 'flex',
    background: 'white',
    color: LightTheme.palette.neutralPrimary,
    boxShadow: `0 -2px 5px 0 ${LightTheme.palette.neutralTertiary}`,
    float: 'right',
    transform: 'rotate(-90deg) translate(-50%, 100%)',
    position: 'fixed',
    right: '0',
    bottom: '50%',
    ':hover, :active': {
      color: 'inherit',
      background: CommunicationColors.tint20,
    },
  },
  callout: {
    width: '80%',
    padding: '20px 24px',
  },
  title: {
    margin: '2% 0 0',
  },
  close: {
    color: LightTheme.palette.neutralPrimary,
    ':hover, :active': {
      color: 'inherit',
    },
  },
})
