import UnstyledButton from '@baseComponents/UnstyledButton'

type Props = {
  show: boolean
  href?: string
}

export default function AIBadge({ show, href }: Props) {
  return (
    <UnstyledButton
      href={href || 'https://www.bloombergindustry.com/ai-reminder'}
      target="_blank"
      iconProps={{ iconName: 'AIIndicator' }}
      ariaLabel="Bloomberg AI"
      className="on-hover-fade-75 on-active-fade-50"
      style={{
        translate: '-0.7em 0.3em',
        display: show ? 'inline-block' : 'none',
      }}
    />
  )
}
