import { useState } from 'react'

import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IComboBoxProps,
  IComboBoxStyles,
  IOnRenderComboBoxLabelProps,
  IRenderFunction,
} from '@fluentui/react'
import React from 'react'

interface Props {
  label?: string
  value?: string
  multiselect?: boolean
  options: IComboBoxOption[]
  selectedKeys?: string[]
  text?: string
  placeholder?: string
  style?: Partial<IComboBoxStyles>
  disabled?: boolean
  comboBoxProps?: Partial<IComboBoxProps>
  onChange: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string,
  ) => void
  onRenderLabel?: IRenderFunction<IOnRenderComboBoxLabelProps>
  labeledBy?: string
}

export default function ComboboxField({
  label,
  value,
  multiselect,
  options,
  selectedKeys,
  text,
  disabled,
  placeholder,
  style,
  comboBoxProps,
  onChange,
  onRenderLabel,
  labeledBy,
}: Props) {
  const [filteredOptions, setFilteredOptions] = useState<IComboBoxOption[]>(options)
  const placeholderText = placeholder ?? `${options.length ? 'Select or ' : ''}Enter ${label}`
  const comboBoxStyles: Partial<IComboBoxStyles> = {
    optionsContainerWrapper: { maxHeight: '145px' },
  }
  options.map(o => {
    o.styles = { optionText: { overflow: 'visible', whiteSpace: 'normal' } }
  })

  const onInputValueChange = (value: string) => {
    value = value.trim()
    if (value === '') setFilteredOptions(options)
    else if (value)
      setFilteredOptions(
        options.filter(
          option =>
            (selectedKeys && selectedKeys.includes(option.key.toString())) ||
            option.text.toLowerCase().includes(value.toLowerCase()),
        ),
      )
  }

  return (
    <ComboBox
      label={label}
      aria-labelledby={onRenderLabel ? labeledBy : undefined}
      onRenderLabel={onRenderLabel}
      styles={{ ...comboBoxStyles, ...style }}
      iconButtonProps={options.length ? {} : { style: { visibility: 'hidden' } }}
      placeholder={placeholderText}
      multiSelect={multiselect}
      options={filteredOptions}
      text={text ?? value}
      onChange={onChange}
      onInputValueChange={onInputValueChange}
      allowFreeInput
      allowFreeform
      openOnKeyboardFocus
      useComboBoxAsMenuWidth
      autoComplete="off"
      disabled={disabled}
      selectedKey={selectedKeys ?? null}
      onMenuDismissed={() => setFilteredOptions(options)}
      {...comboBoxProps}
    />
  )
}
