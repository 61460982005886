import { CSSProperties, PropsWithChildren } from 'react'
import { ITextProps, Text as FluentText } from '@fluentui/react'

type Props = PropsWithChildren &
  ITextProps & {
    style?: CSSProperties
  }

export default function Text(props: Props) {
  const { style } = props
  return (
    <FluentText {...props} style={{ display: 'inline-block', marginBottom: '0.3em', ...style }}>
      {props.children}
    </FluentText>
  )
}
