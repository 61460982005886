import { TextField } from '@fluentui/react'
import styled from 'styled-components'

// remove the * from required fields
// prefer adding (optional) suffix to labels of non-required fields
export const StyledTextField: typeof TextField = styled(TextField)`
  label {
    text-transform: capitalize;
    margin-right: 1em;
  }
`
