import { Context, CSSProperties } from 'react'

import { StyledDivider } from '@baseComponents/StyledDivider'
import { FilterContext } from '@pages/FilterMenu'
import { FacetLabel, FacetListItem, FilterState } from '@modules/ClauseAnalyzer'
import Facet from '@components/Facet'

type Props = {
  group: FacetListItem[]
  facetLabels: FacetLabel
  filterState: FilterState
  context: Context<FilterContext>
  facetsMinimized?: boolean
  multiSelect?: boolean
  loading?: boolean
  styles?: CSSProperties
  onRenderLabel?: (label: string) => string | JSX.Element
}

export default function FacetGroups({
  group,
  facetLabels,
  filterState,
  facetsMinimized,
  context,
  loading,
  styles,
  multiSelect = true,
  onRenderLabel = (l: string) => l,
}: Props) {
  const defaultStyles: CSSProperties = {
    padding: '0 0.5em 1em',
    overflowY: 'auto',
    flex: '1 1 auto',
    opacity: loading ? 0.5 : 1,
    cursor: loading ? 'wait' : 'initial',
    ...styles,
  }
  return (
    <div style={defaultStyles}>
      {group.map(({ field, values }, i) => (
        <Facet
          key={i}
          facet={values}
          facetName={field}
          context={context}
          multiSelect={multiSelect}
          facetsMinimized={facetsMinimized}
          filters={filterState[field] || []}
          label={onRenderLabel(facetLabels[field])}
          Footer={() => (i < group.length - 1 ? <StyledDivider /> : null)}
        />
      ))}
    </div>
  )
}
