import { IMessageBarStyles, IStyle, MessageBar, MessageBarType } from '@fluentui/react'

type Props = {
  message?: string | null
  type?: MessageBarType
  styles?: IStyle
}

export default function ErrorMessage({ message, type = MessageBarType.error, styles }: Props) {
  if (!message) return null

  const baseStyles: IMessageBarStyles = {
    root: {
      marginBottom: '0.7em',
      ...(styles as any), // ts complains styles is not spreadable but it is
    },
  }

  return (
    <MessageBar styles={baseStyles} messageBarType={type}>
      {message}
    </MessageBar>
  )
}
