import ApiClient from './ApiClient'
import Routes from './routes'

const apiClient = new ApiClient()
const routes = new Routes()

export type ResourceUploadLink = {
  url: string
  fields: S3Fields
}

export interface S3Fields {
  [name: string]: string
}

export async function GetResourceUploadLink(count = 1) {
  const url = routes.contractUploadLink(count)
  const response = await apiClient.post<ResourceUploadLink[]>(url, {})

  return response.data[0]
}

export async function GetAnalysisUploadLink(contentHash: string) {
  const url = routes.getDocumentAnalysisUploadUrl(contentHash)
  const response = await apiClient.get<ResourceUploadLink>(url)
  return response.data
}
