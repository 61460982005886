import * as React from 'react'
import { ActionButton, BaseButton, IButtonProps } from '@fluentui/react'
import { MorePagesIconName } from '@components/Icons'

interface Props extends IButtonProps {
  label?: string
  iconName?: string
  disabled?: boolean
  onClick?: React.MouseEventHandler<BaseButton>
}

export const LoadMoreButton: React.FC<Props> = ({
  label = 'Load More',
  iconName = MorePagesIconName,
  disabled,
  onClick,
  ...props
}) => {
  return (
    <ActionButton
      {...props}
      type="button"
      onClick={onClick}
      iconProps={{ iconName }}
      disabled={disabled}
    >
      {label}
    </ActionButton>
  )
}
