import { FC, PropsWithChildren } from 'react'
import {
  FontSizes,
  IMessageBarStyles,
  MessageBar,
  MessageBarButton,
  MessageBarType,
  NeutralColors,
  SharedColors,
} from '@fluentui/react'
import { friendlyDateTime } from '@modules/utils'
import styled from 'styled-components'
import { useTranslation } from '@hooks/useTranslation'

type AutoRecoverDialogProps = PropsWithChildren & {
  onConfirm?: (...args: any) => void
  onDismiss?: () => void
  lastModifiedDateTime?: string
}

export const MessageHeader = styled.div`
  font-weight: bold;
  padding-bottom: 0.7em;
`

const messageBarWarnStyles: IMessageBarStyles = {
  root: {
    boxShadow: `0px 3px 6px 0px ${NeutralColors.gray60}, 0px -3px 1px ${SharedColors.orange10}`,
  },
  innerText: {
    fontSize: FontSizes.size14,
    padding: '0.15em 0.35em 0.2em',
  },
  icon: {
    fontSize: FontSizes.size16,
    color: SharedColors.orange10,
  },
}

const AutoRecoverDialog: FC<AutoRecoverDialogProps> = ({
  onConfirm,
  onDismiss,
  lastModifiedDateTime = new Date().toISOString(),
}) => {
  const { t } = useTranslation()

  return (
    <MessageBar
      styles={messageBarWarnStyles}
      onDismiss={onDismiss}
      dismissButtonAriaLabel="Close"
      messageBarType={MessageBarType.warning}
      actions={
        <div>
          <MessageBarButton onClick={onConfirm}>Sync File</MessageBarButton>
          <MessageBarButton onClick={onDismiss}>Ignore Changes</MessageBarButton>
        </div>
      }
    >
      <MessageHeader>Unsynchronized File Changes</MessageHeader>
      <div>
        <p style={{ marginTop: '0px' }}>
          We have saved a temporary copy of this file to Bloomberg Contract Solutions with recent
          changes not reflected here. You may synchronize this file to retrieve those changes.
        </p>
        <p style={{ marginBottom: '0px' }}>
          {t('label.ContractMetadata.last-modified')}: {friendlyDateTime(lastModifiedDateTime)}
        </p>
      </div>
    </MessageBar>
  )
}

export default AutoRecoverDialog
