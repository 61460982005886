import { type ResourceVersionMetadataDetails } from '@blaw/contracts-api-schema'

import LocalStorage from './LocalStorage'
import { selectDetailInDocument } from './DocumentDefinitions'

const storage = LocalStorage()

type DetailsUnion = ResourceVersionMetadataDetails[keyof ResourceVersionMetadataDetails]
export type MetadataDetail = Exclude<DetailsUnion, undefined>

export function hasLocationInfo(detail: MetadataDetail) {
  return (
    detail.system_extracted === true &&
    detail.documentId &&
    !!detail.paragraphData?.[0] &&
    !!detail.offsets?.[0]
  )
}

export function storePageToNavigateTo(route: string) {
  storage.setItem('navigateToPage', route)
}

export function loadAndRemoveStoredPage() {
  const route = storage.getItem('navigateToPage')
  if (!route) return
  storage.removeItem('navigateToPage')
  return route
}

export function storeDetailInfoInLocalStorage(detail: MetadataDetail, idx: number) {
  storage.setItem('extractedObligation', JSON.stringify({ detail, idx }))
}

export function handleDetailInLocalStorage() {
  const storedDetailJson = storage.getItem('extractedObligation')
  if (!storedDetailJson) return

  const storedDetail = JSON.parse(storedDetailJson)
  selectDetailInDocument(storedDetail.detail, storedDetail.idx)

  storage.removeItem('extractedObligation')
}
