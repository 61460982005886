import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react'
import useDocumentSelection from '@hooks/useDocumentSelection'
import { Typify, EDGAR } from '@modules/ClauseAnalyzer'
import { useDebounce } from '@hooks/useDebounce'

type Props = PropsWithChildren

interface AnalysisContextState {
  selection: string
  hasSelection: boolean
  analysisType: string
  setAnalysisType: (analysisType: string) => void
  allowedAnalysisTypes: string[]
}

export const AnalysisContext = createContext({} as AnalysisContextState)

const typify = new Typify()

const AnalysisContextProvider: FC<Props> = (props: Props) => {
  const selection = useDocumentSelection()
  const [allowedAnalysisTypes, setAllowedAnalysisTypes] = useState([EDGAR])
  const [configLoaded, setConfigLoaded] = useState(false)
  const [analysisType, setAnalysisType] = useState(EDGAR)
  const hasSelection = selection.length > 0
  const debounceLoadAllowedAnalysisTypes = useDebounce(loadAllowedAnalysisTypes, 500, [])

  useEffect(() => {
    if (configLoaded) return
    debounceLoadAllowedAnalysisTypes()
  })

  async function loadAllowedAnalysisTypes() {
    setConfigLoaded(true)
    const allowedRealms = await typify.getRealms()
    setAllowedAnalysisTypes(allowedRealms)
  }

  const value: AnalysisContextState = {
    selection,
    hasSelection,
    analysisType,
    setAnalysisType,
    allowedAnalysisTypes,
  }
  return <AnalysisContext.Provider value={value}>{props.children}</AnalysisContext.Provider>
}

export default AnalysisContextProvider
