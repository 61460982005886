import { DefaultButton, FontWeights } from '@fluentui/react'
import styled from 'styled-components'

// Useful for wrapping headings or content that needs to be clickable.
// Use this instead of spans or divs because clickable elements should use an
// interactive element type for accessiblity.
const UnstyledButton = styled(DefaultButton)`
  all: unset;
  span {
    font-weight: ${FontWeights.regular};
  }
  display: inline-block;
  cursor: pointer;
`

export default UnstyledButton
