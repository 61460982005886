import { type MetadataConfigValue } from '@blaw/contracts-api-schema'
import { KeyTerm, KeyTermData, DataType, defaultDataType } from '@modules/KeyTerm'
import { IComboBoxOption } from '@fluentui/react'

const nullishString = ''
const nullishMultiText: string[] = ['']
const nullishBoolean = null
const nullishData = { notes: '', block: '', text: '' }

const defaultNullishValue = new Map<string, string | string[] | boolean | null | KeyTermData>()
defaultNullishValue.set('currency', nullishString)
defaultNullishValue.set('DurationDays', nullishString)
defaultNullishValue.set('DurationMonths', nullishString)
defaultNullishValue.set('date', nullishString)
defaultNullishValue.set('string', nullishString)
defaultNullishValue.set('Boolean', nullishBoolean)
defaultNullishValue.set('Array', nullishMultiText)
defaultNullishValue.set('data', nullishData)

function choiceTextParser(keyTermValue: string, items: IComboBoxOption[] | undefined) {
  const selectedItem = items?.find(item => item.key === keyTermValue)
  if (!selectedItem) {
    console.warn(
      `Unexpected selected value in choice text doesn't exist in list of options: ${keyTermValue}`,
    )
    return keyTermValue
  }
  return selectedItem.text
}

function durationMonthsParser(keyTermValue: string) {
  const isPlural = keyTermValue.toString() !== '1'
  return `${keyTermValue} ${isPlural ? 'months' : 'month'}`
}

function durationDaysParser(keyTermValue: string) {
  const isPlural = keyTermValue.toString() !== '1'
  return `${keyTermValue} ${isPlural ? 'days' : 'day'}`
}

function currencyParser(keyTermValue: string, currencySymbol = '$') {
  return `${currencySymbol}${keyTermValue}`
}

function dateParser(keyTermValue: string) {
  return new Date(keyTermValue.replace('Z', ''))
    .toLocaleString('en-US', { timeZone: 'UTC' })
    .split(',')[0]
}

function booleanParser(keyTermValue: boolean) {
  return keyTermValue ? 'Yes' : 'No'
}

function multiTextParser(keyTermValue: string[]) {
  return keyTermValue.join(', ')
}

function parseKeyTermValue({ type, value, items }: KeyTerm) {
  switch (type) {
    case 'BOOLEAN':
      return booleanParser(value as boolean)
    case 'DATE':
      return dateParser(value as string)
    case 'MULTI_TEXT':
      return multiTextParser(value as string[])
    case 'TEXT':
    case 'CHOICE_TEXT':
      return choiceTextParser(value as string, items)
    case 'CURRENCY':
      return currencyParser(value as string)
    case 'DURATION_DAYS':
      return durationDaysParser(value as string)
    case 'DURATION_MONTHS':
      return durationMonthsParser(value as string)
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unknown Component Type: ${type}`)
  }
}

function filterNonEmptyKeyTerms(keyTerms: KeyTerm[]) {
  return keyTerms.filter(point => containsValue(point))
}

function filterEmptyKeyTerms(keyTerms: KeyTerm[]) {
  return keyTerms.filter(point => !containsValue(point))
}

function containsValue({ type, value }: KeyTerm): boolean {
  let v
  switch (type) {
    case 'CURRENCY':
    case 'DURATION_DAYS':
    case 'DURATION_MONTHS':
    case 'DATE':
    case 'TEXT':
    case 'CHOICE_TEXT':
      return Boolean(value?.toString().length)
    case 'BOOLEAN':
      // we want to return true if the 'value' argument is (true || false)
      // if we were to use Boolean(value) to check for truthiness,
      // this function would only return true when the value is true
      return typeof value === 'boolean'
    case 'MULTI_TEXT':
      v = value?.toString()
      return Boolean((v && v.length > 1) || (v && v.length === 1 && v[0] !== ''))
    default:
      throw new Error(`Unknown Component Type: ${type}`)
  }
}

function generateNewKeyTerm(
  metadataConfig: MetadataConfigValue[],
  label = '',
  type: DataType = defaultDataType,
): KeyTerm {
  const keyTerm = metadataConfig.find(val => val.label === label)

  let items: IComboBoxOption[] = []
  if (keyTerm?.items) {
    items = mapMetadataItemsToComboBoxOptions(keyTerm.items)
  }
  return {
    label,
    type,
    data: defaultNullishValue.get('data') as KeyTermData,
    value: defaultNullishValue.get(type) as string | boolean | string[] | undefined,
    key: keyTerm?.id || '',
    items: items,
  }
}

function mapMetadataItemsToComboBoxOptions(
  items: { value: string; label: string }[],
): IComboBoxOption[] {
  return items?.map(item => ({ key: item.value, text: item.label }))
}

export {
  parseKeyTermValue,
  filterNonEmptyKeyTerms,
  filterEmptyKeyTerms,
  defaultNullishValue,
  generateNewKeyTerm,
  mapMetadataItemsToComboBoxOptions,
}
