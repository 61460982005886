import { CSSProperties, PropsWithChildren } from 'react'
import BoldText from './BoldText'
import DOMPurify from 'dompurify'

type Props = PropsWithChildren & {
  title?: string
  text?: string
  style?: CSSProperties
}

export default function HighlightedText(props: Props) {
  if (!props.text) return <></>
  return (
    <BoldText style={props.style}>
      {props.title}:
      <span
        className="digest"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.text || '', { ALLOWED_TAGS: ['em'] }),
        }}
      ></span>
    </BoldText>
  )
}
