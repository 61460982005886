import ApiClient from './ApiClient'
import LocalStorage from './LocalStorage'

const storage = LocalStorage()
export const LOGIN_LOOP_DETECTED =
  'Could not establish session. Please clear cache and cookies, then try logging in again.'

// Call this right after logging in
export function onLoginEventOccurred() {
  storage.setItem('LoginEventState', 'true')
}

// Call this if an API call fails with an auth error 401 or 403
export default async function DetectLoginLoop() {
  if (!loginEventOccurred()) return

  storage.removeItem('LoginEventState')
  const apiClient = new ApiClient()
  if (await apiClient.isLoggedIn()) return

  throw Error(LOGIN_LOOP_DETECTED)
}

export function loginEventOccurred() {
  return Boolean(storage.getItem('LoginEventState'))
}
