import { NeutralColors, SharedColors } from '@fluentui/react'

interface Props {
  disabled: boolean
  onClick: () => void
}

function CopyFromSelectionIcon(props: Props) {
  const { disabled, onClick } = props
  const color = disabled ? SharedColors.gray10 : NeutralColors.black

  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 34 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      onClick={onClick}
      cursor={disabled ? 'not-allowed' : 'pointer'}
    >
      <title>Copy Highlighted Text</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-263.000000, -126.000000)" stroke="#3B3A39">
          <g id="Group" transform="translate(263.000000, 126.000000)">
            <rect
              id="Rectangle"
              strokeWidth="2"
              fill="#FFFFFF"
              x="1"
              y="1"
              width="23"
              height="28"
              stroke={color}
            ></rect>
            <line
              x1="6.5"
              y1="6.5"
              x2="19.5"
              y2="6.5"
              id="Line"
              strokeWidth="2"
              strokeLinecap="square"
              stroke={color}
            ></line>
            <line
              x1="6.5"
              y1="11.5"
              x2="19.5"
              y2="11.5"
              id="Line-Copy"
              strokeWidth="2"
              strokeLinecap="square"
              stroke={color}
            ></line>
            <line
              x1="6.5"
              y1="16.5"
              x2="19.5"
              y2="16.5"
              id="Line-Copy-2"
              strokeWidth="2"
              strokeLinecap="square"
              stroke={color}
            ></line>
            <line
              x1="6.5"
              y1="21.5"
              x2="19.5"
              y2="21.5"
              id="Line-Copy-3"
              strokeWidth="2"
              strokeLinecap="square"
              stroke={color}
            ></line>
            <path
              d="M33,6.41155136 L33,35 L10,35 L10,6.41155136 L33,6.41155136 Z"
              id="Rectangle-Copy"
              strokeWidth="2"
              fill="#FFFFFF"
              stroke={color}
            ></path>
            <polygon
              id="Path-3"
              fill="#3B3A39"
              points="19.0767089 16.3376223 24.3781251 21.6390385 19.3346505 26.6825131 18.570518 25.9183806 22.6314656 21.9888986 10 21.9888986 10 21 22.5 21 18.4449329 16.9937994"
              stroke={color}
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CopyFromSelectionIcon
