import { useParams, useSearchParams } from 'react-router-dom'

import { useDataInjection } from '@hooks/useDataInjection'

export default function useContextualContractId() {
  const params = useParams()
  const { resourceId: injectedContractId } = useDataInjection()
  const [searchParams] = useSearchParams()

  return params.id || params.contractId || searchParams.get('contractId') || injectedContractId
}
