import { Dispatch, SetStateAction, useEffect } from 'react'
import { MessageBar, MessageBarType, NeutralColors, Spinner } from '@fluentui/react'

import FloatingMessage from '@components/FloatingMessage'

type Props = {
  loadingMessage: string
  loadingDocument: boolean
  openDocumentError: string
  setOpenDocumentError: Dispatch<SetStateAction<string>>
  clearErrorDelay: number
}

// To be used together with useDocumentOpen hook
export default function DocumentOpenStatus(props: Props) {
  const { loadingDocument, openDocumentError, setOpenDocumentError, clearErrorDelay } = props

  useEffect(() => {
    if (openDocumentError)
      setTimeout(() => {
        setOpenDocumentError('')
      }, clearErrorDelay)
  }, [openDocumentError])

  return (
    <>
      <FloatingMessage show={openDocumentError?.length > 0}>
        <MessageBar
          messageBarType={MessageBarType.error}
          onDismiss={() => setOpenDocumentError('')}
          dismissButtonAriaLabel="Close"
        >
          {openDocumentError}
        </MessageBar>
      </FloatingMessage>

      <FloatingMessage
        show={loadingDocument && !openDocumentError}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0.4em 0.7em',
          backgroundColor: NeutralColors.gray20,
          justifyContent: 'space-between',
        }}
      >
        <span>{props.loadingMessage}</span>
        <Spinner />
      </FloatingMessage>
    </>
  )
}
