import { getCookie, removeCookie } from 'typescript-cookie'

import { type UserInfo } from '@blaw/contracts-analytics'
import LocalStorage from '@modules/LocalStorage'
import { Role } from './AccessControl'

export type SessionInfo = UserInfo & {
  loginError?: string | null
  returnPath?: string | null
  accessTime: string | null
  firstName: string | null
  lastName: string | null
  userRole: string | null
  userGroups: string | null
  trackingCti: string | null
}

export const SESSION_KEY = 'accessTime'
const SESSION_INFO_KEYS = [
  SESSION_KEY,
  'userId',
  'uuid',
  'firstName',
  'lastName',
  'customerId',
  'firmId',
  'unitno',
  'internalUserFlag',
  'userRole',
  'userGroups',
] as const
const storage = LocalStorage()

export function getSession(): SessionInfo {
  return {
    [SESSION_KEY]: getSessionId(),
    uuid: storage.getItem('uuid'),
    firstName: storage.getItem('firstName'),
    lastName: storage.getItem('lastName'),
    userId: storage.getItem('userId'),
    customerId: storage.getItem('customerId'),
    firmId: storage.getItem('firmId'),
    unitno: storage.getItem('unitno'),
    internalUserFlag: storage.getItem('internalUserFlag'),
    userRole: storage.getItem('userRole'),
    userGroups: storage.getItem('userGroups'),
    trackingCti: storage.getItem('trackingCti'),
  }
}

export function formatUserInfo(
  sessionCookie: string,
  userInfo: SessionInfo,
  returnPath: string | null = null,
): SessionInfo {
  return {
    [SESSION_KEY]: sessionCookie,
    returnPath: returnPath,
    uuid: userInfo.uuid,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    userId: userInfo.userId,
    customerId: userInfo.customerId,
    firmId: userInfo.firmId,
    unitno: userInfo.unitno,
    internalUserFlag: userInfo.internalUserFlag,
    userRole: userInfo.userRole ? userInfo.userRole : Role.Business,
    userGroups: JSON.stringify(userInfo.userGroups),
    trackingCti: userInfo.trackingCti,
  }
}

export function clearSession() {
  SESSION_INFO_KEYS.forEach(key => storage.removeItem(key))
  removeCookie(SESSION_KEY)
}

export function storeSession(info: SessionInfo) {
  Object.entries(info).forEach(([key, val]) => storage.setItem(key, val || ''))
}

export function getSessionId() {
  return getCookie(SESSION_KEY) ?? null
}
