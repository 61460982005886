import { Contract, Metadata, MetadataTypes, config, type Options } from '@blaw/contracts-api-schema'
import {
  type ContractCustomFieldsData,
  type CustomFieldState,
  type CustomFieldValueType,
  type CustomFieldValueNames,
} from '@components/ContractCustomFields'
import { yes, no } from '@components/ContractCustomFields/CustomBooleanField'
import {
  extractFields,
  formatCurrency,
  friendlyDate,
  removeFields,
  serialize,
} from '@modules/utils'

export type CustomFieldCategoryLabel = Contract.CustomFieldCategoryLabel

export const currencyChoices = Metadata.getMetadataChoiceItems('monetary_field')

// @ts-ignore TODO: fix type error with config, some fields missing, needs update
export const customFieldCategories = Contract.getCustomFieldCategories(config)

export const monetaryFieldPrefix = customFieldCategories.custom_monetary.prefix
export const monetaryFieldUnitSuffix = '_unit'
export const monetaryFieldUnitPrefix = monetaryFieldPrefix + monetaryFieldUnitSuffix

export function customFieldValueNameToValueType(name: CustomFieldValueNames, fields: Options) {
  const type = fields.find(({ label }) => label === name)?.value
  if (!type) throw new Error(`No custom field found for ${name} in ${serialize(fields)}`)

  return type
}

export function currencyKey(currency: CustomFieldValueType) {
  return currencyChoices.find(({ label }) => label === currency)?.value ?? currency?.toString()
}

export function customFieldKeyToLabel(key: string) {
  const [, category] =
    Object.entries(customFieldCategories).find(([_, { fields }]) => {
      return fields.some(({ value }) => value === key)
    }) ?? []
  if (!category) throw new Error(`No custom field category found for ${key}`)

  const field = category.fields.find(({ value }) => value === key)
  if (!field) throw new Error(`No custom field found for ${key} in ${serialize(category)}`)

  return field.label.toString()
}

// Monetary fields are split between value and unit. Merge the unit into the
// the value field so they can be displayed together in the view page and edit form
export function mergeCustomMonetaryFields(fields: ContractCustomFieldsData) {
  const units = extractFields(fields, key => key.includes('unit'))
  const values = extractFields(fields, key => key.includes('monetary') && !key.includes('unit'))
  const merged = Object.entries(values).reduce((merged, [key, val]) => {
    const unitKey = monetaryUnitKeyFromKey(key)
    const unit: CustomFieldValueType = units[unitKey]?.toString() ?? currencyChoices[0].label

    return { ...merged, [key]: formatCurrency(Number(val), currencyKey(unit)) }
  }, {})

  return { ...removeFields(fields, key => key.includes('unit')), ...merged }
}

// The inverse of mergeCustomMonetaryFields, needed for submitting data to backend
export function divergeCustomMonetaryFields(fields: ContractCustomFieldsData) {
  const values = extractFields(fields, key => key.includes('monetary') && !key.includes('unit'))

  return Object.entries(values).reduce((acc, [key, val]) => {
    if (!val) return acc

    const unit = extractCustomFieldUnitFromMergedValue(val.toString(), MetadataTypes.CURRENCY)
    const value = extractCustomFieldValueFromMergedValue(val.toString(), MetadataTypes.CURRENCY)
    const unitKey = monetaryUnitKeyFromKey(key)

    return { ...acc, [key]: value, [unitKey]: currencyKey(unit) }
  }, fields)
}

export function findCustomFieldsByType(type: MetadataTypes, fields: ContractCustomFieldsData) {
  const prefix = findCustomFieldPrefixByType(type)
  if (!prefix) {
    console.error(`No custom fields by type ${type} found in ${serialize(fields)}`)
    return {}
  }

  return extractFields(fields, key => key.startsWith(prefix))
}

export function getFieldNamesFromFieldOptions(
  fields: Options,
  customFields: ContractCustomFieldsData,
) {
  return fields
    .filter(({ value }) => Object.keys(customFields).includes(value as string))
    .map(({ label }) => label)
}

export function getCustomFieldKeyByLabel(label: CustomFieldValueNames, fields: Options) {
  return fields.find(({ label: l }) => l === label)?.value.toString() ?? label
}

export function transformMergedValueToCustomFieldState(
  type: MetadataTypes,
  value?: CustomFieldValueType,
): CustomFieldState {
  return {
    value: extractCustomFieldValueFromMergedValue(value, type),
    unit: extractCustomFieldUnitFromMergedValue(value, type),
  }
}

export function formatCustomFieldValue(key: string, value?: CustomFieldValueType) {
  let v = value?.toString() ?? ''

  if (key.includes('dates')) v = friendlyDate(v)
  if (key.includes('boolean')) v = v === 'true' ? yes : no

  return v
}

export function sortedCustomFields(fields: ContractCustomFieldsData) {
  return Object.entries(fields).sort(([a], [b]) =>
    customFieldKeyToLabel(a).localeCompare(customFieldKeyToLabel(b)),
  )
}

export function monetaryUnitKeyFromKey(key: string) {
  const [idx] = key.match(/_\d+$/) ?? []
  return `${monetaryFieldUnitPrefix}${idx}` as keyof ContractCustomFieldsData
}

export function monetaryValueKeyFromKey(key: string) {
  const [idx] = key.match(/_\d+$/) ?? []
  return `${monetaryFieldPrefix}${idx}` as keyof ContractCustomFieldsData
}

function findCustomFieldPrefixByType(type: MetadataTypes) {
  const [, { prefix }] = Object.entries(customFieldCategories).find(
    ([_, { type: t }]) => t === type,
  ) ?? [null, {}]

  return prefix
}

function currencyLabel(currency?: string) {
  return currencyChoices.find(({ value }) => value === currency)?.label ?? currency
}

// get currency amount from a formatted field value e.g. given $10, returns 10
function extractCustomFieldValueFromMergedValue(value: CustomFieldValueType, type: MetadataTypes) {
  if (type !== MetadataTypes.CURRENCY) return value
  return value?.toString().replace(/^\D+|,/g, '')
}

// get currency unit from a formatted field value e.g. given $10, returns USD
function extractCustomFieldUnitFromMergedValue(value: CustomFieldValueType, type: MetadataTypes) {
  if (type !== MetadataTypes.CURRENCY) return value

  return currencyLabel(value?.toString().replace(/-|,|\d+/g, ''))
}
