import { MessageBar, MessageBarType, Text } from '@fluentui/react'

import useVersions from '@hooks/useVersions'
import ConfirmDialog from '@components/ConfirmDialog'
import Routes from '@modules/routes'
import ApiClient from '@modules/ApiClient'
import { useContext, useState } from 'react'
import { StoreContext } from '@contexts/StoreContext'
import { deleteBtnStyles } from '@modules/sharedStyles'

const routes = new Routes()

interface Props {
  documentId: string
}

export default function DeleteVersionForm({ documentId }: Props) {
  const {
    deleteModalHidden,
    setDeleteModalHidden,
    selectedVersion,
    loadDocumentVersions,
    setSelectedForCompareOriginal,
    setSelectedForCompareModified,
  } = useVersions(documentId)
  const [deleting, setDeleting] = useState(false)
  const [error, setError] = useState(false)
  const { storeSessionInfo } = useContext(StoreContext)
  const apiClient = new ApiClient(storeSessionInfo, setError)

  return (
    <ConfirmDialog
      title="Confirm Delete Version"
      confirm="Delete"
      hidden={deleteModalHidden}
      disableBtn={deleting}
      onConfirm={deleteVersion}
      onDismiss={() => setDeleteModalHidden(true)}
      confirmBtnTitle="Delete Version"
      btnStyles={deleteBtnStyles}
    >
      <MessageBar messageBarType={MessageBarType.warning}>
        Once you delete a version from the system, you cannot retrieve it.
      </MessageBar>
      <Text style={{ display: 'block', marginTop: '1em' }}>
        Please confirm you want to delete Version {selectedVersion?.versionNumber}.
      </Text>
      {error && (
        <MessageBar styles={{ root: { margin: '1em 0' } }} messageBarType={MessageBarType.error}>
          Failed to delete version.
        </MessageBar>
      )}
    </ConfirmDialog>
  )

  async function deleteVersion() {
    if (!selectedVersion) return

    setDeleting(true)
    setError(false)

    const url = routes.editMetadataUrl(documentId, selectedVersion.version)

    try {
      await apiClient.put(url, {
        metadata: { userMetadata: { version_deleted: true } },
        resourceType: 'document',
      })
      setSelectedForCompareOriginal(undefined)
      setSelectedForCompareModified(undefined)
      loadDocumentVersions()
      setDeleteModalHidden(true)
    } catch (e) {
      console.error(e)
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 10000)
    } finally {
      setDeleting(false)
    }
  }
}
