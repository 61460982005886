import { FC, PropsWithChildren } from 'react'
import {
  FontSizes,
  IMessageBarStyles,
  MessageBar,
  MessageBarType,
  NeutralColors,
  SharedColors,
} from '@fluentui/react'

type AutoRecoverSuccessProps = PropsWithChildren & {
  onDismiss?: () => void
}

const messageBarSuccessStyles: IMessageBarStyles = {
  root: {
    boxShadow: `0px 3px 6px 0px ${NeutralColors.gray60}, 0px -3px 1px ${SharedColors.green10}`,
  },
  innerText: {
    fontSize: FontSizes.size14,
    padding: '0.15em 0.35em 0.2em',
  },
  icon: {
    fontSize: FontSizes.size16,
    color: SharedColors.green10,
  },
}

const AutoRecoverSuccess: FC<AutoRecoverSuccessProps> = ({ onDismiss }) => {
  return (
    <MessageBar
      styles={messageBarSuccessStyles}
      onDismiss={onDismiss}
      dismissButtonAriaLabel="Close"
      messageBarType={MessageBarType.success}
    >
      Current document was updated successfully. Previous revision of this document is available{' '}
      {Office.context.platform === Office.PlatformType.OfficeOnline
        ? 'from Version History menu'
        : 'using Undo operation (Ctrl+Z (Win) or Command+Z (Mac))'}
    </MessageBar>
  )
}

export default AutoRecoverSuccess
