import AnalysisStaleWarning from '@components/AnalysisStaleWarning'

interface Props {
  pageTitle: string
}

const AnalysisToolsStaleWarning = (props: Props) => {
  const { pageTitle } = props
  return (
    <AnalysisStaleWarning
      message="Edits were made to this document after it was analyzed. Click reanalyze to see updated
            issues and terms."
      showAnalyzeBtn={true}
      pageTitle={pageTitle}
    />
  )
}

export default AnalysisToolsStaleWarning
