import { webappDocumentUrl } from '@blaw/contracts-api-schema'
import { WEBAPP_HOST } from '../constants'
import Routes from './routes'

const webappHost = WEBAPP_HOST
const routes = new Routes()

export function webappVersionPath(documentId: string, versionId = 'latest') {
  return webappDocumentUrl(webappHost, documentId, versionId)
}

export function webappDocumentView(documentId: string) {
  return `${routes.authApiHost}/document/${documentId}`
}

export function webappTemplateView(documentId: string) {
  return `${routes.authApiHost}/template/${documentId}`
}
