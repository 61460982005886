import { Dispatch, SetStateAction } from 'react'
import { Spinner, SpinnerSize, Text } from '@fluentui/react'

import Dialog from '@modules/Dialog'
import { NoSession } from '@modules/ApiClient'
import { SessionInfo } from '@modules/SessionInfoStorage'
import Routes from '@modules/routes'
import { hardReloadPage } from '@modules/utils'
import ErrorMessage from '@components/ErrorMessage'
import { StoreSessionInfo } from '@contexts/StoreContext'
import logout from '@modules/logout'
import { onLoginEventOccurred } from '@modules/DetectLoginLoop'

export type SetError = Dispatch<SetStateAction<any>>

export default function Login(
  storeSessionInfo: StoreSessionInfo,
  setError?: SetError,
  checkingSession?: boolean,
) {
  loginPopup(storeSessionInfo, setError, checkingSession)
}

export function renderWaitingForLogin(error?: string) {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {error ? <ErrorMessage message={error} /> : renderWaiting()}
    </div>
  )
}

export function renderWaiting() {
  return (
    <>
      <Spinner size={SpinnerSize.large} />
      <Text variant={'xLarge'}>Waiting for login...</Text>
    </>
  )
}

export function loginPopup(
  storeSessionInfo: StoreSessionInfo,
  setError?: SetError,
  checkingSession?: boolean,
) {
  const dialog = new Dialog({ displayInIframe: false, promptBeforeOpen: false })
  dialog.openExternal(
    loginUrl(),
    25,
    75,
    async (data: string) =>
      await onLoginResponse(data, storeSessionInfo, setError, checkingSession),
  )
}

async function onLoginResponse(
  data: string,
  storeSessionInfo: StoreSessionInfo,
  setError?: SetError,
  checkingSession?: boolean,
) {
  let sessionInfo: SessionInfo | null = null
  let err: Error | null = null

  const info = JSON.parse(data)
  try {
    sessionInfo = info.formattedUserInfo

    if (sessionInfo?.loginError) {
      await logout(storeSessionInfo, setError)
      return hardReloadPage(`/index.html?loginError=${sessionInfo.loginError}`)
    }

    if (!(sessionInfo?.returnPath || sessionInfo?.accessTime)) {
      err = new Error(
        sessionInfo?.loginError || `No returnPath or sesssionId after login. Got: "${data}"`,
      )
    }
  } catch (e) {
    if ((e as Error).message !== NoSession) err = e as Error
  }

  if ((err && !checkingSession) || !sessionInfo)
    return setError?.call(null, err?.message || 'Login failed. Please, try again.')

  onLoginEventOccurred()
  storeSessionInfo(sessionInfo)
  hardReloadPage(`/index.html#${sessionInfo.returnPath}`)
  // add-in crashes in windows when we call location.reload immediately after hardReloadPage
  setTimeout(() => {
    window.location.reload()
  }, 1000)
}

export function loginUrl(returnPath = window.location.hash.replace('#', '')) {
  const { loginUrl, authApiUrl, addInHost } = new Routes()
  const dest = `${addInHost}/index.html?rp=${returnPath}`
  const target = `${authApiUrl}?session=on&dest=${dest}`

  return `${loginUrl}?target=${encodeURIComponent(target)}`
}
