import { HTMLAttributes } from 'react'

export default function BloombergLogoSmall(props: HTMLAttributes<HTMLElement>) {
  return (
    <div className="BloombergLogoSmall" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="163"
        height="26"
        viewBox="0 0 10 70"
        preserveAspectRatio="xMinYMin meet"
        version="1.1"
      >
        <path
          d="M27,29.4v-0.1c4.4-1.3,7.4-4.5,7.4-9.3c0-7.8-6.6-10.7-13-10.7H4.7v42.5H20c8.1,0,16.1-2.9,16.1-12.2
      C36.2,34.3,32.3,29.9,27,29.4 M12.3,15.8h6.3c5.7,0,8.2,1.8,8.2,5.5c0,3.2-2.5,5.4-7.6,5.4h-6.9V15.8z M19.3,45.4h-7V33.1h7.3
      c6.1,0,9,1.6,9,6C28.6,44.7,23.2,45.3,19.3,45.4 M68.9,22.2c-8.7,0-15.7,6.1-15.7,15.2s7,15.2,15.7,15.2s15.7-6.1,15.7-15.2
      S77.6,22.2,68.9,22.2 M68.9,46c-4.8,0.1-8.7-3.7-8.8-8.5c-0.1-4.8,3.7-8.7,8.5-8.8c4.8-0.1,8.7,3.7,8.8,8.5c0,0.1,0,0.1,0,0.2
      c0.1,4.6-3.5,8.5-8,8.6L68.9,46 M103.5,22.2c-8.7,0-15.7,6.1-15.7,15.2s7,15.2,15.7,15.2s15.7-6.1,15.7-15.2S112.2,22.2,103.5,22.2
      M103.5,46c-4.8,0.1-8.7-3.7-8.8-8.5s3.7-8.7,8.5-8.8c4.8-0.1,8.7,3.7,8.8,8.5c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2
      c0.1,4.6-3.5,8.4-8.1,8.6H103.5 M168.3,34.6v17.2h-7.2V35.5c0-3.6-1.1-6.7-5.4-6.7c-4.5,0-6.2,3.7-6.2,7.5v15.6h-7.2V34.6
      c0-3.5-1.4-5.9-4.9-5.9c-4.8,0-6.6,3.5-6.6,7.3v15.7h-7.2V23h6.9v4.5h0.1c1.3-2.7,4-5.2,8.8-5.2c4.4,0,7.5,1.7,9,5.4
      c2.1-3.7,5.2-5.4,9.5-5.4C165.6,22.3,168.3,27.8,168.3,34.6 M191.3,22.3c-3.8-0.2-7.4,1.4-9.8,4.4h-0.2V8.2h-7.2v43.5h6.9v-4.3h0.1
      c2.1,3.5,5.9,5,9.9,5c8.9,0,14.2-6.6,14.2-15.2s-5.7-15.2-14-15.2 M189.6,46c-4.8,0.1-8.8-3.7-8.9-8.4c-0.1-4.8,3.7-8.8,8.4-8.9
      s8.8,3.7,8.9,8.4c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0.2,4.6-3.4,8.5-8,8.7H189.6 M224.3,22.2c-8.7,0-15.7,6.1-15.7,15.2
      s7,15.2,15.7,15.2c4.8,0.1,9.3-2,12.2-5.8l-5.2-3.9c-1.8,2.5-4.7,3.9-7.8,3.9c-4,0.2-7.4-2.9-7.8-6.9h22v-2
      C237.8,27.9,232.3,22.2,224.3,22.2 M215.8,34.5c0.5-4.1,3.2-6.9,7.5-6.9c4.5,0,7.3,2.6,7.3,6.9H215.8z M258.4,22.2
      c0.9,0,1.7,0.2,2.6,0.4v7c-1.1-0.3-2.3-0.5-3.5-0.5c-4-0.3-7.5,2.7-7.7,6.7c0,0.2,0,0.4,0,0.5v15.4h-7.2V23h7.2v4.6h0.1
      C251.4,24.3,254.7,22.2,258.4,22.2 M286.2,23v4.3h-0.1c-2.1-3.5-5.9-5-9.9-5c-8.9,0-14.2,6.6-14.2,15.1c0,8.2,5.7,14.8,14.4,14.8
      c3.6,0,7.3-1.4,9.4-4.2h0.1v2.1c0,5.7-2.2,9.7-9.1,9.7c-3.7,0-7.3-1.5-9.9-4.2l-4.3,5.9c4.1,3.6,9,4.8,14.3,4.8
      c11.2,0,16.3-6.6,16.3-17V23L286.2,23z M277.6,45.7c-4.6,0.1-8.4-3.6-8.5-8.2c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
      c-0.2-4.5,3.2-8.3,7.7-8.6c0.3,0,0.6,0,0.8,0c5.2,0,8.5,3.5,8.5,8.5C286.2,42.1,283,45.7,277.6,45.7 M48.3,51.8H41V8.2h7.3
      L48.3,51.8z"
        />
        <path d="M320,9.5h7.5v35.6h18v6.7H320V9.5z" />
        <path
          d="M369.3,48.2h-0.2c-0.8,1.4-2,2.5-3.5,3.1c-1.7,0.8-3.6,1.3-5.4,1.2c-1.2,0-2.4-0.2-3.6-0.5
      c-1.2-0.3-2.4-0.8-3.5-1.5c-1.1-0.7-2-1.6-2.6-2.8c-0.7-1.3-1.1-2.7-1-4.2c-0.1-1.9,0.6-3.7,1.8-5.1c1.3-1.3,2.9-2.3,4.7-2.9
      c2.1-0.7,4.2-1.2,6.4-1.3c2.3-0.2,4.6-0.3,6.9-0.3v-0.7c0.1-1.6-0.6-3.1-1.9-4c-1.4-0.9-3-1.3-4.6-1.3c-1.5,0-3,0.4-4.4,1
      c-1.3,0.6-2.5,1.4-3.5,2.3l-3.7-4.4c1.6-1.5,3.6-2.7,5.6-3.4c2.1-0.7,4.3-1.1,6.5-1.1c2.2-0.1,4.3,0.3,6.3,1.1
      c1.5,0.6,2.9,1.6,3.9,2.8c0.9,1.1,1.6,2.5,2,3.9c0.4,1.4,0.6,2.9,0.6,4.3v17.5h-6.8L369.3,48.2z M369.2,38.8h-1.6
      c-1.2,0-2.4,0-3.6,0.2c-1.2,0.1-2.4,0.3-3.5,0.7c-1,0.3-1.9,0.8-2.7,1.4c-0.7,0.6-1.1,1.6-1.1,2.5c0,0.6,0.1,1.2,0.5,1.7
      c0.3,0.5,0.7,0.8,1.2,1.1c0.5,0.3,1,0.5,1.6,0.6c0.6,0.1,1.2,0.2,1.8,0.2c2.1,0.1,4.1-0.6,5.6-2c1.3-1.5,2-3.4,1.9-5.4L369.2,38.8z"
        />
        <path
          d="M377.8,23.2h7.8l6,20.6h0.1l6.2-20.6h7.7l6.2,20.6h0.1l6.1-20.6h7.5l-9.9,28.7h-7.3l-6.5-20.1h-0.1l-6.5,20.1
      h-7.3L377.8,23.2z"
        />
        <path
          d="M434.1,26.2c0,1.7-1.3,3.2-3.1,3.2c-1.7,0-3.2-1.3-3.2-3.1c0-1.7,1.3-3.2,3.1-3.2h0.1
      C432.6,23.1,434,24.4,434.1,26.2L434.1,26.2z M428.6,26.2c0,1.3,1,2.4,2.3,2.4c0,0,0,0,0,0h0.1c1.3,0,2.3-1.1,2.3-2.3c0,0,0,0,0,0
      c0,0,0,0,0-0.1c0-1.3-1.1-2.4-2.4-2.4C429.7,23.8,428.6,24.9,428.6,26.2L428.6,26.2L428.6,26.2z M430.4,27.8h-0.7v-3.1
      c0.4-0.1,0.8-0.1,1.2-0.1c0.4,0,0.7,0,1.1,0.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.4-0.3,0.6-0.6,0.7l0,0c0.3,0.1,0.5,0.4,0.6,0.8
      c0,0.3,0.1,0.5,0.2,0.8h-0.8c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1-0.3-0.2-0.5-0.6-0.5h-0.3V27.8z M430.4,26h0.3c0.4,0,0.7-0.1,0.7-0.5
      s-0.2-0.5-0.6-0.5c-0.1,0-0.3,0-0.4,0L430.4,26z"
        />
      </svg>
    </div>
  )
}
