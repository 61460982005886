import ApiClient from '@modules/ApiClient'
import { StoreSessionInfo } from '@contexts/StoreContext'
import { SetError } from '@components/Login'
import { LOGOUT_BFF_URL, LOGOUT_GK_BLAW_URL, LOGOUT_GK_INDUSTRY_URL } from '@src/constants'

// clears session cookies for all bloomberg domains
export default async function logout(storeSessionInfo?: StoreSessionInfo, setError?: SetError) {
  const apiClient = new ApiClient(undefined, setError)
  let loggedOut = true

  try {
    // Logout from Contracts BFF (Backend For Frontend authorizer)
    await apiClient.publicPost(LOGOUT_BFF_URL)
  } catch (e) {
    console.debug('BFF Error')
    console.error(e)
    loggedOut = false
  }

  try {
    // Logout from bloomberglaw domain
    await apiClient.publicGet(LOGOUT_GK_BLAW_URL, { withCredentials: true })
  } catch (e) {
    console.debug('GK BLAW Error')
    console.error(e)
    loggedOut = false
  }

  try {
    // Logout from bloombergindustry domain
    await apiClient.publicGet(LOGOUT_GK_INDUSTRY_URL, { withCredentials: true })
  } catch (e) {
    console.debug('GK INDUSTRY Error')
    console.error(e)
    loggedOut = false
  }

  storeSessionInfo && storeSessionInfo(null)
  return loggedOut
}
