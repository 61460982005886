import { useEffect, useContext } from 'react'
import { Stack, IStackTokens, ChoiceGroup } from '@fluentui/react'

import { ContractField, contractOriginOptions, contractToForm } from '@modules/Contract'
import StyledTextField from '@components/StyledTextField'
import useContractFormData from '@hooks/useContractFormData'
import ConfirmDialog from '@components/ConfirmDialog'
import MultiCombobox from '@components/MultiCombobox'
import { ContractContext } from '@contexts/ContractContext'
import ErrorMessage from '@components/ErrorMessage'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import ComboboxField from '@components/ComboboxField'
import { ContractsContext } from '@contexts/ContractsContext'
import ContractCustomFields from '@components/ContractCustomFields'
import { divergeCustomMonetaryFields } from '@modules/CustomFields'

type Props = {
  hidden: boolean
  setHidden: React.Dispatch<React.SetStateAction<boolean>>
}

const largeStackTokens: IStackTokens = {
  childrenGap: 'l1',
}

const requiredFields: Partial<ContractField[]> = ['contractFolderName']

const EditContractForm: React.FC<Props> = (props: Props) => {
  const { hidden, setHidden } = props
  const { contract, updateMetadata } = useContext(ContractContext)
  const { getFacetOptions } = useContext(ContractsContext)
  const { loadingMetadataConfig, metadataConfig } = useContext(KeyTermsContext)

  const {
    valid,
    change,
    updateComboboxField,
    formData,
    reset,
    handleEditContractFormSubmit,
    formError,
    updateFirstDraftOrigin,
    loading,
  } = useContractFormData(contractToForm(contract), requiredFields, setHidden)

  useEffect(() => {
    hidden || reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden])

  if (!contract || loadingMetadataConfig || !metadataConfig) return null

  const getLabel = metadataConfig.getLabel

  return (
    <ConfirmDialog
      title="Edit Contract"
      hidden={hidden}
      disableBtn={!valid || loading}
      confirm={loading ? 'Applying...' : 'Apply'}
      onConfirm={onSubmit}
      onDismiss={() => setHidden(true)}
      confirmBtnTitle={loading ? 'Applying Changes...' : 'Apply Changes'}
    >
      <form onSubmit={onSubmit}>
        <Stack tokens={largeStackTokens}>
          <Stack.Item>
            <StyledTextField
              required
              name="contractFolderName"
              label={getLabel('name')}
              placeholder={'Enter ' + getLabel('name').toLowerCase()}
              value={formData.contractFolderName}
              onChange={change}
              disabled={loading}
            />
          </Stack.Item>
          <Stack.Item>
            <ChoiceGroup
              options={contractOriginOptions}
              label={getLabel('first_draft_origin')}
              defaultSelectedKey={formData.firstDraftOrigin}
              value={formData.firstDraftOrigin}
              onChange={updateFirstDraftOrigin}
            />
          </Stack.Item>
          <Stack.Item>
            <ComboboxField
              label={getLabel('contract_type')}
              value={formData.contractType}
              options={getFacetOptions('contract_type')}
              onChange={(_event, _option, _index, value) =>
                updateComboboxField('contractType', value)
              }
              disabled={loading}
            />
          </Stack.Item>
          <Stack.Item>
            <MultiCombobox
              name="counterParty"
              label={getLabel('party_name')}
              placeholder={'Enter ' + getLabel('party_name').toLowerCase()}
              values={formData.counterParty}
              options={getFacetOptions('party_name')}
              onJoinValues={(_name, values) => updateComboboxField('counterParty', values)}
              disable={loading}
            />
          </Stack.Item>
          <Stack.Item>
            <MultiCombobox
              name="clientName"
              label={getLabel('client_name')}
              placeholder={'Enter ' + getLabel('client_name').toLowerCase()}
              values={formData.clientName}
              options={getFacetOptions('client_name')}
              onJoinValues={(_name, values) => updateComboboxField('clientName', values)}
              disable={loading}
            />
          </Stack.Item>
          <Stack.Item>
            <ComboboxField
              label={getLabel('contract_category')}
              value={formData.contractCategory}
              options={getFacetOptions('contract_category')}
              onChange={(_event, _option, _index, value) =>
                updateComboboxField('contractCategory', value)
              }
              disabled={loading}
            />
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              multiline
              autoAdjustHeight
              name="description"
              label={getLabel('description')}
              placeholder={'Enter ' + getLabel('description').toLowerCase()}
              value={formData.description}
              onChange={change}
              disabled={loading}
            />
          </Stack.Item>
          <Stack.Item>
            <MultiCombobox
              name="companyStakeholder"
              label={getLabel('company_stakeholder')}
              placeholder={'Enter ' + getLabel('company_stakeholder').toLowerCase()}
              values={formData.companyStakeholder}
              options={getFacetOptions('company_stakeholder')}
              onJoinValues={(_name, values) => updateComboboxField('companyStakeholder', values)}
              disable={loading}
            />
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              name="department"
              label={getLabel('department')}
              placeholder={'Enter ' + getLabel('department').toLowerCase()}
              value={formData.department}
              onChange={change}
              disabled={loading}
            />
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              name="city"
              label={getLabel('city')}
              placeholder={'Enter ' + getLabel('city').toLowerCase()}
              value={formData.city}
              onChange={change}
              disabled={loading}
            />
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              name="state"
              label={getLabel('state')}
              placeholder={'Enter ' + getLabel('state').toLowerCase()}
              value={formData.state}
              onChange={change}
              disabled={loading}
            />
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              name="country"
              label={getLabel('region')}
              placeholder={'Enter ' + getLabel('region').toLowerCase()}
              value={formData.country}
              onChange={change}
              disabled={loading}
            />
          </Stack.Item>
          <Stack.Item>
            <ComboboxField
              label={getLabel('transaction')}
              value={formData.transaction}
              options={getFacetOptions('transaction')}
              onChange={(_event, _option, _index, value) =>
                updateComboboxField('transaction', value)
              }
              disabled={loading}
            />
          </Stack.Item>
          <Stack.Item>
            <ComboboxField
              label={getLabel('contract_id')}
              value={formData.contractID}
              options={getFacetOptions('contract_id')}
              onChange={(_event, _option, _index, value) =>
                updateComboboxField('contractID', value)
              }
              disabled={loading}
            />
          </Stack.Item>

          <ContractCustomFields fieldData={formData} disabled={loading} onChange={change} />

          <Stack.Item
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
            }}
          ></Stack.Item>
        </Stack>
        <input type="submit" style={{ display: 'none' }} />
      </form>
      {formError && <ErrorMessage message={formError} />}
    </ConfirmDialog>
  )

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!contract) return

    const data = { ...formData, ...divergeCustomMonetaryFields(formData) }

    handleEditContractFormSubmit(
      e,
      contract.updatedMetadata(data),
      formData.contractFolderName,
      updateMetadata,
    )
  }
}

export default EditContractForm
