import { useContext } from 'react'

import { StoreContext } from '@contexts/StoreContext'
import { DocumentVersionsResponse, storageToDocumentVersion } from '@modules/DocumentVersion'
import ApiClient from '@modules/ApiClient'
import { VersionsContext } from '@contexts/VersionsContext'

export default function useVersions(documentId: string) {
  const { routes, storeSessionInfo } = useContext(StoreContext)
  const apiClient = new ApiClient(storeSessionInfo)
  const {
    setVersions,
    versions,
    documentName,
    setDocumentName,
    documentAccess,
    setDocumentAccess,
    loading,
    setLoading,
    versionsPreloaded,
    setVersionsPreloaded,
    warning,
    deleteModalHidden,
    setDeleteModalHidden,
    selectedVersion,
    setSelectedVersion,
    selectedForCompareOriginal,
    setSelectedForCompareOriginal,
    selectedForCompareModified,
    setSelectedForCompareModified,
    loadingCompare,
    setLoadingCompare,
    loadingCompareError,
    setLoadingCompareError,
  } = useContext(VersionsContext)

  async function loadDocumentVersions() {
    const url = `${routes.contractUrl}/${documentId}/versions`

    setLoading(true)

    try {
      const {
        data: { versions },
      } = await apiClient.get<DocumentVersionsResponse>(url)
      const documentVersions = versions.map(storageToDocumentVersion)
      setVersions(documentVersions)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return {
    loadDocumentVersions,
    versions,
    setVersions,
    documentName,
    setDocumentName,
    documentAccess,
    setDocumentAccess,
    loading,
    versionsPreloaded,
    setVersionsPreloaded,
    warning,
    deleteModalHidden,
    setDeleteModalHidden,
    selectedVersion,
    setSelectedVersion,
    selectedForCompareOriginal,
    setSelectedForCompareOriginal,
    selectedForCompareModified,
    setSelectedForCompareModified,
    loadingCompare,
    setLoadingCompare,
    loadingCompareError,
    setLoadingCompareError,
  }
}
