import React from 'react'
import { IMessageBarStyles, MessageBar, MessageBarType } from '@fluentui/react'

import BoldText from '@baseComponents/BoldText'

interface Props {
  message: string
  importantInfo?: string
  type: MessageBarType
}

const WarningMessage = (props: Props) => {
  const { message, importantInfo, type } = props

  const styles: IMessageBarStyles = {
    innerText: {
      fontSize: '0.9rem',
      padding: '0.2em',
    },
    icon: {
      fontSize: '1.2em',
    },
  }

  return (
    <MessageBar messageBarType={type} styles={styles}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{message}</span>
        <BoldText style={{ marginTop: '1em', fontSize: '0.9rem' }}>{importantInfo}</BoldText>
      </div>
    </MessageBar>
  )
}

export default WarningMessage
