import { useContext, useState } from 'react'
import { Text, Stack, Label, MessageBarType, ActionButton } from '@fluentui/react'

import Contract, { contractWorkflow } from '@modules/Contract'
import ConfirmDialog from '@components/ConfirmDialog'
import BoldText from '@baseComponents/BoldText'
import ContractStatusMenu from '@components/ContractStatusMenu'
import WarningMessage from './WarningMessage'
import ErrorMessage from '@components/ErrorMessage'
import { StoreContext } from '@contexts/StoreContext'

type Props = {
  contract?: Contract
  updating: boolean
  closed: boolean
  toggleHidden: () => void
  onStatusChange: (selectedStatus: string) => Promise<void>
  error: string | null
  setError: React.Dispatch<React.SetStateAction<string | null>>
  loading: boolean
}

const ContractStatus: React.FC<Props> = (props: Props) => {
  const { contract, updating, closed, toggleHidden, onStatusChange, error, setError, loading } =
    props
  const [selectedStatusIdx, setSelectedStatusIdx] = useState(contract?.statusIdx ?? '')
  const [isWarning, setIsWarning] = useState(false)
  const { access } = useContext(StoreContext)

  if (!contract) return null

  const label = updating ? 'Updating...' : 'Change Status'
  const currentWorkflow = contractWorkflow(contract.statusIdx)

  const submit = () => {
    const selectedWorkflow = contractWorkflow(selectedStatusIdx)

    if (currentWorkflow !== selectedWorkflow && !isWarning) {
      setIsWarning(true)
    } else {
      selectedStatusIdx && onStatusChange(selectedStatusIdx)
      setIsWarning(false)
    }
  }

  const dismiss = () => {
    if (isWarning) {
      setIsWarning(false)
    } else {
      toggleHidden()
      setError(null)
    }
    setSelectedStatusIdx('')
  }

  const warningDisplay = (currentWorkflow: string) => {
    let message
    let importantInfo
    let type

    if (currentWorkflow === 'DRAFT') {
      message =
        'Once you change the status of this contract to Finalized, clauses will be added to Clause library and terms will be extracted.'
      importantInfo = 'Your currently saved terms will not be overwritten.'
      type = MessageBarType.info
    } else {
      message =
        'Reverting this contract to a draft status will cause its clauses to be removed from your clause library. They will be added back when the contract changes to a finalized status.'
      type = MessageBarType.severeWarning
    }

    return <WarningMessage message={message} importantInfo={importantInfo} type={type} />
  }

  const contractStatusContent = () => {
    if (isWarning && currentWorkflow) return warningDisplay(currentWorkflow)

    return (
      <>
        {error && <ErrorMessage message={error} />}
        <Stack style={{ justifyContent: 'space-between', margin: '1em 0' }}>
          <BoldText style={{ paddingBottom: '0.2em' }}>Current Status</BoldText>
          <Text>{contract.status}</Text>
        </Stack>
        <Stack style={{ justifyContent: 'space-between' }}>
          <BoldText style={{ paddingBottom: '0.2em' }}>New Contract Status</BoldText>
          <ContractStatusMenu
            setSelectedStatusIdx={setSelectedStatusIdx}
            contractStatusIdx={selectedStatusIdx}
            disabled={updating}
          />
        </Stack>
      </>
    )
  }

  return (
    <Stack
      horizontal
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '40px',
        display: loading ? 'none' : 'flex',
      }}
    >
      <span style={{ flexGrow: 2, paddingLeft: '0.3em', marginLeft: '0.3em' }}>
        <Label style={{ display: 'inline' }}>Status:&nbsp;</Label>
        {contract.status}
      </span>

      <ActionButton
        style={{
          display: access.canEditContractStatus(contract.accessControls) ? 'block' : 'none',
        }}
        disabled={updating}
        onClick={toggleHidden}
        iconProps={{ iconName: 'Edit' }}
      >
        {label}
      </ActionButton>

      <ConfirmDialog
        title="Change Contract Status"
        hidden={closed}
        disableBtn={updating || !selectedStatusIdx || selectedStatusIdx === contract.statusIdx}
        confirm={label}
        onConfirm={submit}
        onDismiss={dismiss}
      >
        {contractStatusContent()}
      </ConfirmDialog>
    </Stack>
  )
}

export default ContractStatus
