import { CSSProperties, useContext, useEffect } from 'react'

import { AnalysisToolsContext } from '@contexts/AnalysisToolsContext'
import { SectionIssuesContext } from '@contexts/SectionIssuesContext'
import ErrorMessage from '@components/ErrorMessage'
import QuickMessage from '@components/QuickMessage'
import SectionIssuesContent from '@components/SectionIssuesContent'
import { useTranslation } from '@hooks/useTranslation'

export const stackStyles: CSSProperties = {
  marginBottom: '0.7em',
  width: '100%',
}

export default function SectionIssuesActions() {
  const { setAnalysisToolTrigger } = useContext(AnalysisToolsContext)
  const { loading, numIssues, callLoadIssues, error } = useContext(SectionIssuesContext)
  const showMessage = !loading && numIssues === undefined
  const showNoIssues = !loading && numIssues === 0
  const { t } = useTranslation()
  const title = t('nav.Section Numbering')

  useEffect(() => {
    if (!numIssues) enable().catch(e => console.error(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <QuickMessage
        msg={`We are unable to process this document to identify ${title.toLowerCase()}`}
        show={showMessage}
        type="info"
        style={{ marginBottom: '1em' }}
      />

      <QuickMessage
        msg={t('page.SectionNumbering.No Issues Message')}
        show={showNoIssues}
        type="info"
        style={{ marginBottom: '1em' }}
      />

      <SectionIssuesContent />

      <ErrorMessage message={error} />
    </>
  )

  async function enable() {
    setAnalysisToolTrigger('srf', callLoadIssues)
    await callLoadIssues()
  }
}
