import { MutableRefObject, useRef } from 'react'

export type TopOfPageRef = MutableRefObject<null | HTMLDivElement>

export default function useScrollToTop(props?: ScrollIntoViewOptions) {
  const topOfPageRef = useRef<null | HTMLDivElement>(null)
  const scrollToTop = () => {
    topOfPageRef.current?.scrollIntoView({ behavior: 'smooth', ...props })
  }

  return { topOfPageRef, scrollToTop }
}
