import { CSSProperties, PropsWithChildren } from 'react'
import { Stack } from '@fluentui/react'

import Box from '@baseComponents/Box'

interface Props extends PropsWithChildren {
  header?: JSX.Element
  footer: JSX.Element
  style?: CSSProperties
}

const defaultStyle: CSSProperties = {
  marginBottom: '0.3em',
}

const IssueCard: React.FC<Props> = ({ header, children, footer, style }: Props) => {
  return (
    <Box style={{ ...defaultStyle, ...style }}>
      <Stack>
        {header}
        <div style={{ paddingBottom: '0.7em' }}>{children}</div>
        {footer}
      </Stack>
    </Box>
  )
}

export default IssueCard
