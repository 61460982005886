import * as React from 'react'
import {
  IProgressIndicatorProps,
  IProgressIndicatorStyleProps,
  IProgressIndicatorStyles,
  IStyleFunctionOrObject,
  ProgressIndicator,
} from '@fluentui/react'

type Props = IProgressIndicatorProps & {
  message: string
  percentComplete: number
  styles?: IStyleFunctionOrObject<IProgressIndicatorStyleProps, IProgressIndicatorStyles>
}

const UploadProgress: React.FC<Props> = ({ message, percentComplete, styles, ...rest }) => {
  const value = Math.ceil(percentComplete * 100)
  const label = `${message} ${value}%`
  const mergedStyle = { root: { width: '100%' }, ...styles }

  return (
    <ProgressIndicator
      styles={mergedStyle}
      label={label}
      percentComplete={percentComplete}
      {...rest}
    />
  )
}

export default UploadProgress
