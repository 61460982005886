import * as React from 'react'
import { Label } from '@fluentui/react'

type Props = {
  label: string
}

const ResourceListHeadings: React.FC<Props> = ({ label }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Label>{label}</Label>
    </div>
  )
}

export default ResourceListHeadings
