import { MIMETYPE_DOCX } from '@blaw/contracts-api-schema'

export interface StorageDocumentVersion {
  date: string
  link?: string
  mimeType: string
  owner?: string
  version: string
  description: string
}

export interface DocumentVersion extends StorageDocumentVersion {
  versionNumber?: number
  active: boolean
  viewing: boolean
  latest: boolean
  key: string
}

export const storageToDocumentVersion = (resource: StorageDocumentVersion): DocumentVersion => {
  return {
    ...resource,
    active: false,
    viewing: false,
    latest: false,
    key: resource.version,
  }
}

export const transformVersionToDocVersion = (
  version: string,
  date: string,
  description = '',
): DocumentVersion => {
  return {
    date,
    mimeType: MIMETYPE_DOCX,
    version,
    description,
    active: false,
    viewing: false,
    latest: true,
    key: version,
  }
}

export type DocumentVersionsResponse = {
  versions: StorageDocumentVersion[]
}
