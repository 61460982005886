import { MessageBar, MessageBarType, Text } from '@fluentui/react'
import ConfirmDialog from '@components/ConfirmDialog'
import { PlaybooksContext } from '@contexts/PlaybooksContext'
import { useContext } from 'react'
import { deleteBtnStyles } from '@modules/sharedStyles'
interface Props {
  id: string
  name: string
}

export default function DeletePlaybookForm({ id, name }: Props) {
  const { error, deleteModalHidden, deleting, setDeleteModalHidden, deleteSelectedPlaybook } =
    useContext(PlaybooksContext)

  return (
    <ConfirmDialog
      title="Confirm Delete Playbook"
      confirm="Delete"
      hidden={deleteModalHidden}
      disableBtn={deleting}
      onConfirm={() => deleteSelectedPlaybook(id)}
      onDismiss={() => setDeleteModalHidden(true)}
      confirmBtnTitle="Delete Playbook"
      btnStyles={deleteBtnStyles}
    >
      <MessageBar messageBarType={MessageBarType.warning}>
        Once you delete a playbook from the system, you cannot retrieve it.
      </MessageBar>
      <Text style={{ display: 'block', marginTop: '1em' }}>
        Please confirm you want to delete the playbook: {name}.
      </Text>
      {error && (
        <MessageBar styles={{ root: { margin: '1em 0' } }} messageBarType={MessageBarType.error}>
          {error}
        </MessageBar>
      )}
    </ConfirmDialog>
  )
}
