import { SharedColors } from '@fluentui/react'

export function accessDisplayData(isPrivate: boolean) {
  return isPrivate
    ? {
        iconName: 'Lock',
        iconColor: SharedColors.red10,
        label: 'Private (Only You)',
      }
    : {
        iconName: 'Unlock',
        iconColor: SharedColors.green10,
        label: 'Public',
      }
}
