import { PrimaryButton, Stack } from '@fluentui/react'
import { SharedColors } from '@fluentui/theme'
import DocumentVersionsList from '@components/DocumentVersionsList'
import LoadingShimmer from '@components/LoadingShimmer'
import StyledStack from '@components/StyledStack'
import useVersions from '@hooks/useVersions'
import VersionWarning from './VersionWarning'
import getCompareVersions from '@modules/CompareVersions'
import StatusBadge from '@components/StatusBadge'
import { StyledDivider } from '@baseComponents/StyledDivider'

interface Props {
  contractId: string
  documentId: string
  versionId: string
  showDeleteBtn: boolean
}

export default function DocumentVersionsContent(props: Props) {
  const { contractId, documentId, versionId, showDeleteBtn } = props
  const {
    versions,
    documentName,
    loading,
    warning,
    selectedForCompareOriginal,
    selectedForCompareModified,
    setLoadingCompare,
    setLoadingCompareError,
  } = useVersions(documentId)
  if (loading)
    return (
      <StyledStack>
        <LoadingShimmer />
      </StyledStack>
    )

  const getCompareVersionsBadge = () => {
    let originalBadge, modifiedBadge
    if (selectedForCompareOriginal !== undefined) {
      originalBadge = (
        <StatusBadge
          status={'Version ' + selectedForCompareOriginal.versionNumber}
          badgeColor={SharedColors.magenta10}
          style={{ margin: '0.3em 0' }}
        ></StatusBadge>
      )
    }
    if (selectedForCompareModified !== undefined) {
      modifiedBadge = (
        <StatusBadge
          status={'Version ' + selectedForCompareModified.versionNumber}
          badgeColor={SharedColors.magenta10}
          style={{ margin: '0.3em 0.5em' }}
        ></StatusBadge>
      )
    }
    if (selectedForCompareOriginal || selectedForCompareModified) {
      return (
        <div>
          <div>Selected Versions:</div>
          <div style={{ display: 'flex' }}>
            {originalBadge}
            {modifiedBadge}
          </div>
        </div>
      )
    } else {
      return <div style={{ margin: '1em 0 0 0' }}>No Versions Selected</div>
    }
  }

  async function triggerCompare() {
    setLoadingCompare(true)
    setLoadingCompareError('')
    if (selectedForCompareOriginal && selectedForCompareModified) {
      let olderVersion, newerVersion
      if (
        selectedForCompareOriginal.versionNumber &&
        selectedForCompareModified.versionNumber &&
        selectedForCompareOriginal.versionNumber < selectedForCompareModified.versionNumber
      ) {
        olderVersion = selectedForCompareOriginal.version
        newerVersion = selectedForCompareModified.version
      } else {
        olderVersion = selectedForCompareModified.version
        newerVersion = selectedForCompareOriginal.version
      }
      try {
        await getCompareVersions(
          contractId,
          documentId,
          olderVersion,
          contractId,
          documentId,
          newerVersion,
          'docx',
        )
      } catch (e: any) {
        console.error(e)
        setLoadingCompareError((e as Error).message)
      } finally {
        setLoadingCompare(false)
      }
    }
  }

  const getCompareVersionsAnchoredPanel = () => {
    return (
      <div style={{ position: 'fixed', bottom: '0', backgroundColor: 'white', width: '100%' }}>
        <StyledDivider></StyledDivider>
        <h2
          style={{
            margin: '0.5em 0 0 0.417em',
            fontSize: '1.2em',
            fontWeight: 600,
          }}
        >
          Compare Two Versions of this File
        </h2>
        <Stack horizontal horizontalAlign="space-between">
          <div style={{ margin: '0.25em 0 0 0.5em' }}>{getCompareVersionsBadge()}</div>
          <PrimaryButton
            onClick={e => {
              triggerCompare()
            }}
            disabled={!selectedForCompareOriginal || !selectedForCompareModified}
            style={{ margin: '0.5em 0.5em 0.5em 0', height: '3em' }}
          >
            Compare Versions
          </PrimaryButton>
        </Stack>
      </div>
    )
  }

  return (
    <div>
      <StyledStack>
        <VersionWarning warning={warning} />
        <h2
          style={{
            margin: '0px 0.6em 0.6em 0.2em',
            fontSize: '1.2em',
            fontWeight: 600,
          }}
        >
          {documentName}
        </h2>
        <Stack.Item style={{ margin: '0 0 8em 0' }}>
          <DocumentVersionsList
            contractId={contractId}
            documentId={documentId}
            versionId={versionId}
            showDeleteBtn={showDeleteBtn}
          />
        </Stack.Item>
      </StyledStack>
      {versions.length > 1 ? getCompareVersionsAnchoredPanel() : null}
    </div>
  )
}
