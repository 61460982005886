import { CSSProperties, useState } from 'react'
import { Stack, DefaultEffects } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'

import {
  CustomFieldValueType,
  type CustomFieldValueNames,
  type FieldGroupProps,
  CustomFieldValueKeys,
} from './ContractCustomFields'
import MultiCombobox from '@components/MultiCombobox'
import DeleteIcon from '@baseComponents/DeleteIcon'
import BoldText from '@baseComponents/BoldText'
import {
  customFieldValueNameToValueType,
  findCustomFieldsByType,
  getCustomFieldKeyByLabel,
  getFieldNamesFromFieldOptions,
  transformMergedValueToCustomFieldState,
} from '@modules/CustomFields'
import NotificationBadge from '@components/NotificationBadge'

const fieldStyle: CSSProperties = {
  margin: '1em 0 0 0',
  padding: '0.7em',
  paddingTop: '0.3em',
  border: '1px solid #DDD',
  borderRadius: '3px',
  boxShadow: DefaultEffects.elevation8,
}

export default function CustomFieldGroup({
  type,
  name,
  fields,
  fieldData,
  disabled,
  onChange,
  Component,
}: FieldGroupProps) {
  const groupLabel = useId(`${name}-label`)
  const populatedFields = fieldData ? findCustomFieldsByType(type, fieldData) : {}
  const fieldNames = Object.keys(populatedFields).length
    ? getFieldNamesFromFieldOptions(fields, populatedFields)
    : []
  const [values, setValues] = useState(fieldNames as CustomFieldValueNames[])
  const options = fields.map(({ label, value }) => ({
    selected: Object.keys(populatedFields).includes(value.toString()),
    key: value,
    text: label,
  }))

  return (
    <Stack.Item>
      <MultiCombobox
        name={type}
        label={name}
        values={values}
        options={options}
        disable={disabled}
        labeledBy={groupLabel}
        renderLabel={renderlabel}
        onRenderValue={renderFieldValue}
        comboBoxProps={{ allowFreeform: false }}
        onJoinValues={(_, values) => setValues(values)}
        onRemoveValue={label => onChange(getCustomFieldKeyByLabel(label, fields), {})}
      />
    </Stack.Item>
  )

  function renderlabel() {
    return (
      <Stack
        horizontal
        style={{ opacity: disabled ? 0.5 : 1 }}
        tokens={{ childrenGap: '0.3em', padding: '0 0 0.3em 0' }}
      >
        <BoldText id={groupLabel}>{name}</BoldText>
        <NotificationBadge style={{ alignSelf: 'end', display: values.length ? 'block' : 'none' }}>
          {values.length}
        </NotificationBadge>
      </Stack>
    )
  }

  function renderFieldValue(
    idx: string,
    value: CustomFieldValueNames,
    removeValue: (i: string, e: any[]) => void,
  ) {
    const fieldKey = getCustomFieldKeyByLabel(value, fields) as CustomFieldValueKeys
    const populatedValue = (populatedFields[fieldKey] ?? '') as CustomFieldValueType
    const fieldState = transformMergedValueToCustomFieldState(type, populatedValue)

    return (
      <div key={value} style={fieldStyle}>
        <Stack
          horizontal
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            opacity: disabled ? 0.5 : 1,
          }}
        >
          <BoldText>{value}</BoldText>
          <DeleteIcon disabled={disabled} onClick={() => removeValue(idx, [])} />
        </Stack>

        <Component
          type={type}
          label={name}
          name={value}
          value={fieldState}
          disabled={disabled}
          prefix={customFieldValueNameToValueType(value, fields)}
          onChange={onChange}
        />
      </div>
    )
  }
}
