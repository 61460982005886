import { useState } from 'react'
import { ActionButton, IButtonStyles, NeutralColors, SharedColors } from '@fluentui/react'

import CopyFromSelectionIcon from './CopyFromSelectionIcon'

interface Props {
  onClick: () => void
  disabled: boolean
}

const CopyFromSelectionBtn: React.FC<Props> = (props: Props) => {
  const { onClick, disabled } = props

  const [isHover, setIsHover] = useState(false)

  const btnStyles: IButtonStyles = {
    label: {
      marginTop: '1.5em',
      color: disabled
        ? SharedColors.gray10
        : isHover
        ? SharedColors.cyanBlue10
        : NeutralColors.black,
    },
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        width: 'fit-content',
        height: '2em',
        cursor: isHover && !disabled ? 'pointer' : 'not-allowed',
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <CopyFromSelectionIcon disabled={disabled} onClick={onClick} />
      <ActionButton
        styles={btnStyles}
        onClick={onClick}
        disabled={disabled}
        title="Copy Highlighted Text"
      >
        Copy Highlighted Text
      </ActionButton>
    </div>
  )
}

export default CopyFromSelectionBtn
