import { useContext, useState } from 'react'

import {
  selectTermInDocument,
  DefinedTerm,
  Reference,
  IndexedLocation,
} from '@src/modules/DocumentDefinitions'
import { tokenizeDefinition } from '@pages/Definitions/DefinitionCard'
import IssueCard from '@components/IssueCard'
import BoldText from '@baseComponents/BoldText'
import { DefinitionsContext } from '@contexts/DefinitionsContext'
import { AnalysisToolsContext } from '@contexts/AnalysisToolsContext'
import UnstyledActionButton from '@baseComponents/UnstyledActionButton'

interface IssueProps {
  defined_term?: DefinedTerm
  issue_type?: string
  firstRef?: Reference
  referenceText?: string
  style?: any
  location?: IndexedLocation[]
  handleSelected?: () => void
}

const TYPES_WITH_NO_CARD_HEADER = ['REDEFINED', 'CAP_WORD_MISSING_DEFN']
const TYPES_WITH_PREV_NEXT_ARROWS = ['REF_NOT_CAP', 'CAP_WORD_MISSING_DEFN']

export default function DefinitionIssueCard(props: IssueProps) {
  const { setNavigateToDefinitions } = useContext(DefinitionsContext)
  const { analysisIsStale } = useContext(AnalysisToolsContext)
  const [refNotCapSelectedIndex, setRefNotCapSelectedIndex] = useState(0)
  const [firstRefClicked, setFirstRefClicked] = useState(false)

  return (
    <IssueCard style={props.style} header={cardHeader()} footer={actionButton()}>
      {cardContent()}
    </IssueCard>
  )

  function cardContent() {
    if (props.referenceText && props.issue_type === 'REF_BEFORE_DEFN' && props.defined_term) {
      return formatFirstRefContent(props.defined_term.term.text, props.referenceText)
    } else if (
      props.referenceText &&
      props.issue_type &&
      TYPES_WITH_PREV_NEXT_ARROWS.includes(props.issue_type)
    ) {
      return (
        <span>
          <BoldText>{props.referenceText}</BoldText>
        </span>
      )
    }

    if (props.defined_term) {
      const { definition, term, referencesInDefinition } = props.defined_term
      return tokenizeDefinition(definition, term, referencesInDefinition).map((token, index) => {
        const { isTerm, text } = token
        const textComp = isTerm ? <BoldText>{text}</BoldText> : text
        return <span key={index}>{textComp}</span>
      })
    }
  }

  function cardHeader() {
    if (!props.issue_type || TYPES_WITH_NO_CARD_HEADER.includes(props.issue_type)) return <></>

    let headerText = 'Definition'

    if (props.issue_type === 'NO_REF' && props.defined_term) {
      headerText = props.defined_term.term.text
    } else if (props.firstRef) {
      headerText = 'First Reference'
    }

    if (props.issue_type === 'REF_NOT_CAP' && props.location) {
      headerText = 'Reference Text'
    }

    return <BoldText style={{ paddingBottom: '0.7em' }}>{headerText}</BoldText>
  }

  function actionButton() {
    let buttonText = 'View in Document'
    const iconName = 'DocumentSearch'

    if (
      props.issue_type &&
      TYPES_WITH_PREV_NEXT_ARROWS.includes(props.issue_type) &&
      props.location
    ) {
      const displayIdx = refNotCapSelectedIndex + 1
      buttonText = 'Issue ' + displayIdx + '/' + props.location.length
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {baseActionButton('NavigateBack', 'Previous', 'row')}
          <span>{buttonText}</span>
          {baseActionButton('NavigateForward', 'Next', 'row-reverse')}
        </div>
      )
    }

    return <div style={{ textAlign: 'right' }}>{baseActionButton(iconName, buttonText, 'row')}</div>
  }

  function baseActionButton(iconName: string, buttonText: string, flexDirection: string) {
    return (
      <UnstyledActionButton
        iconProps={{ iconName: iconName }}
        onClick={() => {
          props.handleSelected && props.handleSelected()
          navigateToTerm(buttonText)
        }}
        styles={{ flexContainer: { flexDirection: flexDirection } }}
        disabled={analysisIsStale}
      >
        {buttonText}
      </UnstyledActionButton>
    )
  }

  function navigateToTerm(buttonText: string) {
    setNavigateToDefinitions(false)
    if (
      props.issue_type &&
      TYPES_WITH_PREV_NEXT_ARROWS.includes(props.issue_type) &&
      props.location
    ) {
      if (!firstRefClicked) {
        setFirstRefClicked(true)
        const term = props.location[refNotCapSelectedIndex]
        selectTermInDocument(term)
        return
      }
      const n = props.location.length
      let newIdx = 0
      if (buttonText === 'Next') {
        newIdx = (refNotCapSelectedIndex + 1) % n
      } else if (buttonText === 'Previous') {
        newIdx = (refNotCapSelectedIndex - 1 + n) % n
      }
      setRefNotCapSelectedIndex(newIdx)
      const term = props.location[newIdx]
      selectTermInDocument(term)
    } else if (props.defined_term) {
      const term = props.firstRef || props.defined_term.term
      selectTermInDocument(term)
    }
  }

  function formatFirstRefContent(term: string, content: string): JSX.Element {
    const [firstHalfContent, secondHalfContent] = content.split(term)
    return (
      <span>
        {firstHalfContent}
        <BoldText>{term}</BoldText>
        {secondHalfContent}
      </span>
    )
  }
}
