import { useEffect, useState } from 'react'

import { GetResourceUploadLink } from '@modules/GetResourceUploadLink'
import { GetWordDocumentContent } from '@modules/GetWordDocumentContent'
import { formatVersionUploadBody, PostVersionToS3, putVersionToApi } from '@modules/PostVersionToS3'

export default function useSaveNewVersion(
  documentName: string,
  resourceId: string,
  documentId: string,
  description?: string,
  fileCategory?: string,
  overwriteVersion?: string,
) {
  const [submitting, setSubmitting] = useState(false)
  const [percentUploaded, setPercentUploaded] = useState(0)
  const [uploadError, setUploadError] = useState('')

  useEffect(() => {
    uploadError &&
      setTimeout(() => {
        setUploadError('')
      }, 10000)
  }, [uploadError])

  async function submitNewVersion() {
    if (!resourceId || !documentId) return

    setSubmitting(true)

    try {
      let link
      try {
        link = await GetResourceUploadLink()
      } catch (e) {
        handleFailure(e)
        throw Error('failed to get s3 upload link')
      }
      setPercentUploaded(20)

      const docData = await GetWordDocumentContent()
      setPercentUploaded(40)

      let s3Key
      try {
        s3Key = await PostVersionToS3(link, docData, s3ProgressToPercentUploaded)
      } catch (e: any) {
        handleFailure(e)
        throw Error('failed to post version to s3')
      }

      const body = formatVersionUploadBody(
        s3Key,
        documentName,
        resourceId,
        description,
        fileCategory,
        overwriteVersion,
      )
      return await putVersionToApi(documentId, body)
    } catch (e: any) {
      handleFailure(e)
    }
  }

  function s3ProgressToPercentUploaded(progressEvent: any) {
    // multiply progressEvent loading percentage by 0.2 because s3Upload is one of five API calls.
    const updatedPercent = 40 + 0.2 * Math.round((progressEvent.loaded * 100) / progressEvent.total)
    setPercentUploaded(updatedPercent)
  }

  function handleFailure(e: any) {
    setPercentUploaded(0)
    setUploadError('Could not save. Please try again later.')
    setSubmitting(false)
    console.error(e)
  }

  return {
    submitNewVersion,
    submitting,
    percentUploaded,
    setPercentUploaded,
    setSubmitting,
    uploadError,
    setUploadError,
  }
}
