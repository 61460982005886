import { CSSProperties, PropsWithChildren } from 'react'
import { FontWeights, ITextProps, Text } from '@fluentui/react'

type Props = PropsWithChildren &
  ITextProps & {
    style?: CSSProperties
  }

export default function BoldText(props: Props) {
  const { style } = props
  return (
    <Text {...props} style={{ fontWeight: FontWeights.semibold, ...style }}>
      {props.children}
    </Text>
  )
}
