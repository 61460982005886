import ApiClient from './ApiClient'
import { ResourceUploadLink, S3Fields } from './GetResourceUploadLink'
import { AxiosProgressEvent } from 'axios'

const apiClient = new ApiClient()

export async function PutDocumentToS3(
  signedS3LinkData: ResourceUploadLink,
  file: any,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
) {
  const config = {
    onUploadProgress,
  }
  return await apiClient.publicPut(signedS3LinkData.url, file, config)
}
