import {
  useState,
  useEffect,
  createContext,
  type FC,
  type PropsWithChildren,
  type Dispatch,
  type SetStateAction,
} from 'react'

import { type AccessControls } from '@blaw/contracts-api-schema'
import { DocumentVersion } from '@modules/DocumentVersion'
import { useDataInjection } from '@hooks/useDataInjection'

type Props = PropsWithChildren

interface VersionsContextState {
  versions: DocumentVersion[]
  setVersions: Dispatch<SetStateAction<DocumentVersion[]>>
  documentName: string
  setDocumentName: Dispatch<SetStateAction<string>>
  documentAccess: AccessControls | undefined
  setDocumentAccess: Dispatch<SetStateAction<AccessControls | undefined>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  versionsPreloaded: boolean
  setVersionsPreloaded: Dispatch<SetStateAction<boolean>>
  warning: boolean
  deleteModalHidden: boolean
  setDeleteModalHidden: Dispatch<SetStateAction<boolean>>
  selectedVersion: DocumentVersion | undefined
  setSelectedVersion: Dispatch<SetStateAction<DocumentVersion | undefined>>
  selectedForCompareOriginal: DocumentVersion | undefined
  setSelectedForCompareOriginal: Dispatch<SetStateAction<DocumentVersion | undefined>>
  selectedForCompareModified: DocumentVersion | undefined
  setSelectedForCompareModified: Dispatch<SetStateAction<DocumentVersion | undefined>>
  loadingCompare: boolean
  setLoadingCompare: Dispatch<SetStateAction<boolean>>
  loadingCompareError: string
  setLoadingCompareError: Dispatch<SetStateAction<string>>
}

export const VersionsContext = createContext({} as VersionsContextState)

const VersionsContextProvider: FC<Props> = (props: Props) => {
  const { versionId } = useDataInjection()

  const [versions, setVersions] = useState<DocumentVersion[]>([])
  const [documentName, setDocumentName] = useState('')
  const [documentAccess, setDocumentAccess] = useState<AccessControls>()
  const [loading, setLoading] = useState(true)
  const [versionsPreloaded, setVersionsPreloaded] = useState(false)
  const [warning, setWarning] = useState(false)
  const [deleteModalHidden, setDeleteModalHidden] = useState(true)
  const [selectedVersion, setSelectedVersion] = useState<DocumentVersion>()
  const [selectedForCompareOriginal, setSelectedForCompareOriginal] = useState<DocumentVersion>()
  const [selectedForCompareModified, setSelectedForCompareModified] = useState<DocumentVersion>()
  const [loadingCompare, setLoadingCompare] = useState(false)
  const [loadingCompareError, setLoadingCompareError] = useState('')

  useEffect(() => {
    setWarning(versions[0]?.version !== versionId)
  }, [versions, versionId])

  const value = {
    versions,
    setVersions,
    documentName,
    setDocumentName,
    documentAccess,
    setDocumentAccess,
    loading,
    setLoading,
    versionsPreloaded,
    setVersionsPreloaded,
    warning,
    deleteModalHidden,
    setDeleteModalHidden,
    selectedVersion,
    setSelectedVersion,
    selectedForCompareOriginal,
    setSelectedForCompareOriginal,
    selectedForCompareModified,
    setSelectedForCompareModified,
    loadingCompare,
    setLoadingCompare,
    loadingCompareError,
    setLoadingCompareError,
  }

  return <VersionsContext.Provider value={value}>{props.children}</VersionsContext.Provider>
}

export default VersionsContextProvider
