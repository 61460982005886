import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import axiosInstance from './axiosInstance'
import Routes from './routes'
import { StoreSessionInfo } from '@contexts/StoreContext'
import Login, { SetError } from '@components/Login'
import { v4 as uuid } from 'uuid'
import DetectLoginLoop from './DetectLoginLoop'

const REQUEST_ID_KEY = 'x-bb-request-id'
const routes = new Routes()
export const NoSession = 'Session Expired. Prompting for login.'

// Use this class for all HTTP requests.
export default class ApiClient {
  storeSessionInfo?: StoreSessionInfo
  setError?: SetError
  error?: string
  checkingSession?: boolean
  http: AxiosInstance

  constructor(storeSessionInfo?: StoreSessionInfo, setError?: SetError, checkingSession?: boolean) {
    this.storeSessionInfo = storeSessionInfo
    this.setError = setError
    this.checkingSession = checkingSession
    this.http = axiosInstance
  }

  async get<T>(url: string, config: AxiosRequestConfig = {}) {
    return await this.#withRefresh<T>(async () => {
      config.headers = this.#headers(config.headers)
      return await this.http.get<T>(url, config)
    })
  }

  async publicGet<T>(url: string, config: AxiosRequestConfig = {}) {
    return this.http.get<T>(url, { withCredentials: false, ...config })
  }

  async publicPost<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.http.post<T>(url, data, { withCredentials: false, ...config })
  }

  async publicPut<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.http.put<T>(url, data, { withCredentials: false, ...config })
  }

  async post<T>(url: string, data?: any, config: AxiosRequestConfig = {}) {
    return await this.#withRefresh<T>(async () => {
      config.headers = this.#headers(config.headers)
      return await this.http.post<T>(url, data, config)
    })
  }

  async put<T>(url: string, data: any, config: AxiosRequestConfig = {}) {
    return await this.#withRefresh<T>(async () => {
      config.headers = this.#headers(config.headers)
      return await this.http.put<T>(url, data, config)
    })
  }

  async patch<T>(url: string, data: any, config: AxiosRequestConfig = {}) {
    return await this.#withRefresh<T>(async () => {
      config.headers = this.#headers(config.headers)
      return await this.http.patch<T>(url, data, config)
    })
  }

  async delete<T>(url: string, config: AxiosRequestConfig = {}) {
    return await this.#withRefresh<T>(async () => {
      config.headers = this.#headers(config.headers)
      return await this.http.delete<T>(url, config)
    })
  }

  async getBinaryContent<T>(url: string) {
    return this.http.get<T>(url, {
      withCredentials: false,
      responseType: 'arraybuffer',
      responseEncoding: 'binary',
    })
  }

  async #withRefresh<T>(request: () => Promise<AxiosResponse<T>>) {
    try {
      return await request()
    } catch (e) {
      if (!this.#isSessionError((e as Error).message)) throw e

      await DetectLoginLoop()
      await this.refresh()
      return await request()
    }
  }

  async refresh(error = false): Promise<boolean> {
    try {
      await this.http.post(routes.refreshUrl)
      return true
    } catch (e) {
      if (this.storeSessionInfo) Login(this.storeSessionInfo, this.setError, this.checkingSession)
      if (error) throw e

      return false
    }
  }

  async isLoggedIn() {
    try {
      await this.http.post(routes.loggedInUrl)
      return true
    } catch (e) {
      const err = new Error('Login check failed')
      err.stack = (e as Error).stack
      console.error(err)
      return false
    }
  }

  #isSessionError(e: string) {
    return e === NoSession || (e.includes('status') && (e.includes('401') || e.includes('403')))
  }

  #headers(requestHeaders: any = {}) {
    return { ...requestHeaders, [REQUEST_ID_KEY]: 'addin' + uuid() }
  }
}
