import React, { CSSProperties } from 'react'
import { BaseButton, SharedColors } from '@fluentui/react'

import LinkButton from '@components/LinkButton'

type Props = {
  onClick: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement | BaseButton>
  moreShown: boolean
  hide?: boolean
  style?: CSSProperties
}

export default function ShowMoreButton({ onClick, moreShown, hide, style }: Props) {
  if (hide) return null

  return (
    <LinkButton
      onClick={onClick}
      tabIndex={0}
      style={{
        color: SharedColors.cyanBlue10,
        cursor: 'pointer',
        ...style,
      }}
    >
      {moreShown ? 'Show less' : 'Show more'}
    </LinkButton>
  )
}
