// DialogViews require a corresponding React component to be registered as a top
// level route in `App.tsx`.

import { hardReloadPage } from './utils'

const IGNORE_DIALOG_ERROR = 12009
const CLOSE_DIALOG_ERROR = 12006

interface DialogOptions extends Office.DialogOptions {
  baseUrl?: string
}

type DialogMessageHandler = (msg: string, dialog: Office.Dialog) => void

class Dialog {
  options: DialogOptions

  constructor(options: DialogOptions = {}) {
    this.options = {
      displayInIframe: true,
      promptBeforeOpen: true,
      ...options,
    }
  }

  open(view: string, width: number, height: number, msgHandler: DialogMessageHandler) {
    const base = this.options.baseUrl || ''
    const url = `${base}/index.html#${view}`
    this.openExternal(url, width, height, msgHandler)
  }

  openExternal(url: string, width: number, height: number, msgHandler: DialogMessageHandler) {
    const options = { ...this.options, width, height }
    console.debug('Dialog open', { url, options })

    Office.context.ui.displayDialogAsync(url, options, ({ status, value: dialog, error }) => {
      if (status !== Office.AsyncResultStatus.Succeeded) {
        if (error.code === IGNORE_DIALOG_ERROR) return hardReloadPage('/index.html')
        return console.error(`Dialog ${status} "${url}"`, { dialog, error })
      }

      try {
        // @ts-ignore
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, ({ message }) => {
          if (message === 'close') return dialog.close()

          msgHandler(message as string, dialog)
        })

        dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg: any) => {
          switch (arg.error) {
            case CLOSE_DIALOG_ERROR:
              hardReloadPage('/index.html')
              break
            default:
              console.error(arg)
          }
        })
      } catch (e) {
        console.error(e)
      }
    })
  }
}

export default Dialog
